/** @format */

import React, { useEffect } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as styles from './index.css';
import { ReduxState } from 'reducers/rootReducer';
import { BOARD_PATH_INFO, createBoardGoBack, createBoardWizard } from 'actions/createBoardActions';
import { Button } from 'components/ds/Button/Button';
import { times } from 'lodash';

type Props = {
  children: JSX.Element;
  onNextClicked: () => void;
  nextDisabled?: boolean;
};

const WizardStep = ({ children, onNextClicked, nextDisabled }: Props) => {
  const dispatch = useDispatch();

  const { boardConfig, stepNum, wizardComplete } = useSelector(
    (state: ReduxState) => ({
      boardConfig: state.createBoardReducer.boardConfig,
      step: state.createBoardReducer.step,
      stepNum: state.createBoardReducer.stepNum,
      wizardComplete: state.createBoardReducer.wizardComplete,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (wizardComplete && boardConfig) {
      dispatch(
        createBoardWizard(
          {
            postData: {
              boardConfig: boardConfig,
            },
          },
          (data) => {
            window.location.href = `/board/${data.board.id}`;
          },
        ),
      );
    }
  }, [wizardComplete]);

  if (!boardConfig || stepNum === undefined) return <div>Error: no path selected</div>;

  const numStepsTotal = BOARD_PATH_INFO[boardConfig.startPath].steps.length;

  return (
    <div className={styles.root}>
      <div className={styles.stepTrackerContainer}>
        {times(numStepsTotal).map((n) => (
          <div
            className={cx(styles.stepTracker, { completed: n <= stepNum })}
            key={`wizard-stepstracker-${n}`}></div>
        ))}
      </div>
      <div className={styles.bodyContainer}>{children}</div>
      <div className={styles.footerContainer}>
        <Button icon="left-arrow" type="outline" onClick={() => dispatch(createBoardGoBack())} />
        <Button
          text="Next"
          icon="right-arrow"
          type="primary"
          disabled={nextDisabled}
          onClick={onNextClicked}
        />
      </div>
    </div>
  );
};

export default WizardStep;
