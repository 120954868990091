/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import * as styles from './index.css';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';
import { User } from 'auth/types';
import { fetchCareerSearch } from 'actions/careerActions';
import { fetchProgramSearch } from 'actions/programsActions';
import CareerCard from 'components/HomePage/CareerCard/CareerCard';
import { ANALYTIC_PAGES, page } from 'analytics';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import ProgramCard from 'components/HomePage/ProgramCard/ProgramCard';
import { Button } from 'components/ds/Button/Button';

type Props = {
  currentUser: User;
};

const SavedPage = ({ currentUser }: Props) => {
  const dispatch = useDispatch();
  const [showSaved, setShowSaved] = useState<'careers' | 'programs'>('careers');

  const { savedCareers, loadingSavedCareers, savedPrograms, loadingSavedPrograms } = useSelector(
    (state: ReduxState) => ({
      savedCareers: state.careersSearch.careers,
      loadingSavedCareers: createLoadingSelector([ACTION.CAREER_SEARCH], true)(state),
      savedPrograms: state.programsSearch.programs,
      loadingSavedPrograms: createLoadingSelector([ACTION.PROGRAM_SEARCH], true)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      fetchCareerSearch({
        getData: {
          saved: 'true',
          page: '0',
          results_per_page: '100',
        },
      }),
    );
    dispatch(
      fetchProgramSearch({
        getData: {
          saved: 'true',
          page: '0',
          results_per_page: '100',
        },
      }),
    );
    page(ANALYTIC_PAGES.SAVED_PAGE);
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.pageBody}>
        <div className={styles.headerSection}>
          <div className={styles.savedText}>Saved</div>
          <div className={styles.savedToggle}>
            <Button
              text={loadingSavedCareers ? 'Careers' : `Careers (${savedCareers.length})`}
              type={showSaved === 'careers' ? 'primary' : 'ghost'}
              size="large"
              onClick={() => setShowSaved('careers')}
            />

            <Button
              text={loadingSavedPrograms ? 'Programs' : `Programs (${savedPrograms.length})`}
              type={showSaved === 'programs' ? 'primary' : 'ghost'}
              size="large"
              onClick={() => setShowSaved('programs')}
            />
          </div>
        </div>
        <div className={styles.careerResults}>
          {showSaved === 'careers' && loadingSavedCareers ? <LoadingSpinner /> : null}
          {showSaved === 'programs' && loadingSavedPrograms ? <LoadingSpinner /> : null}
          {showSaved === 'careers' &&
            savedCareers.map((career) => (
              <CareerCard
                key={`career-saved-${career.id}`}
                hideVideo
                className={styles.result}
                career={career}
                location="Saved Page"
              />
            ))}
          {showSaved === 'careers' && !loadingSavedCareers && savedCareers.length == 0 && (
            <div>
              <div className={styles.noResultsText}>You don't have any saved careers yet!</div>
              <Button text="Explore Careers" type="primary" to="/careers" />
            </div>
          )}
          {showSaved === 'programs' &&
            savedPrograms.map((program) => (
              <ProgramCard
                key={`program-saved-${program.id}`}
                className={styles.result}
                program={program}
                location="Saved Page"
              />
            ))}
          {showSaved === 'programs' && !loadingSavedPrograms && savedPrograms.length == 0 && (
            <div>
              <div className={styles.noResultsText}>You don't have any saved programs yet!</div>
              <Button text="Explore Program" type="primary" to="/programs" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavedPage;
