/** @format */

import React from 'react';

import * as styles from './LocationFilter.css';
import { Menu, MenuActionItem } from 'components/ds/Menu/Menu';
import { DropdownButton } from 'components/ds/DropdownButton/DropdownButton';

import { COMP_STATE_OPTIONS, DistanceRangeMiles, STATE_CODE_TO_NAME } from 'constants/data';
import { Button } from 'components/ds/Button/Button';
import { Input } from 'components/ds/Input/Input';
import { LocationConfigOptions } from '../SearchPage';

const state_codes = Object.keys(COMP_STATE_OPTIONS);
state_codes.sort();
const ORDERED_STATE_OPTIONS = state_codes.map((code) => ({
  id: code,
  name: STATE_CODE_TO_NAME[code],
}));

type Props = {
  onApplyFilters: () => void;
  locationConfig: LocationConfigOptions;
  onLocationConfigUpdated: (newConfig: LocationConfigOptions) => void;
  applyRequired?: boolean;
};

const LocationFilter = ({
  locationConfig,
  applyRequired,
  onApplyFilters,
  onLocationConfigUpdated,
}: Props) => {
  const {
    selectedStateCode,
    selectedLocationName,
    locationRange,
    locationSearchBy,
    selectedZipcode,
  } = locationConfig;

  const rangeSelector = (
    <Menu
      align="end"
      side="bottom"
      trigger={
        <DropdownButton
          className={styles.locationDropdownBtn}
          text={locationRange ?? 'Select a Range'}
        />
      }>
      {Object.values(DistanceRangeMiles).map((distanceOption) => (
        <MenuActionItem
          style="program"
          text={distanceOption}
          key={`location-selection-range-${distanceOption}`}
          onSelect={() =>
            onLocationConfigUpdated({
              ...locationConfig,
              locationRange: distanceOption,
            })
          }
          selected={distanceOption === selectedStateCode}
        />
      ))}
    </Menu>
  );

  const stateFilterOptions = (
    <Menu
      align="end"
      side="bottom"
      trigger={
        <DropdownButton
          className={styles.locationDropdownBtn}
          text={selectedStateCode ? STATE_CODE_TO_NAME[selectedStateCode] : 'Select a State'}
        />
      }>
      {ORDERED_STATE_OPTIONS.map((stateOption) => (
        <MenuActionItem
          style="program"
          text={stateOption.name}
          key={`location-selection-state-program-${stateOption.id}`}
          onSelect={() =>
            onLocationConfigUpdated({
              ...locationConfig,
              selectedStateCode: stateOption.id,
              selectedLocationName: undefined,
            })
          }
          selected={stateOption.id === selectedStateCode}
        />
      ))}
    </Menu>
  );

  const regionFilterOptions = (
    <>
      {rangeSelector}
      <Menu
        align="end"
        side="bottom"
        trigger={
          <DropdownButton
            className={styles.locationDropdownBtn}
            text={selectedStateCode ? STATE_CODE_TO_NAME[selectedStateCode] : 'Select a State'}
          />
        }>
        {ORDERED_STATE_OPTIONS.map((stateOption) => (
          <MenuActionItem
            style="program"
            text={stateOption.name}
            key={`location-selection-state-program-${stateOption.id}`}
            onSelect={() =>
              onLocationConfigUpdated({
                ...locationConfig,
                selectedStateCode: stateOption.id,
                selectedLocationName: undefined,
              })
            }
            selected={stateOption.id === selectedStateCode}
          />
        ))}
      </Menu>
      <Menu
        align="end"
        side="bottom"
        disabled={selectedStateCode === undefined}
        trigger={
          <DropdownButton
            disabled={selectedStateCode === undefined}
            className={styles.locationDropdownBtn}
            text={selectedLocationName ?? 'Select a Region'}
          />
        }>
        {selectedStateCode &&
          COMP_STATE_OPTIONS[selectedStateCode].map((msaOption) => (
            <MenuActionItem
              style="program"
              text={msaOption.level === 'state' ? 'Statewide' : msaOption.location}
              key={`location-selection-region-${selectedStateCode}-${msaOption.location}`}
              onSelect={() =>
                onLocationConfigUpdated({
                  ...locationConfig,
                  selectedLocationName: msaOption.location,
                })
              }
              selected={selectedLocationName === msaOption.location}
            />
          ))}
      </Menu>
    </>
  );

  const zipcodeFilterOptions = (
    <>
      {rangeSelector}
      <Input
        className={styles.zipcodeInput}
        value={selectedZipcode || ''}
        onChange={(newZipcode) =>
          onLocationConfigUpdated({
            ...locationConfig,
            selectedZipcode: newZipcode,
          })
        }
        placeholder="Enter Zipcode"
      />
    </>
  );

  let buttonText = 'United States';

  if (locationSearchBy === 'state' && selectedStateCode) {
    buttonText = STATE_CODE_TO_NAME[selectedStateCode];
  } else if (locationSearchBy === 'region' && locationRange && selectedLocationName) {
    buttonText = `${locationRange} of ${selectedLocationName}`;
  } else if (
    locationSearchBy === 'zipcode' &&
    locationRange &&
    selectedZipcode &&
    selectedZipcode.length > 4
  ) {
    buttonText = `${locationRange} of ${selectedZipcode}`;
  }

  return (
    <div className={styles.root}>
      <Menu
        noMaxHeight
        trigger={<DropdownButton text={buttonText} size="large" fullWidth />}
        side="bottom"
        align="start"
        footerConfig={{
          primaryActionBtnText: 'Apply',
          onPrimaryBtnClicked: () => onApplyFilters(),
          primaryBtnDisabled: !applyRequired,
        }}>
        <div className={styles.locationFilterBody}>
          <div className={styles.toggleButtons}>
            <div className={styles.toggleLabel}>Search by</div>
            <Button
              text="State"
              type={locationSearchBy === 'state' ? 'primary' : 'ghost'}
              onClick={() =>
                onLocationConfigUpdated({
                  ...locationConfig,
                  locationSearchBy: 'state',
                })
              }
            />
            <Button
              text="Region"
              type={locationSearchBy === 'region' ? 'primary' : 'ghost'}
              onClick={() =>
                onLocationConfigUpdated({
                  ...locationConfig,
                  locationSearchBy: 'region',
                })
              }
            />
            <Button
              text="Zipcode"
              type={locationSearchBy === 'zipcode' ? 'primary' : 'ghost'}
              onClick={() =>
                onLocationConfigUpdated({
                  ...locationConfig,
                  locationSearchBy: 'zipcode',
                })
              }
            />
          </div>

          {locationSearchBy === 'region' ? regionFilterOptions : null}
          {locationSearchBy === 'state' ? stateFilterOptions : null}
          {locationSearchBy === 'zipcode' ? zipcodeFilterOptions : null}
        </div>
      </Menu>
    </div>
  );
};

export default LocationFilter;
