/** @format */

import { combineReducers, Reducer } from 'redux';
import { ACTION } from 'actions/types';

import authDataReducer from 'reducers/authDataReducer';
import careersSearchReducer from './careersSearchReducer';
import loadingReducer from './loadingReducer';
import careerInfoReducer from './careerInfoReducer';
import programsSearchReducer from './programsSearchReducer';
import programInfoReducer from './programInfoReducer';
import dataCleaningReducer from './dataCleaningReducer';
import interestReducer from './interestReducer';
import boardReducer from './boardReducer';
import boardPageReducer from './boardPageReducer';
import superuserReducer from './superuserReducer';
import createBoardReducer from './createBoardReducer';

const appReducer = combineReducers({
  loading: loadingReducer,
  currentUser: authDataReducer,
  careersSearch: careersSearchReducer,
  careerInfo: careerInfoReducer,
  programsSearch: programsSearchReducer,
  programInfo: programInfoReducer,
  dataCleaningReducer: dataCleaningReducer,
  interestReducer: interestReducer,
  boards: boardReducer,
  boardData: boardPageReducer,
  superuser: superuserReducer,
  createBoardReducer: createBoardReducer,
});

const rootReducer: Reducer<ReduxState, Action> = (state, action) => {
  if (action.type === ACTION.CLEAR_STATE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type ReduxState = ReturnType<typeof appReducer>;

export default rootReducer;

export interface Action {
  type: string;
  payload: any;
}
