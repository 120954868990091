/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as styles from './index.css';
import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { fetchProgramInfo, updateSaveProgram } from 'actions/programsActions';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import { saveProgramBoards } from 'actions/boardActions';
import { ANALYTIC_EVENTS, ANALYTIC_PAGES, page, track } from 'analytics';
import AddToBoardPopover from 'components/AddToBoardPopover';
import { Button } from 'components/ds/Button/Button';
import { convertProgramLengthToMixMaxTime } from 'components/SearchPage/ProgramSearchResult';
import CareerCardSmall from 'components/HomePage/CareerCardSmall/CareerCardSmall';
import ProgramCardSmall from 'components/HomePage/ProgramCardSmall/ProgramCardSmall';
import { Icon } from 'components/ds/Icon/Icon';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type Props = {};

const ProgramPage = ({}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [boardIds, setBoardIds] = useState<number[]>([]);
  const { programId } = useParams<{ programId: string }>();

  const { program, programLoading } = useSelector(
    (state: ReduxState) => ({
      program: state.programInfo.program,
      programLoading: createLoadingSelector([ACTION.PROGRAM_INFO], true)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      fetchProgramInfo({ id: programId }, (data) => {
        let referrer = undefined;
        if (window.location.search) {
          const urlParams = new URLSearchParams(window.location.search);
          const queryParams = Object.fromEntries(urlParams.entries());
          if (queryParams.referrer) {
            referrer = queryParams.referrer;
          }
        }

        page(ANALYTIC_PAGES.PROGRAM_PAGE, undefined, {
          program_id: programId,
          program_name: data.program.name,
          provider_name: data.program.provider_name,
          referrer,
        });
      }),
    );
  }, [dispatch, programId]);

  useEffect(() => {
    program && setBoardIds(program.board_ids);
  }, [program?.board_ids]);

  if (!programLoading && !program) return <div>Error</div>;
  if (programLoading || !program)
    return (
      <div className={styles.loadingPage}>
        <LoadingSpinner />
      </div>
    );

  const costPerYear =
    program.student_cost_per_year !== null
      ? USDollar.format(Math.round(program.student_cost_per_year / 1000) * 1000)
      : '-';

  const costText =
    program.program_length_max && program.program_length_max <= 12 ? 'Cost (Total)' : 'Cost / Yr';

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div>
          <Button
            className={styles.backBtn}
            icon="left-arrow"
            text="Back"
            type="ghost"
            onClick={() => history.goBack()}
          />
        </div>
        <div className={styles.headerLayout}>
          <div>
            <div className={styles.programName}>{program.name}</div>
            <div className={styles.providerName}>
              {program.website_url ? (
                <>
                  <a
                    className={styles.providerNameLink}
                    href={program.website_url}
                    target="_blank"
                    onClick={() => {
                      track(ANALYTIC_EVENTS.PROGRAM_PAGE_WEBSITE_CLICKED, {
                        program_name: program.name,
                        program_id: program.id,
                      });
                    }}>
                    {program.provider_name}
                  </a>
                  <Icon className={styles.providerIconLink} fontSize={18} name="external-arrow" />
                </>
              ) : (
                program.provider_name
              )}
            </div>
            <div className={styles.programInfoContainer}>
              <div className={styles.nuggetContainer}>
                <div className={styles.nuggetHeader}>{costText}</div>
                <div className={styles.nuggetValue}>💸 {costPerYear}</div>
              </div>
              <div className={styles.nuggetContainer}>
                <div className={styles.nuggetHeader}>Learn Time</div>
                <div className={styles.nuggetValue}>
                  📖{' '}
                  {convertProgramLengthToMixMaxTime(
                    program.program_length_max,
                    program.program_length_min,
                  )}
                </div>
              </div>
              <div className={styles.nuggetContainer}>
                <div className={styles.nuggetHeader}>Location</div>
                <div className={styles.nuggetValue}>
                  📍 {program.provider_city}, {program.provider_state}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.actionBtns}>
            <Button
              size="large"
              icon="bookmark"
              type="outline"
              iconFilled={program.is_saved}
              onClick={() => {
                track(ANALYTIC_EVENTS.PROGRAM_SAVED, {
                  location: 'Program Page',
                  program_id: program.id,
                  program_name: program.name,
                  provider_name: program.provider_name,
                  is_saved: !program.is_saved,
                });
                dispatch(
                  updateSaveProgram(
                    { id: program.id, postData: { isSaved: !program.is_saved } },
                    () => {
                      toast(
                        <div>
                          <b>{program.name}</b> {!program.is_saved ? 'saved' : 'unsaved'}!
                        </div>,
                        {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          hideProgressBar: true,
                          autoClose: 2000,
                        },
                      );
                    },
                  ),
                );
              }}
            />
            <AddToBoardPopover
              title="Add to Board"
              selectedBoards={boardIds}
              onSaveClicked={(boardIds) => {
                setBoardIds(boardIds);
                dispatch(
                  saveProgramBoards({ postData: { program_id: program.id, board_ids: boardIds } }),
                );
              }}
              buttonProps={{
                size: 'large',
                icon: 'add-board',
                type: 'primary',
                text: 'Add to Board',
              }}
              menuProps={{
                align: 'end',
              }}
              location="Program Page"
              entityType="Program"
              entityId={program.id}
            />
          </div>
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.sectionHeader}>Related Careers</div>
        <div className={styles.carasoulContainer}>
          {program.careers.map((career) => (
            <CareerCardSmall
              key={`program-page-careers-${career.id}`}
              className={styles.careerCards}
              career={career}
              location="Program Page Careers"
            />
          ))}
        </div>
        <div className={styles.sectionHeader}>Similar Programs</div>
        <div className={styles.carasoulContainer}>
          {program.related_programs.map((program) => (
            <ProgramCardSmall
              key={`program-page-programs-${program.id}`}
              className={styles.programCards}
              program={program}
              location="Program Page Similar Programs"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgramPage;
