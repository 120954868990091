/** @format */

import { createReducer } from '@reduxjs/toolkit';

import {
  InterestData,
  fetchInterestsSuccess,
  updateStudentInterestReactionRequest,
} from 'actions/interestActions';

type State = { [intrest_id: string]: InterestData };

const initialState: State = {};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(fetchInterestsSuccess, (state, { payload }) => {
      payload.interests.forEach((interest) => {
        state[interest.id] = interest;
      });

      return state;
    })
    .addCase(updateStudentInterestReactionRequest, (state, { payload }) => {
      // if career id is present, its coming from career info page so don't do anything
      if (!payload.id || payload.postData.careerId) return state;
      const interest = state[payload.id];
      interest.reaction = payload.postData.reaction ?? null;
      return state;
    })
    .addDefaultCase((state) => state);
});
