/** @format */

import React from 'react';
import cx from 'classnames';
import * as Progress from '@radix-ui/react-progress';

import * as styles from './ProgressBar.css';

type Props = {
  className?: string;
  percentFilled: number;
};

export default ({ className, percentFilled }: Props) => {
  return (
    <Progress.Root className={cx(styles.root, className)} value={percentFilled}>
      <Progress.Indicator
        className={styles.indicator}
        style={{ transform: `translateX(-${100 - percentFilled}%)` }}
      />
    </Progress.Root>
  );
};
