/** @format */

import React from 'react';

import * as styles from './LearnTimeFilter.css';

import { Checkbox } from 'components/ds/Checkbox/Checkbox';

type Props = {
  selectedOptions?: number[];
  updateSelectedOptions: (option: number, isSelected: boolean) => void;
  disabled?: boolean;
};

export const DEGREE_TYPE_MAP: Record<string, string> = {
  [0]: 'Certificate',
  [1]: "Associate's Degree",
  [2]: "Bachelor's Degree",
  [3]: 'Apprenticeship',
};

const DegreeTypeFilter = ({ disabled, selectedOptions, updateSelectedOptions }: Props) => {
  return (
    <div className={styles.root}>
      {Object.keys(DEGREE_TYPE_MAP).map((learnTimeOption) => {
        const isChecked = (selectedOptions || []).indexOf(Number(learnTimeOption)) > -1;
        return (
          <div className={styles.inputContainer} key={`degree-type-filter-${learnTimeOption}`}>
            <div className={styles.option}>
              <Checkbox
                isChecked={isChecked}
                onChange={() => updateSelectedOptions(Number(learnTimeOption), !isChecked)}
                disabled={disabled}
              />
              <div className={styles.label}>{DEGREE_TYPE_MAP[learnTimeOption]}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DegreeTypeFilter;
