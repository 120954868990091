/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import * as styles from './AddToBoardPopover.css';
import { ReduxState } from 'reducers/rootReducer';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';
import { fetchBoards } from 'actions/boardActions';
import { Checkbox } from './ds/Checkbox/Checkbox';
import { Button, Props as ButtonProps } from './ds/Button/Button';
import { Menu, Props as MenuProps } from './ds/Menu/Menu';
import CreateBoardModal from './shared/CreateBoardModal';
import { LoadingSpinner } from './ds/LoadingSpinner/LoadingSpinner';
import { ANALYTIC_EVENTS, track } from 'analytics';
import {
  BoardStartingPath,
  createBoardCareers,
  createBoardPath,
  createBoardPrograms,
} from 'actions/createBoardActions';
import { useHistory } from 'react-router-dom';

type Props = {
  title?: string;
  selectedBoards: number[];
  onSaveClicked: (boardIds: number[]) => void;
  menuProps?: Partial<MenuProps>;
  buttonProps?: Partial<ButtonProps>;
  location: string;
  entityType: string;
  entityId: number;
  trigger?: JSX.Element;
};

export default ({
  selectedBoards,
  buttonProps,
  menuProps,
  title,
  onSaveClicked,
  location,
  entityId,
  entityType,
  trigger,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [createBoardModalOpen, setCreateBoardModalOpen] = useState<boolean>(false);
  const [defaultOpenPopover, setDefaultOpenPopover] = useState<boolean>(false);
  const [boardIds, setBoardIds] = useState<number[]>(selectedBoards);

  useEffect(() => {
    setBoardIds(selectedBoards);
  }, [selectedBoards]);

  const { boardsById, boardsFetched, boardsLoading } = useSelector(
    (state: ReduxState) => ({
      boardsById: state.boards.boards,
      boardsFetched: state.boards.boardsFetched,
      boardsLoading: createLoadingSelector([ACTION.FETCH_BOARDS], true)(state),
    }),
    shallowEqual,
  );

  const noBoardsCreated = boardsFetched && !boardsLoading && Object.values(boardsById).length === 0;

  const onCreateBoardClicked = () => {
    dispatch(
      createBoardPath({
        path: entityType === 'Career' ? BoardStartingPath.CAREER : BoardStartingPath.PROGRAM,
      }),
    );
    entityType === 'Career'
      ? dispatch(createBoardCareers({ careerIds: [entityId] }))
      : dispatch(createBoardPrograms({ programIds: [entityId] }));
    history.push('/new-board?prevent_clear=true');
    track(ANALYTIC_EVENTS.CREATE_BOARD_CLICKED, {
      location,
      entity_id: entityId,
      entity_type: entityType,
    });
  };

  let popoverBody = noBoardsCreated ? (
    <div
      className={styles.emptyRoot}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
      <Button
        text="Create your first Board!"
        size="large"
        icon="add-board"
        type="primary"
        onClick={onCreateBoardClicked}
      />
    </div>
  ) : (
    <div className={styles.root}>
      <div className={styles.header}>{title ?? 'Add to Board'}</div>
      {boardsLoading ? <LoadingSpinner /> : null}
      {Object.values(boardsById).map((board) => {
        const isSelected = boardIds.includes(board.id);
        return (
          <div className={styles.inputContainer} key={`board-popover-${board.id}`}>
            <div className={styles.option}>
              <Checkbox
                isChecked={isSelected}
                onChange={() => {
                  if (!isSelected) {
                    setBoardIds([...boardIds, board.id]);
                  } else {
                    setBoardIds(boardIds.filter((id) => id !== board.id));
                  }
                }}
              />
              <div className={styles.label}>
                {board.emoji} {board.name}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  const footerConfig = noBoardsCreated
    ? undefined
    : {
        primaryActionBtnText: 'Save',
        onPrimaryBtnClicked: () => {
          onSaveClicked(boardIds);
          track(ANALYTIC_EVENTS.ADD_TO_BOARD_SAVED, {
            location,
            entity_id: entityId,
            entity_type: entityType,
          });
          toast('Board updates saved!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000,
          });
        },
        secondaryActionBtnText: 'Create a Board',
        onSecondaryBtnClicked: onCreateBoardClicked,
      };

  return (
    <>
      <Menu
        align="end"
        side="bottom"
        trigger={trigger ?? <Button type="ghost" icon="add-board" {...buttonProps} />}
        isOpen={defaultOpenPopover}
        onOpenChange={(open) => {
          if (open && !boardsFetched) {
            dispatch(fetchBoards());
          }

          if (open) {
            track(ANALYTIC_EVENTS.ADD_TO_BOARD_CLICKED, {
              location,
              entity_id: entityId,
              entity_type: entityType,
            });
          }

          if (!open) {
            setDefaultOpenPopover(false);
          }
        }}
        footerConfig={footerConfig}
        {...menuProps}>
        {popoverBody}
      </Menu>
      <CreateBoardModal
        isOpen={createBoardModalOpen}
        closeModal={() => {
          setCreateBoardModalOpen(false);
          setDefaultOpenPopover(true);
        }}
        location={location}
      />
    </>
  );
};
