/** @format */

import React, { FC } from 'react';
import cx from 'classnames';

const nameMapping = {
  'left-arrow': 'arrow_back',
  'down-arrow': 'arrow_downward',
  'up-arrow': 'arrow_upward',
  'right-arrow': 'arrow_forward',
  home: 'home',
  work: 'work',
  school: 'school',
  heart: 'favorite',
  filter: 'filter_alt',
  'neutral-face': 'sentiment_neutral',
  'sad-face': 'sentiment_stressed',
  'like-face': 'sentiment_satisfied',
  'love-face': 'sentiment_excited',
  'add-reaction': 'add_reaction',
  'caret-down': 'arrow_drop_down',
  'caret-up': 'arrow_drop_up',
  'caret-left': 'arrow_left',
  'caret-right': 'arrow_right',
  check: 'done',
  career: 'ar_on_you',
  programs: 'import_contacts',
  filters: 'page_info',
  cross: 'close',
  'location-pin': 'location_on',
  trending_up: 'trending_up',
  paid: 'paid',
  'avg-pace': 'avg_pace',
  remote: 'podcasts',
  builder: 'domain',
  search: 'search',
  star: 'star',
  save: 'save',
  plus: 'add',
  'person-add': 'person_add',
  person: 'person',
  'dock-to-left': 'dock_to_left',
  celebration: 'celebration',
  board: 'dashboard',
  'add-board': 'dashboard_customize',
  leaderboard: 'leaderboard',
  image: 'image',
  media: 'play_circle',
  plan: 'tactic',
  pathway: 'conversion_path',
  'triple-dots-horiz': 'more_horiz',
  rare: 'diamond',
  common: 'all_inclusive',
  money: 'attach_money',
  'advanced-degree': 'biotech',
  college: 'account_balance',
  error: 'error',
  asteriks: 'emergency',
  info: 'info',
  credential: 'workspace_premium',
  'learn-time': 'schedule',
  students: 'groups',
  locale: 'location_city',
  'admission-rate': 'approval_delegation',
  cost: 'local_atm',
  diversity: 'diversity_3',
  'thumbs-up': 'thumb_up',
  'no-test': 'layers_clear',
  military: 'military_tech',
  selective: 'reduce_capacity',
  computer: 'computer',
  'community-college': 'local_library',
  'private-university': 'corporate_fare',
  rural: 'cottage',
  climate: 'sunny',
  'helping-others': 'diversity_1',
  hollywood: 'videocam',
  flag: 'flag',
  'social-butterfly': 'emoji_nature',
  construction: 'construction',
  tree: 'park',
  gavel: 'gavel',
  explore: 'explore',
  'clock-10': 'clock_loader_10',
  'clock-40': 'clock_loader_40',
  'clock-60': 'clock_loader_60',
  'clock-90': 'clock_loader_90',
  bookmark: 'bookmark',
  'thumbs-down': 'thumb_down',
  maker: 'engineering',
  'expand-up': 'expand_circle_up',
  'expand-down': 'expand_circle_down',
  'expand-right': 'expand_circle_right',
  'external-arrow': 'arrow_outward',
  undo: 'undo',
  cancel: 'block',
  'plus-circle': 'add_circle',
  'no-video': 'videocam_off',
  target: 'target',
  trash: 'delete',
  agriculture: 'agriculture',
  architecture: 'foundation',
  art: 'palette',
  teacher: 'nutrition',
  finance: 'payments',
  healthcare: 'health_and_safety',
  hospitality: 'hotel',
  hr: 'face',
  it: 'laptop_map',
  manufacturing: 'factory',
  marketing: 'campaign',
  stem: 'biotech',
  transportation: 'directions_car',
};

export type IconName = keyof typeof nameMapping;

type ClassNameType = {
  /**
   * Style override
   */
  className?: string;
};

type BaseProps = ClassNameType & {
  name: IconName;
  fontSize?: number;
  filled?: boolean;
};

export type Props = BaseProps;

export const Icon: FC<Props> = ({ className, name, fontSize, filled }) => {
  const iconName = nameMapping[name];

  return (
    <span
      className={cx(filled ? 'material-symbols-outlined' : 'material-symbols-rounded', className)}
      style={{ fontSize }}>
      {iconName}
    </span>
  );

  // return <IconClass className={className} style={{ fontSize }} />;
};
