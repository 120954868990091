/** @format */

export enum ACTION {
  LOGIN_USER = 'LOGIN_USER',
  CLEAR_STATE = 'CLEAR_STATE',
  CHANGE_USER_THEME = 'CHANGE_USER_THEME',
  SUBMIT_SETUP_DATA = 'SUBMIT_SETUP_DATA',
  GOOGLE_OAUTH_VERIFICATION = 'GOOGLE_OAUTH_VERIFICATION',
  FETCH_INTERESTS = 'FETCH_INTERESTS',
  CAREER_SEARCH = 'CAREER_SEARCH',
  CAREER_SEARCH_CACHE = 'CAREER_SEARCH_CACHE',
  USE_CAREER_SEARCH_CACHE = 'USE_CAREER_SEARCH_CACHE',
  UPDATE_CAREER_CACHE_URL_PARAMS = 'UPDATE_CAREER_CACHE_URL_PARAMS',
  CACHE_CAREER_SEARCH_SCROLL_TOP = 'CACHE_CAREER_SEARCH_SCROLL_TOP',
  PROGRAM_SEARCH = 'PROGRAM_SEARCH',
  PROGRAM_SEARCH_CACHE = 'PROGRAM_SEARCH_CACHE',
  UPDATE_PROGRAM_CACHE_URL_PARAMS = 'UPDATE_PROGRAM_CACHE_URL_PARAMS',
  USE_PROGRAM_SEARCH_CACHE = 'USE_PROGRAM_SEARCH_CACHE',
  CACHE_PROGRAM_SEARCH_SCROLL_TOP = 'CACHE_PROGRAM_SEARCH_SCROLL_TOP',
  CAREER_INFO = 'CAREER_INFO',
  PROGRAM_INFO = 'PROGRAM_INFO',
  FETCH_CAREER_OPTIONS = 'FETCH_CAREER_OPTIONS',
  UPDATE_STUDENT_INTEREST_REACTION = 'UPDATE_STUDENT_INTEREST_REACTION',
  UPDATE_STUDENT_SAVE_CAREER = 'UPDATE_STUDENT_SAVE_CAREER',
  UPDATE_STUDENT_SAVE_PROGRAM = 'UPDATE_STUDENT_SAVE_PROGRAM',
  CREATE_RECOMMENDED_ONBOARDING_BOARD = 'CREATE_RECOMMENDED_ONBOARDING_BOARD',
  SUBMIT_PREFERRED_CAREERS = 'SUBMIT_PREFERRED_CAREERS',

  FETCH_ASSIGNED_PROVIDERS = 'FETCH_ASSIGNED_PROVIDERS',
  FETCH_PROVIDER_TO_EDIT = 'FETCH_PROVIDER_TO_EDIT',

  SAVE_MANUAL_PROGRAM_UPDATES = 'SAVE_MANUAL_PROGRAM_UPDATES',
  SAVE_MANUAL_PROVIDER_UPDATES = 'SAVE_MANUAL_PROVIDER_UPDATES',
  ASSIGN_PROVIDER_TO = 'ASSIGN_PROVIDER_TO',
  CREATE_NEW_LEARNING_PROGRAM = 'CREATE_NEW_LEARNING_PROGRAM',

  CREATE_BOARD = 'CREATE_BOARD',
  SAVE_CAREER_BOARDS = 'SAVE_CAREER_BOARDS',
  SAVE_PROGRAM_BOARDS = 'SAVE_PROGRAM_BOARDS',
  FETCH_BOARDS = 'FETCH_BOARDS',
  FETCH_BOARD_DATA = 'FETCH_BOARDS_DATA',

  SUPER_RESET_ONBOARDING_FETCH = 'SUPER_RESET_ONBOARDING_FETCH',
  SUPER_RESET_ONBOARDING = 'SUPER_RESET_ONBOARDING',
  FETCH_CAREERS_LIST = 'FETCH_CAREERS_LIST',
  UPDATE_CAREER_CATEGORIES = 'UPDATE_CAREER_CATEGORIES',
  SAVE_CAREER_CATEGORIES = 'SAVE_CAREER_CATEGORIES',

  UPDATE_ONBOARDING_STATUS = 'UPDATE_ONBOARDING_STATUS',
  SEARCH_CAREER_PROGRAMS = 'SEARCH_CAREER_PROGRAMS',

  CLEAR_CREATE_BOARD_STATE = 'CLEAR_CREATE_BOARD_STATE',
  CREATE_BOARD_GO_BACK = 'CREATE_BOARD_GO_BACK',
  CREATE_BOARD_PATH = 'CREATE_BOARD_PATH',
  CREATE_BOARD_EMOJI = 'CREATE_BOARD_EMOJI',
  CREATE_BOARD_CAREERS = 'CREATE_BOARD_CAREERS',
  CREATE_BOARD_PROGRAMS = 'CREATE_BOARD_PROGRAMS',
  CREATE_BOARD_PURPOSE = 'CREATE_BOARD_PURPOSE',
  CREATE_BOARD_LOCATION = 'CREATE_BOARD_LOCATION',
  CREATE_BOARD_WIZARD = 'CREATE_BOARD_WIZARD',

  REMOVE_CAREER_FROM_BOARD = 'REMOVE_CAREER_FROM_BOARD',
  REMOVE_PROGRAM_FROM_BOARD = 'REMOVE_PROGRAM_FROM_BOARD',
}

export type ErrorResponse = {
  detail: string;
  status: number;
  error_msg?: string;
};
