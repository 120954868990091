/** @format */

import { defineAPIPostAction } from 'actions/actionUtils';

import { ACTION } from 'actions/types';

type SuperUserResetUserArgs = {
  email: string;
};

export const {
  actionFn: superUserResetUser,
  requestAction: superUserResetUserRequest,
  successAction: superUserResetUserSuccess,
  errorAction: superUserResetUserError,
} = defineAPIPostAction<SuperUserResetUserArgs, {}>(
  ACTION.SUPER_RESET_ONBOARDING,
  'superuser',
  'reset_user',
  'POST',
);
