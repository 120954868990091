/** @format */

import React, { useState } from 'react';

import * as styles from './LearnTimeFilter.css';

import { Checkbox } from 'components/ds/Checkbox/Checkbox';
import { CareerSearchResult } from 'actions/careerActions';
import { Input } from 'components/ds/Input/Input';
import { Icon } from 'components/ds/Icon/Icon';
import { orderBy, sortBy } from 'lodash';
import { InterestData, InterestReactions } from 'actions/interestActions';
import { Menu } from 'components/ds/Menu/Menu';
import { DropdownButton } from 'components/ds/DropdownButton/DropdownButton';

type Props = {
  interests: InterestData[];
  selectedOptions?: number[];
  updateSelectedOptions: (option: number, isSelected: boolean) => void;
  numResultsToShow?: number;
  disabled?: boolean;
};

const InterestsFilter = ({
  interests,
  selectedOptions,
  updateSelectedOptions,
  numResultsToShow,
  disabled,
}: Props) => {
  const [searchString, setSearchString] = useState<string>('');
  let interestsToRender = interests;
  const lovedInterests = interests.filter(
    (interest) => interest.reaction === InterestReactions.LOVE,
  );
  const unlovedInterests = interests.filter(
    (interest) => interest.reaction !== InterestReactions.LOVE,
  );

  if (searchString.length > 0) {
    const lovedSearch = lovedInterests.filter((interests) =>
      interests.name.toLowerCase().includes(searchString.toLowerCase()),
    );
    const unlovedSearch = unlovedInterests.filter((interests) =>
      interests.name.toLowerCase().includes(searchString.toLowerCase()),
    );

    interestsToRender = [...lovedSearch, ...unlovedSearch];
  } else {
    interestsToRender = [
      ...lovedInterests,
      ...unlovedInterests.filter((interests) => selectedOptions?.includes(interests.id)),
    ];
    if (numResultsToShow && interestsToRender.length < numResultsToShow) {
      interestsToRender = [
        ...interestsToRender,
        ...unlovedInterests
          .filter(
            (interests) =>
              interestsToRender.findIndex((interest) => interest.id === interests.id) === -1,
          )
          .slice(0, numResultsToShow - interestsToRender.length),
      ];
    }
    interestsToRender = orderBy(
      interestsToRender,
      (interests) => selectedOptions?.includes(interests.id),
      'desc',
    );
  }

  return (
    <div className={styles.root}>
      <Menu
        noMaxHeight
        trigger={
          <DropdownButton
            text={
              selectedOptions?.length && selectedOptions?.length > 0
                ? `${selectedOptions.length} interests selected`
                : 'Select Interests'
            }
            size="large"
            fullWidth
          />
        }
        side="bottom"
        align="start">
        <div className={styles.popoverBody}>
          <Input
            value={searchString}
            onChange={(newValue) => setSearchString(newValue)}
            className={styles.searchInput}
            placeholder="Search Interests"
          />
          <div className={styles.options}>
            {interestsToRender.map((interests) => {
              const isChecked = (selectedOptions || []).indexOf(interests.id) > -1;
              return (
                <div className={styles.inputContainer} key={`interests-filter-${interests.id}`}>
                  <div className={styles.option}>
                    <Checkbox
                      isChecked={isChecked}
                      onChange={() => updateSelectedOptions(interests.id, !isChecked)}
                      disabled={disabled}
                    />
                    <div className={styles.label}>{interests.name}</div>
                  </div>
                  {interests.reaction === InterestReactions.LOVE && <Icon name="heart" filled />}
                </div>
              );
            })}
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default InterestsFilter;
