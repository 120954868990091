/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';

import * as styles from './index.css';

type Props = {};

const BoardInstitution = ({}: Props) => {
  const dispatch = useDispatch();

  return <div className={styles.root}></div>;
};

export default BoardInstitution;
