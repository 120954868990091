/** @format */

import { createReducer } from '@reduxjs/toolkit';

import {
  createBoardSuccess,
  fetchBoardsSuccess,
  BoardData,
  CareersCompressedData,
  ProgramCompressedData,
  updateBoardRequest,
} from 'actions/boardActions';
import { createBoardWizardSuccess } from 'actions/createBoardActions';

type State = {
  boards: { [boardId: string]: BoardData };
  careers: { [careerId: number]: CareersCompressedData };
  programs: { [programId: number]: ProgramCompressedData };
  boardsFetched: boolean;
};

const initialState: State = { boards: {}, careers: {}, programs: {}, boardsFetched: false };

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(fetchBoardsSuccess, (state, { payload }) => {
      payload.boards.forEach((board) => {
        state.boards[board.id] = board;
      });

      payload.programs.forEach((program) => {
        state.programs[program.id] = program;
      });

      payload.careers.forEach((career) => {
        state.careers[career.id] = career;
      });

      state.boardsFetched = true;

      return state;
    })
    .addCase(createBoardSuccess, (state, { payload }) => {
      state.boards[payload.board.id] = payload.board;

      return state;
    })
    .addCase(createBoardWizardSuccess, (state, { payload }) => {
      state.boards[payload.board.id] = payload.board;

      return state;
    })

    .addCase(updateBoardRequest, (state, { payload }) => {
      if (!payload.id || !state.boards || !state.boards[payload.id]) return state;
      state.boards[payload.id] = {
        ...state.boards[payload.id],
        ...payload.postData.board,
      };

      return state;
    })

    .addDefaultCase((state) => state);
});
