/** @format */

import React, { useState } from 'react';

import * as styles from './SuperuserActionsPage.css';
import { Menu, MenuActionItem } from 'components/ds/Menu/Menu';
import { DropdownButton } from 'components/ds/DropdownButton/DropdownButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import ResetUserSection from 'components/SuperuserPage/ResetUserSection';

enum SuperUserActions {
  RESET_ACCOUNT = 'Reset Account',
  PROVISION_ACCOUNT = 'Provision Account',
}

type Props = {};

const SuperuserActionsPage = ({}: Props) => {
  const [selectedAction, setSelectedAction] = useState<SuperUserActions>();
  const dispatch = useDispatch();

  const {} = useSelector((state: ReduxState) => ({}), shallowEqual);

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTop}>
          <div className={styles.headerTopContainer}>
            <div className={styles.headerText}>Career Data Cleaning</div>
          </div>
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.bodyHeader}>Action</div>
        <Menu
          align="start"
          side="bottom"
          trigger={<DropdownButton text={selectedAction ?? 'Select an action'} />}>
          {Object.values(SuperUserActions).map((actionName) => (
            <MenuActionItem
              style="data"
              text={actionName}
              key={`super-user-action-${actionName}`}
              onSelect={() => {
                setSelectedAction(actionName);
              }}
              selected={actionName === selectedAction}
            />
          ))}
        </Menu>
        <div className={styles.bodyHeader}>Parameters</div>
        {selectedAction === SuperUserActions.RESET_ACCOUNT ? <ResetUserSection /> : null}
      </div>
    </div>
  );
};

export default SuperuserActionsPage;
