/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';

import * as styles from 'components/EntityInfoPage/styles.css';
import SectionContainer from '../SectionContainer';
import { CareerInfoResult } from 'actions/careerActions';
import SalarySection from './SalarySection';
import { Icon, IconName } from 'components/ds/Icon/Icon';
import { InterestButton } from 'components/ds/InterestButton/InterestButton';
import { ANALYTIC_EVENTS, track } from 'analytics';
import { updateStudentInterestReaction } from 'actions/interestActions';

const INFO_BY_JOB_ZONE: Record<string, { title: string; description: string; icon: IconName }> = {
  '2': {
    title: 'Learn on the Job',
    description: 'High School Diploma',
    icon: 'clock-10',
  },
  '3': {
    title: '1-2 Years',
    description: "Associate's Degree",
    icon: 'clock-40',
  },
  '4': {
    title: '4 Years',
    description: "Bachelor's Degree",
    icon: 'clock-60',
  },
  '5': {
    title: '6+ years',
    description: 'Advanced Degree',
    icon: 'clock-90',
  },
};

type Props = {
  career: CareerInfoResult;
};

const DetailSection = ({ career }: Props) => {
  const dispatch = useDispatch();

  const jobZoneInfo = INFO_BY_JOB_ZONE[career.job_zone];
  return (
    <div className={styles.componentContainer}>
      <div className={styles.learnTimeContainer}>
        <div className={styles.learnTimeIconContainer}>
          <Icon name={jobZoneInfo.icon} />
        </div>
        <div>
          <div className={styles.learnTimeTitle}>{jobZoneInfo.title}</div>
          <div className={styles.learnTimeDescription}>{jobZoneInfo.description}</div>
        </div>
      </div>
      <div style={{ marginBottom: 60 }} className={styles.sectionBodyText}>
        {career.description}
      </div>

      <SectionContainer section="Related Interests">
        <div className={styles.interestsContainer}>
          {career.interests.map((interest) => (
            <InterestButton
              name={interest.name}
              careerId={career.id}
              key={`interest-${career.id}-${interest.id}`}
              selectedReaction={interest.reaction}
              onInterestSelected={(reaction) => {
                track(ANALYTIC_EVENTS.CAREER_PAGE_INTEREST_REACTION_UPDATED, {
                  career_id: career.id,
                  interest: interest.name,
                  reaction: reaction,
                  location: 'Career Page',
                });
                dispatch(
                  updateStudentInterestReaction({
                    id: interest.id,
                    postData: {
                      reaction: reaction,
                      careerId: career.id,
                    },
                  }),
                );
              }}
            />
          ))}
        </div>
      </SectionContainer>

      <SectionContainer section="Salary">
        <SalarySection career={career} />
      </SectionContainer>
    </div>
  );
};

export default DetailSection;
