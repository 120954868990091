/** @format */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { FocusStyleManager } from '@blueprintjs/core';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { baseThemeName } from 'components/ds/vars.css';
import { ToastContainer } from 'react-toastify';
import { H } from 'highlight.run';

import configureStore from './store/store';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './styleThemes';

import 'material-symbols';
import 'react-toastify/dist/ReactToastify.min.css';

H.init('4g8l230d', {
  environment: process.env.REACT_APP_ENVIRONMENT,
  serviceName: 'frontend-app',
  tracingOrigins: false,
  networkRecording: {
    enabled: false,
    recordHeadersAndBody: true,
    urlBlocklist: [
      // insert full or partial urls that you don't want to record here
      // Out of the box, Highlight will not record these URLs (they can be safely removed):
      'https://www.googleapis.com/identitytoolkit',
      'https://securetoken.googleapis.com',
    ],
  },
});

FocusStyleManager.onlyShowFocusOnTabs();

class MainApp extends Component {
  render() {
    return (
      <div className={baseThemeName}>
        <Provider store={configureStore()}>
          <GoogleOAuthProvider clientId="697896580985-vsa531mglh8ods1rk9tfvj30amopsc53.apps.googleusercontent.com">
            <ThemeProvider theme={theme}>
              <div id="app-root">
                <App />
              </div>
            </ThemeProvider>
          </GoogleOAuthProvider>
        </Provider>
        <ToastContainer />
      </div>
    );
  }
}

ReactDOM.render(<MainApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
