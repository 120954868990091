/** @format */

import Cookies from 'js-cookie';
import * as Redux from 'redux';
import { createAction } from '@reduxjs/toolkit';

import {
  createRequestAction,
  createErrorAction,
  createSuccessAction,
  defineAPIPostAction,
} from 'actions/actionUtils';

import { ACTION } from 'actions/types';
import { FetchProfileData } from 'auth/types';

export const logInUserSuccess = createSuccessAction<FetchProfileData>(ACTION.LOGIN_USER);

export const clearState = createAction(ACTION.CLEAR_STATE);

export function logOutUser() {
  const actionFn: () => void = () => {
    const token = Cookies.get('pointb_auth_token');

    if (!token) return async () => null;

    // if they have no token, then this is a no-op
    return (dispatch: Redux.Dispatch) => {
      Cookies.remove('pointb_auth_token');
      dispatch(clearState());
      window.location.href = '/';
    };
  };

  return actionFn();
}

const logInUserRequest = createRequestAction(ACTION.LOGIN_USER);
const logInUserError = createErrorAction<string>(ACTION.LOGIN_USER);

// const VERIFICATION_EMAIL_SENT = 'Verification e-mail sent.';

type LoginErrorResponse = {
  non_field_errors: string[];
  email?: string[];
  detail?: string;
};

export function loginUser(
  email: string,
  password: string,
  successCallback: (user: FetchProfileData) => void,
  errorCallback: (response: LoginErrorResponse) => void,
) {
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(logInUserRequest({}));
    return fetch(process.env.REACT_APP_API_URL + 'rest-auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (!data.key) {
          dispatch(logInUserError(''));
          errorCallback(data);
        } else {
          Cookies.set('pointb_auth_token', data.key, { sameSite: 'Strict' });
          dispatch(logInUserSuccess(data.user));

          successCallback(data.user);
        }
      });
  };
}

type GoogleOAuthLoginBody = {
  email: string;
};

type ExternalAuthData = {
  token: string;
  user: FetchProfileData;
};

export const {
  actionFn: googleOAuthLogin,
  requestAction: googleOAuthLoginRequest,
  errorAction: googleOAuthLoginError,
  successAction: googleOAuthLoginSuccess,
} = defineAPIPostAction<GoogleOAuthLoginBody, ExternalAuthData>(
  ACTION.GOOGLE_OAUTH_VERIFICATION,
  'auth',
  'google_oauth_login',
  'POST',
);
