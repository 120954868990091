/** @format */

import { createReducer } from '@reduxjs/toolkit';
import { saveCareerBoardsSuccess } from 'actions/boardActions';

import {
  CareerSearchResult,
  cacheCareerSearch,
  cacheCareerSearchScrollTop,
  fetchCareerSearchRequest,
  fetchCareerSearchSuccess,
  updateCareerCacheUrlParams,
  updateSaveCareerRequest,
  useCareerSearchCache,
} from 'actions/careerActions';

type State = {
  careers: CareerSearchResult[];
  totalResults: number;
  cachedState?: {
    careers: CareerSearchResult[];
    totalResults: number;
  };
  cachedUrlSearch?: string;
  cachedScrollTop?: number;
};

const initialState: State = { careers: [], totalResults: 0 };

const floorComp = (n: number) => {
  return Math.floor(n / 1000) * 1000;
};

const ceilComp = (n: number) => {
  return Math.ceil(n / 1000) * 1000;
};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(fetchCareerSearchSuccess, (state, { payload }) => {
      if (payload.getData?.page === '0') {
        state.careers = payload.careers;
        state.totalResults = payload.total_results;
      } else {
        state.careers = [...state.careers, ...payload.careers];
        state.totalResults = payload.total_results;
      }
      return state;
    })
    .addCase(fetchCareerSearchRequest, (state, { payload }) => {
      if (payload.getData?.page === '0') {
        return {
          cachedUrlSearch: state.cachedUrlSearch,
          ...initialState,
        };
      }
    })
    .addCase(updateSaveCareerRequest, (state, { payload }) => {
      const isSaved = payload.postData.isSaved;
      const careerId = payload.id;
      if (!careerId) return state;

      const career = state.careers.find((career) => career.id == careerId);
      if (!career) return state;

      career.is_saved = isSaved;

      return state;
    })
    .addCase(saveCareerBoardsSuccess, (state, { payload }) => {
      const careerId = payload.postData.career_id;

      const career = state.careers.find((career) => career.id == careerId);
      if (!career) return state;

      career.board_ids = payload.postData.board_ids;

      const cachedCareer = state.cachedState?.careers.find((c) => c.id === careerId);
      if (cachedCareer) cachedCareer.board_ids = payload.postData.board_ids;

      return state;
    })
    .addCase(cacheCareerSearch, (state, { payload }) => {
      state.cachedState = {
        careers: state.careers,
        totalResults: state.totalResults,
      };
      return state;
    })
    .addCase(updateCareerCacheUrlParams, (state, { payload }) => {
      state.cachedUrlSearch = payload.urlParams;
      return state;
    })
    .addCase(cacheCareerSearchScrollTop, (state, { payload }) => {
      state.cachedScrollTop = payload.scrollTop;
      return state;
    })
    .addCase(useCareerSearchCache, (state, { payload }) => {
      if (!state.cachedState) return;
      return {
        cachedState: state.cachedState,
        careers: state.cachedState.careers,
        totalResults: state.cachedState.totalResults,
        cachedUrlSearch: state.cachedUrlSearch,
      };
    })

    .addDefaultCase((state) => state);
});
