/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as styles from './CareerCleaningPage.css';
import { ReduxState } from 'reducers/rootReducer';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import {
  listCareers,
  saveCareerCategories,
  updateCareerCategories,
} from 'actions/dataCleaningActions';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';
import { CareerCategoryFilter } from 'pages/CareersOverviewPage';
import { Checkbox } from 'components/ds/Checkbox/Checkbox';
import { Tooltip } from 'components/ds/Tooltip/Tooltip';
import { Button } from 'components/ds/Button/Button';

const CATEGORIES_TO_CLEAN = [
  CareerCategoryFilter.CLIMATE,
  CareerCategoryFilter.HELPING_OTHERS,
  CareerCategoryFilter.HOLLYWOOD,
  CareerCategoryFilter.PATRIOTIC,
  CareerCategoryFilter.SOCIAL_BUTTERFLY,
  CareerCategoryFilter.BUILDER,
  CareerCategoryFilter.OUTDOORS,
  CareerCategoryFilter.INDOORS,
  CareerCategoryFilter.LAW_AND_ORDER,
];

type Props = {};

const CareerCleaningPage = ({}: Props) => {
  const [showSaved, setShowSaved] = useState(false);
  const [[savedPage, unsavedPage], setPage] = useState([0, 0]);
  const dispatch = useDispatch();

  const { careersLoading, careers } = useSelector(
    (state: ReduxState) => ({
      careers: state.dataCleaningReducer.careerCleaning.careerList,
      careersLoading: createLoadingSelector([ACTION.FETCH_CAREERS_LIST], true)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(listCareers());
  }, [dispatch]);

  if (careersLoading)
    return (
      <div className={styles.loadingPage}>
        <LoadingSpinner />
      </div>
    );

  const unsavedCareers = careers.filter((c) => c.manual_category_processed === false);
  const savedCareers = careers.filter((c) => c.manual_category_processed === true);
  const careersToShow = showSaved
    ? savedCareers.slice(0, (savedPage + 1) * 10)
    : unsavedCareers.slice(0, (unsavedPage + 1) * 10);

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTop}>
          <div className={styles.headerTopContainer}>
            <div className={styles.headerText}>Career Data Cleaning</div>
          </div>
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.toggleBtns}>
          <Button
            type={showSaved ? 'ghost' : 'primary'}
            text={`Todo (${unsavedCareers.length})`}
            onClick={() => setShowSaved(false)}
          />
          <Button
            type={!showSaved ? 'ghost' : 'primary'}
            text={`Saved (${savedCareers.length})`}
            onClick={() => setShowSaved(true)}
          />
        </div>
        <div className={styles.headerRow}>
          <div className={styles.headerCareerName}>.</div>
          {CATEGORIES_TO_CLEAN.map((category) => (
            <div key={`header-row-${category}`} className={styles.categoryHeader}>
              {category}
            </div>
          ))}
          <div className={styles.doneBtnContainer} style={{ visibility: 'hidden' }}>
            <Button icon="check" type="ghost" text="Done" onClick={() => {}} />
          </div>
        </div>
        {careersToShow.map((career) => (
          <div key={`career-category-matching-${career.id}`} className={styles.careerRow}>
            <Tooltip text={career.description} side="bottom">
              <div className={styles.careerName}>{career.name}</div>
            </Tooltip>
            {CATEGORIES_TO_CLEAN.map((category) => {
              const isChecked = !!career.manual_categories?.includes(category);
              return (
                <div
                  key={`career-category-${career.id}-${category}`}
                  className={styles.checkboxContainer}>
                  <Checkbox
                    isChecked={isChecked}
                    onChange={() => {
                      let newCategories = career.manual_categories || '';
                      if (!isChecked) {
                        newCategories = newCategories + category;
                      } else {
                        newCategories = newCategories.replace(category, '');
                      }

                      dispatch(
                        updateCareerCategories({ careerId: career.id, categories: newCategories }),
                      );
                    }}
                  />
                </div>
              );
            })}
            <div className={styles.doneBtnContainer}>
              <Button
                icon="check"
                type="ghost"
                text="Save"
                onClick={() => {
                  dispatch(
                    saveCareerCategories({
                      postData: {
                        career_id: career.id,
                        categories: career.manual_categories || '',
                      },
                    }),
                  );
                }}
              />
            </div>
          </div>
        ))}
        <div className={styles.loadMoreBtnContainer}>
          <Button
            size="large"
            type="primary"
            text="Load More Careers"
            onClick={() => {
              setPage([savedPage + (showSaved ? 1 : 0), unsavedPage + (showSaved ? 0 : 1)]);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CareerCleaningPage;
