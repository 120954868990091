/** @format */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as styles from './index.css';
import WizardStep from '../WizardStep/WizardStep';
import { createBoardLocation } from 'actions/createBoardActions';
import { Input } from 'components/ds/Input/Input';

type Props = {};

const BoardLocation = ({}: Props) => {
  const dispatch = useDispatch();
  const [location, setLocation] = useState<string>('');

  return (
    <div className={styles.root}>
      <WizardStep
        nextDisabled={!location}
        onNextClicked={() => {
          dispatch(createBoardLocation({ location }));
        }}>
        <div className={styles.root}>
          <div className={styles.header}>Enter a city / state</div>
          <div className={styles.subHeader}>Where would you want to live?</div>
          <Input
            value={location}
            onChange={(value) => setLocation(value)}
            className={styles.input}
            placeholder="Enter a city or state"
          />
        </div>
      </WizardStep>
    </div>
  );
};

export default BoardLocation;
