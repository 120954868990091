/** @format */

import React from 'react';

import * as styles from 'components/EntityInfoPage/styles.css';

type Props = {
  section: string;
  children?: JSX.Element;
};

const SectionContainer = ({ section, children }: Props) => {
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionContainerHeader}>
        <div className={styles.sectionContainerHeaderText}>{section}</div>
      </div>
      <div className={styles.sectionContainerBody}>{children}</div>
    </div>
  );
};

export default SectionContainer;
