/** @format */

import { vars } from 'components/ds';
import {
  candylandTheme,
  groundedTheme,
  luminovaTheme,
  minimalTheme,
} from 'components/ds/theme.css';

export enum THEME_PALETTE {
  LUMINOVA = 'Luminova',
  GROUNDED = 'Grounded',
  CANDYLAND = 'Candyland',
  MINIMAL = 'Minimal',
}

export type Palette = {
  colors: string[];
  name: string;
};

export const PALETTE_BY_THEME: Record<THEME_PALETTE, Palette> = {
  [THEME_PALETTE.LUMINOVA]: {
    colors: [
      vars.themes.luminova.homePrimaryColor,
      vars.themes.luminova.careersPrimaryColor,
      vars.themes.luminova.programsPrimaryColor,
    ],
    name: THEME_PALETTE.LUMINOVA,
  },
  [THEME_PALETTE.GROUNDED]: {
    colors: [
      vars.themes.grounded.homePrimaryColor,
      vars.themes.grounded.careersPrimaryColor,
      vars.themes.grounded.programsPrimaryColor,
    ],
    name: THEME_PALETTE.GROUNDED,
  },
  [THEME_PALETTE.CANDYLAND]: {
    colors: [
      vars.themes.candyland.homePrimaryColor,
      vars.themes.candyland.careersPrimaryColor,
      vars.themes.candyland.programsPrimaryColor,
    ],
    name: THEME_PALETTE.CANDYLAND,
  },
  [THEME_PALETTE.MINIMAL]: {
    colors: [
      vars.themes.minimal.homePrimaryColor,
      vars.themes.minimal.careersPrimaryColor,
      vars.themes.minimal.programsPrimaryColor,
    ],
    name: THEME_PALETTE.MINIMAL,
  },
};

export const THEME_BY_CLASS_NAME: Record<THEME_PALETTE, string> = {
  [THEME_PALETTE.LUMINOVA]: luminovaTheme,
  [THEME_PALETTE.GROUNDED]: groundedTheme,
  [THEME_PALETTE.CANDYLAND]: candylandTheme,
  [THEME_PALETTE.MINIMAL]: minimalTheme,
};
