/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import take from 'lodash/take';
import { useDispatch } from 'react-redux';

import * as styles from 'components/EntityInfoPage/styles.css';
import SectionContainer from '../SectionContainer';
import { CareerInfoResult } from 'actions/careerActions';
import { Button } from 'components/ds/Button/Button';
import { ANALYTIC_EVENTS, track } from 'analytics';

type Props = {
  career: CareerInfoResult;
};

const ActivitiesSection = ({ career }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  const numTasksToDisplay = isExpanded ? career.tasks.length : 5;
  const showExpandButton = career.tasks.length > 5;

  return (
    <div className={styles.componentContainer}>
      <SectionContainer section="On the Job Activities">
        <>
          {take(career.tasks, numTasksToDisplay).map((task) => (
            <div
              key={task.task_id}
              className={cx(styles.sectionBodyText, styles.sectionBodyBulletItem)}>
              <span className={styles.sectionBodyBullet} />
              {task.task_name}
            </div>
          ))}
          {showExpandButton && (
            <Button
              icon={isExpanded ? 'up-arrow' : 'down-arrow'}
              text={isExpanded ? 'See Less Tasks' : 'See More Tasks'}
              type="primary"
              onClick={() => {
                setIsExpanded(!isExpanded);
                track(ANALYTIC_EVENTS.CAREER_PAGE_ACTIVITIES_TOGGLED, {
                  career_id: career.id,
                  is_open: !isExpanded,
                });
              }}
            />
          )}
        </>
      </SectionContainer>
    </div>
  );
};

export default ActivitiesSection;
