/** @format */

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import * as styles from './ResetUserSection.css';
import { ResetOnboardingData } from 'reducers/superuserReducer';
import { Input } from 'components/ds/Input/Input';
import { Button } from 'components/ds/Button/Button';
import { superUserResetUser } from 'actions/superuserActions';

type Props = {};

const ResetUserSection = ({}: Props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const [emailConfirmation, setEmailConfirmation] = useState<string>('');

  return (
    <div className={styles.root}>
      <div className={styles.label}>User Email</div>
      <Input
        className={styles.input}
        placeholder="Enter User Email"
        value={email}
        onChange={(value) => setEmail(value)}
      />
      <div className={styles.label}>Confirm User Email</div>
      <Input
        className={styles.input}
        placeholder="Confirm User Email"
        value={emailConfirmation}
        onChange={(value) => setEmailConfirmation(value)}
      />
      <Button
        type="primary"
        color="danger"
        text="Reset User"
        onClick={() => {
          if (!email || !emailConfirmation) {
            toast.error('Both email fields are required', {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 2000,
            });
          } else if (email !== emailConfirmation) {
            toast.error('Emails much match', {
              position: toast.POSITION.BOTTOM_RIGHT,
              hideProgressBar: true,
              autoClose: 2000,
            });
          } else {
            dispatch(
              superUserResetUser(
                { postData: { email: email } },
                () => {
                  toast.success(
                    <div>
                      User <b>{email}</b> successfully reset
                    </div>,
                    {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 2000,
                    },
                  );
                },
                (data) => {
                  toast.error(data.error_msg ?? 'Internal Server Error', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 2000,
                  });
                },
              ),
            );
          }
        }}
      />
    </div>
  );
};

export default ResetUserSection;
