/** @format */

import React, { FC, forwardRef, Ref } from 'react';
import cx from 'classnames';

import * as styles from './index.css';
import { Icon, IconName } from '../Icon/Icon';

type ClassNameType = {
  /**
   * Style override
   */
  className?: string;
};

type BaseProps = ClassNameType & {
  text?: string;
  icon?: IconName;
  disabled?: boolean;
  hideDownCaret?: boolean;
  loading?: boolean;
  onClick?: () => void;
  error?: boolean;
  size?: 'large';
  fullWidth?: boolean;
};

export type Props = BaseProps;

export const DropdownButton: FC<Props> = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    {
      error,
      text,
      icon,
      disabled,
      loading,
      hideDownCaret,
      onClick,
      className,
      fullWidth,
      size,
      ...props
    },
    ref,
  ) => {
    // const elementRef = parentRef ?? ref;
    const elementRef = ref;
    const sharedProps = {
      ...props,
      className: cx(
        styles.root({
          disabled: disabled ? 'disabled' : undefined,
          size,
          fullWidth: fullWidth ? 'fullWidth' : undefined,
        }),
        className,
        {
          error,
        },
      ),
    };

    return (
      <button
        disabled={disabled}
        {...sharedProps}
        onClick={() => !disabled && onClick}
        ref={elementRef as Ref<HTMLButtonElement>}>
        {icon && (
          <Icon
            name={icon}
            fontSize={20}
            className={text === undefined && hideDownCaret ? styles.iconOnly : styles.icon}
          />
        )}
        <div className={styles.buttonText}>{text}</div>
        {!hideDownCaret && <Icon name="caret-down" className={styles.rightIcon} />}
      </button>
    );
  },
);
