/** @format */

import React, { useEffect, useState, useCallback } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import * as styles from './index.css';
import { fetchInterests } from 'actions/interestActions';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';
import { ANALYTIC_EVENTS, ANALYTIC_PAGES, page, track } from 'analytics';
import { Button } from 'components/ds/Button/Button';
import { Icon, IconName } from 'components/ds/Icon/Icon';
import { BUCKET_FILTERS, CareerBucketCategory } from 'pages/CareersOverviewPage';
import { CATEGORY_FILTERS } from 'pages/ProgramsOverviewPage';
import { Link, useHistory } from 'react-router-dom';
import { searchCareerPrograms } from 'actions/searchActions';
import { debounce } from 'lodash';
import { CareerSearchResult } from 'actions/careerActions';
import { ProgramSearchResult } from 'actions/programsActions';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import CareerCardSmall from 'components/HomePage/CareerCardSmall/CareerCardSmall';
import ProgramCardSmall from 'components/HomePage/ProgramCardSmall/ProgramCardSmall';
import { CAREER_CLUSTER_INFO } from 'components/SearchPage/CareerFilters/CareerClusterFilter';

type Props = {};

const ExplorePage = ({}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchString, setSearchString] = useState<string>('');
  const [careerResults, setCareerResults] = useState<CareerSearchResult[]>([]);
  const [programResults, setProgramResults] = useState<ProgramSearchResult[]>([]);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);

  const { searchLoading } = useSelector(
    (state: ReduxState) => ({
      searchLoading: createLoadingSelector([ACTION.SEARCH_CAREER_PROGRAMS], false)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchInterests());

    page(ANALYTIC_PAGES.EXPLORE_PAGE);
  }, []);

  const onSearchStringUpdated = useCallback(
    debounce((s?: string) => {
      if (!searchString && !s) return;

      setSearchOpen(true);

      s &&
        dispatch(
          searchCareerPrograms({ postData: { query: s } }, (data) => {
            setCareerResults(data.careers);
            setProgramResults(data.programs);
          }),
        );
      track(ANALYTIC_EVENTS.EXPLORE_SEARCH, { query: s });
    }, 500),
    [],
  );

  return (
    <div className={styles.root} onClick={() => setSearchOpen(false)}>
      <div className={styles.headerContainer}>
        <div className={styles.searchInputContainer}>
          <input
            className={styles.headerSearchInput}
            placeholder="Looking for something specific?"
            value={searchString}
            onFocus={() => setSearchOpen(true)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(e) => {
              const query = e.target.value;
              setSearchString(query);
              setCareerResults([]);
              setProgramResults([]);

              onSearchStringUpdated(query);
            }}
          />
          <Icon fontSize={24} className={styles.searchIcon} name="search" />
          {searchOpen && searchString && (searchLoading || careerResults || programResults) ? (
            <div
              className={styles.searchPopover}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
              {searchLoading ? (
                <div className={styles.loading}>
                  <LoadingSpinner />
                </div>
              ) : null}
              {!searchLoading && (careerResults || programResults) ? (
                <>
                  <div className={styles.searchPopoverResult}>Career Results</div>
                  <div className={styles.carasoulContainer}>
                    {careerResults.map((career) => (
                      <CareerCardSmall
                        hideVideo
                        hideNuggets
                        hideInterest
                        career={career}
                        location="Explore page"
                      />
                    ))}
                    {careerResults.length === 0 && <div>No Results</div>}
                  </div>
                  <div className={styles.searchPopoverResult}>Program Results</div>
                  <div className={styles.carasoulContainer}>
                    {programResults.map((program) => (
                      <ProgramCardSmall
                        hideNuggets
                        className={styles.programCard}
                        hideSave
                        program={program}
                        location="Explore page"
                      />
                    ))}
                    {programResults.length === 0 && <div>No Results</div>}
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionHeaderText}>Programs</div>
            {/* <Button
              text="View All Programs"
              type="ghost"
              whiteMode
              onClick={() => {
                track(ANALYTIC_EVENTS.EXPLORE_ALL_PROGRAMS_CLICKED);
                history.push(`/programs`);
              }}
            /> */}
          </div>
          <div className={styles.sectionCardsContainer}>
            {CATEGORY_FILTERS.map((category) => (
              <div
                onClick={() => {
                  track(ANALYTIC_EVENTS.PROGRAM_SEARCH_BUCKET_UPDATED, {
                    bucket_name: category.name,
                  });
                  history.push(`/programs?category=${encodeURIComponent(category.name)}`);
                }}
                key={`program-category-explore-${category.name}`}
                className={cx(styles.bucketCard, styles.programBucketCard)}>
                <div className={styles.programBucketIcon}>
                  <Icon name={category.icon as IconName} />
                </div>
                <div>{category.name}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionHeaderText}>{CareerBucketCategory.THEME}</div>
            {/* <Button
              text="View All Careers"
              type="ghost"
              whiteMode
              onClick={() => {
                track(ANALYTIC_EVENTS.EXPLORE_ALL_CAREERS_CLICKED);
                history.push(`/careers`);
              }}
            /> */}
          </div>
          <div className={styles.sectionCardsContainer}>
            {BUCKET_FILTERS.filter((bucket) => bucket.category === CareerBucketCategory.THEME).map(
              (bucket) => (
                <div
                  onClick={() => {
                    track(ANALYTIC_EVENTS.CAREER_SEARCH_BUCKET_UPDATED, {
                      bucket_name: bucket.name,
                    });
                    history.push(`/careers?category=${encodeURIComponent(bucket.name)}`);
                  }}
                  key={`career-bucket-explore-${bucket.name}`}
                  className={cx(styles.bucketCard, styles.careerBucketCard)}>
                  <div>
                    <Icon name={bucket.icon as IconName} />
                  </div>
                  <div>{bucket.name}</div>
                </div>
              ),
            )}
          </div>
        </div>

        <div className={styles.sectionContainer}>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionHeaderText}>Career Clusters</div>
          </div>
          <div className={styles.sectionCardsContainer}>
            {Object.values(CAREER_CLUSTER_INFO).map((bucket) => (
              <div
                onClick={() => {
                  track(ANALYTIC_EVENTS.CAREER_SEARCH_BUCKET_UPDATED, {
                    bucket_name: bucket.name,
                  });
                  history.push(`/careers?career_clusters=${encodeURIComponent(bucket.id)}`);
                }}
                key={`career-bucket-explore-${bucket.name}`}
                className={cx(styles.bucketCard, styles.careerBucketCard)}>
                <div>
                  <Icon name={bucket.icon} />
                </div>
                <div>{bucket.name}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.sectionContainer}>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionHeaderText}>{CareerBucketCategory.JOB_ATTRIBUTES}</div>
          </div>
          <div className={styles.sectionCardsContainer}>
            {BUCKET_FILTERS.filter(
              (bucket) => bucket.category === CareerBucketCategory.JOB_ATTRIBUTES,
            ).map((bucket) => (
              <div
                onClick={() => {
                  track(ANALYTIC_EVENTS.CAREER_SEARCH_BUCKET_UPDATED, {
                    bucket_name: bucket.name,
                  });
                  history.push(`/careers?category=${encodeURIComponent(bucket.name)}`);
                }}
                key={`career-bucket-explore-${bucket.name}`}
                className={cx(styles.bucketCard, styles.careerBucketCard)}>
                <div>
                  <Icon name={bucket.icon as IconName} />
                </div>
                <div>{bucket.name}</div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.sectionContainer}>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionHeaderText}>{CareerBucketCategory.REQUIREMENTS}</div>
          </div>
          <div className={styles.sectionCardsContainer}>
            {BUCKET_FILTERS.filter(
              (bucket) => bucket.category === CareerBucketCategory.REQUIREMENTS,
            ).map((bucket) => (
              <div
                onClick={() => {
                  track(ANALYTIC_EVENTS.CAREER_SEARCH_BUCKET_UPDATED, {
                    bucket_name: bucket.name,
                  });
                  history.push(`/careers?category=${encodeURIComponent(bucket.name)}`);
                }}
                key={`career-bucket-explore-${bucket.name}`}
                className={cx(styles.bucketCard, styles.careerBucketCard)}>
                <div>
                  <Icon name={bucket.icon as IconName} />
                </div>
                <div>{bucket.name}</div>
              </div>
            ))}
          </div>
        </div>

        {/* 
        <div className={styles.sectionContainer}>
          <div className={styles.sectionHeader}>Providers</div>
          <div>
            <div>
              <div>
                <Icon name="money" />
              </div>
              <div>High Salary</div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ExplorePage;
