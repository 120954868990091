/** @format */

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';

import * as styles from './CareerSearchResult.css';
import * as sharedStyles from './SharedResultStyles.css';
import { Link } from 'react-router-dom';
import { Button } from 'components/ds/Button/Button';
import { CareerSearchResult, updateSaveCareer } from 'actions/careerActions';
import AddToBoardPopover from 'components/AddToBoardPopover';
import { saveCareerBoards } from 'actions/boardActions';
import { ANALYTIC_EVENTS, track } from 'analytics';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type Props = {
  career: CareerSearchResult;
  truncateDescription?: boolean;
  location: string;
};

export default ({ career, truncateDescription, location }: Props) => {
  const [boardIds, setBoardIds] = useState<number[]>(career.board_ids);
  const dispatch = useDispatch();

  useEffect(() => {
    setBoardIds(career.board_ids);
  }, [career.board_ids]);

  return (
    <div className={styles.root}>
      <Link
        className={cx(sharedStyles.resultContainer, styles.careerResultContainer)}
        to={`/careers/${career.id}?referrer=${location}`}>
        <div className={styles.careerNameContainer}>
          <div className={styles.resultSubHeader}>{career.career_cluster}</div>
          <div className={styles.resultTitle}>
            {career.search_name_highlighted ? parse(career.search_name_highlighted) : career.name}
          </div>
        </div>
        <div className={styles.resultDescription}>
          {career.search_description_highlighted
            ? parse(career.search_description_highlighted)
            : processDescription(career.description, truncateDescription)}
        </div>
        <div className={styles.metaDataRow}>
          <div className={styles.resultMetadataContainer}>
            <div className={sharedStyles.resultMetadataTitle}>Expected Learn Time</div>
            <div className={sharedStyles.resultsMetadataData}>
              {convertProgramLengthToMixMaxTime(
                career.program_length_max,
                career.program_length_expected,
              )}
            </div>
          </div>
          <div className={styles.resultMetadataContainer}>
            <div className={sharedStyles.resultMetadataTitle}>Salary</div>
            <div className={sharedStyles.resultsMetadataData}>
              {career.avg_starting_salary
                ? USDollar.format(Math.round(career.avg_starting_salary / 1000) * 1000)
                : '-'}
            </div>
          </div>
        </div>
        <div className={sharedStyles.hiddenPlaceholder}>
          <Button stopPropogation icon="bookmark" type="ghost" />
        </div>
        <div className={sharedStyles.hiddenPlaceholder}>
          <Button stopPropogation icon="add-board" type="ghost" />
        </div>
      </Link>
      <div className={sharedStyles.resultLikeContainer}>
        <Button
          stopPropogation
          icon="bookmark"
          iconFilled={career.is_saved}
          type="ghost"
          onClick={() => {
            track(ANALYTIC_EVENTS.CAREER_SAVED, {
              location,
              career_id: career.id,
              career_name: career.name,
              is_saved: !career.is_saved,
            });
            dispatch(
              updateSaveCareer({ id: career.id, postData: { isSaved: !career.is_saved } }, () => {
                toast(
                  <div>
                    <b>{career.name}</b> {!career.is_saved ? 'saved' : 'unsaved'}!
                  </div>,
                  {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 2000,
                  },
                );
              }),
            );
          }}
        />
        <AddToBoardPopover
          title="Add Career to Board"
          selectedBoards={boardIds}
          onSaveClicked={(boardIds) => {
            setBoardIds(boardIds);
            dispatch(saveCareerBoards({ postData: { career_id: career.id, board_ids: boardIds } }));
          }}
          buttonProps={
            boardIds.length > 0
              ? {
                  icon: 'board',
                  iconFilled: true,
                }
              : undefined
          }
          location={location}
          entityType="Career"
          entityId={career.id}
        />
      </div>
    </div>
  );
};

export const convertProgramLengthToMixMaxTime = (
  maxMonths: number | null,
  minMonths: number | null,
) => {
  if (maxMonths === null && minMonths !== null) maxMonths = minMonths;
  if (minMonths === null && maxMonths !== null) minMonths = maxMonths;

  if (maxMonths === null || minMonths === null) return '-';

  const convertUnit = maxMonths >= 24 ? (x: number) => Math.floor(x / 12) : (x: number) => x;
  const unit = maxMonths >= 24 ? 'years' : 'months';

  if (maxMonths === minMonths) {
    return `${convertUnit(maxMonths)} ${unit}`;
  }

  return `${convertUnit(minMonths)}-${convertUnit(maxMonths)} ${unit}`;
};

const processDescription = (description: string, truncateDescription?: boolean) => {
  if (!truncateDescription) return description;

  const MAX_CHARS = 200;

  if (description.length <= MAX_CHARS) return description;

  return `${description.slice(0, MAX_CHARS)}...`;
};
