/** @format */

import React from 'react';

import * as styles from './LearnTimeFilter.css';

import { Checkbox } from 'components/ds/Checkbox/Checkbox';

type Props = {
  selectedOptions?: number[];
  updateSelectedOptions: (option: number, isSelected: boolean) => void;
  disabled?: boolean;
};

export const LEARN_TIME_MAP: Record<string, string> = {
  [0]: 'Less than 1 year',
  [1]: '1-2 years',
  [2]: '2-4 years',
  [3]: '4-6 years',
  [4]: '6+ years',
};

const LearnTimeFilter = ({ selectedOptions, updateSelectedOptions, disabled }: Props) => {
  return (
    <div className={styles.root}>
      {Object.keys(LEARN_TIME_MAP).map((learnTimeOption) => {
        const isChecked = (selectedOptions || []).indexOf(Number(learnTimeOption)) > -1;
        return (
          <div className={styles.inputContainer} key={`learn-time-filter-${learnTimeOption}`}>
            <div className={styles.option}>
              <Checkbox
                disabled={disabled}
                isChecked={isChecked}
                onChange={() => updateSelectedOptions(Number(learnTimeOption), !isChecked)}
              />
              <div className={styles.label}>{LEARN_TIME_MAP[learnTimeOption]}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LearnTimeFilter;
