/** @format */

import React from 'react';
import cx from 'classnames';

import * as styles from 'components/EntityInfoPage/styles.css';
import { Icon } from 'components/ds/Icon/Icon';

type Props = {
  salary10: number;
  salary90: number;
  noData: boolean;
  livingWageSalary?: number;
};

const SalaryLineChart = ({ salary10, salary90, livingWageSalary, noData }: Props) => {
  const salary10Thousand = Math.round(salary10 / 1000);
  const salary90Thousand = Math.round(salary90 / 1000);
  const livingWageThousand = Math.round((livingWageSalary || 0) / 1000);

  let salary10TopPercent = 0;
  let salary90TopPercent = 0;
  let livingWageLinePercent = 0;
  if (noData) {
  } else {
    const salaryUpperBound = Math.ceil(salary90Thousand / 100) * 100;
    salary10TopPercent = Math.round((1 - salary10Thousand / salaryUpperBound) * 100);
    salary90TopPercent = Math.round((1 - salary90Thousand / salaryUpperBound) * 100);
    livingWageLinePercent = Math.round((1 - livingWageThousand / salaryUpperBound) * 100);
  }

  return (
    <div className={styles.lineChartContainer}>
      <div className={cx(styles.axisLabel, styles.xAxisLabel)}>Salary</div>
      <div className={cx(styles.axisLabel, styles.yAxisLabel)}>Experience</div>

      <div className={styles.lineChartGraph}>
        <div className={styles.yAxisCaret}>
          <Icon name="caret-up" fontSize={35} />
        </div>
        <div className={styles.xAxisCaret}>
          <Icon name="caret-right" fontSize={35} />
        </div>
        {noData && (
          <div>
            <Icon name="error" />
            <div>Salary data unavailable for this region. Please select another</div>
          </div>
        )}
        {!noData && livingWageSalary && (
          <div
            className={styles.livingWageLine}
            style={{ height: `${livingWageLinePercent}%` }}></div>
        )}

        {!noData && (
          <div className={styles.lowDataPoint} style={{ top: `${salary10TopPercent}%` }}>
            <div className={styles.lowLabel}>${salary10Thousand}k</div>
          </div>
        )}
        {!noData && (
          <div className={styles.highDataPoint} style={{ top: `${salary90TopPercent}%` }}>
            <div className={styles.highLabel}>${salary90Thousand}k</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalaryLineChart;
