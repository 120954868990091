/** @format */

import { createAction } from '@reduxjs/toolkit';

import { ACTION } from 'actions/types';
import { THEME_PALETTE } from 'constants/setup';
import { defineAPIPostAction } from './actionUtils';
import { CareerSearchResult } from './careerActions';
import { ProgramSearchResult } from './programsActions';

type SearchCareerProgramsBody = {
  query: string;
};

type SearchCareerProgramsResponse = {
  careers: CareerSearchResult[];
  programs: ProgramSearchResult[];
};

export const {
  actionFn: searchCareerPrograms,
  requestAction: searchCareerProgramsRequest,
  errorAction: searchCareerProgramsError,
  successAction: searchCareerProgramsSuccess,
} = defineAPIPostAction<SearchCareerProgramsBody, SearchCareerProgramsResponse>(
  ACTION.SEARCH_CAREER_PROGRAMS,
  'search',
  'program_careers',
  'POST',
);
