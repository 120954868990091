/** @format */

import React, { FC } from 'react';
import cx from 'classnames';
import * as styles from './RegionSelector.css';
import { Menu, MenuActionItem } from '../Menu/Menu';
import { DropdownButton } from '../DropdownButton/DropdownButton';
import { COMP_STATE_OPTIONS, STATE_CODE_TO_NAME } from 'constants/data';

const state_codes = Object.keys(COMP_STATE_OPTIONS);
state_codes.sort();
const ORDERED_STATE_OPTIONS = state_codes.map((code) => ({
  id: code,
  name: STATE_CODE_TO_NAME[code],
}));

export type Props = {
  style: 'home' | 'career' | 'program' | 'data';
  selectedStateCode?: string;
  selectedLocationName?: string;
  updateLocation: (stateCode?: string, locationName?: string) => void;
  onApply: () => void;
  applyText?: string;
  noSelectionText?: string;
  buttonClassName?: string;
  error?: boolean;
};

export const RegionSelector: FC<Props> = ({
  style,
  selectedStateCode,
  selectedLocationName,
  updateLocation,
  onApply,
  applyText,
  noSelectionText,
  buttonClassName,
  error,
}) => {
  return (
    <Menu
      align="end"
      side="bottom"
      trigger={
        <DropdownButton
          className={cx(styles.locationDropdown, buttonClassName)}
          hideDownCaret
          text={selectedLocationName ?? (noSelectionText || 'United States')}
          icon="location-pin"
          error={error}
        />
      }
      headerTitle="Location"
      footerConfig={{
        primaryActionBtnText: applyText || 'Apply',
        onPrimaryBtnClicked: onApply,
        secondaryActionBtnText: 'Clear',
        onSecondaryBtnClicked: () => {
          updateLocation();
        },
      }}>
      <div className={styles.locationFilterBody}>
        <Menu
          align="end"
          side="bottom"
          trigger={
            <DropdownButton
              className={styles.locationDropdownBtn}
              text={selectedStateCode ? STATE_CODE_TO_NAME[selectedStateCode] : 'Select a State'}
            />
          }>
          {ORDERED_STATE_OPTIONS.map((stateOption) => (
            <MenuActionItem
              style={style}
              text={stateOption.name}
              key={`location-selection-state-${style}-${stateOption.id}`}
              onSelect={() =>
                updateLocation(stateOption.id, COMP_STATE_OPTIONS[stateOption.id][0].location)
              }
              selected={stateOption.id === selectedStateCode}
            />
          ))}
        </Menu>
        <Menu
          align="end"
          side="bottom"
          disabled={selectedStateCode === undefined}
          trigger={
            <DropdownButton
              disabled={selectedStateCode === undefined}
              className={styles.locationDropdownBtn}
              text={
                selectedStateCode && selectedLocationName
                  ? COMP_STATE_OPTIONS[selectedStateCode][0].location === selectedLocationName
                    ? 'Statewide'
                    : selectedLocationName
                  : 'Select a Region'
              }
            />
          }>
          {selectedStateCode &&
            COMP_STATE_OPTIONS[selectedStateCode].map((msaOption) => (
              <MenuActionItem
                style={style}
                text={msaOption.level === 'state' ? 'Statewide' : msaOption.location}
                key={`location-selection-region-${selectedStateCode}-${msaOption.location}`}
                onSelect={() => updateLocation(selectedStateCode, msaOption.location)}
                selected={selectedLocationName === msaOption.location}
              />
            ))}
        </Menu>
      </div>
    </Menu>
  );
};
