/** @format */

import { createReducer } from '@reduxjs/toolkit';
import { H } from 'highlight.run';
import * as analytics from 'analytics';

import { FetchProfileData, User } from 'auth/types';
import { logInUserSuccess } from 'actions/authActions';
import {
  changeUserTheme,
  submitSetupDataSuccess,
  updateOnboardingStatusSuccess,
} from 'actions/onboardingActions';

const initialUser: User = {
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  logged_in: false,
  email_verified: false,
  is_sso_account: false,
  is_superuser: false,
  profile_data: {
    data: {},
    is_done_with_setup: false,
    is_done_with_interests: false,
    is_done_with_career_preferences: false,
    is_done_with_homepage_onboarding: false,
    is_done_with_career_search_onboarding: false,
    career_preference_ids: [],
    category_preferences: undefined,
    personal_email: undefined,
  },
};

function loginUser(user: FetchProfileData): User {
  return {
    id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    logged_in: true,
    email_verified: user.email_verified,
    is_sso_account: user.is_sso_account,
    is_superuser: user.is_superuser,
    profile_data: {
      data: user.profile_data?.data || {},
      is_done_with_setup: user.profile_data?.is_done_with_setup || false,
      is_done_with_interests: user.profile_data?.is_done_with_interests || false,
      is_done_with_career_preferences: user.profile_data?.is_done_with_career_preferences || false,
      is_done_with_homepage_onboarding:
        user.profile_data?.is_done_with_homepage_onboarding || false,
      is_done_with_career_search_onboarding:
        user.profile_data?.is_done_with_career_search_onboarding || false,
      category_preferences: user.profile_data?.category_preferences,
      career_preference_ids: user.profile_data?.career_preference_ids || [],
      personal_email: user.profile_data?.personal_email,
      location_name: user.profile_data?.location_name,
      state_code: user.profile_data?.state_code,
      zipcode: user.profile_data?.zipcode,
    },
  };
}

export default createReducer<User>(initialUser, (builder) => {
  builder
    .addCase(logInUserSuccess, (state, { payload }) => {
      H.identify(payload.email, {
        id: payload.id,
        firstName: payload.first_name,
        lastName: payload.last_name,
        email: payload.email,
      });

      state = loginUser(payload);

      analytics.identify(state);

      return state;
    })
    .addCase(changeUserTheme, (state, { payload }) => {
      if (!state.profile_data?.data) return state;
      state.profile_data.data.selected_theme = payload.theme;
      return state;
    })
    .addCase(submitSetupDataSuccess, (state, { payload }) => {
      if (!state.profile_data?.data) return state;
      state.profile_data.data.selected_avatar = payload.postData.selected_avatar;
      state.profile_data.data.selected_theme = payload.postData.selected_theme;
      state.profile_data.personal_email = payload.postData.personal_email;
      state.profile_data.location_name = payload.postData.location_name;
      state.profile_data.zipcode = payload.postData.zipcode;
      state.profile_data.state_code = payload.postData.state_code;
      state.first_name = payload.postData.first_name;
      state.last_name = payload.postData.last_name;
      return state;
    })
    .addCase(updateOnboardingStatusSuccess, (state, { payload }) => {
      if (!state.profile_data) return state;
      state.profile_data.is_done_with_homepage_onboarding =
        payload.postData.is_done_with_homepage_onboarding ||
        state.profile_data.is_done_with_homepage_onboarding;
      state.profile_data.is_done_with_career_search_onboarding =
        payload.postData.is_done_with_career_search_onboarding ||
        state.profile_data.is_done_with_career_search_onboarding;
      state.profile_data.category_preferences =
        payload.postData.category_preferences ?? state.profile_data.category_preferences;
      return state;
    })
    .addDefaultCase((state) => state);
});
