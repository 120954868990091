/** @format */

import React, { FC } from 'react';
import cx from 'classnames';

import * as styles from './index.css';

type ClassNameType = {
  /**
   * Style override
   */
  className?: string;
};

type BaseProps = ClassNameType & {
  value?: string;
  onChange?: (newValue: string) => void;
};

export type Props = BaseProps;

export const TextArea: FC<Props> = ({ className, value, onChange }) => {
  return (
    <textarea
      rows={5}
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      className={cx(styles.root, className)}
    />
  );
};
