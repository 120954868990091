/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';

import { ProgramSearchResult, updateSaveProgram } from 'actions/programsActions';
import * as styles from './ProgramSearchResult.css';
import * as sharedStyles from './SharedResultStyles.css';
import { Button } from 'components/ds/Button/Button';
import { useDispatch } from 'react-redux';
import { Menu } from 'components/ds/Menu/Menu';
import AddToBoardPopover from 'components/AddToBoardPopover';
import { saveProgramBoards } from 'actions/boardActions';
import { ANALYTIC_EVENTS, track } from 'analytics';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type Props = {
  program: ProgramSearchResult;
  location: string;
};

export default ({ program, location }: Props) => {
  const [boardIds, setBoardIds] = useState<number[]>(program.board_ids);
  const dispatch = useDispatch();

  const costPerYear =
    program.student_cost_per_year !== null
      ? USDollar.format(Math.round(program.student_cost_per_year / 1000) * 1000)
      : '-';

  const costText =
    program.program_length_max && program.program_length_max <= 12 ? 'Cost (Total)' : 'Cost / Yr';

  return (
    <div className={styles.root}>
      <Link
        className={cx(sharedStyles.resultContainer, styles.programResultContainer)}
        to={`/programs/${program.id}?referrer=${location}`}>
        <div className={styles.programNameContainer}>
          <div className={styles.providerName}>
            {program.search_provider_name_highlighted
              ? parse(program.search_provider_name_highlighted)
              : program.provider_name}
          </div>
          <div className={styles.programNameText}>
            {program.search_name_highlighted
              ? parse(program.search_name_highlighted)
              : program.name}
          </div>
        </div>
        <div className={styles.metaDataRow}>
          <div className={styles.resultMetadataContainer}>
            <div className={sharedStyles.resultMetadataTitle}>Location</div>
            <div
              className={cx(sharedStyles.resultsMetadataData, sharedStyles.resultsMetadataNoWrap)}>
              {program.provider_city}, {program.provider_state}
            </div>
          </div>
          <div className={styles.resultMetadataContainer}>
            <div className={sharedStyles.resultMetadataTitle}>Credential</div>
            <div className={sharedStyles.resultsMetadataData}>{program.degree_type}</div>
          </div>
          <div className={styles.resultMetadataContainer}>
            <div className={sharedStyles.resultMetadataTitle}>Learn Time</div>
            <div className={sharedStyles.resultsMetadataData}>
              {convertProgramLengthToMixMaxTime(
                program.program_length_max,
                program.program_length_min,
              )}
            </div>
          </div>

          <div className={styles.resultMetadataContainer}>
            <div className={sharedStyles.resultMetadataTitle}>{costText}</div>
            <div className={sharedStyles.resultMetadataValue}>
              <div className={sharedStyles.resultsMetadataData}>{costPerYear}</div>
              {program.yearly_earning ? (
                <div className={sharedStyles.metadataValueTag}>
                  Earn {USDollar.format(Math.round(program.yearly_earning / 1000))}k/year
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className={sharedStyles.hiddenPlaceholder}>
          <Button stopPropogation icon="bookmark" type="ghost" iconFilled={program.is_saved} />
        </div>

        <div className={sharedStyles.hiddenPlaceholder}>
          <Button stopPropogation icon="add-board" type="ghost" />
        </div>
      </Link>
      <div className={sharedStyles.resultLikeContainer}>
        <Button
          stopPropogation
          icon="bookmark"
          type="ghost"
          iconFilled={program.is_saved}
          onClick={() => {
            track(ANALYTIC_EVENTS.PROGRAM_SAVED, {
              location,
              program_id: program.id,
              program_name: program.name,
              provider_name: program.provider_name,
              is_saved: !program.is_saved,
            });

            dispatch(
              updateSaveProgram(
                { id: program.id, postData: { isSaved: !program.is_saved } },
                () => {
                  toast(
                    <div>
                      <b>{program.name}</b> {!program.is_saved ? 'saved' : 'unsaved'}!
                    </div>,
                    {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 2000,
                    },
                  );
                },
              ),
            );
          }}
        />
        <AddToBoardPopover
          title="Add Program to Board"
          selectedBoards={boardIds}
          onSaveClicked={(boardIds) => {
            setBoardIds(boardIds);
            dispatch(
              saveProgramBoards({ postData: { program_id: program.id, board_ids: boardIds } }),
            );
          }}
          buttonProps={
            boardIds.length > 0
              ? {
                  icon: 'board',
                  iconFilled: true,
                }
              : undefined
          }
          location={location}
          entityType="Program"
          entityId={program.id}
        />
      </div>
    </div>
  );
};

export const convertProgramLengthToMixMaxTime = (
  maxMonths: number | null,
  minMonths: number | null,
) => {
  if (maxMonths === null && minMonths !== null) maxMonths = minMonths;
  if (minMonths === null && maxMonths !== null) minMonths = maxMonths;

  if (maxMonths === null || minMonths === null) return '-';

  const convertUnit =
    maxMonths >= 24 ? (x: number) => Math.round((x / 12) * 10) / 10 : (x: number) => x;
  const unit = maxMonths >= 24 ? 'years' : 'months';

  if (maxMonths === minMonths) {
    return `${convertUnit(maxMonths)} ${unit}`;
  }

  return `${convertUnit(minMonths)}-${convertUnit(maxMonths)} ${unit}`;
};
