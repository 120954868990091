/** @format */

import React, { FC, HTMLInputTypeAttribute } from 'react';
import cx from 'classnames';

import * as styles from './index.css';

type ClassNameType = {
  /**
   * Style override
   */
  className?: string;
};

type BaseProps = ClassNameType & {
  value?: string;
  onChange?: (newValue: string) => void;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  error?: boolean;
  errorMsg?: string;
};

export type Props = BaseProps;

export const Input: FC<Props> = ({ className, placeholder, type, value, onChange, error }) => {
  return (
    <input
      type={type}
      value={value || ''}
      onChange={(e) => onChange?.(e.target.value)}
      className={cx(styles.root, className, { error })}
      placeholder={placeholder}
    />
  );
};
