/** @format */

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import * as styles from './ProfileEditor.css';
import { AVATARS, AVATAR_BY_ID } from 'constants/svgs';
import SectionContainer from 'components/EntityInfoPage/SectionContainer';
import { PALETTE_BY_THEME, THEME_PALETTE } from 'constants/setup';
import ThemeSelector from 'components/SetupPage/ThemeSelector';
import { Input } from 'components/ds/Input/Input';
import { ReduxState } from 'reducers/rootReducer';
import { changeUserTheme, submitSetupData } from 'actions/onboardingActions';
import { Button } from 'components/ds/Button/Button';
import * as EmailValidator from 'email-validator';
import { RegionSelector } from 'components/ds/RegionSelector/RegionSelector';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';
import { theme } from 'components/ds/theme.css';
import { ANALYTIC_EVENTS, track } from 'analytics';

const validateFields = (values: UserInfo) => {
  const errorFields = [];
  if (!values.firstName) errorFields.push('firstName');
  if (!values.lastName) errorFields.push('lastName');
  if (!values.zipcode) errorFields.push('zipcode');
  if (!values.locationName) errorFields.push('locationName');
  if (!values.stateCode) errorFields.push('stateCode');

  if (!EmailValidator.validate(values.email || '')) errorFields.push('email');

  return errorFields;
};

type UserInfo = {
  firstName?: string;
  lastName?: string;
  nickname?: string;
  email?: string;
  zipcode?: string;
  locationName?: string;
  stateCode?: string;
};

type Props = {
  className?: string;
  onSave?: () => void;
};

const ProfileEditor = ({ className, onSave }: Props) => {
  const [selectedAvatar, setSelectedAvatar] = useState(AVATARS.ONE);
  const [selectedTheme, setSelectedTheme] = useState(THEME_PALETTE.LUMINOVA);
  const [userInfo, setUserInfo] = useState<UserInfo>({});
  const [errorFields, setErrorFields] = useState<Set<string>>(new Set());

  const dispatch = useDispatch();

  const { currentUser, saveProfielDataLoading } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      saveProfielDataLoading: createLoadingSelector([ACTION.SUBMIT_SETUP_DATA], false)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    setSelectedAvatar((currentUser.profile_data?.data.selected_avatar as AVATARS) || AVATARS.ONE);
    setSelectedTheme(
      (currentUser.profile_data?.data.selected_theme as THEME_PALETTE) || THEME_PALETTE.LUMINOVA,
    );
    setUserInfo({
      firstName: currentUser.first_name,
      lastName: currentUser.last_name,
      email: currentUser.profile_data?.personal_email,
      nickname: currentUser.profile_data?.data.nickname,
      zipcode: currentUser.profile_data?.zipcode,
      locationName: currentUser.profile_data?.location_name,
      stateCode: currentUser.profile_data?.state_code,
      ...userInfo,
    });
  }, [currentUser]);

  return (
    <div className={cx(styles.body, className)}>
      <div className={styles.bodyContainer}>
        <SectionContainer section="Avatar">
          <div className={styles.avatarRow}>
            {Object.values(AVATARS).map((avatarKey) => (
              <div
                key={`avatar-${avatarKey}`}
                className={cx(styles.iconSelectorBtn, {
                  selected: selectedAvatar === avatarKey,
                })}
                onClick={() => {
                  setSelectedAvatar(avatarKey);
                }}>
                {AVATAR_BY_ID[avatarKey](40, 40)}
              </div>
            ))}
          </div>
        </SectionContainer>
        <SectionContainer section="Theme">
          <div className={styles.themeContainer}>
            {Object.values(THEME_PALETTE).map((theme) => (
              <ThemeSelector
                className={styles.themeSelector}
                key={`selector-${theme}`}
                name={theme}
                colors={PALETTE_BY_THEME[theme].colors}
                onClick={() => {
                  setSelectedTheme(theme);
                  dispatch(changeUserTheme({ theme }));

                  track(ANALYTIC_EVENTS.THEME_SELECTED, { theme });
                }}
                isSelected={selectedTheme === theme}
              />
            ))}
          </div>
        </SectionContainer>
        <SectionContainer section="Information">
          <>
            <div className={styles.inputsRow}>
              <Input
                className={styles.fullInput}
                error={errorFields.has('firstName')}
                placeholder="First Name"
                value={userInfo.firstName ?? (currentUser.first_name || '')}
                onChange={(newValue) =>
                  setUserInfo({
                    ...userInfo,
                    firstName: newValue,
                  })
                }
              />
              <Input
                className={styles.fullInput}
                error={errorFields.has('lastName')}
                placeholder="Last Name"
                value={userInfo.lastName ?? (currentUser.last_name || '')}
                onChange={(newValue) =>
                  setUserInfo({
                    ...userInfo,
                    lastName: newValue,
                  })
                }
              />
            </div>
            <div className={styles.inputsRow}>
              <Input
                className={styles.fullInput}
                error={errorFields.has('nickname')}
                placeholder="Nickname (Optional)"
                value={userInfo.nickname}
                onChange={(newValue) =>
                  setUserInfo({
                    ...userInfo,
                    nickname: newValue,
                  })
                }
              />
              <Input
                className={styles.fullInput}
                error={errorFields.has('email')}
                type="email"
                placeholder="Personal Email"
                value={userInfo.email}
                onChange={(newValue) =>
                  setUserInfo({
                    ...userInfo,
                    email: newValue,
                  })
                }
              />
            </div>
          </>
        </SectionContainer>
        <SectionContainer section="Location">
          <div className={styles.inputsRow}>
            <RegionSelector
              buttonClassName={styles.regionSelectorBtn}
              style="home"
              error={errorFields.has('locationName') || errorFields.has('stateCode')}
              selectedLocationName={userInfo.locationName}
              selectedStateCode={userInfo.stateCode}
              updateLocation={(stateCode, locationName) => {
                setUserInfo({
                  ...userInfo,
                  stateCode,
                  locationName,
                });
              }}
              onApply={() => {}}
              applyText="Save"
              noSelectionText="Select your region"
            />
            <Input
              className={styles.fullInput}
              error={errorFields.has('zipcode')}
              placeholder="Zipcode"
              value={userInfo.zipcode}
              onChange={(newValue) =>
                setUserInfo({
                  ...userInfo,
                  zipcode: newValue,
                })
              }
            />
          </div>
        </SectionContainer>

        <div className={styles.nextBtnContainer}>
          <Button
            type="primary"
            size="large"
            text="Save Profile"
            disabled={saveProfielDataLoading}
            onClick={() => {
              if (saveProfielDataLoading) return;
              const values = {
                firstName: currentUser.first_name,
                lastName: currentUser.last_name,
                ...userInfo,
              };
              const errorFields = validateFields(values);
              if (errorFields.length > 0) {
                track(ANALYTIC_EVENTS.PROFILE_UPDATE_SUBMITTED, {
                  successful: false,
                  errorFields: errorFields,
                  nicknameAdded: !!values.nickname,
                  emailAdded: !!values.email,
                });

                setErrorFields(new Set(errorFields));
                return;
              }

              track(ANALYTIC_EVENTS.PROFILE_UPDATE_SUBMITTED, {
                successful: true,
                nicknameEdited: !!values.nickname,
              });

              setErrorFields(new Set());
              dispatch(
                submitSetupData(
                  {
                    postData: {
                      first_name: values.firstName,
                      last_name: values.lastName,
                      personal_email: values.email || '',
                      selected_avatar: selectedAvatar,
                      selected_theme: selectedTheme,
                      location_name: values.locationName || '',
                      state_code: values.stateCode || '',
                      zipcode: values.zipcode || '',
                    },
                  },
                  () => {
                    toast('Profile updated!', {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 2000,
                    });

                    if (onSave) onSave();
                    else window.location.href = '/interests';
                  },
                ),
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileEditor;
