/** @format */

import { createReducer } from '@reduxjs/toolkit';
import {
  BOARD_PATH_INFO,
  CreateBoardConfig,
  CreateBoardStep,
  clearCreateBoardState,
  createBoardCareers,
  createBoardEmoji,
  createBoardGoBack,
  createBoardLocation,
  createBoardPath,
  createBoardPrograms,
  createBoardPurpose,
  createBoardWizardSuccess,
} from 'actions/createBoardActions';

export type ResetOnboardingData = {};

type State = {
  boardConfig?: CreateBoardConfig;
  step?: CreateBoardStep;
  stepNum?: number;
  wizardComplete?: boolean;
};

const initialState: State = {};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(clearCreateBoardState, (state, { payload }) => {
      return {};
    })
    .addCase(createBoardGoBack, (state, { payload }) => {
      if (state.stepNum === undefined || !state.boardConfig) return {};
      if (state.stepNum === 0) {
        return {};
      }

      state.step = BOARD_PATH_INFO[state.boardConfig.startPath].steps[state.stepNum - 1];
      state.stepNum -= 1;

      return state;
    })

    .addCase(createBoardPath, (state, { payload }) => {
      state.boardConfig = {
        startPath: payload.path,
      };

      state.step = BOARD_PATH_INFO[payload.path].steps[0];
      state.stepNum = 0;

      return state;
    })
    .addCase(createBoardPurpose, (state, { payload }) => {
      if (!state.boardConfig || state.stepNum === undefined) return {};

      state.boardConfig.purpose = payload.purpose;

      // we'll have the WizardStep call the create board post action if it is the last step
      // so don't worry about indexing out of list on the steps
      if (BOARD_PATH_INFO[state.boardConfig.startPath].steps.length === state.stepNum + 1) {
        state.wizardComplete = true;
        return state;
      }

      state.step = BOARD_PATH_INFO[state.boardConfig.startPath].steps[state.stepNum + 1];
      state.stepNum += 1;
      return state;
    })
    .addCase(createBoardLocation, (state, { payload }) => {
      if (!state.boardConfig || state.stepNum === undefined) return {};

      state.boardConfig.location = payload.location;

      // we'll have the WizardStep call the create board post action if it is the last step
      // so don't worry about indexing out of list on the steps
      if (BOARD_PATH_INFO[state.boardConfig.startPath].steps.length === state.stepNum + 1) {
        state.wizardComplete = true;
        return state;
      }

      state.step = BOARD_PATH_INFO[state.boardConfig.startPath].steps[state.stepNum + 1];
      state.stepNum += 1;
      return state;
    })

    .addCase(createBoardEmoji, (state, { payload }) => {
      if (!state.boardConfig || state.stepNum === undefined) return {};

      state.boardConfig.emoji = payload.emoji;

      // we'll have the WizardStep call the create board post action if it is the last step
      // so don't worry about indexing out of list on the steps
      if (BOARD_PATH_INFO[state.boardConfig.startPath].steps.length === state.stepNum + 1) {
        state.wizardComplete = true;
        return state;
      }

      state.step = BOARD_PATH_INFO[state.boardConfig.startPath].steps[state.stepNum + 1];
      state.stepNum += 1;
      return state;
    })
    .addCase(createBoardCareers, (state, { payload }) => {
      if (!state.boardConfig || state.stepNum === undefined) return {};

      state.boardConfig.careerIds = payload.careerIds;

      // we'll have the WizardStep call the create board post action if it is the last step
      // so don't worry about indexing out of list on the steps
      if (BOARD_PATH_INFO[state.boardConfig.startPath].steps.length === state.stepNum + 1) {
        state.wizardComplete = true;
        return state;
      }

      state.step = BOARD_PATH_INFO[state.boardConfig.startPath].steps[state.stepNum + 1];
      state.stepNum += 1;
      return state;
    })
    .addCase(createBoardPrograms, (state, { payload }) => {
      if (!state.boardConfig || state.stepNum === undefined) return {};

      state.boardConfig.programIds = payload.programIds;

      // we'll have the WizardStep call the create board post action if it is the last step
      // so don't worry about indexing out of list on the steps
      if (BOARD_PATH_INFO[state.boardConfig.startPath].steps.length === state.stepNum + 1) {
        state.wizardComplete = true;
        return state;
      }

      state.step = BOARD_PATH_INFO[state.boardConfig.startPath].steps[state.stepNum + 1];
      state.stepNum += 1;
      return state;
    })
    .addCase(createBoardWizardSuccess, (state, { payload }) => {
      return {};
    })

    .addDefaultCase((state) => state);
});
