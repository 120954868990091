/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import * as styles from 'components/SearchPage/style.css';
import SearchPage, { FilterType } from 'components/SearchPage/SearchPage';
import {
  cacheCareerSearch,
  fetchCareerSearch,
  updateCareerCacheUrlParams,
  cacheCareerSearchScrollTop,
} from 'actions/careerActions';
import { ReduxState } from 'reducers/rootReducer';
import { SearchSortOption, CAREER_SEARCH_SORTS } from 'constants/search';
import SalaryFilter from 'components/SearchPage/CareerFilters/SalaryFilter';
import LearnTimeFilter from 'components/SearchPage/CareerFilters/LearnTimeFilter';
import CareerClusterFilter, {
  CAREER_CLUSTER_INFO,
} from 'components/SearchPage/CareerFilters/CareerClusterFilter';
import { COMP_STATE_OPTIONS } from 'constants/data';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';
import { InterestData, fetchInterests } from 'actions/interestActions';
import { theme } from 'components/ds/theme.css';
import { User } from 'auth/types';
import InterestsFilter from 'components/SearchPage/CareerFilters/InterestsFilter';
import { ANALYTIC_EVENTS, ANALYTIC_PAGES, page, track } from 'analytics';
import CareerCard from 'components/HomePage/CareerCard/CareerCard';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export enum CareerCategoryFilter {
  NOT_FOR_YOU = 'Not For You',
  UNIQUE = 'Unique',
  COMMON = 'Common',
  HIGH_SALARY = 'High Salary',
  BACHELORS = 'Bachelors Degree',
  ADVANCED_DEGREE = 'Advanced Degree',
  HIGH_DEMAND = 'High Demand',
  CLIMATE = 'Climate',
  HELPING_OTHERS = 'Helping Others',
  HOLLYWOOD = 'Hollywood',
  PATRIOTIC = 'Patriotic',
  SOCIAL_BUTTERFLY = 'Extrovert',
  BUILDER = 'Building',
  OUTDOORS = 'Outdoors',
  INDOORS = 'Indoors',
  LAW_AND_ORDER = 'Justice',
}

export enum CareerBucketCategory {
  THEME = 'Career Themes',
  REQUIREMENTS = 'Career Requirements',
  JOB_ATTRIBUTES = 'Job Prospects',
}

const BUCKET_SORT_PREF: Record<string, SearchSortOption> = {
  [CareerCategoryFilter.HIGH_DEMAND]: SearchSortOption.DEMAND_HL,
};

export const BUCKET_FILTERS = [
  {
    id: CareerCategoryFilter.CLIMATE,
    name: CareerCategoryFilter.CLIMATE,
    icon: 'climate',
    tooltipText: 'Careers that can advance the effort to curb climate change.',
    category: CareerBucketCategory.THEME,
  },
  {
    id: CareerCategoryFilter.PATRIOTIC,
    name: CareerCategoryFilter.PATRIOTIC,
    icon: 'flag',
    tooltipText: 'Careers that help advance the interests of the United States.',
    category: CareerBucketCategory.THEME,
  },
  {
    id: CareerCategoryFilter.HOLLYWOOD,
    name: CareerCategoryFilter.HOLLYWOOD,
    icon: 'hollywood',
    tooltipText: 'Careers in show biz.',
    category: CareerBucketCategory.THEME,
  },
  {
    id: CareerCategoryFilter.SOCIAL_BUTTERFLY,
    name: CareerCategoryFilter.SOCIAL_BUTTERFLY,
    icon: 'social-butterfly',
    tooltipText: 'Careers that extroverts tend to love.',
    category: CareerBucketCategory.THEME,
  },
  {
    id: CareerCategoryFilter.OUTDOORS,
    name: CareerCategoryFilter.OUTDOORS,
    icon: 'tree',
    tooltipText: 'Careers that will get you into the outdoors.',
    category: CareerBucketCategory.THEME,
  },
  {
    id: CareerCategoryFilter.BUILDER,
    name: CareerCategoryFilter.BUILDER,
    icon: 'maker',
    tooltipText: 'Careers that involve making and creating things.',
    category: CareerBucketCategory.THEME,
  },
  // {
  //   name: CareerCategoryFilter.HELPING_OTHERS,
  //   icon: 'helping-others',
  //   tooltipText: 'Careers that involving helping others.',
  //   category: CareerBucketCategory.THEME,
  // },
  // //------
  // {
  //   name: CareerCategoryFilter.LAW_AND_ORDER,
  //   icon: 'gavel',
  //   tooltipText: 'Careers that help maintain law and order in society.',
  //   category: CareerBucketCategory.THEME,
  // },

  // {
  //   name: CareerCategoryFilter.NOT_FOR_YOU,
  //   icon: 'sad-face',
  //   tooltipText: 'Careers that match the interests that you dislike.',
  // },
  {
    id: CareerCategoryFilter.BACHELORS,
    name: CareerCategoryFilter.BACHELORS,
    icon: 'college',
    tooltipText: "Careers that require a Bachelor's Degree to start.",
    category: CareerBucketCategory.REQUIREMENTS,
  },
  {
    id: CareerCategoryFilter.ADVANCED_DEGREE,
    name: CareerCategoryFilter.ADVANCED_DEGREE,
    icon: 'advanced-degree',
    tooltipText: 'Careers that require at least a Graduate Degree to start.',
    category: CareerBucketCategory.REQUIREMENTS,
  },
  {
    id: CareerCategoryFilter.UNIQUE,
    name: CareerCategoryFilter.UNIQUE,
    icon: 'rare',
    tooltipText: 'Bottom 5% of careers in terms of number of people employed.',
    category: CareerBucketCategory.JOB_ATTRIBUTES,
  },
  {
    id: CareerCategoryFilter.COMMON,
    name: CareerCategoryFilter.COMMON,
    icon: 'common',
    tooltipText: 'Top 5% of careers in terms of number of people employed.',
    category: CareerBucketCategory.JOB_ATTRIBUTES,
  },
  {
    id: CareerCategoryFilter.HIGH_SALARY,
    name: CareerCategoryFilter.HIGH_SALARY,
    icon: 'money',
    tooltipText: 'Top 5% of careers with the highest entry level pay.',
    category: CareerBucketCategory.JOB_ATTRIBUTES,
  },
  {
    id: CareerCategoryFilter.HIGH_DEMAND,
    name: CareerCategoryFilter.HIGH_DEMAND,
    icon: 'trending_up',
    tooltipText:
      'Careers that, for the selected region, employ many more people relative to other places.',
    category: CareerBucketCategory.JOB_ATTRIBUTES,
  },
];

export const DEFAULT_SEARCH_PARAMS: SearchParams = {
  page: 0,
  sort: SearchSortOption.INTEREST_RELEVANCE,
};

export type SearchParams = {
  page: number;
  sort: SearchSortOption;
  compMax?: number;
  compMin?: number;
  learnTimeOptions?: number[];
  careerClusterOptions?: string[];
  locationName?: string;
  stateCode?: string;
  bucketName?: string;
  savedOnly?: boolean;
  searchString?: string;
  interestsOptions?: number[];
};

type Props = {
  currentUser: User;
};

export const fetchCareerSearchData = (
  params: SearchParams,
  onUpdateCache: (urlParams: string) => void,
  onFetchResults: (params: Record<string, string>) => void,
  dontChangeUrl?: boolean,
) => {
  const getData: Record<string, string> = {
    page: params.page.toString(),
    sort: params.sort,
    results_per_page: '10',
  };

  if (params.bucketName !== undefined) getData['category'] = params.bucketName;
  if (params.searchString !== undefined) getData['search_string'] = params.searchString;
  if (params.savedOnly !== undefined) getData['saved'] = params.savedOnly.toString();
  if (params.compMax !== undefined) getData['comp_max'] = params.compMax.toString();
  if (params.compMin !== undefined) getData['comp_min'] = params.compMin.toString();
  if (params.learnTimeOptions !== undefined && params.learnTimeOptions.length > 0)
    getData['learn_time'] = params.learnTimeOptions.toString();
  if (params.careerClusterOptions !== undefined && params.careerClusterOptions.length > 0)
    getData['career_clusters'] = params.careerClusterOptions.join('|');
  if (params.interestsOptions !== undefined && params.interestsOptions.length > 0)
    getData['interests'] = params.interestsOptions.toString();

  if (params.stateCode && params.locationName) {
    const locations = COMP_STATE_OPTIONS[params.stateCode];
    const level = locations[0].location === params.locationName ? 'state' : 'msa';
    getData['location_name'] = params.locationName;
    getData['location_level'] = level;
    getData['state_code'] = params.stateCode;
  }

  if (!dontChangeUrl) {
    const urlParams = new URLSearchParams(getData).toString();

    const newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      urlParams;
    window.history.pushState({ path: newUrl }, '', newUrl);
    onUpdateCache(urlParams);
  }

  onFetchResults(getData);
};

export const createCareerFilterConfigs = (
  searchParams: SearchParams,
  tempSearchParams: SearchParams,
  defaultSearchParams: SearchParams,
  setSearchParams: (params: SearchParams) => void,
  setTempSearchParams: (params: SearchParams) => void,
  fetchData: (params: SearchParams) => void,
  interests: Record<string, InterestData>,
  compMin: number,
  compMax: number,
  loadingResults?: boolean,
) => {
  const applyFilters = (searchParams: SearchParams) => {
    const newSearchParam = {
      ...searchParams,
      page: 0,
    };
    setSearchParams(newSearchParam);
    fetchData(newSearchParam);
    setTempSearchParams(newSearchParam);
    track(ANALYTIC_EVENTS.CAREER_SEARCH_FILTERS_APPLIED, newSearchParam);
  };

  return {
    onSearchStringUpdated: debounce((s?: string) => {
      if (!searchParams.searchString && !s) return;

      const sortOption =
        s && s.length > 0
          ? SearchSortOption.RELEVANCE
          : defaultSearchParams.sort || searchParams.sort;

      const newSearchParam = {
        ...searchParams,
        page: 0,
        searchString: s,
        sort: sortOption,
      };
      fetchData(newSearchParam);
      setSearchParams(newSearchParam);
      setTempSearchParams(newSearchParam);
      track(ANALYTIC_EVENTS.CAREER_SEARCH_SEARCH, newSearchParam);
    }, 500),
    resetFilters: () => {
      fetchData(defaultSearchParams);
      setSearchParams(defaultSearchParams);
      setTempSearchParams(defaultSearchParams);
      track(ANALYTIC_EVENTS.CAREER_SEARCH_LOAD_MORE_CLICKED);
    },
    onLoadMoreClicked: () => {
      const newSearchParam = {
        ...searchParams,
        page: searchParams.page + 1,
      };
      fetchData(newSearchParam);
      setSearchParams(newSearchParam);
      setTempSearchParams(newSearchParam);
      track(ANALYTIC_EVENTS.CAREER_SEARCH_LOAD_MORE_CLICKED, newSearchParam);
    },
    onSortChange: (sort: SearchSortOption) => {
      const newSearchParam = {
        ...searchParams,
        page: 0,
        sort,
      };
      fetchData(newSearchParam);
      setSearchParams(newSearchParam);
      setTempSearchParams(newSearchParam);

      track(ANALYTIC_EVENTS.CAREER_SEARCH_SORT_UPDATED, newSearchParam);
    },
    filterConfigs: [
      {
        type: FilterType.CAREER_CLUSTER,
        component: (
          <CareerClusterFilter
            selectedOptions={tempSearchParams.careerClusterOptions}
            updateSelectedOptions={(option, isSelected) => {
              let newOptions;
              if (isSelected) {
                newOptions = [...(tempSearchParams.careerClusterOptions || []), option];
              } else {
                newOptions = (tempSearchParams.careerClusterOptions || []).filter(
                  (selectedOption) => selectedOption !== option,
                );
              }
              const newSearchParam = {
                ...tempSearchParams,
                careerClusterOptions: [...newOptions],
              };
              setTempSearchParams(newSearchParam);
              applyFilters(newSearchParam as SearchParams);
            }}
            disabled={loadingResults}
          />
        ),
      },
      {
        type: FilterType.SALARY,
        component: (
          <SalaryFilter
            compMax={compMax}
            compMin={compMin}
            setMax={tempSearchParams.compMax}
            setMin={tempSearchParams.compMin}
            updateMinMax={(min, max, applyFilter) => {
              const newSearchParam = {
                ...tempSearchParams,
                compMax: max,
                compMin: min,
              };
              setSearchParams(newSearchParam);
              setTempSearchParams(newSearchParam);
              applyFilter && applyFilters(newSearchParam as SearchParams);
            }}
            disabled={loadingResults}
          />
        ),
      },
      {
        type: FilterType.LEARN_TIME,
        component: (
          <LearnTimeFilter
            selectedOptions={tempSearchParams.learnTimeOptions}
            updateSelectedOptions={(option, isSelected) => {
              let newOptions;
              if (isSelected) {
                newOptions = [...(tempSearchParams.learnTimeOptions || []), option];
              } else {
                newOptions = (tempSearchParams.learnTimeOptions || []).filter(
                  (selectedOption) => selectedOption !== option,
                );
              }
              const newSearchParam = {
                ...tempSearchParams,
                learnTimeOptions: [...newOptions],
              };
              setSearchParams(newSearchParam);
              setTempSearchParams(newSearchParam);
              applyFilters(newSearchParam as SearchParams);
            }}
            disabled={loadingResults}
          />
        ),
      },
      {
        type: FilterType.INTERESTS,
        component: (
          <InterestsFilter
            interests={Object.values(interests)}
            selectedOptions={tempSearchParams.interestsOptions}
            updateSelectedOptions={(option, isSelected) => {
              let newOptions;
              if (isSelected) {
                newOptions = [...(tempSearchParams.interestsOptions || []), option];
              } else {
                newOptions = (tempSearchParams.interestsOptions || []).filter(
                  (selectedOption) => selectedOption !== option,
                );
              }
              const newSearchParam = {
                ...tempSearchParams,
                interestsOptions: [...newOptions],
              };
              setSearchParams(newSearchParam);
              setTempSearchParams(newSearchParam);
              applyFilters(newSearchParam as SearchParams);
            }}
            disabled={loadingResults}
          />
        ),
      },
    ],
  };
};

const CareersOverviewPage = ({ currentUser }: Props) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    ...DEFAULT_SEARCH_PARAMS,
    locationName: currentUser.profile_data?.location_name,
    stateCode: currentUser.profile_data?.state_code,
  });
  const [tempSearchParams, setTempSearchParams] = useState<SearchParams>(searchParams);

  const {
    cachedState,
    cachedScrollTop,
    interests,
    careers,
    compMax,
    compMin,
    totalResults,
    loadingResults,
  } = useSelector(
    (state: ReduxState) => ({
      cachedState: state.careersSearch.cachedState,
      cachedScrollTop: state.careersSearch.cachedScrollTop,
      interests: state.interestReducer,
      careers: state.careersSearch.careers,
      compMax: 300000,
      compMin: 0,
      totalResults: state.careersSearch.totalResults,
      loadingResults: createLoadingSelector([ACTION.CAREER_SEARCH], true)(state),
    }),
    shallowEqual,
  );

  const fetchData = (params: SearchParams, dontChangeUrl?: boolean) =>
    fetchCareerSearchData(
      params,
      (urlParams) => dispatch(updateCareerCacheUrlParams({ urlParams })),
      (params) => dispatch(fetchCareerSearch({ getData: params })),
      dontChangeUrl,
    );

  useEffect(() => {
    dispatch(fetchInterests());

    let params = {
      ...DEFAULT_SEARCH_PARAMS,
      locationName: currentUser.profile_data?.location_name,
      stateCode: currentUser.profile_data?.state_code,
    };
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const queryParams = Object.fromEntries(urlParams.entries());
      const defaultFilters: FilterType[] = [];
      params.searchString = queryParams.search_string;
      params.bucketName = queryParams.category;
      params.savedOnly = queryParams.saved === 'true';
      params.compMax = queryParams.comp_max ? parseInt(queryParams.comp_max) : undefined;
      params.compMin = queryParams.comp_min ? parseInt(queryParams.comp_min) : undefined;
      if (queryParams.sort) params.sort = queryParams.sort as SearchSortOption;
      if (queryParams.location_name) params.locationName = queryParams.location_name;
      if (queryParams.state_code) params.stateCode = queryParams.state_code;

      if (queryParams.learn_time) {
        defaultFilters.push(FilterType.LEARN_TIME);
        params.learnTimeOptions = queryParams.learn_time.split(',').map((s) => parseInt(s));
      }
      if (queryParams.interests) {
        defaultFilters.push(FilterType.INTERESTS);
        params.interestsOptions = queryParams.interests.split(',').map((s) => parseInt(s));
      }

      if (queryParams.career_clusters) {
        defaultFilters.push(FilterType.CAREER_CLUSTER);
        params.careerClusterOptions = queryParams.career_clusters.split('|');
      }
      if (queryParams.comp_max || queryParams.comp_min) {
        defaultFilters.push(FilterType.SALARY);
      }

      setSearchParams(params);
      setTempSearchParams(params);
    }

    // if (cachedState) {
    //   dispatch(useCareerSearchCache());
    // } else {
    fetchData(params, true);
    // }

    page(ANALYTIC_PAGES.CAREER_SEARCH_PAGE);

    return () => {
      dispatch(cacheCareerSearch());
    };
  }, [dispatch]);

  const careerSearchConfig = createCareerFilterConfigs(
    searchParams,
    tempSearchParams,
    { ...DEFAULT_SEARCH_PARAMS, bucketName: searchParams.bucketName },
    setSearchParams,
    setTempSearchParams,
    fetchData,
    interests,
    compMin,
    compMax,
    loadingResults,
  );

  return (
    <SearchPage
      style="career"
      searchPlaceholder="Search Careers"
      cachedScrollTop={undefined}
      onBodyScroll={(scrollTop) => {
        dispatch(cacheCareerSearchScrollTop({ scrollTop }));
      }}
      secondaryColor={theme.colors.careersSecondaryColor}
      categoryFilters={[...BUCKET_FILTERS, ...Object.values(CAREER_CLUSTER_INFO)]}
      totalResults={totalResults}
      resultObjects={careers}
      loadingResults={loadingResults}
      searchString={searchParams.searchString}
      onSearchStringUpdated={careerSearchConfig.onSearchStringUpdated}
      selectedBucket={
        searchParams.bucketName
          ? searchParams.bucketName
          : searchParams.careerClusterOptions && searchParams.careerClusterOptions.length === 1
          ? searchParams.careerClusterOptions[0]
          : undefined
      }
      resultRenderer={(career) => (
        <CareerCard
          className={styles.careerCard}
          career={career}
          key={career.id}
          location="Careers Search Page"
        />
      )}
      onSortChange={careerSearchConfig.onSortChange}
      onLoadMoreClicked={careerSearchConfig.onLoadMoreClicked}
      loadMoreBtnText="Load more careers"
      resetFilters={careerSearchConfig.resetFilters}
      sortOptions={CAREER_SEARCH_SORTS}
      selectedSort={searchParams.sort}
      filtersConfig={careerSearchConfig.filterConfigs}
    />
  );
};

export default CareersOverviewPage;
