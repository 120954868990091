/** @format */

import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Icon, IconName } from 'components/ds/Icon/Icon';
import * as styles from './styles.css';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

import WebsiteLogo from 'images/site_logo.png';
import { Menu, MenuActionItem } from 'components/ds/Menu/Menu';
import { ReduxState } from 'reducers/rootReducer';
import { logOutUser } from 'actions/authActions';

import { sprinkles, vars } from 'components/ds';
import { theme } from 'components/ds/theme.css';
import { AVATARS, AVATAR_BY_ID } from 'constants/svgs';
import { ANALYTIC_EVENTS, track } from 'analytics';
import { Button } from 'components/ds/Button/Button';
import { fetchBoards } from 'actions/boardActions';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';

export enum NAV_TAB_NAMES {
  HOME = 'Home',
  EXPLORE = 'Explore',
  SAVED = 'Saved',
  DATA = 'Data',
  PROFILE = 'Profile',
  CAREERS = 'Careers',
  PROGRAMS = 'Programs',
  BOARDS = 'Boards',
}

const STUDENT_NAVIGATION_TABS = {
  [NAV_TAB_NAMES.HOME]: {
    name: NAV_TAB_NAMES.HOME,
    path: '/',
    icon: 'home',
    primaryColor: theme.colors.careersPrimaryColor,
    isSuperUserOnly: false,
  },
  [NAV_TAB_NAMES.EXPLORE]: {
    name: NAV_TAB_NAMES.EXPLORE,
    path: '/explore',
    icon: 'explore',
    primaryColor: theme.colors.programsPrimaryColor,
    isSuperUserOnly: false,
  },
  [NAV_TAB_NAMES.SAVED]: {
    name: NAV_TAB_NAMES.SAVED,
    path: '/saved',
    icon: 'bookmark',
    primaryColor: theme.colors.homePrimaryColor,
    isSuperUserOnly: false,
  },
  [NAV_TAB_NAMES.DATA]: {
    name: NAV_TAB_NAMES.DATA,
    path: '/data',
    icon: 'star',
    primaryColor: vars.colors.dataPrimaryColor,
    isSuperUserOnly: true,
  },
};

const STUDENT_NAVIGATION_TABS_ORDER = [
  STUDENT_NAVIGATION_TABS[NAV_TAB_NAMES.HOME],
  STUDENT_NAVIGATION_TABS[NAV_TAB_NAMES.EXPLORE],
  STUDENT_NAVIGATION_TABS[NAV_TAB_NAMES.SAVED],
  STUDENT_NAVIGATION_TABS[NAV_TAB_NAMES.DATA],
];

type PassedProps = {
  activeTabId?: NAV_TAB_NAMES;
  className?: string;
};

type Props = PassedProps;

const Sidebar = ({ activeTabId, className }: Props) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { currentUser, cachedCareerUrlSearch, cachedProgramUrlSearch, boardPageId, boardsById } =
    useSelector(
      (state: ReduxState) => ({
        currentUser: state.currentUser,
        cachedCareerUrlSearch: state.careersSearch.cachedUrlSearch,
        cachedProgramUrlSearch: state.programsSearch.cachedUrlSearch,
        boardsById: state.boards.boards,
        boardsLoading: createLoadingSelector([ACTION.FETCH_BOARDS], true)(state),
        boardPageId: state.boardData.board?.id,
      }),
      shallowEqual,
    );

  const renderUserInfoContent = (isUnselected?: boolean) => {
    let initials = currentUser.first_name[0] || '';
    if (initials.length === 0) {
      initials = 'A';
    }
    return (
      <div className={styles.footerContainer}>
        <div className={isUnselected ? styles.footerUserIconInactive : styles.footerUserIcon}>
          {currentUser.profile_data?.data.selected_avatar
            ? AVATAR_BY_ID[currentUser.profile_data.data.selected_avatar as AVATARS](20, 20)
            : initials}
        </div>
        <div className={styles.footerUserName}>
          {currentUser.first_name} {currentUser.last_name}
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(fetchBoards());
  }, []);

  if (width <= 640) {
    return (
      <div className={styles.mobileSidebar}>
        {STUDENT_NAVIGATION_TABS_ORDER.map(
          (tab) =>
            (tab.isSuperUserOnly ? currentUser.is_superuser : true) && (
              <Link
                className={styles.sidebarTab}
                key={tab.name}
                to={tab.path}
                style={{ color: activeTabId === tab.name ? tab.primaryColor : undefined }}>
                <div
                  className={cx(styles.sidebarTabIcon, {
                    [styles.selectedSidebarTabIcon]: activeTabId === tab.name,
                  })}
                  style={{
                    backgroundColor: activeTabId === tab.name ? tab.primaryColor : undefined,
                  }}>
                  <Icon name={tab.icon as IconName} />
                </div>
                {tab.name}
              </Link>
            ),
        )}
        <Link className={cx(styles.sidebarTab)} to="/profile">
          {renderUserInfoContent(activeTabId !== NAV_TAB_NAMES.PROFILE)}
          <div style={{ marginTop: 4 }}>Profile</div>
        </Link>
      </div>
    );
  }

  return (
    <div className={cx(styles.sidebarContainer, className)}>
      <div>
        <div className={sprinkles({ paddingX: 'sp1', paddingY: 'sp3', marginBottom: 'sp5' })}>
          <Link className={sprinkles({ paddingX: 'sp2', display: 'flex' })} to="/">
            <img className={styles.siteLogo} src={WebsiteLogo} />
          </Link>
        </div>
        <div
          className={sprinkles({ display: 'flex', flexDirection: 'column', alignItems: 'center' })}>
          {STUDENT_NAVIGATION_TABS_ORDER.map(
            (tab) =>
              (tab.isSuperUserOnly ? currentUser.is_superuser : true) && (
                <Link
                  className={styles.sidebarTab}
                  key={tab.name}
                  to={
                    tab.name === NAV_TAB_NAMES.CAREERS && cachedCareerUrlSearch
                      ? `/careers/?${cachedCareerUrlSearch}`
                      : tab.name === NAV_TAB_NAMES.PROGRAMS && cachedProgramUrlSearch
                      ? `/programs/?${cachedProgramUrlSearch}`
                      : tab.path
                  }
                  style={{ color: activeTabId === tab.name ? tab.primaryColor : undefined }}>
                  <div
                    className={cx(styles.sidebarTabIcon, {
                      [styles.selectedSidebarTabIcon]: activeTabId === tab.name,
                    })}>
                    <Icon fontSize={28} name={tab.icon as IconName} />
                  </div>
                  {tab.name}
                </Link>
              ),
          )}
        </div>
        <div>
          <div className={styles.boardSectionHeader}>
            <div className={styles.boardSectionText}>My Boards</div>
            <Button
              className={styles.addBoardBtn}
              icon="plus-circle"
              type="ghost"
              to="/new-board"
            />
          </div>
          <div>
            {Object.values(boardsById).map((board) => (
              <div className={styles.navBoardOptionContainer} key={`sidebar-boards-${board.id}`}>
                <Link
                  className={cx(styles.navBoardOption, {
                    selected:
                      activeTabId === NAV_TAB_NAMES.BOARDS &&
                      boardPageId &&
                      boardPageId === board.id,
                  })}
                  to={`/board/${board.id}`}>
                  {board.emoji || '⚪️'} {board.name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.sidebarFooter}>
        <Menu align="end" side="right" trigger={renderUserInfoContent()} width="small">
          <MenuActionItem onSelect={() => (window.location.href = '/profile')} text="Profile" />
          <MenuActionItem
            onSelect={() => {
              track(ANALYTIC_EVENTS.LOGOUT_CLICKED);
              dispatch(logOutUser());
            }}
            text="Log Out"
          />
        </Menu>
      </div>
    </div>
  );
};

export default Sidebar;
