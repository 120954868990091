/** @format */

import { createAction } from '@reduxjs/toolkit';
import { defineAPIPostAction } from 'actions/actionUtils';

import { ACTION } from 'actions/types';
import { IconName } from 'components/ds/Icon/Icon';
import { BoardData } from './boardActions';

export enum CreateBoardStep {
  PICK_PATH = 'Pick Path',
  PURPOSE = 'Purpose',
  CAREERS = 'Careers',
  PROGRAMS = 'Programs',
  LOCATION = 'Location',
  INSTITUTION = 'Institution',
  EMOJI = 'Emoji',
}

export type CreateBoardConfig = {
  startPath: BoardStartingPath;
  purpose?: string;
  location?: string;
  emoji?: string;
  careerIds?: number[];
  programIds?: number[];
};

export enum BoardStartingPath {
  PURPOSE = 'Purpose',
  CAREER = 'Career',
  INSTITUTION = 'Institution',
  PROGRAM = 'Program',
  LOCATION = 'Location',
}

export const BOARD_PATH_INFO: Record<
  BoardStartingPath,
  { icon: IconName; description: string; steps: CreateBoardStep[] }
> = {
  [BoardStartingPath.PURPOSE]: {
    icon: 'target',
    description: 'What you want to do to benefit the world',
    steps: [
      CreateBoardStep.PURPOSE,
      CreateBoardStep.EMOJI,
      CreateBoardStep.CAREERS,
      CreateBoardStep.PROGRAMS,
    ],
  },
  [BoardStartingPath.CAREER]: {
    icon: 'career',
    description: 'What you want to work as',
    steps: [CreateBoardStep.CAREERS, CreateBoardStep.PROGRAMS, CreateBoardStep.EMOJI],
  },
  [BoardStartingPath.PROGRAM]: {
    icon: 'programs',
    description: 'What you’re interested in studying',
    steps: [CreateBoardStep.PROGRAMS, CreateBoardStep.CAREERS, CreateBoardStep.EMOJI],
  },
  [BoardStartingPath.INSTITUTION]: {
    icon: 'college',
    description: 'ex: Point Community College',
    steps: [
      CreateBoardStep.INSTITUTION,
      CreateBoardStep.EMOJI,
      CreateBoardStep.CAREERS,
      CreateBoardStep.PROGRAMS,
    ],
  },
  [BoardStartingPath.LOCATION]: {
    icon: 'location-pin',
    description: 'What city or state you want to live in',
    steps: [
      CreateBoardStep.LOCATION,
      CreateBoardStep.EMOJI,
      CreateBoardStep.CAREERS,
      CreateBoardStep.PROGRAMS,
    ],
  },
};

export const clearCreateBoardState = createAction(ACTION.CLEAR_CREATE_BOARD_STATE);
export const createBoardGoBack = createAction(ACTION.CREATE_BOARD_GO_BACK);
export const createBoardPath = createAction<{ path: BoardStartingPath }>(ACTION.CREATE_BOARD_PATH);
export const createBoardPurpose = createAction<{ purpose: string }>(ACTION.CREATE_BOARD_PURPOSE);
export const createBoardLocation = createAction<{ location: string }>(ACTION.CREATE_BOARD_LOCATION);
export const createBoardEmoji = createAction<{ emoji: string }>(ACTION.CREATE_BOARD_EMOJI);
export const createBoardCareers = createAction<{ careerIds: number[] }>(
  ACTION.CREATE_BOARD_CAREERS,
);
export const createBoardPrograms = createAction<{ programIds: number[] }>(
  ACTION.CREATE_BOARD_PROGRAMS,
);

type CreateBoardArgs = {
  boardConfig: CreateBoardConfig;
};

type CreateBoardResponse = {
  board: BoardData;
};

export const {
  actionFn: createBoardWizard,
  requestAction: createBoardWizardRequest,
  successAction: createBoardWizardSuccess,
  errorAction: createBoardWizardError,
} = defineAPIPostAction<CreateBoardArgs, CreateBoardResponse>(
  ACTION.CREATE_BOARD_WIZARD,
  'boards',
  'create_wizard',
  'POST',
);
