/** @format */

import { Action, ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { get } from 'lodash';

export const createLoadingSelector =
  (actions: ACTION[], isInitiallyLoading = true) =>
  (state: ReduxState) => {
    // default loading is true
    const loadingValues = actions.map((action: ACTION) =>
      get(state, `loading.${action}`, isInitiallyLoading),
    );
    // will only say that it is not loading (false) if all the actions are not loading
    return loadingValues.some((bool: boolean) => bool);
  };

// all the loading states are default true, however that can be changed here
const loadingReducerInitialState = {};

export default (state = loadingReducerInitialState, action: Action) => {
  const { type } = action;
  if (!type) return state;

  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_ERROR actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  };
};
