/** @format */

import React from 'react';

import * as styles from './AssigneeFilter.css';

import { Checkbox } from 'components/ds/Checkbox/Checkbox';
import { User } from 'auth/types';

type Props = {
  assignees: User[];
  selectedAssigneeIds?: number[];
  updateSelectedOptions: (assignedId: number, isSelected: boolean) => void;
};

const AssigneeFilter = ({ assignees, selectedAssigneeIds, updateSelectedOptions }: Props) => {
  return (
    <div className={styles.root}>
      {assignees.map((user) => {
        const isChecked = (selectedAssigneeIds || []).indexOf(user.id) > -1;
        return (
          <div className={styles.inputContainer} key={`learn-time-filter-${user.id}`}>
            <Checkbox
              isChecked={isChecked}
              onChange={() => updateSelectedOptions(user.id, !isChecked)}
            />
            <div className={styles.label}>
              {user.first_name} {user.last_name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AssigneeFilter;
