/** @format */

const career_job_map: Record<string, string> = {
  '19-5011.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-9011.00.mp4',
  '25-1021.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1054.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1125.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1042.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1064.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1067.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1082.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1032.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1031.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1063.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1065.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1066.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1113.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1122.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1051.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1041.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1053.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1072.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1081.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1112.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1123.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1193.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1062.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1071.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1121.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1061.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1126.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1192.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1194.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1111.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1022.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1124.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1052.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '25-1043.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1011.00.mp4',
  '47-3012.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/47-2031.00.mp4',
  '11-9179.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/11-9039.02.mp4',
  '15-2099.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-2041.00.mp4',
  '21-2021.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/21-2011.00.mp4',
  '29-1141.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1141.00.mp4',
  '29-1141.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1141.00.mp4',
  '29-1141.03': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1141.00.mp4',
  '29-1141.04': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1141.00.mp4',
  '29-1141.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1141.00.mp4',
  '29-1221.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1065.00.mp4',
  '29-1217.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-1241.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-1222.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-1223.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-1229.04': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-1213.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-1212.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-1214.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-1229.05': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-1224.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-1229.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1211.00.mp4',
  '29-9093.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2055.00.mp4',
  '29-1229.03': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1069.01.mp4',
  '29-1229.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1069.01.mp4',
  '29-1292.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2021.00.mp4',
  '29-1242.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1067.00.mp4',
  '29-1243.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1067.00.mp4',
  '29-1299.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1199.05.mp4',
  '29-1299.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1199.04.mp4',
  '29-1215.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1062.00.mp4',
  '29-1291.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1199.01.mp4',
  '29-1218.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1064.00.mp4',
  '29-2099.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2011.00.mp4',
  '31-9099.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2011.00.mp4',
  '29-2012.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2011.00.mp4',
  '33-3051.04': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/33-3021.05.mp4',
  '43-4151.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/43-4071.00.mp4',
  '51-8012.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/51-8011.00.mp4',
  '11-1021.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/11-1011.00.mp4',
  '15-1232.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1151.00.mp4',
  '15-2051.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1199.08.mp4',
  '33-3051.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/33-3021.00.mp4',
  '41-1012.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/41-1011.00.mp4',
  '47-3013.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/47-2111.00.mp4',
  '11-9179.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/39-1021.00.mp4',
  '13-1041.08': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/11-9199.02.mp4',
  '15-1211.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1121.00.mp4',
  '23-1023.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/23-1021.00.mp4',
  '29-1129.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1125.01.mp4',
  '29-2099.08': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/43-4051.03.mp4',
  '31-2021.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/31-2022.00.mp4',
  '33-1021.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/33-1011.00.mp4',
  '43-9111.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-2041.00.mp4',
  '11-3111.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/11-3121.00.mp4',
  '11-9131.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/43-5052.00.mp4',
  '15-1211.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1121.01.mp4',
  '17-2051.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/17-2051.00.mp4',
  '19-3033.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-3031.00.mp4',
  '25-2032.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-2023.00.mp4',
  '33-2022.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/33-2021.00.mp4',
  '39-1022.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/39-1021.00.mp4',
  '43-4081.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/43-4071.00.mp4',
  '43-4161.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/43-4071.00.mp4',
  '13-1131.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/11-2031.00.mp4',
  '31-2012.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/31-2011.00.mp4',
  '39-7012.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/39-7011.00.mp4',
  '43-6013.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/43-6014.00.mp4',
  '47-5081.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/47-5011.00.mp4',
  '51-2092.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/51-2011.00.mp4',
  '15-1244.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1142.00.mp4',
  '15-1299.03': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1199.07.mp4',
  '19-3034.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-3031.00.mp4',
  '19-4012.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-4011.00.mp4',
  '19-4071.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-1031.00.mp4',
  '25-2022.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-2031.00.mp4',
  '25-2051.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-2052.00.mp4',
  '31-1121.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/31-1011.00.mp4',
  '31-1133.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2053.00.mp4',
  '31-9095.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2052.00.mp4',
  '31-9099.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1127.00.mp4',
  '35-3041.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/35-3031.00.mp4',
  '41-9021.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/41-9022.00.mp4',
  '53-1042.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/53-1021.00.mp4',
  '53-1043.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/53-1021.00.mp4',
  '53-4031.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/53-4011.00.mp4',
  '17-3026.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/17-3026.00.mp4',
  '19-4012.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1199.04.mp4',
  '19-4042.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-4091.00.mp4',
  '21-1014.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/21-1011.00.mp4',
  '25-2057.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-2052.00.mp4',
  '25-3041.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-3099.02.mp4',
  '25-9044.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-1191.00.mp4',
  '29-1122.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1122.00.mp4',
  '29-2011.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2011.00.mp4',
  '29-2099.05': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2057.00.mp4',
  '31-1122.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/39-9021.00.mp4',
  '39-4021.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/11-9171.00.mp4',
  '43-4031.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/43-4071.00.mp4',
  '43-4141.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/43-3021.00.mp4',
  '43-4171.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/43-4071.00.mp4',
  '43-5061.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/43-5071.00.mp4',
  '43-9081.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/27-3041.00.mp4',
  '47-3015.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/47-2152.00.mp4',
  '49-9098.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/49-9071.00.mp4',
  '53-5031.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/53-5011.00.mp4',
  '25-2012.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-2021.00.mp4',
  '25-2059.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-2052.00.mp4',
  '29-1129.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1125.02.mp4',
  '31-1132.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/31-1014.00.mp4',
  '51-6051.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/51-6052.00.mp4',
  '11-3013.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/11-9199.07.mp4',
  '13-1199.07': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/11-9199.07.mp4',
  '13-2054.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/13-2051.00.mp4',
  '15-1252.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1132.00.mp4',
  '15-1255.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1134.00.mp4',
  '15-1299.04': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1122.00.mp4',
  '17-3027.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/17-3027.00.mp4',
  '19-3039.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-3039.01.mp4',
  '19-3039.03': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-3039.01.mp4',
  '19-4043.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-4041.00.mp4',
  '25-2055.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-2052.00.mp4',
  '25-2056.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-2052.00.mp4',
  '25-2058.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-2052.00.mp4',
  '25-3031.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-2031.00.mp4',
  '25-9042.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-9041.00.mp4',
  '25-9043.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/25-9041.00.mp4',
  '29-2011.04': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2011.00.mp4',
  '29-2012.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2011.00.mp4',
  '29-2036.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1124.00.mp4',
  '29-2042.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2041.00.mp4',
  '29-2043.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2041.00.mp4',
  '29-2072.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2071.00.mp4',
  '31-1131.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/31-1014.00.mp4',
  '33-9094.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/53-3022.00.mp4',
  '35-2013.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/35-2011.00.mp4',
  '35-2014.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/35-2011.00.mp4',
  '15-1299.08': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1143.00.mp4',
  '19-1029.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-2041.00.mp4',
  '15-1221.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1111.00.mp4',
  '19-1011.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-1012.00.mp4',
  '19-2011.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-2012.00.mp4',
  '17-2111.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/17-2111.00.mp4',
  '11-3031.03': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/11-9199.03.mp4',
  '33-3021.06': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/33-3021.00.mp4',
  '13-1161.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1199.10.mp4',
  '15-1241.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1143.00.mp4',
  '15-1251.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1131.00.mp4',
  '15-1253.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/15-1199.01.mp4',
  '19-1013.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-1012.00.mp4',
  '19-1022.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-1020.01.mp4',
  '19-1029.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-1020.01.mp4',
  '19-2041.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-2041.00.mp4',
  '19-4051.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/17-2161.00.mp4',
  '19-5012.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-9011.00.mp4',
  '29-1071.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1071.00.mp4',
  '29-1216.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-1063.00.mp4',
  '17-2112.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/17-2112.00.mp4',
  '17-2199.08': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/17-2199.05.mp4',
  '19-1029.04': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-1020.01.mp4',
  '19-2032.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/17-2131.00.mp4',
  '19-2041.02': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-2041.00.mp4',
  '19-3091.00': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/19-3091.01.mp4',
  '27-2012.03': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/27-2012.00.mp4',
  '29-2011.01': 'https://cdn.careeronestop.org/OccVids/OccupationVideos/29-2011.00.mp4',
};

export const getVideoUrl = (careerCode: string) => {
  if (career_job_map[careerCode]) return career_job_map[careerCode];

  return `https://cdn.careeronestop.org/OccVids/OccupationVideos/${careerCode}.mp4`;
};
