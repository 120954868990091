/** @format */

import React from 'react';
import cx from 'classnames';

import * as styles from './ThemeSelector.css';

type Props = {
  colors: string[];
  name: string;
  isSelected: boolean;
  className?: string;
  onClick: () => void;
};

const ThemeSelector = ({ colors, name, className, onClick, isSelected }: Props) => {
  return (
    <div className={cx(styles.root, className, { selected: isSelected })} onClick={onClick}>
      <div className={styles.colorPalette}>
        <div className={styles.color} style={{ backgroundColor: colors[0] }} />
        <div className={styles.color} style={{ backgroundColor: colors[1] }} />
        <div className={styles.color} style={{ backgroundColor: colors[2] }} />
      </div>
      <div className={styles.nameContainer}>
        <div className={styles.selectorIcon}>
          <div className={cx({ [styles.selectorInner]: isSelected })}></div>
        </div>
        {name}
      </div>
    </div>
  );
};

export default ThemeSelector;
