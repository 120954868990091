/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import * as styles from './LearningProviderPage.css';
import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import {
  LearningProgramFullData,
  LearningProviderFullData,
  createNewLearningProgram,
  fetchProviderDataForCleaning,
  saveManualProgramUpdates,
  saveManualProviderUpdates,
} from 'actions/dataCleaningActions';
import LearningProgramManualEntryPage from './LearningProgramManualEntryPage';
import { Menu, MenuActionItem } from 'components/ds/Menu/Menu';
import { DropdownButton } from 'components/ds/DropdownButton/DropdownButton';
import { Input } from 'components/ds/Input/Input';
import { Button } from 'components/ds/Button/Button';
import { Modal } from 'components/ds/Modal/Modal';
import { fetchCareerOptions } from 'actions/programsActions';
import { Icon } from 'components/ds/Icon/Icon';

type Props = {};

const LearningProviderPage = ({}: Props) => {
  const dispatch = useDispatch();
  const { providerId } = useParams<{ providerId: string }>();

  const [createProgramModalOpen, setCreateProgramModalOpen] = useState<boolean>(false);
  const [fieldUpdates, setFieldUpdates] = useState<Partial<LearningProgramFullData> | undefined>(
    undefined,
  );
  const [newProgramData, setNewProgramData] = useState<
    Partial<LearningProgramFullData> | undefined
  >(undefined);
  const [newProgramCareerIds, setNewProgramCareerIds] = useState<number[]>([]);

  const [providerUpdates, setProviderUpdates] = useState<
    Partial<LearningProviderFullData> | undefined
  >(undefined);
  const [selectedUnprocessedProgramId, setSelectedUnprocessedProgramId] = useState<string>();
  const [selectedProcessedProgramId, setSelectedProcessedProgramId] = useState<string>();
  const [selectedTab, setSelectedTab] = useState<string>('UN_PROCESSED');

  const {
    providerLoading,
    careers,
    orderedPrograms,
    saveProgramLoading,
    provider,
    newProgramLoading,
  } = useSelector(
    (state: ReduxState) => ({
      provider: state.dataCleaningReducer.editingProvider,
      orderedPrograms: state.dataCleaningReducer.orderedPrograms,
      careers: state.dataCleaningReducer.careers,
      providerLoading: createLoadingSelector([ACTION.FETCH_PROVIDER_TO_EDIT], true)(state),
      saveProgramLoading: createLoadingSelector([ACTION.SAVE_MANUAL_PROGRAM_UPDATES], false)(state),
      newProgramLoading: createLoadingSelector([ACTION.CREATE_NEW_LEARNING_PROGRAM], false)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      fetchProviderDataForCleaning({ id: providerId }, (data) => {
        data.programs.sort((a, b) => a.name.localeCompare(b.name));
        const processed = data.programs.filter((p) => p.manual_entry_completed);
        const unprocessed = data.programs.filter((p) => !p.manual_entry_completed);
        if (processed.length > 0) setSelectedProcessedProgramId(processed[0].id);
        if (unprocessed.length > 0) setSelectedUnprocessedProgramId(unprocessed[0].id);
      }),
    );
    dispatch(fetchCareerOptions());
  }, [dispatch, providerId]);

  if (providerLoading)
    return (
      <div className={styles.loadingPage}>
        <LoadingSpinner />
      </div>
    );

  if (!provider || orderedPrograms === undefined)
    return <div className={styles.loadingPage}>error</div>;

  const selectedProgramId =
    selectedTab === 'UN_PROCESSED' ? selectedUnprocessedProgramId : selectedProcessedProgramId;

  const selectedProgramData = selectedProgramId
    ? orderedPrograms.find((p) => p.id === selectedProgramId)
    : undefined;

  const processed = orderedPrograms.filter((p) => p.manual_entry_completed);
  const unprocessed = orderedPrograms.filter((p) => !p.manual_entry_completed);

  const programsToShow = selectedTab === 'UN_PROCESSED' ? unprocessed : processed;
  const manualProgramsToShow = programsToShow.filter((p) => p.manually_created);
  const oNETProgramsToShow = programsToShow.filter((p) => !p.manually_created);
  const providerUpdatesToUse = providerUpdates ?? provider;

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTop}>
          <div className={styles.headerText}>{provider.name}</div>
          <div className={styles.headerButtons}>
            <Menu
              align="end"
              side="bottom"
              headerTitle="Provider Details"
              footerConfig={{
                primaryActionBtnText: 'Save',
                onPrimaryBtnClicked: () => {
                  providerUpdates &&
                    dispatch(
                      saveManualProviderUpdates({
                        id: provider.id,
                        postData: { updates: providerUpdates },
                      }),
                    );
                },
                secondaryActionBtnText: '',
                onSecondaryBtnClicked: () => {},
              }}
              trigger={<DropdownButton text="Edit Provider Details" />}>
              <div className={styles.providerInputs}>
                <div className={styles.popoverLabelInputFirst}>Calendar Type</div>
                <Menu
                  align="end"
                  side="bottom"
                  trigger={
                    <DropdownButton
                      className={styles.providerInput}
                      text={providerUpdatesToUse.manual_calendar_segment || 'Calendar System'}
                    />
                  }>
                  {['Semester', 'Quarter', 'Trimester'].map((option) => (
                    <MenuActionItem
                      style="data"
                      selected={providerUpdatesToUse.manual_calendar_segment === option}
                      text={option}
                      key={option}
                      onSelect={() =>
                        setProviderUpdates({
                          ...providerUpdatesToUse,
                          manual_calendar_segment: option,
                        })
                      }
                    />
                  ))}
                </Menu>
                <div className={styles.popoverLabelInput}>Website URL</div>
                <Input
                  className={styles.providerInput}
                  onChange={(newValue) =>
                    setProviderUpdates({
                      ...providerUpdatesToUse,
                      manual_website_url: newValue,
                    })
                  }
                  value={providerUpdatesToUse.manual_website_url}
                />
                <div className={styles.popoverLabelInput}>Image URL</div>
                <Input
                  className={styles.providerInput}
                  onChange={(newValue) =>
                    setProviderUpdates({
                      ...providerUpdatesToUse,
                      manual_image_url: newValue,
                    })
                  }
                  value={providerUpdatesToUse.manual_image_url}
                />
              </div>
            </Menu>
            <Button
              className={styles.addProgramBtn}
              text="Add Program"
              icon="plus"
              type="primary"
              onClick={() => setCreateProgramModalOpen(true)}
            />
          </div>
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.sidebar}>
          <div className={styles.sidebarTabsContainer}>
            <div
              className={cx(styles.sidebarTab, {
                [styles.sidebarTabSelected]: selectedTab === 'UN_PROCESSED',
              })}
              onClick={() => {
                setSelectedTab('UN_PROCESSED');
                setFieldUpdates(undefined);
              }}>
              Un-processed ({unprocessed.length})
            </div>
            <div
              className={cx(styles.sidebarTab, {
                [styles.sidebarTabSelected]: selectedTab === 'PROCESSED',
              })}
              onClick={() => {
                setSelectedTab('PROCESSED');
                setFieldUpdates(undefined);
              }}>
              Processed ({processed.length})
            </div>
          </div>
          <div className={styles.sidebarPrograms}>
            {[...manualProgramsToShow, ...oNETProgramsToShow].map((program) => (
              <div
                key={`program-manual-edit-selector-${program.id}`}
                className={cx(styles.programSelector, {
                  [styles.programSelectorSelected]: program.id === selectedProgramId,
                })}
                onClick={() => {
                  selectedTab === 'UN_PROCESSED'
                    ? setSelectedUnprocessedProgramId(program.id)
                    : setSelectedProcessedProgramId(program.id);
                  setFieldUpdates(undefined);
                }}>
                <div>
                  <div className={styles.programName}>{program.name}</div>
                  <div className={styles.programDegree}>{program.degree_type}</div>
                </div>
                {program.manually_created ? <Icon name="star" filled /> : null}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.mainArea}>
          {selectedProgramData ? (
            <LearningProgramManualEntryPage
              careers={careers}
              selectedCareerIds={selectedProgramData.career_ids}
              values={fieldUpdates || selectedProgramData}
              program={selectedProgramData}
              onChange={(newUpdates) => setFieldUpdates(newUpdates)}
              saveLoading={saveProgramLoading}
              saveChanges={() =>
                fieldUpdates &&
                dispatch(
                  saveManualProgramUpdates({
                    id: selectedProgramData.id,
                    postData: { updates: fieldUpdates },
                  }),
                )
              }
              markProcessed={() => {
                if (selectedProgramData.manual_entry_completed) {
                  setSelectedUnprocessedProgramId(selectedProgramData.id);
                  const processed = orderedPrograms.filter(
                    (p) => p.manual_entry_completed && p.id !== selectedProgramData.id,
                  );
                  if (processed.length > 0) setSelectedProcessedProgramId(processed[0].id);
                } else {
                  setSelectedProcessedProgramId(selectedProgramData.id);
                  const unprocessed = orderedPrograms.filter(
                    (p) => !p.manual_entry_completed && p.id !== selectedProgramData.id,
                  );
                  if (unprocessed.length > 0) setSelectedUnprocessedProgramId(unprocessed[0].id);
                }
                setFieldUpdates(undefined);
                dispatch(
                  saveManualProgramUpdates({
                    id: selectedProgramData.id,
                    postData: {
                      updates: {
                        manual_entry_completed: !selectedProgramData.manual_entry_completed,
                      },
                    },
                  }),
                );
              }}
            />
          ) : (
            'selected a program'
          )}
        </div>
      </div>
      <Modal
        isOpen={createProgramModalOpen}
        onClose={() => {
          setCreateProgramModalOpen(false);
        }}
        size="large"
        title="Create a new Program"
        tertiaryButtonProps={{
          text: 'Cancel',
          onClick: () => {
            setCreateProgramModalOpen(false);
            setNewProgramData(undefined);
            setNewProgramCareerIds([]);
          },
        }}
        primaryButtonProps={{
          text: 'Create',
          loading: newProgramLoading,
          onClick: () => {
            !newProgramLoading &&
              newProgramData &&
              dispatch(
                createNewLearningProgram(
                  {
                    id: providerId,
                    postData: {
                      program_data: newProgramData,
                      career_ids: newProgramData.career_ids || [],
                    },
                  },
                  () => {
                    setCreateProgramModalOpen(false);
                    setNewProgramData(undefined);
                    setNewProgramCareerIds([]);
                  },
                ),
              );
          },
        }}>
        <div>
          <LearningProgramManualEntryPage
            isNewProgram
            careers={careers}
            values={newProgramData}
            selectedCareerIds={newProgramData?.career_ids || []}
            updateSelectedCareerIds={setNewProgramCareerIds}
            onChange={(newUpdates) => setNewProgramData(newUpdates)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default LearningProviderPage;
