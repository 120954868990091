/** @format */

import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as styles from './index.css';
import { fetchInterests } from 'actions/interestActions';
import { ANALYTIC_PAGES, page } from 'analytics';
import { User } from 'auth/types';
import { ReduxState } from 'reducers/rootReducer';
import PickBoardPath from 'components/BoardWizard/PickBoardPath/PickBoardPath';
import { CreateBoardStep, clearCreateBoardState } from 'actions/createBoardActions';
import BoardPurpose from 'components/BoardWizard/BoardPurpose/BoardPurpose';
import BoardCareer from 'components/BoardWizard/BoardCareer/BoardCareer';
import BoardInstitution from 'components/BoardWizard/BoardInstiution/BoardInstitution';
import BoardLocation from 'components/BoardWizard/BoardLocation/BoardLocation';
import BoardPrograms from 'components/BoardWizard/BoardPrograms/BoardPrograms';
import BoardEmoji from 'components/BoardWizard/BoardEmoji/BoardEmoji';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';

type Props = {};

const NewBoardWizardPage = ({}: Props) => {
  const dispatch = useDispatch();

  const { boardConfig, step, createBoardLoading } = useSelector(
    (state: ReduxState) => ({
      boardConfig: state.createBoardReducer.boardConfig,
      step: state.createBoardReducer.step,
      createBoardLoading: createLoadingSelector([ACTION.CREATE_BOARD_WIZARD], false)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchInterests());
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlParams.entries());

    if (!queryParams.prevent_clear) {
      dispatch(clearCreateBoardState());
    }

    page(ANALYTIC_PAGES.NEW_BOARD_PAGE);
  }, []);

  if (createBoardLoading) {
    return (
      <div className={styles.loadingPage}>
        <LoadingSpinner />
      </div>
    );
  }

  if (!step || !boardConfig || !boardConfig.startPath) {
    return <PickBoardPath />;
  }

  switch (step) {
    case CreateBoardStep.PURPOSE:
      return <BoardPurpose />;
    case CreateBoardStep.CAREERS:
      return <BoardCareer />;
    case CreateBoardStep.INSTITUTION:
      return <BoardInstitution />;
    case CreateBoardStep.LOCATION:
      return <BoardLocation />;
    case CreateBoardStep.PROGRAMS:
      return <BoardPrograms />;
    case CreateBoardStep.EMOJI:
      return <BoardEmoji />;
    default:
      return <div></div>;
  }
};

export default NewBoardWizardPage;
