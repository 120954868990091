/** @format */

import { createAction } from '@reduxjs/toolkit';

import { ACTION } from 'actions/types';
import { THEME_PALETTE } from 'constants/setup';
import { defineAPIPostAction } from './actionUtils';

type ChangeThemePayload = {
  theme: THEME_PALETTE;
};

export const changeUserTheme = createAction<ChangeThemePayload>(ACTION.CHANGE_USER_THEME);

type SubmitSetupDataArgs = {
  selected_avatar: string;
  selected_theme: string;
  nickname?: string;
  first_name: string;
  last_name: string;
  personal_email: string;
  location_name: string;
  state_code: string;
  zipcode: string;
};

export const {
  actionFn: submitSetupData,
  requestAction: submitSetupDataRequest,
  successAction: submitSetupDataSuccess,
  errorAction: submitSetupDataError,
} = defineAPIPostAction<SubmitSetupDataArgs, {}>(
  ACTION.SUBMIT_SETUP_DATA,
  'onboarding',
  'submit_setup_data',
  'POST',
);

type CompleteInterestsStepArgs = {};

export const {
  actionFn: completeInterestsStep,
  requestAction: completeInterestsStepRequest,
  successAction: completeInterestsStepSuccess,
  errorAction: completeInterestsStepError,
} = defineAPIPostAction<CompleteInterestsStepArgs, {}>(
  ACTION.SUBMIT_SETUP_DATA,
  'onboarding',
  'complete_interests',
  'POST',
);

type UpdateOnboardStatusBody = {
  is_done_with_homepage_onboarding?: boolean;
  is_done_with_career_search_onboarding?: boolean;
  is_done_with_career_preferences?: boolean;
  category_preferences?: string;
};

export const {
  actionFn: updateOnboardingStatus,
  requestAction: updateOnboardingStatusRequest,
  errorAction: updateOnboardingStatusError,
  successAction: updateOnboardingStatusSuccess,
} = defineAPIPostAction<UpdateOnboardStatusBody, {}>(
  ACTION.UPDATE_ONBOARDING_STATUS,
  'onboarding',
  'update_onboarding_status',
  'POST',
);

type SubmitPreferredCareersBody = {
  career_ids?: number[];
};

export const {
  actionFn: submitPreferredCareers,
  requestAction: submitPreferredCareersRequest,
  errorAction: submitPreferredCareersError,
  successAction: submitPreferredCareersSuccess,
} = defineAPIPostAction<SubmitPreferredCareersBody, {}>(
  ACTION.SUBMIT_PREFERRED_CAREERS,
  'onboarding',
  'submit_preferred_careers',
  'POST',
);
