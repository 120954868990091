/** @format */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { ROUTES } from 'constants/routes';

import { User } from './types';
import SiteSidebarWrapper from 'components/Navigation/SiteSidebarWrapper';
import { NAV_TAB_NAMES } from 'components/Navigation/Sidebar';

type Props = {
  pageComponent: any;
  permissionFn?: (user: User) => boolean;
  withNavigation?: boolean;
  noMatch?: boolean;
  activeTabId?: NAV_TAB_NAMES;
  exact?: boolean;
  path?: string;
  componentProps?: any;
  superuserOnly?: boolean;
};

const PrivateRoute = ({
  activeTabId,
  pageComponent: Component,
  noMatch,
  permissionFn,
  exact,
  path,
  withNavigation,
  componentProps,
  superuserOnly,
}: Props) => {
  const { currentUser } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
    }),
    shallowEqual,
  );

  const routeComponent = (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        // if the user isn't logged in, send them to the login page if they're trying to go to a page
        // that requires a session
        if (!currentUser.logged_in) {
          if (props.location.pathname === ROUTES.LOGIN)
            return <Component {...props} {...componentProps} />;

          // otherwise redirect them to login
          return <Redirect to={{ pathname: ROUTES.LOGIN }} />;
        } else if (superuserOnly && !currentUser.is_superuser) {
          return <Redirect to={{ pathname: ROUTES.HOME }} />;
        } else if (!currentUser.profile_data?.is_done_with_setup) {
          if (
            props.location.pathname === ROUTES.WELCOME ||
            props.location.pathname === ROUTES.SETUP
          )
            return <Component {...props} {...componentProps} />;

          return <Redirect to={{ pathname: ROUTES.WELCOME }} />;
        } else if (!currentUser.profile_data?.is_done_with_interests) {
          if (props.location.pathname === ROUTES.INTEREST_MATCHER) return <Component {...props} />;

          return <Redirect to={{ pathname: ROUTES.INTEREST_MATCHER }} />;
        } else if (!currentUser.profile_data?.is_done_with_career_preferences) {
          if (props.location.pathname === ROUTES.CAREER_PREFERENCES)
            return <Component {...props} {...componentProps} />;

          return <Redirect to={{ pathname: ROUTES.CAREER_PREFERENCES }} />;
        }
        // otherwise, if the user is going to the login page, send them to the home page
        else if (props.location.pathname === ROUTES.LOGIN) {
          return <Redirect to={{ pathname: ROUTES.HOME }} />;
        }
        // otherwise, check generic permissions on the page to see if they can go there
        else if (permissionFn) {
          if (!permissionFn(currentUser)) {
            return <Redirect to={{ pathname: ROUTES.HOME }} />;
          }

          return <Component {...props} {...componentProps} />;
        }
        // otherwise, if they're not going to a valid page, send them to the home page
        else if (noMatch) {
          return <Redirect to={{ pathname: ROUTES.HOME }} />;
        }
        // otherwise, send them to whatever page they were going to
        else {
          return <Component {...props} {...componentProps} />;
        }
      }}
    />
  );

  if (withNavigation)
    return <SiteSidebarWrapper activeTabId={activeTabId}>{routeComponent}</SiteSidebarWrapper>;

  return routeComponent;
};

export default PrivateRoute;
