/** @format */

import { createAction } from '@reduxjs/toolkit';

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';

import { ACTION } from 'actions/types';
import { User } from 'auth/types';
import { CareersCompressedData } from './boardActions';

export type LearningProviderData = {
  id: string;
  name: string;
  is_unmatched: boolean;
  is_operating: boolean;
  description?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  manual_entry_assignee_id?: number;
  manual_entry_is_complete?: boolean;

  num_programs: number;
  num_processed_programs?: number;
  num_created_programs?: number;
};

type FetchAssignedProvidersResponse = {
  providers: LearningProviderData[];
  assignees: User[];
};

export const {
  actionFn: fetchAssignedProviders,
  requestAction: fetchAssignedProvidersRequest,
  successAction: fetchAssignedProvidersSuccess,
  errorAction: fetchAssignedProvidersError,
} = defineAPIAction<FetchAssignedProvidersResponse>(
  ACTION.FETCH_ASSIGNED_PROVIDERS,
  'data_cleaning',
  'assigned_providers',
  'GET',
);

export type LearningProviderFullData = LearningProviderData & {
  website_url?: string;
  manual_entry_assignee?: string;
  manual_entry_is_complete?: boolean;
  manual_calendar_segment?: string;
  manual_website_url?: string;
  manual_image_url?: string;
};

export type LearningProgramFullData = {
  id: string;
  name: string;
  degree_type: string;
  manually_created: boolean;
  manual_program_length?: number;
  manual_program_url?: string;
  manual_program_name?: string;
  manual_is_active?: boolean;
  manual_is_included?: boolean;
  manual_cost?: number;
  manual_cost_outstate?: number;
  manual_description?: string;
  manual_entry_completed: boolean;
  career_ids: number[];
};

type FetchProviderDataForCleaningResponse = {
  provider: LearningProviderFullData;
  programs: LearningProgramFullData[];
};

export const {
  actionFn: fetchProviderDataForCleaning,
  requestAction: fetchProviderDataForCleaningRequest,
  successAction: fetchProviderDataForCleaningSuccess,
  errorAction: fetchProviderDataForCleaningError,
} = defineAPIAction<FetchProviderDataForCleaningResponse>(
  ACTION.FETCH_PROVIDER_TO_EDIT,
  'data_cleaning',
  'provider',
  'GET',
);

type SaveManualProgramUpdatesArgs = {
  updates: Partial<LearningProgramFullData>;
};

export const {
  actionFn: saveManualProgramUpdates,
  requestAction: saveManualProgramUpdatesRequest,
  successAction: saveManualProgramUpdatesSuccess,
  errorAction: saveManualProgramUpdatesError,
} = defineAPIPostAction<SaveManualProgramUpdatesArgs, {}>(
  ACTION.SAVE_MANUAL_PROGRAM_UPDATES,
  'data_cleaning',
  'save_manual_updates',
  'POST',
);

type SaveManualProviderUpdatesArgs = {
  updates: Partial<LearningProviderFullData>;
};

export const {
  actionFn: saveManualProviderUpdates,
  requestAction: saveManualProviderUpdatesRequest,
  successAction: saveManualProviderUpdatesSuccess,
  errorAction: saveManualProviderUpdatesError,
} = defineAPIPostAction<SaveManualProviderUpdatesArgs, {}>(
  ACTION.SAVE_MANUAL_PROVIDER_UPDATES,
  'data_cleaning',
  'save_provider_manual_updates',
  'POST',
);

type AssignProviderToArgs = {
  assign_to_id: number;
};

export const {
  actionFn: assignProviderTo,
  requestAction: assignProviderToRequest,
  successAction: assignProviderToSuccess,
  errorAction: assignProviderToError,
} = defineAPIPostAction<AssignProviderToArgs, {}>(
  ACTION.ASSIGN_PROVIDER_TO,
  'data_cleaning',
  'assign_provider_to',
  'POST',
);

type CreateNewLearningProgramArgs = {
  program_data: Partial<LearningProgramFullData>;
  career_ids: number[];
};

type CreateNewLearningProgramResponse = {
  new_program: LearningProgramFullData;
};

export const {
  actionFn: createNewLearningProgram,
  requestAction: createNewLearningProgramRequest,
  successAction: createNewLearningProgramSuccess,
  errorAction: createNewLearningProgramError,
} = defineAPIPostAction<CreateNewLearningProgramArgs, CreateNewLearningProgramResponse>(
  ACTION.CREATE_NEW_LEARNING_PROGRAM,
  'data_cleaning',
  'create_program',
  'POST',
);

type CareerListResponse = {
  careers: CareersCompressedData[];
};

export const {
  actionFn: listCareers,
  requestAction: listCareersRequest,
  successAction: listCareersSuccess,
  errorAction: listCareersError,
} = defineAPIAction<CareerListResponse>(ACTION.FETCH_CAREERS_LIST, 'careers', '', 'GET');

type UpdateCareerCategoriesPayload = {
  careerId: number;
  categories: string;
};

export const updateCareerCategories = createAction<UpdateCareerCategoriesPayload>(
  ACTION.UPDATE_CAREER_CATEGORIES,
);

type SaveCareerCategoriesArgs = {
  career_id: number;
  categories: string;
};

type SaveCareerCategoriesResponse = {};

export const {
  actionFn: saveCareerCategories,
  requestAction: saveCareerCategoriesRequest,
  successAction: saveCareerCategoriesSuccess,
  errorAction: saveCareerCategoriesError,
} = defineAPIPostAction<SaveCareerCategoriesArgs, SaveCareerCategoriesResponse>(
  ACTION.SAVE_CAREER_CATEGORIES,
  'data_cleaning',
  'update_career_categories',
  'POST',
);
