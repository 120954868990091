/** @format */

export enum ROUTES {
  HOME = '/',
  LOGIN = '/login',
  WELCOME = '/welcome',
  SETUP = '/setup',
  PROFILE = '/profile',
  INTEREST_MATCHER = '/interests',
  CAREER_PREFERENCES = '/career-preferences',

  // v2 routes
  EXPLORE = '/explore',
  SAVED = '/saved',
  NEW_BOARD_WIZARD = '/new-board',

  // v1 routes
  CAREERS_OVERVIEW = '/careers',
  PROGRAMS_OVERVIEW = '/programs',
  CAREER_PAGE = '/careers/:careerId',
  PROGRAM_PAGE = '/programs/:programId',
  BOARD = '/board/:boardId',

  DATA = '/data',
  PROGRAM_CLEANING = '/program-cleaning',
  PROGRAM_AI_DESCRIPTION = '/program-ai-descriptions',
  CAREER_CLEANING = '/career-cleaning',
  SUPERUSER_ACTIONS = '/superuser-actions',

  LEARNING_PROVIDER_CLEAN = '/program-cleaning/provider/:providerId',
}
