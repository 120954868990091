/** @format */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import axios from 'axios';

import * as styles from './SignInPage.css';
import { googleOAuthLogin, logInUserSuccess } from 'actions/authActions';
import { LOGO } from 'constants/svgs';
import GoogleLogo from 'images/google_logo.png';
import { Button } from 'components/ds/Button/Button';

const SignInPage = () => {
  const dispatch = useDispatch();
  const [loginLoading, setLoginLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: (user) => {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          dispatch(
            googleOAuthLogin(
              {
                postData: {
                  email: res.data.email,
                },
              },
              (response) => {
                dispatch(logInUserSuccess(response.user));
                Cookies.set('pointb_auth_token', response.token, { sameSite: 'Strict' });
                window.location.href = '/';
                setLoginLoading(false);
              },
              (response) => {
                setLoginLoading(false);
              },
            ),
          );
        })
        .catch((err) => {
          console.log(err);
          setLoginLoading(false);
        });
    },
    onError: (error) => {
      console.log('Login Failed:', error);
      setLoginLoading(false);
    },
  });

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {LOGO()}
        <div className={styles.loginText}>Log in with your school account</div>
        <Button
          customIcon={<img className={styles.customLogo} src={GoogleLogo} alt="google logo" />}
          type="outline"
          color="neutral"
          text="Log in with Google"
          loading={loginLoading}
          onClick={() => {
            login();
            setLoginLoading(true);
          }}
          size="large"
        />
        <div className={styles.termsContainer}>
          <a className={styles.termsLink} href="/terms_of_service.pdf">
            Privacy Policy
          </a>
          <div className={styles.termsDot} />
          <a className={styles.termsLink} href="/terms_of_service.pdf">
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
