/** @format */
import Cookies from 'js-cookie';
import { FetchProfileData } from './types';

export function fetchProfile(
  successCallback: (data: FetchProfileData) => void,
  failureCallback: () => void,
) {
  const token = Cookies.get('pointb_auth_token');

  if (token) {
    fetch(process.env.REACT_APP_API_URL + 'fetch-profile/', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Token ' + token,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.detail) {
          Cookies.remove('pointb_auth_token');
          failureCallback();
        } else {
          successCallback(data.user);
        }
      });
  } else {
    failureCallback();
  }
}
