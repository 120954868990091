/** @format */

import { createReducer } from '@reduxjs/toolkit';
import { saveCareerBoardsSuccess, saveProgramBoardsSuccess } from 'actions/boardActions';

import {
  CareerInfoResult,
  CompensationInfoData,
  fetchCareerInfoSuccess,
  updateSaveCareerRequest,
} from 'actions/careerActions';
import { updateStudentInterestReactionSuccess } from 'actions/interestActions';
import { updateSaveProgramRequest } from 'actions/programsActions';

type State = { career?: CareerInfoResult };

const initialState: State = {};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(fetchCareerInfoSuccess, (state, { payload }) => {
      const career = payload.career;

      const processed_comp_data = {
        state: {} as { [location: string]: CompensationInfoData },
        msa: {} as { [location: string]: CompensationInfoData },
        state_to_msa_map: {} as { [state: string]: string[] },
      };

      payload.career.comp_data.forEach((comp) => {
        if (comp.level === 'state') {
          processed_comp_data.state[comp.state] = comp;
          if (processed_comp_data.state_to_msa_map[comp.state] === undefined) {
            processed_comp_data.state_to_msa_map[comp.state] = [];
          }
        } else {
          processed_comp_data.msa[comp.location] = comp;
          if (processed_comp_data.state_to_msa_map[comp.state] === undefined) {
            processed_comp_data.state_to_msa_map[comp.state] = [];
          }
          processed_comp_data.state_to_msa_map[comp.state].push(comp.location);
        }
      });

      career.processed_comp_data = processed_comp_data;

      state.career = career;

      return state;
    })
    .addCase(updateStudentInterestReactionSuccess, (state, { payload }) => {
      if (!state.career) return state;

      const interest = state.career.interests.find((interest) => interest.id === payload.id);

      if (!interest) return state;

      interest.reaction = payload.postData.reaction ?? null;

      return state;
    })
    .addCase(updateSaveProgramRequest, (state, { payload }) => {
      if (!state.career) return state;
      const isSaved = payload.postData.isSaved;
      const programId = payload.id;
      if (!programId) return state;

      const program = state.career.programs.find((program) => program.id == programId);
      if (!program) return state;

      program.is_saved = isSaved;

      return state;
    })
    .addCase(updateSaveCareerRequest, (state, { payload }) => {
      if (!state.career) return state;
      if (state.career.id === payload.id) {
        state.career.is_saved = payload.postData.isSaved;
      } else {
        const related_career = state.career.related_careers.find(
          (career) => career.id == payload.id,
        );
        if (related_career) {
          related_career.is_saved = payload.postData.isSaved;
        }
      }

      return state;
    })
    .addCase(saveProgramBoardsSuccess, (state, { payload }) => {
      if (!state.career) return state;
      const program = state.career.programs.find(
        (program) => program.id == payload.postData.program_id,
      );

      if (program) {
        program.board_ids = payload.postData.board_ids;
      }

      return state;
    })

    .addCase(saveCareerBoardsSuccess, (state, { payload }) => {
      if (!state.career) return state;
      if (state.career.id === payload.postData.career_id) {
        state.career.board_ids = payload.postData.board_ids;
      } else {
        const career = state.career.related_careers.find(
          (career) => career.id == payload.postData.career_id,
        );
        if (career) {
          career.board_ids = payload.postData.board_ids;
        }
      }

      return state;
    })

    .addDefaultCase((state) => state);
});
