/** @format */

import React from 'react';
import { TooltipProps, TooltipRenderProps } from 'react-joyride';

import * as styles from './OnboardingTooltip.css';

type Props = TooltipProps & TooltipRenderProps;

export const OnboardingTooltip = ({ title, text }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};
