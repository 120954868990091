/** @format */

export enum DistanceRangeMiles {
  WITHIN_25_MILES = 'Within 25 Miles',
  WITHIN_50_MILES = 'Within 50 Miles',
  WITHIN_100_MILES = 'Within 100 Miles',
  WITHIN_250_MILES = 'Within 250 Miles',
}

export const STATE_CODE_TO_NAME: Record<string, string> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const COMP_STATE_OPTIONS: Record<
  string,
  { level: string; location: string; latLong?: number[] }[]
> = {
  WA: [
    { level: 'state', location: 'Washington' },
    {
      level: 'msa',
      location: 'Bellingham, WA',
      latLong: [48.753282628187456, -122.46337218140265],
    },
    {
      level: 'msa',
      location: 'Bremerton-Silverdale, WA',
      latLong: [47.62578305707468, -122.65266803824055],
    },
    {
      level: 'msa',
      location: 'Kennewick-Richland, WA',
      latLong: [46.2018926342765, -119.17993861717207],
    },
    { level: 'msa', location: 'Longview, WA', latLong: [46.1418908480761, -122.944320465605] },
    {
      level: 'msa',
      location: 'Mount Vernon-Anacortes, WA',
      latLong: [48.44940477904445, -122.31115153032711],
    },
    {
      level: 'msa',
      location: 'Olympia-Tumwater, WA',
      latLong: [47.025849829968266, -122.90341405416144],
    },
    {
      level: 'msa',
      location: 'Seattle-Tacoma-Bellevue, WA',
      latLong: [47.643165516429704, -122.19618174310382],
    },
    {
      level: 'msa',
      location: 'Spokane-Spokane Valley, WA',
      latLong: [47.661030051410904, -117.37773267941012],
    },
    {
      level: 'msa',
      location: 'Walla Walla, WA',
      latLong: [46.060475610317816, -118.33560012101177],
    },
    { level: 'msa', location: 'Wenatchee, WA', latLong: [47.42715872735282, -120.32710053301867] },
    { level: 'msa', location: 'Yakima, WA', latLong: [46.594386842151124, -120.54848306338398] },
  ],
  GA: [
    { level: 'state', location: 'Georgia' },
    { level: 'msa', location: 'Albany, GA', latLong: [31.578461, -84.155884] },
    { level: 'msa', location: 'Athens-Clarke County, GA', latLong: [33.957409, -83.376801] },
    {
      level: 'msa',
      location: 'Atlanta-Sandy Springs-Roswell, GA',
      latLong: [33.79274193268189, -84.3892412963648],
    },
    { level: 'msa', location: 'Augusta-Richmond County, GA-SC', latLong: [] },
    { level: 'msa', location: 'Brunswick, GA', latLong: [33.457365721865045, -82.03129366455451] },
    { level: 'msa', location: 'Columbus, GA-AL', latLong: [32.48943988087727, -84.99822188504628] },
    { level: 'msa', location: 'Dalton, GA', latLong: [34.76813588688658, -84.96790793130484] },
    {
      level: 'msa',
      location: 'Gainesville, GA',
      latLong: [34.294843224784195, -83.82249049011435],
    },
    { level: 'msa', location: 'Hinesville, GA', latLong: [31.824928160009176, -81.61631844692212] },
    { level: 'msa', location: 'Macon, GA', latLong: [32.8361527421837, -83.63764914933164] },
    { level: 'msa', location: 'Rome, GA', latLong: [34.25058317368447, -85.17225494956583] },
    { level: 'msa', location: 'Savannah, GA', latLong: [32.062783328093786, -81.12401778457979] },
    { level: 'msa', location: 'Valdosta, GA', latLong: [30.841051490730806, -83.28505396357774] },
    {
      level: 'msa',
      location: 'Warner Robins, GA',
      latLong: [32.6099250842855, -83.64068437930285],
    },
  ],
  NY: [
    { level: 'state', location: 'New York' },
    {
      level: 'msa',
      location: 'Albany-Schenectady-Troy, NY',
      latLong: [42.73422152767174, -73.81853140579501],
    },
    { level: 'msa', location: 'Binghamton, NY', latLong: [42.10417468482772, -75.9050401807183] },
    {
      level: 'msa',
      location: 'Buffalo-Cheektowaga-Niagara Falls, NY',
      latLong: [42.93147299305512, -78.87365767563676],
    },
    { level: 'msa', location: 'Elmira, NY', latLong: [42.09461983184194, -76.81701491162643] },
    { level: 'msa', location: 'Glens Falls, NY', latLong: [43.31007734973626, -73.6440316617981] },
    { level: 'msa', location: 'Ithaca, NY', latLong: [42.44496785076095, -76.50245145924903] },
    { level: 'msa', location: 'Kingston, NY', latLong: [41.92645038727087, -74.00278159808754] },
    {
      level: 'msa',
      location: 'New York-Newark-Jersey City, NY-NJ-PA',
      latLong: [40.71220387255021, -74.01315292965813],
    },
    { level: 'msa', location: 'Rochester, NY', latLong: [43.16110135735225, -77.60440396043272] },
    { level: 'msa', location: 'Syracuse, NY', latLong: [43.049506624229586, -76.14546235205435] },
    { level: 'msa', location: 'Utica-Rome, NY', latLong: [43.157266396307364, -75.36248807603322] },
    {
      level: 'msa',
      location: 'Watertown-Fort Drum, NY',
      latLong: [44.05947700700205, -75.8352877051673],
    },
  ],
  MD: [
    { level: 'state', location: 'Maryland' },
    {
      level: 'msa',
      location: 'Baltimore-Columbia-Towson, MD',
      latLong: [39.28299059228461, -76.63356856744117],
    },
    {
      level: 'msa',
      location: 'California-Lexington Park, MD',
      latLong: [38.29270612838558, -76.49819446709789],
    },
    {
      level: 'msa',
      location: 'Cumberland, MD-WV',
      latLong: [39.637288820837014, -78.774445065805],
    },
    {
      level: 'msa',
      location: 'Hagerstown-Martinsburg, MD-WV',
      latLong: [39.62297401429472, -77.70813281058533],
    },
    {
      level: 'msa',
      location: 'Salisbury, MD-DE',
      latLong: [38.36254674176196, -75.59222363319827],
    },
  ],
  OH: [
    { level: 'state', location: 'Ohio' },
    { level: 'msa', location: 'Akron, OH', latLong: [41.08631983131727, -81.51298815763006] },
    {
      level: 'msa',
      location: 'Canton-Massillon, OH',
      latLong: [40.81298209246083, -81.39018016247367],
    },
    {
      level: 'msa',
      location: 'Cincinnati, OH-KY-IN',
      latLong: [39.10267677378787, -84.52057726008304],
    },
    {
      level: 'msa',
      location: 'Cleveland-Elyria, OH',
      latLong: [41.46603523221111, -81.66810463552967],
    },
    { level: 'msa', location: 'Columbus, OH', latLong: [39.959306875415805, -82.98825216153814] },
    { level: 'msa', location: 'Dayton, OH', latLong: [39.758212969165285, -84.20934243262077] },
    { level: 'msa', location: 'Lima, OH', latLong: [40.74212871034547, -84.10919930727462] },
    { level: 'msa', location: 'Mansfield, OH', latLong: [40.75984391621071, -82.52015671209185] },
    { level: 'msa', location: 'Springfield, OH', latLong: [39.92206974574005, -83.80386962031221] },
    { level: 'msa', location: 'Toledo, OH', latLong: [41.65335908419527, -83.53924734039488] },
    {
      level: 'msa',
      location: 'Youngstown-Warren-Boardman, OH-PA',
      latLong: [41.13003789216659, -80.61880155675132],
    },
  ],
  FL: [
    { level: 'state', location: 'Florida' },
    {
      level: 'msa',
      location: 'Cape Coral-Fort Myers, FL',
      latLong: [26.63601999822969, -81.8648077820777],
    },
    {
      level: 'msa',
      location: 'Crestview-Fort Walton Beach-Destin, FL',
      latLong: [30.741659114910153, -86.5751680410126],
    },
    {
      level: 'msa',
      location: 'Deltona-Daytona Beach-Ormond Beach, FL',
      latLong: [29.108156059563402, -81.12720078634837],
    },
    { level: 'msa', location: 'Gainesville, FL', latLong: [29.65753128825013, -82.33898403365646] },
    {
      level: 'msa',
      location: 'Homosassa Springs, FL',
      latLong: [28.800889071153073, -82.56946921835502],
    },
    {
      level: 'msa',
      location: 'Jacksonville, FL',
      latLong: [30.32798301108974, -81.64332917127466],
    },
    {
      level: 'msa',
      location: 'Lakeland-Winter Haven, FL',
      latLong: [28.032472988373744, -81.89923374191939],
    },
    {
      level: 'msa',
      location: 'Miami-Fort Lauderdale-West Palm Beach, FL',
      latLong: [25.90529704108141, -80.26176052702503],
    },
    {
      level: 'msa',
      location: 'Naples-Immokalee-Marco Island, FL',
      latLong: [26.117910079783204, -81.75520961097962],
    },
    {
      level: 'msa',
      location: 'North Port-Sarasota-Bradenton, FL',
      latLong: [27.38248319136293, -82.54523649589666],
    },
    { level: 'msa', location: 'Ocala, FL', latLong: [29.186449764683456, -82.1379281978608] },
    {
      level: 'msa',
      location: 'Orlando-Kissimmee-Sanford, FL',
      latLong: [28.546280217268325, -81.38350006346677],
    },
    {
      level: 'msa',
      location: 'Palm Bay-Melbourne-Titusville, FL',
      latLong: [28.05693731171203, -80.58616556719744],
    },
    {
      level: 'msa',
      location: 'Panama City, FL',
      latLong: [30.168271253472618, -85.66415600018014],
    },
    {
      level: 'msa',
      location: 'Pensacola-Ferry Pass-Brent, FL',
      latLong: [30.463577945917528, -87.23146534985814],
    },
    {
      level: 'msa',
      location: 'Port St. Lucie, FL',
      latLong: [27.276792683827168, -80.35907455359684],
    },
    {
      level: 'msa',
      location: 'Punta Gorda, FL',
      latLong: [26.926706344359577, -82.04920744053857],
    },
    {
      level: 'msa',
      location: 'Sebastian-Vero Beach, FL',
      latLong: [27.64117649357306, -80.40851557307893],
    },
    { level: 'msa', location: 'Sebring, FL', latLong: [27.50128031212856, -81.43619027008191] },
    { level: 'msa', location: 'Tallahassee, FL', latLong: [30.43507162344667, -84.28845345091263] },
    {
      level: 'msa',
      location: 'Tampa-St. Petersburg-Clearwater, FL',
      latLong: [27.961468294647293, -82.43496676764212],
    },
    { level: 'msa', location: 'The Villages, FL', latLong: [] },
  ],
  MO: [
    { level: 'state', location: 'Missouri' },
    {
      level: 'msa',
      location: 'Cape Girardeau, MO-IL',
      latLong: [37.30095526458058, -89.5260194755255],
    },
    { level: 'msa', location: 'Columbia, MO', latLong: [38.9522521883259, -92.3287896247496] },
    {
      level: 'msa',
      location: 'Jefferson City, MO',
      latLong: [38.57266087338675, -92.17889506355681],
    },
    { level: 'msa', location: 'Joplin, MO', latLong: [37.08258330425099, -94.50071313567675] },
    {
      level: 'msa',
      location: 'Kansas City, MO-KS',
      latLong: [39.09147209786407, -94.60379579176063],
    },
    { level: 'msa', location: 'Springfield, MO', latLong: [37.21590072887642, -93.28817953064663] },
    {
      level: 'msa',
      location: 'St. Joseph, MO-KS',
      latLong: [39.82936769318956, -94.85806060004217],
    },
    { level: 'msa', location: 'St. Louis, MO-IL', latLong: [38.62175132993262, -90.19903507745] },
  ],
  IL: [
    { level: 'state', location: 'Illinois' },
    { level: 'msa', location: 'Bloomington, IL', latLong: [40.48162153543994, -88.99999377972199] },
    {
      level: 'msa',
      location: 'Carbondale-Marion, IL',
      latLong: [37.72407370476801, -89.22356704223702],
    },
    {
      level: 'msa',
      location: 'Champaign-Urbana, IL',
      latLong: [40.11264793272616, -88.23033191731733],
    },
    {
      level: 'msa',
      location: 'Chicago-Naperville-Elgin, IL-IN-WI',
      latLong: [41.85952477913419, -87.80453027531031],
    },
    { level: 'msa', location: 'Danville, IL', latLong: [40.13522558853978, -87.6241670611439] },
    { level: 'msa', location: 'Decatur, IL', latLong: [39.84310502732687, -88.93320171346785] },
    { level: 'msa', location: 'Kankakee, IL', latLong: [41.12030688342812, -87.86116424073677] },
    { level: 'msa', location: 'Peoria, IL', latLong: [40.69639135613107, -89.5892582664906] },
    { level: 'msa', location: 'Rockford, IL', latLong: [42.265056219521185, -89.08592229091718] },
    { level: 'msa', location: 'Springfield, IL', latLong: [39.78271968894314, -89.66260208190177] },
  ],
  IA: [
    { level: 'state', location: 'Iowa' },
    { level: 'msa', location: 'Ames, IA', latLong: [42.02745143570353, -93.62813697215388] },
    {
      level: 'msa',
      location: 'Cedar Rapids, IA',
      latLong: [41.97727335373027, -91.65788063922145],
    },
    {
      level: 'msa',
      location: 'Davenport-Moline-Rock Island, IA-IL',
      latLong: [41.503727032822425, -90.53506822891882],
    },
    {
      level: 'msa',
      location: 'Des Moines-West Des Moines, IA',
      latLong: [41.58613065707954, -93.64912132709664],
    },
    { level: 'msa', location: 'Dubuque, IA', latLong: [42.50707509529056, -90.70771631458638] },
    { level: 'msa', location: 'Iowa City, IA', latLong: [41.65633206407971, -91.53581736747736] },
    {
      level: 'msa',
      location: 'Sioux City, IA-NE-SD',
      latLong: [42.45840444134893, -96.44566472183384],
    },
    {
      level: 'msa',
      location: 'Waterloo-Cedar Falls, IA',
      latLong: [42.52802263044695, -92.40840349787283],
    },
  ],
  PA: [
    { level: 'state', location: 'Pennsylvania' },
    {
      level: 'msa',
      location: 'Allentown-Bethlehem-Easton, PA-NJ',
      latLong: [40.64446542561843, -75.37410490456892],
    },
    { level: 'msa', location: 'Altoona, PA', latLong: [40.5119782610652, -78.39552458452151] },
    {
      level: 'msa',
      location: 'Bloomsburg-Berwick, PA',
      latLong: [41.00380767118424, -76.45551067136964],
    },
    {
      level: 'msa',
      location: 'Chambersburg-Waynesboro, PA',
      latLong: [39.92450993985063, -77.6653433657516],
    },
    {
      level: 'msa',
      location: 'East Stroudsburg, PA',
      latLong: [41.00234548984143, -75.18112954205615],
    },
    { level: 'msa', location: 'Erie, PA', latLong: [42.11942970097981, -80.08227918178936] },
    { level: 'msa', location: 'Gettysburg, PA', latLong: [39.82981862853583, -77.23135818848125] },
    {
      level: 'msa',
      location: 'Harrisburg-Carlisle, PA',
      latLong: [40.24155480982314, -77.00212742342438],
    },
    { level: 'msa', location: 'Johnstown, PA', latLong: [40.32104640345756, -78.91904102516499] },
    { level: 'msa', location: 'Lancaster, PA', latLong: [40.03825963097858, -76.30343950995099] },
    { level: 'msa', location: 'Lebanon, PA', latLong: [40.33935899487274, -76.42078110196933] },
    {
      level: 'msa',
      location: 'Philadelphia-Camden-Wilmington, PA-NJ-DE-MD',
      latLong: [39.975495883681425, -75.21881603024829],
    },
    { level: 'msa', location: 'Pittsburgh, PA', latLong: [40.43042243002593, -79.9860905845759] },
    { level: 'msa', location: 'Reading, PA', latLong: [40.33461368752934, -75.92822154299054] },
    {
      level: 'msa',
      location: 'Scranton--Wilkes-Barre--Hazleton, PA',
      latLong: [41.32252158758762, -75.75377506444396],
    },
    {
      level: 'msa',
      location: 'State College, PA',
      latLong: [40.792954388367846, -77.85543214740417],
    },
    {
      level: 'msa',
      location: 'Williamsport, PA',
      latLong: [41.240082885322636, -77.01864534792723],
    },
    {
      level: 'msa',
      location: 'York-Hanover, PA',
      latLong: [39.809117853369145, -76.98482742855325],
    },
  ],
  WV: [
    { level: 'state', location: 'West Virginia' },
    { level: 'msa', location: 'Beckley, WV', latLong: [37.77896104289627, -81.18305766029556] },
    { level: 'msa', location: 'Charleston, WV', latLong: [38.34853602419663, -81.63770974554177] },
    {
      level: 'msa',
      location: 'Huntington-Ashland, WV-KY-OH',
      latLong: [38.440773762122944, -82.5580851369075],
    },
    { level: 'msa', location: 'Morgantown, WV', latLong: [39.63015523914194, -79.95185360184193] },
    {
      level: 'msa',
      location: 'Parkersburg-Vienna, WV',
      latLong: [39.286266319603705, -81.55101777180522],
    },
    {
      level: 'msa',
      location: 'Weirton-Steubenville, WV-OH',
      latLong: [40.399662090622876, -80.61074742005552],
    },
    { level: 'msa', location: 'Wheeling, WV-OH', latLong: [40.06287167727134, -80.7113246369131] },
  ],
  SC: [
    { level: 'state', location: 'South Carolina' },
    {
      level: 'msa',
      location: 'Charleston-North Charleston, SC',
      latLong: [32.83857468881456, -79.95658650028187],
    },
    { level: 'msa', location: 'Columbia, SC', latLong: [34.0074756325113, -81.05022032262856] },
    { level: 'msa', location: 'Florence, SC', latLong: [34.186954050746415, -79.77915331199708] },
    {
      level: 'msa',
      location: 'Greenville-Anderson-Mauldin, SC',
      latLong: [34.808565415722946, -82.35159924679834],
    },
    {
      level: 'msa',
      location: 'Hilton Head Island-Bluffton-Beaufort, SC',
      latLong: [32.231521780950764, -80.86253221385977],
    },
    {
      level: 'msa',
      location: 'Myrtle Beach-Conway-North Myrtle Beach, SC-NC',
      latLong: [33.773058714779125, -78.81196059881019],
    },
    { level: 'msa', location: 'Spartanburg, SC', latLong: [34.9459275477384, -81.93413870813713] },
    { level: 'msa', location: 'Sumter, SC', latLong: [33.92398098484073, -80.3460299241782] },
  ],
  NC: [
    { level: 'state', location: 'North Carolina' },
    { level: 'msa', location: 'Asheville, NC', latLong: [35.595790964131865, -82.54138400089919] },
    { level: 'msa', location: 'Burlington, NC', latLong: [36.09578054868502, -79.44823120199538] },
    {
      level: 'msa',
      location: 'Charlotte-Concord-Gastonia, NC-SC',
      latLong: [35.226866276087385, -80.83291431136084],
    },
    {
      level: 'msa',
      location: 'Durham-Chapel Hill, NC',
      latLong: [35.91848729837735, -79.04845737689416],
    },
    { level: 'msa', location: 'Fayetteville, NC', latLong: [35.05577211816469, -78.8857359199804] },
    { level: 'msa', location: 'Goldsboro, NC', latLong: [35.38569692137979, -77.98771045684335] },
    {
      level: 'msa',
      location: 'Greensboro-High Point, NC',
      latLong: [35.970293470092386, -79.99877797101314],
    },
    { level: 'msa', location: 'Greenville, NC', latLong: [35.59927587511327, -77.37289453891114] },
    {
      level: 'msa',
      location: 'Hickory-Lenoir-Morganton, NC',
      latLong: [35.79102634742896, -81.48864088082871],
    },
    {
      level: 'msa',
      location: 'Jacksonville, NC',
      latLong: [34.75544359124277, -77.40344237270874],
    },
    { level: 'msa', location: 'New Bern, NC', latLong: [35.10265225992992, -77.0541521159933] },
    { level: 'msa', location: 'Raleigh, NC', latLong: [35.786024582211034, -78.63975030582894] },
    { level: 'msa', location: 'Rocky Mount, NC', latLong: [35.95250191463681, -77.79449483753395] },
    { level: 'msa', location: 'Wilmington, NC', latLong: [34.209379774046326, -77.88408913038633] },
    {
      level: 'msa',
      location: 'Winston-Salem, NC',
      latLong: [36.09780161955737, -80.24621553957036],
    },
  ],
  VA: [
    { level: 'state', location: 'Virginia' },
    {
      level: 'msa',
      location: 'Blacksburg-Christiansburg-Radford, VA',
      latLong: [37.17064777778118, -80.47525554764177],
    },
    {
      level: 'msa',
      location: 'Charlottesville, VA',
      latLong: [38.033776100911716, -78.48354292612379],
    },
    {
      level: 'msa',
      location: 'Harrisonburg, VA',
      latLong: [38.44575692117938, -78.87375799124595],
    },
    { level: 'msa', location: 'Lynchburg, VA', latLong: [37.40725933312696, -79.16769218783891] },
    { level: 'msa', location: 'Richmond, VA', latLong: [37.5457817877956, -77.44589374905128] },
    { level: 'msa', location: 'Roanoke, VA', latLong: [37.2680095909604, -79.94724554054224] },
    {
      level: 'msa',
      location: 'Staunton-Waynesboro, VA',
      latLong: [38.10029417510675, -78.97629763197445],
    },
    {
      level: 'msa',
      location: 'Virginia Beach-Norfolk-Newport News, VA-NC',
      latLong: [36.86756648057132, -76.2895264982594],
    },
    {
      level: 'msa',
      location: 'Winchester, VA-WV',
      latLong: [39.186366116414064, -78.16146213614009],
    },
  ],
  TN: [
    { level: 'state', location: 'Tennessee' },
    {
      level: 'msa',
      location: 'Chattanooga, TN-GA',
      latLong: [35.04618527418128, -85.3071819838677],
    },
    {
      level: 'msa',
      location: 'Clarksville, TN-KY',
      latLong: [36.53592017886638, -87.345946792396],
    },
    { level: 'msa', location: 'Cleveland, TN', latLong: [35.16135024188456, -84.8724074856795] },
    { level: 'msa', location: 'Jackson, TN', latLong: [35.6488413132128, -88.83448902237741] },
    {
      level: 'msa',
      location: 'Johnson City, TN',
      latLong: [36.318784570459336, -82.35292747672402],
    },
    {
      level: 'msa',
      location: 'Kingsport-Bristol-Bristol, TN-VA',
      latLong: [36.569517647939584, -82.44462543441688],
    },
    { level: 'msa', location: 'Knoxville, TN', latLong: [35.966903608835054, -83.91963362253398] },
    {
      level: 'msa',
      location: 'Memphis, TN-MS-AR',
      latLong: [35.15618320615482, -90.06252902998176],
    },
    { level: 'msa', location: 'Morristown, TN', latLong: [36.2110046685681, -83.29175422796125] },
    {
      level: 'msa',
      location: 'Nashville-Davidson--Murfreesboro--Franklin, TN',
      latLong: [36.15736528812083, -86.7811697143483],
    },
  ],
  CA: [
    { level: 'state', location: 'California' },
    {
      level: 'msa',
      location: 'Bakersfield, CA',
      latLong: [35.381014633117424, -119.02010494018207],
    },
    { level: 'msa', location: 'Chico, CA', latLong: [39.73188912695755, -121.83295530540941] },
    { level: 'msa', location: 'El Centro, CA', latLong: [32.79147926284792, -115.56150640060818] },
    { level: 'msa', location: 'Fresno, CA', latLong: [36.75076678159254, -119.78015284783605] },
    {
      level: 'msa',
      location: 'Hanford-Corcoran, CA',
      latLong: [36.24037579447029, -119.63335668147005],
    },
    {
      level: 'msa',
      location: 'Los Angeles-Long Beach-Anaheim, CA',
      latLong: [34.01662449868332, -118.11926702617943],
    },
    { level: 'msa', location: 'Madera, CA', latLong: [36.95769767062478, -120.07465662065253] },
    { level: 'msa', location: 'Merced, CA', latLong: [37.299808315283855, -120.48272292262087] },
    { level: 'msa', location: 'Modesto, CA', latLong: [37.631672214312644, -120.98910494860304] },
    { level: 'msa', location: 'Napa, CA', latLong: [38.29482615484669, -122.29030445718224] },
    {
      level: 'msa',
      location: 'Oxnard-Thousand Oaks-Ventura, CA',
      latLong: [34.172108050468104, -118.84254485389],
    },
    { level: 'msa', location: 'Redding, CA', latLong: [40.585130862901735, -122.37989933157009] },
    {
      level: 'msa',
      location: 'Riverside-San Bernardino-Ontario, CA',
      latLong: [34.0405785057033, -117.33124161004189],
    },
    {
      level: 'msa',
      location: 'Sacramento--Roseville--Arden-Arcade, CA',
      latLong: [38.577842835394904, -121.48937524642568],
    },
    { level: 'msa', location: 'Salinas, CA', latLong: [36.68166475268737, -121.65710946425529] },
    {
      level: 'msa',
      location: 'San Diego-Carlsbad, CA',
      latLong: [32.82530294926844, -117.15979161846799],
    },
    {
      level: 'msa',
      location: 'San Francisco-Oakland-Hayward, CA',
      latLong: [37.76407741458775, -122.27751246301995],
    },
    {
      level: 'msa',
      location: 'San Jose-Sunnyvale-Santa Clara, CA',
      latLong: [37.31795553840504, -121.95247878619458],
    },
    {
      level: 'msa',
      location: 'San Luis Obispo-Paso Robles-Arroyo Grande, CA',
      latLong: [35.26986941903165, -120.6616095153545],
    },
    {
      level: 'msa',
      location: 'Santa Cruz-Watsonville, CA',
      latLong: [36.98427438994297, -122.01341148614121],
    },
    {
      level: 'msa',
      location: 'Santa Maria-Santa Barbara, CA',
      latLong: [34.42833047927856, -119.71068838558217],
    },
    { level: 'msa', location: 'Santa Rosa, CA', latLong: [38.43384719325938, -122.72457908707482] },
    {
      level: 'msa',
      location: 'Stockton-Lodi, CA',
      latLong: [38.125421503636744, -121.27660124740328],
    },
    {
      level: 'msa',
      location: 'Vallejo-Fairfield, CA',
      latLong: [38.10793088659344, -122.23909141869444],
    },
    {
      level: 'msa',
      location: 'Visalia-Porterville, CA',
      latLong: [36.16698393442383, -119.12254708068484],
    },
    { level: 'msa', location: 'Yuba City, CA', latLong: [39.13932377323529, -121.62283546066415] },
  ],
  ID: [
    { level: 'state', location: 'Idaho' },
    { level: 'msa', location: 'Boise City, ID', latLong: [43.60864305326029, -116.20575535755728] },
    {
      level: 'msa',
      location: "Coeur d'Alene, ID",
      latLong: [47.69605638313721, -116.78486695968854],
    },
    {
      level: 'msa',
      location: 'Idaho Falls, ID',
      latLong: [43.488440165188884, -112.0395460098968],
    },
    { level: 'msa', location: 'Lewiston, ID-WA', latLong: [46.401170764107, -117.00207361571128] },
    { level: 'msa', location: 'Pocatello, ID', latLong: [42.867976560637565, -112.44383344391227] },
    { level: 'msa', location: 'Twin Falls, ID', latLong: [42.5560113158573, -114.46395403121535] },
  ],
  TX: [
    { level: 'state', location: 'Texas' },
    { level: 'msa', location: 'Abilene, TX', latLong: [32.44959887518275, -99.73638903522696] },
    { level: 'msa', location: 'Amarillo, TX', latLong: [35.21644196862765, -101.82623765377859] },
    {
      level: 'msa',
      location: 'Austin-Round Rock, TX',
      latLong: [30.304376495480724, -97.72360324768803],
    },
    {
      level: 'msa',
      location: 'Beaumont-Port Arthur, TX',
      latLong: [30.07933228762964, -94.13477747585334],
    },
    {
      level: 'msa',
      location: 'Brownsville-Harlingen, TX',
      latLong: [26.073503292555323, -97.57460574270927],
    },
    {
      level: 'msa',
      location: 'College Station-Bryan, TX',
      latLong: [30.63025679452225, -96.36090087840051],
    },
    {
      level: 'msa',
      location: 'Corpus Christi, TX',
      latLong: [27.742223779920753, -97.43021137261604],
    },
    {
      level: 'msa',
      location: 'Dallas-Fort Worth-Arlington, TX',
      latLong: [32.740147386000814, -97.29440569220246],
    },
    { level: 'msa', location: 'El Paso, TX', latLong: [31.811877499549418, -106.45340337323651] },
    {
      level: 'msa',
      location: 'Houston-The Woodlands-Sugar Land, TX',
      latLong: [29.765521473078913, -95.37936680067833],
    },
    {
      level: 'msa',
      location: 'Killeen-Temple, TX',
      latLong: [31.126618760986037, -97.7211573554364],
    },
    { level: 'msa', location: 'Laredo, TX', latLong: [27.522353014191644, -99.46659313180137] },
    { level: 'msa', location: 'Longview, TX', latLong: [32.50597057607156, -94.74879231029102] },
    { level: 'msa', location: 'Lubbock, TX', latLong: [33.57830755024104, -101.86722133407189] },
    {
      level: 'msa',
      location: 'McAllen-Edinburg-Mission, TX',
      latLong: [26.251616370460727, -98.25954480982566],
    },
    { level: 'msa', location: 'Midland, TX', latLong: [32.01143496994322, -102.10233647887443] },
    { level: 'msa', location: 'Odessa, TX', latLong: [31.845317001504185, -102.36677535691462] },
    {
      level: 'msa',
      location: 'San Angelo, TX',
      latLong: [31.451153952214668, -100.44669710514307],
    },
    {
      level: 'msa',
      location: 'San Antonio-New Braunfels, TX',
      latLong: [29.4330536959795, -98.49519858160345],
    },
    {
      level: 'msa',
      location: 'Sherman-Denison, TX',
      latLong: [33.696264811417464, -96.58817752160427],
    },
    {
      level: 'msa',
      location: 'Texarkana, TX-AR',
      latLong: [33.43023743750667, -94.04744101129855],
    },
    { level: 'msa', location: 'Tyler, TX', latLong: [32.34966250428634, -95.29856594584687] },
    { level: 'msa', location: 'Victoria, TX', latLong: [28.812988765208807, -96.99953727877293] },
    { level: 'msa', location: 'Waco, TX', latLong: [31.545616379833156, -97.15943719793941] },
    {
      level: 'msa',
      location: 'Wichita Falls, TX',
      latLong: [33.904890353763555, -98.506315480072],
    },
  ],
  CO: [
    { level: 'state', location: 'Colorado' },
    { level: 'msa', location: 'Boulder, CO', latLong: [40.01350563506471, -105.25851949441119] },
    {
      level: 'msa',
      location: 'Colorado Springs, CO',
      latLong: [38.844022491453686, -104.8112417755631],
    },
    {
      level: 'msa',
      location: 'Denver-Aurora-Lakewood, CO',
      latLong: [39.74486876045722, -104.99110895947693],
    },
    {
      level: 'msa',
      location: 'Fort Collins, CO',
      latLong: [40.582447771211406, -105.0831041185478],
    },
    {
      level: 'msa',
      location: 'Grand Junction, CO',
      latLong: [39.07331645101774, -108.54856012840808],
    },
    { level: 'msa', location: 'Greeley, CO', latLong: [40.41407857487073, -104.71932950606116] },
    { level: 'msa', location: 'Pueblo, CO', latLong: [38.26281716022694, -104.60598537123555] },
  ],
  MI: [
    { level: 'state', location: 'Michigan' },
    { level: 'msa', location: 'Ann Arbor, MI', latLong: [42.27837150065154, -83.7411252020599] },
    {
      level: 'msa',
      location: 'Battle Creek, MI',
      latLong: [42.321002127298605, -85.18134439426579],
    },
    { level: 'msa', location: 'Bay City, MI', latLong: [43.591063537423054, -83.8862822012954] },
    {
      level: 'msa',
      location: 'Detroit-Warren-Dearborn, MI',
      latLong: [42.39643927831454, -83.15625495367097],
    },
    { level: 'msa', location: 'Flint, MI', latLong: [43.015970897135844, -83.68646108395369] },
    {
      level: 'msa',
      location: 'Grand Rapids-Wyoming, MI',
      latLong: [42.907154538934556, -85.70037834786935],
    },
    { level: 'msa', location: 'Jackson, MI', latLong: [42.24425927864185, -84.4003169403139] },
    {
      level: 'msa',
      location: 'Kalamazoo-Portage, MI',
      latLong: [42.271873857846835, -85.62617406701406],
    },
    {
      level: 'msa',
      location: 'Lansing-East Lansing, MI',
      latLong: [42.67137861292527, -84.64677052463813],
    },
    { level: 'msa', location: 'Midland, MI', latLong: [43.61947813036397, -84.24478808624366] },
    { level: 'msa', location: 'Monroe, MI', latLong: [41.91146832364306, -83.3966014758466] },
    { level: 'msa', location: 'Muskegon, MI', latLong: [43.23200812331955, -86.24828973820034] },
    {
      level: 'msa',
      location: 'Niles-Benton Harbor, MI',
      latLong: [41.886328175740786, -86.36355159541738],
    },
    { level: 'msa', location: 'Saginaw, MI', latLong: [43.420957498709384, -83.95127162776234] },
  ],
  DE: [
    { level: 'state', location: 'Delaware' },
    { level: 'msa', location: 'Dover, DE', latLong: [39.15904904948158, -75.52509557400465] },
  ],
  MN: [
    { level: 'state', location: 'Minnesota' },
    { level: 'msa', location: 'Duluth, MN-WI', latLong: [46.778870742368795, -92.1363707581472] },
    {
      level: 'msa',
      location: 'Mankato-North Mankato, MN',
      latLong: [44.168728687138795, -94.0089320277426],
    },
    {
      level: 'msa',
      location: 'Minneapolis-St. Paul-Bloomington, MN-WI',
      latLong: [44.97503922925896, -93.26457081413517],
    },
    { level: 'msa', location: 'Rochester, MN', latLong: [44.014907814625495, -92.48225979392289] },
    { level: 'msa', location: 'St. Cloud, MN', latLong: [45.5573491158491, -94.16853240122111] },
  ],
  WI: [
    { level: 'state', location: 'Wisconsin' },
    { level: 'msa', location: 'Appleton, WI', latLong: [44.26399765687576, -88.41011760345512] },
    { level: 'msa', location: 'Eau Claire, WI', latLong: [44.81228705817842, -91.49158378746786] },
    { level: 'msa', location: 'Fond du Lac, WI', latLong: [43.772595987145685, -88.447491757999] },
    { level: 'msa', location: 'Green Bay, WI', latLong: [44.511566024118416, -88.0172833826742] },
    {
      level: 'msa',
      location: 'Janesville-Beloit, WI',
      latLong: [42.605043884495736, -89.02165172836828],
    },
    {
      level: 'msa',
      location: 'La Crosse-Onalaska, WI-MN',
      latLong: [43.85780910504287, -91.24929083250862],
    },
    { level: 'msa', location: 'Madison, WI', latLong: [43.068323965148615, -89.39424244462006] },
    {
      level: 'msa',
      location: 'Milwaukee-Waukesha-West Allis, WI',
      latLong: [43.042132181053205, -87.94337607921655],
    },
    {
      level: 'msa',
      location: 'Oshkosh-Neenah, WI',
      latLong: [44.182351334737135, -88.46335824782021],
    },
    { level: 'msa', location: 'Racine, WI', latLong: [42.725174292839306, -87.79964644996232] },
    { level: 'msa', location: 'Sheboygan, WI', latLong: [43.74947263636572, -87.72743007270404] },
    { level: 'msa', location: 'Wausau, WI', latLong: [44.95921075920968, -89.63090778088244] },
  ],
  KY: [
    { level: 'state', location: 'Kentucky' },
    {
      level: 'msa',
      location: 'Bowling Green, KY',
      latLong: [36.9616633337196, -86.45224616229739],
    },
    {
      level: 'msa',
      location: 'Elizabethtown-Fort Knox, KY',
      latLong: [37.88399869415623, -85.96286661764466],
    },
    {
      level: 'msa',
      location: 'Lexington-Fayette, KY',
      latLong: [38.05363168146234, -84.49080188222325],
    },
    {
      level: 'msa',
      location: 'Louisville/Jefferson County, KY-IN',
      latLong: [38.244450511088445, -85.69466607925705],
    },
    { level: 'msa', location: 'Owensboro, KY', latLong: [37.76489807291088, -87.1090478451446] },
  ],
  IN: [
    { level: 'state', location: 'Indiana' },
    {
      level: 'msa',
      location: 'Bloomington, IN',
      latLong: [39.165606727193634, -86.52846542109789],
    },
    { level: 'msa', location: 'Columbus, IN', latLong: [39.20063102765015, -85.92012843523837] },
    {
      level: 'msa',
      location: 'Elkhart-Goshen, IN',
      latLong: [41.58660745788517, -85.8349500348137],
    },
    {
      level: 'msa',
      location: 'Evansville, IN-KY',
      latLong: [37.985928356480166, -87.54412551829164],
    },
    { level: 'msa', location: 'Fort Wayne, IN', latLong: [41.08249559502673, -85.13099753865858] },
    {
      level: 'msa',
      location: 'Indianapolis-Carmel-Anderson, IN',
      latLong: [39.7701687966104, -86.15473313092426],
    },
    { level: 'msa', location: 'Kokomo, IN', latLong: [40.481584381572475, -86.12998272778322] },
    {
      level: 'msa',
      location: 'Lafayette-West Lafayette, IN',
      latLong: [40.414378797671276, -86.88054028617317],
    },
    {
      level: 'msa',
      location: 'Michigan City-La Porte, IN',
      latLong: [41.71120023741272, -86.87095324078176],
    },
    { level: 'msa', location: 'Muncie, IN', latLong: [40.19302416098588, -85.38939036402554] },
    {
      level: 'msa',
      location: 'South Bend-Mishawaka, IN-MI',
      latLong: [41.67267314674238, -86.21948968906817],
    },
    { level: 'msa', location: 'Terre Haute, IN', latLong: [39.46581946928509, -87.403877262902] },
  ],
  OK: [
    { level: 'state', location: 'Oklahoma' },
    { level: 'msa', location: 'Enid, OK', latLong: [36.401485807853405, -97.86724291319054] },
    { level: 'msa', location: 'Lawton, OK', latLong: [34.60999673524131, -98.39933870347303] },
    {
      level: 'msa',
      location: 'Oklahoma City, OK',
      latLong: [35.45983134360578, -97.5149476106737],
    },
    { level: 'msa', location: 'Tulsa, OK', latLong: [36.15074093800714, -95.97143839670774] },
  ],
  OR: [
    { level: 'state', location: 'Oregon' },
    { level: 'msa', location: 'Albany, OR', latLong: [44.635462907661655, -123.10060597951927] },
    {
      level: 'msa',
      location: 'Bend-Redmond, OR',
      latLong: [44.071427830060166, -121.31068773751888],
    },
    { level: 'msa', location: 'Corvallis, OR', latLong: [44.566954495654585, -123.26319130241208] },
    { level: 'msa', location: 'Eugene, OR', latLong: [44.054220899809586, -123.09817703392837] },
    { level: 'msa', location: 'Grants Pass, OR', latLong: [42.4342465049296, -123.33375019036524] },
    { level: 'msa', location: 'Medford, OR', latLong: [42.32985365997684, -122.86541353192166] },
    {
      level: 'msa',
      location: 'Portland-Vancouver-Hillsboro, OR-WA',
      latLong: [45.53091348700472, -122.68113519481275],
    },
    { level: 'msa', location: 'Salem, OR', latLong: [44.94316900531317, -123.03164303852888] },
  ],
  AK: [
    { level: 'state', location: 'Alaska' },
    { level: 'msa', location: 'Anchorage, AK', latLong: [61.21109388479556, -149.7921457543544] },
    { level: 'msa', location: 'Fairbanks, AK', latLong: [64.83638446938131, -147.69331277496002] },
  ],
  ND: [
    { level: 'state', location: 'North Dakota' },
    { level: 'msa', location: 'Bismarck, ND', latLong: [46.807359163986455, -100.78444151500868] },
    { level: 'msa', location: 'Fargo, ND-MN', latLong: [46.86083070909336, -96.79632782357443] },
    {
      level: 'msa',
      location: 'Grand Forks, ND-MN',
      latLong: [47.91749493634638, -97.0418278984513],
    },
  ],
  NM: [
    { level: 'state', location: 'New Mexico' },
    {
      level: 'msa',
      location: 'Albuquerque, NM',
      latLong: [35.10106637966907, -106.63120569964894],
    },
    { level: 'msa', location: 'Farmington, NM', latLong: [36.73727495810517, -108.20306732903214] },
    { level: 'msa', location: 'Las Cruces, NM', latLong: [32.31721714134603, -106.76950767964333] },
    { level: 'msa', location: 'Santa Fe, NM', latLong: [35.67995547520606, -105.94390003868358] },
  ],
  AR: [
    { level: 'state', location: 'Arkansas' },
    {
      level: 'msa',
      location: 'Fayetteville-Springdale-Rogers, AR-MO',
      latLong: [36.10322085579381, -94.14059085592741],
    },
    {
      level: 'msa',
      location: 'Fort Smith, AR-OK',
      latLong: [35.378506092394254, -94.398131302623],
    },
    { level: 'msa', location: 'Hot Springs, AR', latLong: [34.50392751408806, -93.05295875129409] },
    { level: 'msa', location: 'Jonesboro, AR', latLong: [35.82416775246822, -90.69075706087555] },
    {
      level: 'msa',
      location: 'Little Rock-North Little Rock-Conway, AR',
      latLong: [34.74000285586209, -92.29836776231438],
    },
    { level: 'msa', location: 'Pine Bluff, AR', latLong: [34.2176904791548, -92.01134800709666] },
  ],
  AZ: [
    { level: 'state', location: 'Arizona' },
    { level: 'msa', location: 'Flagstaff, AZ', latLong: [35.19063610069483, -111.63268943433089] },
    {
      level: 'msa',
      location: 'Lake Havasu City-Kingman, AZ',
      latLong: [34.45686359929259, -114.31455908329728],
    },
    {
      level: 'msa',
      location: 'Phoenix-Mesa-Scottsdale, AZ',
      latLong: [33.47334314804031, -112.04269932096705],
    },
    { level: 'msa', location: 'Prescott, AZ', latLong: [34.54977653730339, -112.4573065980646] },
    {
      level: 'msa',
      location: 'Sierra Vista-Douglas, AZ',
      latLong: [31.596335482881052, -110.24438296960994],
    },
    { level: 'msa', location: 'Tucson, AZ', latLong: [32.239474358301784, -110.96690073519771] },
    { level: 'msa', location: 'Yuma, AZ', latLong: [32.68287812855014, -114.6192172513448] },
  ],
  AL: [
    { level: 'state', location: 'Alabama' },
    {
      level: 'msa',
      location: 'Anniston-Oxford-Jacksonville, AL',
      latLong: [33.81364161240884, -85.75930518560988],
    },
    { level: 'msa', location: 'Auburn-Opelika, AL', latLong: [32.605125290901, -85.4689060879152] },
    {
      level: 'msa',
      location: 'Birmingham-Hoover, AL',
      latLong: [33.51706255579431, -86.80074430031637],
    },
    {
      level: 'msa',
      location: 'Daphne-Fairhope-Foley, AL',
      latLong: [30.619077087780298, -87.89231395924294],
    },
    { level: 'msa', location: 'Decatur, AL', latLong: [34.583125509813605, -86.98229595282449] },
    { level: 'msa', location: 'Dothan, AL', latLong: [31.215285429220817, -85.3962974745573] },
    {
      level: 'msa',
      location: 'Florence-Muscle Shoals, AL',
      latLong: [34.78626046407055, -87.66619247760137],
    },
    { level: 'msa', location: 'Gadsden, AL', latLong: [34.01189608333747, -86.01086299493738] },
    { level: 'msa', location: 'Huntsville, AL', latLong: [34.72581966239846, -86.59936979128904] },
    { level: 'msa', location: 'Mobile, AL', latLong: [30.68468007198784, -88.06355447635366] },
    { level: 'msa', location: 'Montgomery, AL', latLong: [32.37907192529736, -86.2934276436316] },
    { level: 'msa', location: 'Tuscaloosa, AL', latLong: [33.20346771357995, -87.53550968869855] },
  ],
  NE: [
    { level: 'state', location: 'Nebraska' },
    { level: 'msa', location: 'Grand Island, NE', latLong: [40.92169336745816, -98.3486647273011] },
    { level: 'msa', location: 'Lincoln, NE', latLong: [40.81452442762665, -96.69829170582932] },
    {
      level: 'msa',
      location: 'Omaha-Council Bluffs, NE-IA',
      latLong: [41.25005632818826, -95.98786476308952],
    },
  ],
  MS: [
    { level: 'state', location: 'Mississippi' },
    {
      level: 'msa',
      location: 'Gulfport-Biloxi-Pascagoula, MS',
      latLong: [30.403730352032884, -89.05866425549362],
    },
    { level: 'msa', location: 'Hattiesburg, MS', latLong: [31.314379984259258, -89.2991714220629] },
    { level: 'msa', location: 'Jackson, MS', latLong: [32.305578567530056, -90.17808321792667] },
  ],
  LA: [
    { level: 'state', location: 'Louisiana' },
    { level: 'msa', location: 'Alexandria, LA', latLong: [31.301649832377922, -92.45625685361331] },
    { level: 'msa', location: 'Baton Rouge, LA', latLong: [30.45794477650497, -91.14919591371685] },
    { level: 'msa', location: 'Hammond, LA', latLong: [30.50014824417478, -90.46439876255147] },
    {
      level: 'msa',
      location: 'Houma-Thibodaux, LA',
      latLong: [29.587611302835228, -90.70818212637383],
    },
    { level: 'msa', location: 'Lafayette, LA', latLong: [30.21552943899991, -92.02037586130028] },
    {
      level: 'msa',
      location: 'Lake Charles, LA',
      latLong: [30.21998521548082, -93.21737575332982],
    },
    { level: 'msa', location: 'Monroe, LA', latLong: [32.509236609858505, -92.10014266663785] },
    {
      level: 'msa',
      location: 'New Orleans-Metairie, LA',
      latLong: [29.954086191369896, -90.04465967179081],
    },
    {
      level: 'msa',
      location: 'Shreveport-Bossier City, LA',
      latLong: [32.525607880450586, -93.73381084167511],
    },
  ],
  NV: [
    { level: 'state', location: 'Nevada' },
    {
      level: 'msa',
      location: 'Carson City, NV',
      latLong: [39.153526430987604, -119.75027659169824],
    },
    {
      level: 'msa',
      location: 'Las Vegas-Henderson-Paradise, NV',
      latLong: [36.14888782945845, -115.14517701028643],
    },
    { level: 'msa', location: 'Reno, NV', latLong: [39.531869633689816, -119.81377305505748] },
  ],
  KS: [
    { level: 'state', location: 'Kansas' },
    { level: 'msa', location: 'Lawrence, KS', latLong: [38.966786464236435, -95.24715305758887] },
    { level: 'msa', location: 'Manhattan, KS', latLong: [39.18789123648611, -96.58385816184861] },
    { level: 'msa', location: 'Topeka, KS', latLong: [39.0316095176703, -95.69362204511151] },
    { level: 'msa', location: 'Wichita, KS', latLong: [37.68154194458404, -97.3338200742752] },
  ],
  UT: [
    { level: 'state', location: 'Utah' },
    { level: 'msa', location: 'Logan, UT-ID', latLong: [41.73889637301325, -111.83462623707561] },
    {
      level: 'msa',
      location: 'Ogden-Clearfield, UT',
      latLong: [41.16468194118854, -112.01339681062774],
    },
    {
      level: 'msa',
      location: 'Provo-Orem, UT',
      latLong: [40.272338851270725, -111.67358017880161],
    },
    {
      level: 'msa',
      location: 'Salt Lake City, UT',
      latLong: [40.7598495590651, -111.89165624878406],
    },
    { level: 'msa', location: 'St. George, UT', latLong: [37.09522133892877, -113.57036673420572] },
  ],
  PR: [
    { level: 'state', location: 'Puerto Rico' },
    {
      level: 'msa',
      location: 'Aguadilla-Isabela, PR',
      latLong: [18.428555998472877, -67.15263095152031],
    },
    { level: 'msa', location: 'Arecibo, PR', latLong: [18.45802691458812, -66.74460614921682] },
    { level: 'msa', location: 'Guayama, PR', latLong: [17.979673925768257, -66.11748686269223] },
    { level: 'msa', location: 'Mayaguez, PR', latLong: [18.198430650683335, -67.13729309439479] },
    { level: 'msa', location: 'Ponce, PR', latLong: [18.01058317557845, -66.61249475658349] },
    { level: 'msa', location: 'San German, PR', latLong: [18.082254141661743, -67.04224903856745] },
    {
      level: 'msa',
      location: 'San Juan-Carolina-Caguas, PR',
      latLong: [18.397819734034062, -66.07650206996924],
    },
  ],
  MT: [
    { level: 'state', location: 'Montana' },
    { level: 'msa', location: 'Billings, MT', latLong: [45.78180098404945, -108.51974861868179] },
    { level: 'msa', location: 'Great Falls, MT', latLong: [47.50281646149313, -111.3055839467842] },
    { level: 'msa', location: 'Missoula, MT', latLong: [46.868692717969815, -113.99414368574205] },
  ],
  SD: [
    { level: 'state', location: 'South Dakota' },
    { level: 'msa', location: 'Rapid City, SD', latLong: [44.07576180323342, -103.21898185065093] },
    { level: 'msa', location: 'Sioux Falls, SD', latLong: [43.54560475405813, -96.72453682698378] },
  ],
  NJ: [
    { level: 'state', location: 'New Jersey' },
    {
      level: 'msa',
      location: 'Atlantic City-Hammonton, NJ',
      latLong: [39.36733567865418, -74.44920434503119],
    },
    { level: 'msa', location: 'Ocean City, NJ', latLong: [39.266330913602594, -74.59719855987566] },
    { level: 'msa', location: 'Trenton, NJ', latLong: [40.217619177387185, -74.76339148701102] },
    {
      level: 'msa',
      location: 'Vineland-Bridgeton, NJ',
      latLong: [39.42844423686519, -75.23281524350418],
    },
  ],
  HI: [
    { level: 'state', location: 'Hawaii' },
    {
      level: 'msa',
      location: 'Kahului-Wailuku-Lahaina, HI',
      latLong: [20.88370920677666, -156.4729813857126],
    },
    {
      level: 'msa',
      location: 'Urban Honolulu, HI',
      latLong: [21.32283644703724, -157.8505843583878],
    },
  ],
  DC: [
    { level: 'state', location: 'District of Columbia' },
    {
      level: 'msa',
      location: 'Washington-Arlington-Alexandria, DC-VA-MD-WV',
      latLong: [38.901637497407, -77.01084302286753],
    },
  ],
  ME: [
    { level: 'state', location: 'Maine' },
    { level: 'msa', location: 'Bangor, ME', latLong: [44.80995538137689, -68.77277010458006] },
    {
      level: 'msa',
      location: 'Lewiston-Auburn, ME',
      latLong: [44.09923824409977, -70.21006908239757],
    },
    {
      level: 'msa',
      location: 'Portland-South Portland, ME',
      latLong: [43.65510486396919, -70.29988614521841],
    },
  ],
  MA: [
    { level: 'state', location: 'Massachusetts' },
    {
      level: 'msa',
      location: 'Barnstable Town, MA',
      latLong: [41.68329053464812, -70.33677280398186],
    },
    {
      level: 'msa',
      location: 'Boston-Cambridge-Nashua, MA-NH',
      latLong: [42.35508911148466, -71.0697568905792],
    },
    {
      level: 'msa',
      location: 'Leominster-Gardner, MA',
      latLong: [42.585045734761536, -71.98564102325616],
    },
    { level: 'msa', location: 'New Bedford, MA', latLong: [41.64683402034901, -70.93203837280633] },
    { level: 'msa', location: 'Pittsfield, MA', latLong: [42.447467151579374, -73.25596732149462] },
    {
      level: 'msa',
      location: 'Springfield, MA-CT',
      latLong: [42.11128284170971, -72.56351011857693],
    },
    {
      level: 'msa',
      location: 'Worcester, MA-CT',
      latLong: [42.26284142029761, -71.80053074685077],
    },
  ],
  CT: [
    { level: 'state', location: 'Connecticut' },
    {
      level: 'msa',
      location: 'Bridgeport-Stamford-Norwalk, CT',
      latLong: [41.15331999279992, -73.28799052237069],
    },
    { level: 'msa', location: 'Danbury, CT', latLong: [41.396137699351996, -73.46072403751883] },
    {
      level: 'msa',
      location: 'Hartford-West Hartford-East Hartford, CT',
      latLong: [41.76299123767155, -72.67270614900445],
    },
    { level: 'msa', location: 'New Haven, CT', latLong: [41.311670155383496, -72.92326197817674] },
    {
      level: 'msa',
      location: 'Norwich-New London-Westerly, CT-RI',
      latLong: [41.536727269364434, -72.08439374513587],
    },
    { level: 'msa', location: 'Waterbury, CT', latLong: [41.55598264850169, -73.04222581848447] },
  ],
  VT: [
    { level: 'state', location: 'Vermont' },
    {
      level: 'msa',
      location: 'Burlington-South Burlington, VT',
      latLong: [44.49019225049316, -73.20348549103791],
    },
  ],
  NH: [
    { level: 'state', location: 'New Hampshire' },
    {
      level: 'msa',
      location: 'Dover-Durham, NH-ME',
      latLong: [43.15457838933834, -70.91237589922466],
    },
    { level: 'msa', location: 'Manchester, NH', latLong: [42.99285420320682, -71.45539304597693] },
    {
      level: 'msa',
      location: 'Portsmouth, NH-ME',
      latLong: [43.069485575565125, -70.76615120135881],
    },
  ],
  RI: [
    { level: 'state', location: 'Rhode Island' },
    {
      level: 'msa',
      location: 'Providence-Warwick, RI-MA',
      latLong: [41.82596068440572, -71.41386740192938],
    },
  ],
  WY: [
    { level: 'state', location: 'Wyoming' },
    { level: 'msa', location: 'Casper, WY', latLong: [42.8458154314855, -106.30209871212915] },
    { level: 'msa', location: 'Cheyenne, WY', latLong: [41.13922971572942, -104.81066578701187] },
  ],
  GU: [{ level: 'state', location: 'Guam' }],
  VI: [{ level: 'state', location: 'Virgin Islands' }],
};

export const LAT_LONG_BY_REGION: Record<string, { lat: number; long: number }> = {};

Object.values(COMP_STATE_OPTIONS).forEach((stateData) => {
  stateData.forEach((region) => {
    if (!region['latLong']) return;

    LAT_LONG_BY_REGION[region['location']] = {
      lat: region['latLong'][0],
      long: region['latLong'][1],
    };
  });
});
