/** @format */

export enum SearchSortOption {
  INTEREST_RELEVANCE = 'Interest Match',
  SALARY_HIGH_LOW = 'Salary (High to Low)',
  SALARY_LOW_HIGH = 'Salary (Low to High)',
  LEARN_TIME_LOW_HIGH = 'Learn Time (High to Low)',
  LEARN_TIME_HIGH_LOW = 'Learn Time (Low to High)',
  PROGRAM_COST_HIGH_LOW = 'Program Cost (High to Low)',
  PROGRAM_COST_LOW_HIGH = 'Program Cost (Low to High)',
  DISTANCE_FROM_YOU_LH = 'Distance From You (Near to Far)',
  DEMAND_HL = 'Regional Demand (High to Low)',
  RELEVANCE = 'Search Relevance',

  NUM_PROGRAMS_HL = 'Number of Programs (High to Low)',
  NUM_PROGRAMS_LH = 'Number of Programs (Low to High)',
  ALPHABETICALLY_HL = 'Alphabetically (High to Low)',
  ALPHABETICALLY_LH = 'Alphabetically (Low to High)',
}

export const CAREER_SEARCH_SORTS = [
  SearchSortOption.INTEREST_RELEVANCE,
  SearchSortOption.DEMAND_HL,
  SearchSortOption.SALARY_HIGH_LOW,
  SearchSortOption.SALARY_LOW_HIGH,
  SearchSortOption.LEARN_TIME_HIGH_LOW,
  SearchSortOption.LEARN_TIME_LOW_HIGH,
  SearchSortOption.RELEVANCE,
];

export const PROGRAM_SEARCH_SORTS = [
  SearchSortOption.DISTANCE_FROM_YOU_LH,
  SearchSortOption.LEARN_TIME_HIGH_LOW,
  SearchSortOption.LEARN_TIME_LOW_HIGH,
  SearchSortOption.PROGRAM_COST_HIGH_LOW,
  SearchSortOption.PROGRAM_COST_LOW_HIGH,
];

export const DATA_SEARCH_SORTS = [
  SearchSortOption.ALPHABETICALLY_LH,
  SearchSortOption.ALPHABETICALLY_HL,
  SearchSortOption.NUM_PROGRAMS_HL,
  SearchSortOption.NUM_PROGRAMS_LH,
];
