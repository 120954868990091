/** @format */

import React from 'react';

import * as styles from './DataPage.css';
import { Link } from 'react-router-dom';

type Props = {};

const DataPage = ({}: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTop}>
          <div className={styles.headerTopContainer}>
            <div className={styles.headerText}>Data Tools</div>
          </div>
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <Link to="/program-cleaning" className={styles.navPanel}>
          Program Data Cleaning
        </Link>
        <Link to="/program-ai-descriptions" className={styles.navPanel}>
          Program AI Descriptions
        </Link>
        <Link to="/career-cleaning" className={styles.navPanel}>
          Careers Data
        </Link>
        <Link to="/superuser-actions" className={styles.navPanel}>
          Super User Actions
        </Link>
      </div>
    </div>
  );
};

export default DataPage;
