/** @format */

import React from 'react';

import * as styles from './LearningProgramManualEntryPage.css';
import { LearningProgramFullData } from 'actions/dataCleaningActions';
import { Input } from 'components/ds/Input/Input';
import { Switch } from 'components/ds/Switch/Switch';
import { TextArea } from 'components/ds/TextArea/TextArea';
import { Button } from 'components/ds/Button/Button';
import { DropdownButton } from 'components/ds/DropdownButton/DropdownButton';
import { Menu, MenuActionItem } from 'components/ds/Menu/Menu';
import CareerFilter from 'components/SearchPage/CareerFilters/CareerFilter';
import { CareerSearchResult } from 'actions/careerActions';

type Props = {
  program?: LearningProgramFullData;
  values?: Partial<LearningProgramFullData>;
  onChange: (newValues: Partial<LearningProgramFullData>) => void;
  saveChanges?: () => void;
  saveLoading?: boolean;
  markProcessed?: () => void;
  isNewProgram?: boolean;
  careers: CareerSearchResult[];
  selectedCareerIds?: number[];
  updateSelectedCareerIds?: (careerIds: number[]) => void;
};

const LearningProgramManualEntryPage = ({
  program,
  saveLoading,
  onChange,
  values,
  saveChanges,
  markProcessed,
  isNewProgram,
  careers,
  selectedCareerIds,
  updateSelectedCareerIds,
}: Props) => {
  const selectedCareerNames = selectedCareerIds
    ?.map((careerId) => careers.find((c) => c.id === careerId)?.name)
    .filter((name) => !!name);
  return (
    <div className={styles.root}>
      {!isNewProgram && program ? (
        <div className={styles.headerContainer}>
          <div>
            <div className={styles.subHeader}>{program.degree_type}</div>
            <div className={styles.header}>{program.name}</div>
          </div>
          <div className={styles.actionButtons}>
            <Button
              text="Save"
              type="outline"
              icon="save"
              className={styles.saveButton}
              onClick={saveChanges}
              loading={saveLoading}
              disabled={saveLoading}
            />
            <Button
              icon={program.manual_entry_completed ? 'cross' : 'check'}
              text={program.manual_entry_completed ? 'Mark Un-processed' : 'Mark Processed'}
              type="primary"
              onClick={markProcessed}
            />
          </div>
        </div>
      ) : null}
      <div className={styles.bodyContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.inputLabel}>Program Name</div>
          <div className={styles.input}>
            <Input
              className={styles.fullWidthInput}
              onChange={(newValue) => {
                onChange({
                  ...values,
                  manual_program_name: newValue,
                });
              }}
              value={values?.manual_program_name || ''}
            />
          </div>
        </div>

        {isNewProgram ? (
          <div className={styles.inputContainer}>
            <div className={styles.inputLabel}>Degree Type</div>
            <div className={styles.input}>
              <Menu
                align="start"
                side="bottom"
                trigger={<DropdownButton text={values?.degree_type || 'Select a degree type'} />}>
                {[
                  'Less than 12 weeks certificate',
                  'Less than one year certificate',
                  'One but less than two years certificate',
                  'Two but less than 4 years certificate',
                  'Bachelor’s degree',
                  'Associate’s degree',
                ].map((option) => (
                  <MenuActionItem
                    style="data"
                    selected={values?.degree_type === option}
                    text={option}
                    key={option}
                    onSelect={() =>
                      onChange({
                        ...values,
                        degree_type: option,
                      })
                    }
                  />
                ))}
              </Menu>
            </div>
          </div>
        ) : null}

        <div className={styles.inputContainer}>
          <div className={styles.inputLabel}>Linked Careers</div>
          <div className={styles.input}>
            <Menu
              align="start"
              side="bottom"
              trigger={
                <DropdownButton
                  className={styles.fullWidthInput}
                  text={
                    selectedCareerIds && selectedCareerNames && selectedCareerIds.length > 0
                      ? `(${selectedCareerNames.length}) ${selectedCareerNames.join(', ')}`
                      : 'Select a career'
                  }
                />
              }>
              <CareerFilter
                numResultsToShow={10}
                savedCareers={[]}
                unsavedCareers={careers}
                selectedOptions={selectedCareerIds}
                updateSelectedOptions={(option, isSelected) => {
                  let newOptions;
                  if (isSelected) {
                    newOptions = [...(selectedCareerIds || []), option];
                  } else {
                    newOptions = (selectedCareerIds || []).filter(
                      (selectedOption) => selectedOption !== option,
                    );
                  }
                  // updateSelectedCareerIds?.(newOptions);
                  onChange({
                    ...values,
                    career_ids: newOptions,
                  });
                }}
              />
            </Menu>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputLabel}>Program URL</div>
          <div className={styles.input}>
            <Input
              className={styles.fullWidthInput}
              onChange={(newValue) =>
                onChange({
                  ...values,
                  manual_program_url: newValue,
                })
              }
              value={values?.manual_program_url || ''}
            />
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputLabel}>Program Length (Months)</div>
          <div className={styles.input}>
            <Input
              type="number"
              onChange={(newValue) =>
                onChange({
                  ...values,
                  manual_program_length: parseInt(newValue),
                })
              }
              value={String(values?.manual_program_length || '')}
            />
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputLabel}>Program Cost - In-State ($ Yearly or Total)</div>
          <div className={styles.input}>
            <Input
              type="number"
              onChange={(newValue) =>
                onChange({
                  ...values,
                  manual_cost: parseInt(newValue),
                })
              }
              value={String(values?.manual_cost || '')}
            />
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputLabel}>Program Cost - Out of State ($ Yearly or Total)</div>
          <div className={styles.input}>
            <Input
              type="number"
              onChange={(newValue) =>
                onChange({
                  ...values,
                  manual_cost_outstate: parseInt(newValue),
                })
              }
              value={String(values?.manual_cost_outstate || '')}
            />
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputLabel}>Program Description</div>
          <div className={styles.input}>
            <TextArea
              className={styles.fullWidthInput}
              onChange={(newValue) =>
                onChange({
                  ...values,
                  manual_description: newValue,
                })
              }
              value={values?.manual_description || ''}
            />
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputLabel}>Program Is Active</div>
          <div className={styles.input}>
            <Switch
              onChange={(newValue) =>
                onChange({
                  ...values,
                  manual_is_active: newValue,
                })
              }
              switchOn={values?.manual_is_active ?? true}
            />
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputLabel}>Include Program</div>
          <div className={styles.input}>
            <Switch
              onChange={(newValue) =>
                onChange({
                  ...values,
                  manual_is_included: newValue,
                })
              }
              switchOn={values?.manual_is_included ?? true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningProgramManualEntryPage;
