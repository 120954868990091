/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as styles from './DataCleaningPage.css';
import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import {
  LearningProviderData,
  assignProviderTo,
  fetchAssignedProviders,
} from 'actions/dataCleaningActions';
import ProviderResult from 'components/DataCleaningPage/ProviderResult';
import { Button } from 'components/ds/Button/Button';
import SearchFilterSortBar from 'components/SearchPage/SearchFilterSortBar';
import { DATA_SEARCH_SORTS, SearchSortOption } from 'constants/search';
import AssigneeFilter from 'components/SearchPage/DataFilters/AssigneeFilter';
import { FilterType } from 'components/SearchPage/SearchPage';
import { Icon } from 'components/ds/Icon/Icon';
import { debounce } from 'lodash';
import ProgressBar from 'components/ds/ProgressBar/ProgressBar';
import { Modal } from 'components/ds/Modal/Modal';

enum TOGGLE_STATE {
  ASSIGNED = 'Assigned',
  UNASSIGNED = 'Unassigned',
  ALL = 'All',
}

type Props = {};

const DEFAULT_SEARCH_PARAMS: SearchParams = {
  page: 0,
  sort: SearchSortOption.ALPHABETICALLY_LH,
  toggleFilter: TOGGLE_STATE.ASSIGNED,
  selectedAssigneeIds: [],
};

export type SearchParams = {
  page: number;
  sort: SearchSortOption;
  selectedAssigneeIds: number[];
  locationName?: string;
  stateCode?: string;
  toggleFilter: TOGGLE_STATE;
  searchString?: string;
};

const filterProviders = (providers: LearningProviderData[], searchParams: SearchParams) => {
  let filteredProviders;

  filteredProviders = providers.filter((provider) => {
    if (searchParams.toggleFilter === TOGGLE_STATE.ALL) {
      return true;
    } else if (searchParams.toggleFilter === TOGGLE_STATE.ASSIGNED) {
      return provider.manual_entry_assignee_id !== null;
    } else if (searchParams.toggleFilter === TOGGLE_STATE.UNASSIGNED) {
      return provider.manual_entry_assignee_id === null;
    }
  });

  const searchString = searchParams.searchString;
  if (searchString) {
    filteredProviders = filteredProviders.filter((provider) => {
      return provider.name.toLowerCase().includes(searchString.toLowerCase());
    });
  }

  if (searchParams.stateCode) {
    filteredProviders = filteredProviders.filter(
      (provider) => provider.state === searchParams.stateCode,
    );
  }

  if (searchParams.selectedAssigneeIds.length > 0) {
    filteredProviders = filteredProviders.filter((provider) =>
      searchParams.selectedAssigneeIds.includes(provider.manual_entry_assignee_id ?? -1),
    );
  }

  if (searchParams.sort === SearchSortOption.NUM_PROGRAMS_HL) {
    filteredProviders.sort((a, b) => b.num_programs - a.num_programs);
  } else if (searchParams.sort === SearchSortOption.NUM_PROGRAMS_LH) {
    filteredProviders.sort((a, b) => a.num_programs - b.num_programs);
  } else if (searchParams.sort === SearchSortOption.ALPHABETICALLY_HL) {
    filteredProviders.sort((a, b) => b.name.localeCompare(a.name));
  } else if (searchParams.sort === SearchSortOption.ALPHABETICALLY_LH) {
    filteredProviders.sort((a, b) => a.name.localeCompare(b.name));
  }

  return filteredProviders;
};

const providerStats = (providers: LearningProviderData[]) => {
  let totalPrograms = 0;
  let numManualPrograms = 0;
  let numProcessedPrograms = 0;
  let numCompletedProviders = 0;
  providers.forEach((provider) => {
    totalPrograms += provider.num_programs || 0;
    numManualPrograms += provider.num_created_programs || 0;
    numProcessedPrograms += provider.num_processed_programs || 0;
    numCompletedProviders += provider.num_programs === provider.num_processed_programs ? 1 : 0;
  });

  const pctProgramsCompleted = Math.round((numProcessedPrograms / totalPrograms) * 100);
  const pctProvidersCompleted = Math.round((numCompletedProviders / providers.length) * 100);

  return {
    totalResults: providers.length,
    numCompletedProviders,
    pctProvidersCompleted,
    totalPrograms,
    numManualPrograms,
    numProcessedPrograms,
    pctProgramsCompleted,
  };
};

const DataCleaningPage = ({}: Props) => {
  const dispatch = useDispatch();
  const { providers, providersLoading, currentUser, assignees } = useSelector(
    (state: ReduxState) => ({
      providers: state.dataCleaningReducer.providers,
      assignees: state.dataCleaningReducer.assignees,
      providersLoading: createLoadingSelector([ACTION.FETCH_ASSIGNED_PROVIDERS], true)(state),
      currentUser: state.currentUser,
    }),
    shallowEqual,
  );

  const [statsModalOpen, setStatsModalOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    ...DEFAULT_SEARCH_PARAMS,
    selectedAssigneeIds: [currentUser.id],
  });
  const [tempSearchParams, setTempSearchParams] = useState<SearchParams>(searchParams);

  const { careerId } = useParams<{ careerId: string }>();

  useEffect(() => {
    dispatch(fetchAssignedProviders());
  }, [dispatch, careerId]);

  if (providersLoading)
    return (
      <div className={styles.loadingPage}>
        <LoadingSpinner />
      </div>
    );

  let filteredProviders = filterProviders(providers, searchParams);

  const {
    totalResults,
    totalPrograms,
    pctProgramsCompleted,
    numManualPrograms,
    numProcessedPrograms,
    numCompletedProviders,
    pctProvidersCompleted,
  } = providerStats(filteredProviders);

  filteredProviders = filteredProviders.slice(0, (searchParams.page + 1) * 10);

  const noMorePages = filteredProviders.length === totalResults;

  const onSearchStringUpdated = debounce((s?: string) => {
    const newSearchParam = {
      ...searchParams,
      page: 0,
      searchString: s,
    };
    setSearchParams(newSearchParam);
    setTempSearchParams(newSearchParam);
  }, 500);

  const statSection = (
    <div className={styles.statSection}>
      <div className={styles.statContainer}>
        <div className={styles.statName}>{pctProvidersCompleted}% Providers Processed</div>

        <div className={styles.statNum}>
          <ProgressBar percentFilled={pctProvidersCompleted} />
          <div className={styles.pctNums}>
            {numCompletedProviders.toLocaleString()} / {totalResults.toLocaleString()}
          </div>
        </div>
      </div>
      <div className={styles.statContainer}>
        <div className={styles.statName}>{pctProgramsCompleted}% Programs Processed</div>
        <div className={styles.statNum}>
          <ProgressBar percentFilled={pctProgramsCompleted} />
          <div className={styles.pctNums}>
            {numProcessedPrograms.toLocaleString()} / {totalPrograms.toLocaleString()}
          </div>
        </div>
      </div>
      <div className={styles.statContainer}>
        <div className={styles.statName}>Created Programs</div>
        <div className={styles.statNum}>{numManualPrograms.toLocaleString()}</div>
      </div>
    </div>
  );

  const header = searchOpen ? (
    <div className={styles.headerTop}>
      <div className={styles.headerTopContainer}>
        <div className={styles.searchIcon}>
          <Icon name="search" fontSize={40} />
        </div>
        <input
          autoFocus
          className={styles.searchInput}
          placeholder="Search Providers"
          onChange={(e) => onSearchStringUpdated(e.target.value)}
        />
        <div>
          <Button
            icon="cross"
            type="ghost"
            size="large"
            onClick={() => {
              setSearchOpen(false);
              onSearchStringUpdated(undefined);
            }}
          />
        </div>
      </div>
      {statSection}
    </div>
  ) : (
    <div className={styles.headerTop}>
      <div className={styles.headerTopContainer}>
        <div className={styles.headerText}>Program Data Cleaning</div>
        <div className={styles.headerButtons}>
          <div className={styles.savedToggle}>
            <Button
              text="Assigned"
              type={searchParams.toggleFilter === TOGGLE_STATE.ASSIGNED ? 'primary' : 'ghost'}
              size="large"
              onClick={() =>
                searchParams.toggleFilter !== TOGGLE_STATE.ASSIGNED &&
                setSearchParams({ ...searchParams, toggleFilter: TOGGLE_STATE.ASSIGNED })
              }
            />

            <Button
              text="Unassigned"
              type={searchParams.toggleFilter === TOGGLE_STATE.UNASSIGNED ? 'primary' : 'ghost'}
              size="large"
              onClick={() => {
                const newParams = {
                  ...searchParams,
                  selectedAssigneeIds: [],
                  toggleFilter: TOGGLE_STATE.UNASSIGNED,
                };
                setSearchParams(newParams);
                setTempSearchParams(newParams);
              }}
            />
            <Button
              text="All"
              type={searchParams.toggleFilter === TOGGLE_STATE.ALL ? 'primary' : 'ghost'}
              size="large"
              onClick={() => {
                const newParams = {
                  ...searchParams,
                  selectedAssigneeIds: [],
                  toggleFilter: TOGGLE_STATE.ALL,
                };
                setSearchParams(newParams);
                setTempSearchParams(newParams);
              }}
            />
          </div>
          <Button
            className={styles.statsBtn}
            icon="leaderboard"
            type="primary"
            size="large"
            text="Stats"
            onClick={() => setStatsModalOpen(true)}
          />

          <Button icon="search" type="outline" size="large" onClick={() => setSearchOpen(true)} />
        </div>
      </div>
      {statSection}
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>{header}</div>
      <div className={styles.bodyContainer}>
        <SearchFilterSortBar
          filtersConfig={[
            {
              type: FilterType.ASSIGNEE,
              component: (
                <AssigneeFilter
                  assignees={assignees}
                  selectedAssigneeIds={tempSearchParams.selectedAssigneeIds}
                  updateSelectedOptions={(option, isSelected) => {
                    let newOptions;
                    if (isSelected) {
                      newOptions = [...(tempSearchParams.selectedAssigneeIds || []), option];
                    } else {
                      newOptions = (tempSearchParams.selectedAssigneeIds || []).filter(
                        (selectedOption) => selectedOption !== option,
                      );
                    }
                    const newSearchParam = {
                      ...searchParams,
                      selectedAssigneeIds: [...newOptions],
                    };
                    setTempSearchParams(newSearchParam);
                  }}
                />
              ),
            },
          ]}
        />
        {filteredProviders.map((provider) => (
          <ProviderResult
            key={`clean-provider-${provider.id}`}
            provider={provider}
            assignees={assignees}
            onAssign={(assignToId) =>
              dispatch(
                assignProviderTo({ id: provider.id, postData: { assign_to_id: assignToId } }),
              )
            }
          />
        ))}
        {!noMorePages && !providersLoading ? (
          <div className={styles.loadMoreContainer}>
            <Button
              type="primary"
              text="Load more providers"
              onClick={() => {
                const newSearchParam = {
                  ...searchParams,
                  page: searchParams.page + 1,
                };
                setSearchParams(newSearchParam);
                setTempSearchParams(newSearchParam);
              }}
            />
          </div>
        ) : null}
      </div>
      <Modal
        isOpen={statsModalOpen}
        onClose={() => {
          setStatsModalOpen(false);
        }}
        size="medium"
        title="Data Cleaning Stats">
        <div>stats</div>
      </Modal>
    </div>
  );
};

export default DataCleaningPage;
