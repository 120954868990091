/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';

import * as styles from './index.css';
import { Button } from 'components/ds/Button/Button';
import { Icon } from 'components/ds/Icon/Icon';
import { Tooltip } from 'components/ds/Tooltip/Tooltip';
import { ANALYTIC_EVENTS, track } from 'analytics';
import { toast } from 'react-toastify';
import { ProgramSearchResult, updateSaveProgram } from 'actions/programsActions';
import { convertProgramLengthToMixMaxTime } from 'components/SearchPage/ProgramSearchResult';
import AddToBoardPopover from 'components/AddToBoardPopover';
import { saveProgramBoards } from 'actions/boardActions';
import { Link } from 'react-router-dom';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type Props = {
  program: ProgramSearchResult;
  className?: string;
  location: string;
  hideSave?: boolean;
  hideNuggets?: boolean;
};

const ProgramCardSmall = ({ className, program, location, hideNuggets, hideSave }: Props) => {
  const [boardIds, setBoardIds] = useState<number[]>(program.board_ids);
  const dispatch = useDispatch();

  const costPerYear =
    program.student_cost_per_year !== null
      ? USDollar.format(Math.round(program.student_cost_per_year / 1000) * 1000)
      : '-';

  const costText =
    program.program_length_max && program.program_length_max <= 12 ? 'Cost (Total)' : 'Cost / Yr';

  return (
    <div className={cx(styles.root, className)}>
      <Link to={`/programs/${program.id}`} className={styles.linkContainer}>
        <div className={styles.headerContainer}>
          <div style={{ minWidth: 0 }}>
            <div className={styles.headerDegreeType}>{program.degree_type}</div>
            <div className={styles.headerText}>
              {program.search_name_highlighted
                ? parse(program.search_name_highlighted)
                : program.name}
            </div>
            <div className={styles.headerProviderName}>
              {program.search_provider_name_highlighted
                ? parse(program.search_provider_name_highlighted)
                : program.provider_name}
            </div>
          </div>

          {!hideSave ? (
            <div className={styles.headerButtons}>
              <Tooltip text="Save" side="bottom" align="center">
                <Button
                  icon="bookmark"
                  type="ghost"
                  iconFilled={program.is_saved}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    track(ANALYTIC_EVENTS.PROGRAM_SAVED, {
                      location,
                      program_id: program.id,
                      program_name: program.name,
                      is_saved: !program.is_saved,
                    });
                    dispatch(
                      updateSaveProgram(
                        { id: program.id, postData: { isSaved: !program.is_saved } },
                        () => {
                          toast(
                            <div>
                              <b>{program.name}</b> {!program.is_saved ? 'saved' : 'unsaved'}!
                            </div>,
                            {
                              position: toast.POSITION.BOTTOM_RIGHT,
                              hideProgressBar: true,
                              autoClose: 2000,
                            },
                          );
                        },
                      ),
                    );
                  }}
                />
              </Tooltip>
            </div>
          ) : null}
        </div>
        {hideNuggets ? null : (
          <div className={styles.infoContainer}>
            <div className={styles.nuggetContainer}>
              <div className={styles.nuggetHeader}>{costText}</div>
              <div className={styles.nuggetValue}>💸 {costPerYear}</div>
            </div>
            <div className={styles.nuggetContainer}>
              <div className={styles.nuggetHeader}>Learn Time</div>
              <div className={styles.nuggetValue}>
                📖{' '}
                {convertProgramLengthToMixMaxTime(
                  program.program_length_max,
                  program.program_length_min,
                )}
              </div>
            </div>
            <div className={styles.nuggetContainer}>
              <div className={styles.nuggetHeader}>Location</div>
              <div className={styles.nuggetValue}>
                📍 {program.provider_city}, {program.provider_state}
              </div>
            </div>
          </div>
        )}
      </Link>
    </div>
  );
};

export default ProgramCardSmall;
