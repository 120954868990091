/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as styles from './BoardPage.css';
import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import {
  fetchBoardData,
  removeCareerFromBoard,
  removeProgramFromBoard,
  saveCareerBoards,
  saveProgramBoards,
  updateBoard,
} from 'actions/boardActions';
import { Button } from 'components/ds/Button/Button';
import { Modal } from 'components/ds/Modal/Modal';
import { ANALYTIC_EVENTS, ANALYTIC_PAGES, page, track } from 'analytics';
import CareerCard from 'components/HomePage/CareerCard/CareerCard';
import ProgramCard from 'components/HomePage/ProgramCard/ProgramCard';
import EmojiPickerPopover from 'components/ds/EmojiPicker/EmojiPicker';
import InputInPlace from 'components/ds/InputInPlace/InputInPlace';
import { toast } from 'react-toastify';

enum BoardTabs {
  CAREERS = 'Pathways',
  MEDIA = 'Media',
  PLANNING = 'Planning',
}

type Props = {};

const BoardPage = ({}: Props) => {
  const dispatch = useDispatch();
  const { boardId } = useParams<{ boardId: string }>();
  const [editMode, setEditMode] = useState<boolean>(false);

  const [archiveBoardConfirmationOpen, setArchiveBoardConfirmationOpen] = useState<boolean>(false);

  const { board, programs, careers, boardDataLoading } = useSelector(
    (state: ReduxState) => ({
      board: state.boardData.board,
      careers: state.boardData.careers,
      programs: state.boardData.programs,
      boardDataLoading: createLoadingSelector([ACTION.FETCH_BOARD_DATA], true)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      fetchBoardData({ id: boardId }, (data) => {
        page(ANALYTIC_PAGES.BOARD_PAGE, undefined, {
          board_id: boardId,
          board_name: data.board.name,
        });
      }),
    );
  }, [dispatch, boardId]);

  if (!boardDataLoading && !board) return <div>Error</div>;
  if (boardDataLoading || !board)
    return (
      <div className={styles.loadingPage}>
        <LoadingSpinner />
      </div>
    );

  return (
    <div className={styles.root}>
      <div className={styles.editActions}>
        <Button
          icon="trash"
          size="large"
          type="outline"
          onClick={() => setArchiveBoardConfirmationOpen(true)}
        />
        <Button
          size="large"
          type="primary"
          text={editMode ? 'Done' : 'Edit'}
          onClick={() => {
            setEditMode(!editMode);
            track(ANALYTIC_EVENTS.BOARD_PAGE_EDIT_CLICKED, {
              board_id: board.id,
              board_name: board.name,
            });
          }}
        />
      </div>
      <div className={styles.mainPage}>
        <div className={styles.container}>
          <div className={styles.emoji}>
            {editMode ? (
              <EmojiPickerPopover
                onSelectedEmoji={(emoji) => {
                  track(ANALYTIC_EVENTS.EMOJI_SELECTED, {
                    emoji: emoji,
                    board_id: board.id,
                  });
                  dispatch(
                    updateBoard({
                      id: board.id,
                      postData: {
                        board: {
                          emoji: emoji,
                        },
                      },
                    }),
                  );
                }}
                buttonProps={{
                  className: styles.emojiSelector,
                  icon: board.emoji ? undefined : 'add-reaction',
                  text: board.emoji ? board.emoji : undefined,
                  isIconOnly: true,
                  size: 'large',
                  type: 'outline',
                }}
              />
            ) : (
              board.emoji
            )}
          </div>
          <div className={styles.name}>
            {editMode ? (
              <InputInPlace
                startingText={board.name || 'Untitled'}
                onNewTextSaved={(newText) =>
                  dispatch(
                    updateBoard({
                      id: board.id,
                      postData: {
                        board: {
                          name: newText,
                        },
                      },
                    }),
                  )
                }
              />
            ) : (
              board.name
            )}
          </div>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionHeaderText}>Careers</div>
            <Button type="ghost" text="Add more" to="/careers" />
          </div>
          <div>
            {board.career_ids.map((careerId) => (
              <CareerCard
                hideVideo
                hideAddToBoard
                key={`board-page-career-${careerId}`}
                className={styles.card}
                career={careers[careerId]}
                location="Board Page"
                rightButtons={
                  editMode ? (
                    <Button
                      icon="cross"
                      type="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        dispatch(
                          saveCareerBoards(
                            {
                              postData: {
                                career_id: careerId,
                                board_ids: careers[careerId].board_ids.filter(
                                  (b) => b !== board.id,
                                ),
                              },
                            },
                            () => {
                              dispatch(
                                removeCareerFromBoard({ boardId: board.id, careerId: careerId }),
                              );
                              toast.success(
                                <div>
                                  <b>{careers[careerId].name}</b> removed from this board!
                                </div>,
                                {
                                  position: toast.POSITION.BOTTOM_RIGHT,
                                  hideProgressBar: true,
                                  autoClose: 2000,
                                },
                              );
                            },
                          ),
                        );
                      }}
                    />
                  ) : undefined
                }
              />
            ))}
          </div>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionHeaderText}>Programs</div>
            <Button type="ghost" text="Add more" to="/programs" />
          </div>
          <div>
            {board.program_ids.map((programId) => (
              <ProgramCard
                key={`board-page-program-${programId}`}
                className={styles.card}
                hideAddToBoard
                program={programs[programId]}
                location="Board Page"
                rightButtons={
                  editMode ? (
                    <Button
                      icon="cross"
                      type="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        dispatch(
                          saveProgramBoards(
                            {
                              postData: {
                                program_id: programId,
                                board_ids: programs[programId].board_ids.filter(
                                  (b) => b !== board.id,
                                ),
                              },
                            },
                            () => {
                              dispatch(
                                removeProgramFromBoard({ boardId: board.id, programId: programId }),
                              );
                              toast.success(
                                <div>
                                  <b>{programs[programId].name}</b> removed from this board!
                                </div>,
                                {
                                  position: toast.POSITION.BOTTOM_RIGHT,
                                  hideProgressBar: true,
                                  autoClose: 2000,
                                },
                              );
                            },
                          ),
                        );
                      }}
                    />
                  ) : undefined
                }
              />
            ))}
          </div>
        </div>
      </div>
      <Modal
        title="Are you sure you want to archive this board?"
        isOpen={archiveBoardConfirmationOpen}
        onClose={() => setArchiveBoardConfirmationOpen(false)}
        size="small"
        primaryButtonProps={{
          text: 'Archive',
          loading: boardDataLoading,
          onClick: () => {
            track(ANALYTIC_EVENTS.BOARD_ARCHIVED, {
              board_id: board.id,
            });
            !boardDataLoading &&
              dispatch(
                updateBoard(
                  {
                    id: board.id,
                    postData: {
                      board: {
                        archived: true,
                      },
                    },
                  },
                  () => {
                    window.location.href = '/';
                  },
                ),
              );
          },
        }}
        secondaryButtonProps={{
          text: 'Cancel',
          onClick: () => setArchiveBoardConfirmationOpen(false),
        }}></Modal>
    </div>
  );
};

export default BoardPage;
