/** @format */

import Cookies from 'js-cookie';
import * as Redux from 'redux';
import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';
import axios, { Method, AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';

import { ErrorResponse } from './types';

export function createRequestAction<T>(action: string) {
  return createAction<T>(`${action}_REQUEST`);
}

export function createErrorAction<T>(action: string) {
  return createAction<T>(`${action}_ERROR`);
}

export function createSuccessAction<T>(action: string) {
  return createAction<T>(`${action}_SUCCESS`);
}

export interface ActionFnArgs {
  id?: string | number | null;
  customerToken?: string;
  jwt?: string;
  errorData?: ErrorResponse;
  getData?: Record<string, string>;
  getQueryString?: string;
}

type ActionFn<T> = (
  args?: ActionFnArgs,
  onSuccess?: (data: T) => void,
  onError?: (errorMsg: ErrorResponse) => void,
) => void;

interface ActionFnArgsWithPost<T> extends ActionFnArgs {
  postData: T;
}

export type ActionFnWithArgs<K, T = {}> = (
  args: ActionFnArgsWithPost<K>,
  onSuccess?: (data: T) => void,
  onError?: (errorMsg: ErrorResponse) => void,
) => void;

export function defineAPIAction<T>(
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
) {
  const requestAction = createRequestAction<ActionFnArgs>(actionName);
  const successAction = createSuccessAction<ActionFnArgs & T>(actionName);
  const errorAction = createErrorAction<ActionFnArgs & T & { errorData?: ErrorResponse }>(
    actionName,
  );

  const actionFn: ActionFn<T> = (
    args: ActionFnArgs = {},
    onSuccess?: (data: T) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    return runAction<{}, T>(
      urlBeforeId,
      urlAfterId,
      requestType,
      requestAction,
      successAction,
      errorAction,
      args,
      onSuccess,
      onError,
    );
  };

  return { actionFn, requestAction, successAction, errorAction };
}

export function defineAPIPostAction<K, T>(
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
) {
  const requestAction = createRequestAction<ActionFnArgsWithPost<K>>(actionName);
  const successAction = createSuccessAction<ActionFnArgsWithPost<K> & T>(actionName);
  const errorAction = createErrorAction<
    // eventually want to remove T
    ActionFnArgsWithPost<K> & T & { errorData?: ErrorResponse } & { error_msg?: string }
  >(actionName);

  const actionFn: ActionFnWithArgs<K, T> = (
    args: ActionFnArgsWithPost<K>,
    onSuccess?: (data: T) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    return runAction<K, T>(
      urlBeforeId,
      urlAfterId,
      requestType,
      requestAction,
      successAction,
      errorAction,
      args,
      onSuccess,
      onError,
    );
  };

  return { actionFn, requestAction, successAction, errorAction };
}

function runAction<K, T>(
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
  requestAction: ActionCreatorWithPayload<any>,
  successAction: ActionCreatorWithPayload<any>,
  errorAction: ActionCreatorWithPayload<any>,
  args: ActionFnArgsWithPost<K> | ActionFnArgs,
  onSuccess?: (data: T) => void,
  onError?: (errorMsg: ErrorResponse) => void,
) {
  let url = args.id ? `${urlBeforeId}/${args.id}/` : `${urlBeforeId}/`;
  if (urlAfterId && urlAfterId !== '') {
    url += `${urlAfterId}/`;
  }
  if (args.getData) {
    url += `?${new URLSearchParams(args.getData).toString()}`;
  } else if (args.getQueryString) {
    url += `?${args.getQueryString}`;
  }
  return (dispatch: Redux.Dispatch) => {
    dispatch(requestAction(args));
    const requestConfig = createApiRequestConfig(
      url,
      requestType,
      'postData' in args ? args.postData : undefined,
      args.customerToken,
      args.jwt,
    );
    return axios(requestConfig)
      .then((response: AxiosResponse) => {
        const { data } = response;

        if ((data.success !== 0 || data.status === 'OK') && !isErrorHttpStatus(response.status)) {
          // Note: data.status is for django-rest-passwordreset.
          dispatch(successAction({ ...args, ...data }));
          onSuccess?.(data);
        } else {
          dispatch(errorAction({ ...args, ...data }));
          onError?.(data);
        }
      })
      .catch((error: AxiosError) => {
        console.error(error.response);
        dispatch(
          errorAction({
            ...args,
            errorData: error.response && {
              ...error.response.data,
              status: error.response.status,
            },
          }),
        );
        onError?.(error.response && { ...error.response.data, status: error.response.status });
      });
  };
}

export function defineAuthAction<K, T>(
  actionName: string,
  urlBeforeId: string,
  urlAfterId: string,
  requestType: Method,
) {
  const requestAction = createRequestAction<ActionFnArgsWithPost<K>>(actionName);
  const successAction = createSuccessAction<ActionFnArgsWithPost<K> & T>(actionName);
  const errorAction = createErrorAction<ActionFnArgsWithPost<K> & { errorData?: ErrorResponse }>(
    actionName,
  );

  const actionFn: ActionFnWithArgs<K, T> = (
    args: ActionFnArgsWithPost<K>,
    onSuccess?: (data: T) => void,
    onError?: (errorMsg: ErrorResponse) => void,
  ) => {
    let url = args.id ? `${urlBeforeId}/${args.id}/` : `${urlBeforeId}/`;
    if (urlAfterId && urlAfterId !== '') {
      url += `${urlAfterId}/`;
    }
    return (dispatch: Redux.Dispatch) => {
      dispatch(requestAction(args));
      const apiRequest = createApiRequestConfig(
        url,
        requestType,
        args.postData,
        args.customerToken,
      );
      return axios(apiRequest)
        .then((response: AxiosResponse) => {
          const { data } = response;
          if ((data.success !== 0 || data.status === 'OK') && !isErrorHttpStatus(response.status)) {
            // Note: data.status is for django-rest-passwordreset.
            dispatch(successAction({ ...args, ...data }));
            onSuccess?.(data);
          } else {
            dispatch(errorAction({ ...args, ...data }));
            onError?.(data);
          }
        })
        .catch((error: AxiosError) => {
          console.error(error.response);
          dispatch(
            errorAction({
              ...args,
              errorData: error.response && {
                ...error.response.data,
                status: error.response.status,
              },
            }),
          );
          onError?.(error.response && { ...error.response.data, status: error.response.status });
        });
    };
  };

  return { actionFn, requestAction, successAction, errorAction };
}

const unauthenticatedRoutes = [
  '/end_user_portal_authenticate',
  '/get_share_data',
  '/fetch_end_user_portal_metadata',
  '/end_user_portal_sign_in',
];

export const createApiRequestConfig = (
  url: string,
  requestType: Method,
  postData: unknown | undefined,
  customerToken?: string,
  jwt?: string,
): AxiosRequestConfig => {
  return {
    url: process.env.REACT_APP_API_URL + url,
    method: requestType,
    headers: createHeaders(
      customerToken,
      jwt,
      unauthenticatedRoutes.some((route) => url.includes(route)),
    ),
    data: postData,
  };
};

export function createHeaders(
  customerToken: string | undefined,
  jwt: string | undefined,
  isUnauthenticatedRequest?: boolean,
) {
  const headers: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (isUnauthenticatedRequest) return headers;

  const authToken = Cookies.get('pointb_auth_token');

  if (customerToken) headers['Customer-Token'] = customerToken;
  else if (jwt) headers['Customer-Jwt'] = jwt;
  else if (authToken) headers['Authorization'] = 'Token ' + authToken;

  return headers;
}

/**
 * Checks if the status is a 4XX or 5XX
 */
const isErrorHttpStatus = (httpStatus: number) => {
  const statusCodeCategory = Math.floor(httpStatus / 100);
  return statusCodeCategory === 4 || statusCodeCategory === 5;
};
