/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';

import * as styles from './index.css';
import { CareerSearchResult, updateSaveCareer } from 'actions/careerActions';
import { Button } from 'components/ds/Button/Button';
import { Icon } from 'components/ds/Icon/Icon';
import { convertProgramLengthToMixMaxTime } from 'components/SearchPage/CareerSearchResult';
import { Tooltip } from 'components/ds/Tooltip/Tooltip';
import { ANALYTIC_EVENTS, track } from 'analytics';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getVideoUrl } from '../CareerCard/videoUtil';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type Props = {
  career: CareerSearchResult;
  className?: string;
  location: string;
  hideVideo?: boolean;
  hideNuggets?: boolean;
  hideInterest?: boolean;
};

const CareerCardSmall = ({
  className,
  hideInterest,
  career,
  location,
  hideVideo,
  hideNuggets,
}: Props) => {
  const dispatch = useDispatch();
  const [videoErrored, setVideoErrored] = useState<boolean>(false);

  const headerBtns = (
    <div className={styles.headerButtons}>
      <Tooltip text="Save" side="bottom" align="center">
        <Button
          whiteMode={!videoErrored}
          icon="bookmark"
          type="ghost"
          iconFilled={career.is_saved}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            track(ANALYTIC_EVENTS.CAREER_SAVED, {
              location,
              career_id: career.id,
              career_name: career.name,
              is_saved: !career.is_saved,
            });
            dispatch(
              updateSaveCareer({ id: career.id, postData: { isSaved: !career.is_saved } }, () => {
                toast(
                  <div>
                    <b>{career.name}</b> {!career.is_saved ? 'saved' : 'unsaved'}!
                  </div>,
                  {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 2000,
                  },
                );
              }),
            );
          }}
        />
      </Tooltip>
    </div>
  );

  return (
    <div className={cx(styles.root, className)}>
      <Link to={`/careers/${career.id}`} className={styles.linkContainer}>
        {!hideVideo ? (
          <div style={{ position: 'relative' }}>
            {!videoErrored ? (
              <video
                src={getVideoUrl(career.code)}
                width="100%"
                height="100%"
                controls
                onError={() => setVideoErrored(true)}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            ) : (
              <div className={styles.noVideoChip}>
                <Icon name="no-video" />
              </div>
            )}
            <div className={styles.headerButtonsVideo}>{headerBtns}</div>
          </div>
        ) : null}
        <div>
          <div className={styles.title}>
            {career.search_name_highlighted ? parse(career.search_name_highlighted) : career.name}
          </div>
        </div>
        {!hideNuggets ? (
          <div className={styles.infoContainer}>
            <div className={styles.nuggetContainer}>
              <div className={styles.nuggetHeader}>Salary</div>
              <div className={styles.nuggetValue}>
                💰{' '}
                {career.avg_starting_salary
                  ? USDollar.format(Math.round(career.avg_starting_salary / 1000) * 1000)
                  : '-'}{' '}
                -{' '}
                {career.avg_ending_salary
                  ? USDollar.format(Math.round(career.avg_ending_salary / 1000) * 1000)
                  : '-'}
              </div>
            </div>
            <div className={styles.nuggetContainer}>
              <div className={styles.nuggetHeader}>Learn Time</div>
              <div className={styles.nuggetValue}>
                📖{' '}
                {convertProgramLengthToMixMaxTime(
                  career.program_length_max,
                  career.program_length_expected,
                )}
              </div>
            </div>
          </div>
        ) : null}

        <div className={styles.infoDescription}>
          {career.search_description_highlighted
            ? parse(career.search_description_highlighted)
            : career.description}
        </div>
        {hideInterest ? null : (
          <div className={styles.infoMatchInfo}>
            <Icon fontSize={14} className={styles.infoMatchIcon} filled name="heart" /> Matches{' '}
            {career.num_love_interests} Interests
          </div>
        )}
      </Link>
    </div>
  );
};

export default CareerCardSmall;
