/** @format */

import { createReducer } from '@reduxjs/toolkit';

export type ResetOnboardingData = {};

type State = {};

const initialState: State = {};

export default createReducer<State>(initialState, (builder) => {
  builder.addDefaultCase((state) => state);
});
