/** @format */

import React, { useEffect, useState } from 'react';
import { fetchCareerInfo, updateSaveCareer } from 'actions/careerActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as styles from './index.css';
import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/loadingReducer';
import DetailSection from 'components/EntityInfoPage/CareerInfoComponents/DetailSection';
import ActivitiesSection from 'components/EntityInfoPage/CareerInfoComponents/ActivitiesSection';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import { saveCareerBoards } from 'actions/boardActions';
import { ANALYTIC_EVENTS, ANALYTIC_PAGES, page, track } from 'analytics';
import AddToBoardPopover from 'components/AddToBoardPopover';
import { Button } from 'components/ds/Button/Button';
import ProgramCardSmall from 'components/HomePage/ProgramCardSmall/ProgramCardSmall';
import CareerCardSmall from 'components/HomePage/CareerCardSmall/CareerCardSmall';
import {
  DEFAULT_SEARCH_PARAMS,
  LocationSearchOptions,
  SearchParams,
  createProgramFilterConfigs,
  fetchProgramSearchData,
} from 'pages/ProgramsOverviewPage';
import { fetchCareerOptions, fetchProgramSearch } from 'actions/programsActions';
import SearchPage from 'components/SearchPage/SearchPage';
import { theme } from 'components/ds/theme.css';
import { PROGRAM_SEARCH_SORTS } from 'constants/search';
import { User } from 'auth/types';
import { DistanceRangeMiles } from 'constants/data';
import { getVideoUrl } from 'components/HomePage/CareerCard/videoUtil';

type Props = { currentUser: User };

const CareerPage = ({ currentUser }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [boardIds, setBoardIds] = useState<number[]>([]);
  const [videoErrored, setVideoErrored] = useState<boolean>(false);
  const { careerId } = useParams<{ careerId: string }>();

  const defaultUserParams = {
    ...DEFAULT_SEARCH_PARAMS,
    selectedLocationName: currentUser.profile_data?.location_name,
    selectedStateCode: currentUser.profile_data?.state_code,
    selectedZipcode: currentUser.profile_data?.zipcode,
    locationSearchBy: 'zipcode' as LocationSearchOptions,
    locationRange: DistanceRangeMiles.WITHIN_100_MILES,
    careerOptions: [parseInt(careerId)],
  };

  const [sideBarTab, setSideBarTab] = useState<'programs' | 'careers'>('programs');
  const [programSearchParams, setProgramSearchParams] = useState<SearchParams>(defaultUserParams);
  const [tempSearchParams, setTempSearchParams] = useState<SearchParams>(programSearchParams);

  const {
    career,
    careerLoading,
    programs,
    totalResults,
    loadingResults,
    costMin,
    careerOptions,
    costMax,
  } = useSelector(
    (state: ReduxState) => ({
      career: state.careerInfo.career,
      careerLoading: createLoadingSelector([ACTION.CAREER_INFO], true)(state),
      programs: state.programsSearch.programs,
      totalResults: state.programsSearch.totalResults,
      careerOptions: state.programsSearch.careerOptions,
      costMin: state.programsSearch.costMin,
      costMax: state.programsSearch.costMax,
      loadingResults: createLoadingSelector([ACTION.PROGRAM_SEARCH], true)(state),
    }),
    shallowEqual,
  );

  const fetchProgramDataWrapper = (params: SearchParams) =>
    fetchProgramSearchData(
      params,
      () => {},
      (params) => dispatch(fetchProgramSearch({ getData: params })),
      true,
    );

  const programSearchConfig = createProgramFilterConfigs(
    programSearchParams,
    tempSearchParams,
    defaultUserParams,
    setProgramSearchParams,
    setTempSearchParams,
    fetchProgramDataWrapper,
    careerOptions,
    costMin,
    costMax,
    loadingResults,
    true,
  );

  useEffect(() => {
    dispatch(fetchCareerOptions());
    setVideoErrored(false);
    dispatch(
      fetchCareerInfo({ id: careerId }, (data) => {
        let referrer = undefined;
        if (window.location.search) {
          const urlParams = new URLSearchParams(window.location.search);
          const queryParams = Object.fromEntries(urlParams.entries());
          if (queryParams.referrer) {
            referrer = queryParams.referrer;
          }
        }
        page(ANALYTIC_PAGES.CAREER_PAGE, undefined, {
          career_id: careerId,
          career_name: data.career.name,
          referrer,
        });
      }),
    );
    fetchProgramDataWrapper(defaultUserParams);
  }, [dispatch, careerId]);

  useEffect(() => {
    career && setBoardIds(career.board_ids);
  }, [career?.board_ids]);

  if (!careerLoading && !career) return <div>Error</div>;
  if (careerLoading || !career)
    return (
      <div className={styles.loadingPage}>
        <LoadingSpinner />
      </div>
    );

  // rendering so that switching tabs doesn't re-render. Mostly for the
  // related careers since it renders the video
  const relatedProgramsRendered = (
    <SearchPage
      mini
      style="program"
      searchPlaceholder="Search Programs"
      onBodyScroll={(scrollTop) => {}}
      secondaryColor={theme.colors.careersSecondaryColor}
      resultsClassName={styles.searchResults}
      categoryFilters={[]}
      totalResults={totalResults}
      loadingResults={loadingResults}
      resultObjects={programs}
      resultRenderer={(program) => (
        <ProgramCardSmall
          className={styles.programElement}
          program={program}
          key={program.id}
          location="Programs Search Page"
        />
      )}
      onSortChange={programSearchConfig.onSortChange}
      resetFilters={programSearchConfig.resetFilters}
      searchString={programSearchParams.searchString}
      onSearchStringUpdated={programSearchConfig.onSearchStringUpdated}
      selectedBucket={programSearchParams.selectedCategory}
      onLoadMoreClicked={programSearchConfig.onLoadMoreClicked}
      loadMoreBtnText="Load more programs"
      sortOptions={PROGRAM_SEARCH_SORTS}
      selectedSort={programSearchParams.sort}
      filtersConfig={programSearchConfig.filterConfigs}
    />
  );

  const relatedCareersRendered = (
    <div className={styles.careerSideBarContent}>
      {career.related_careers.map((career) => (
        <CareerCardSmall
          key={`career-page-careers-${career.id}`}
          className={styles.careerElement}
          career={career}
          location="Career Page Similar Careers"
        />
      ))}
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.mainBodyContainer}>
        {!videoErrored ? (
          <div style={{ position: 'relative' }}>
            <video
              src={getVideoUrl(career.code)}
              width="100%"
              height="100%"
              controls
              onError={() => setVideoErrored(true)}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
            <Button
              className={styles.videoBackBtn}
              text="Back"
              type="primary"
              icon="left-arrow"
              onClick={() => history.goBack()}
            />
          </div>
        ) : null}
        <div className={styles.headerContainer}>
          <div className={styles.headerMainContent}>
            {videoErrored ? (
              <Button
                className={styles.noVideoBackBtn}
                type="ghost"
                icon="left-arrow"
                onClick={() => history.goBack()}
              />
            ) : null}

            <div className={styles.careerName}>{career.name}</div>
          </div>

          <div className={styles.actionBtns}>
            <AddToBoardPopover
              title="Add to Board"
              selectedBoards={boardIds}
              onSaveClicked={(boardIds) => {
                setBoardIds(boardIds);
                dispatch(
                  saveCareerBoards({ postData: { career_id: career.id, board_ids: boardIds } }),
                );
              }}
              buttonProps={{
                size: 'large',
                icon: 'add-board',
                type: 'primary',
                text: 'Add to Board',
              }}
              menuProps={{
                align: 'end',
              }}
              location="Career Page"
              entityType="Career"
              entityId={career.id}
            />
            <Button
              size="large"
              icon="bookmark"
              type="outline"
              iconFilled={career.is_saved}
              onClick={() => {
                dispatch(
                  updateSaveCareer(
                    { id: career.id, postData: { isSaved: !career.is_saved } },
                    () => {
                      toast(
                        <div>
                          <b>{career.name}</b> {!career.is_saved ? 'saved' : 'unsaved'}!
                        </div>,
                        {
                          position: toast.POSITION.BOTTOM_RIGHT,
                          hideProgressBar: true,
                          autoClose: 2000,
                        },
                      );
                    },
                  ),
                );
                track(ANALYTIC_EVENTS.CAREER_SAVED, {
                  location: 'Career Page',
                  career_id: career.id,
                  career_name: career.name,
                  is_saved: !career.is_saved,
                });
              }}
            />
          </div>
        </div>
        <DetailSection career={career} key={`${career.id}-details`} />
        <ActivitiesSection career={career} key={`${career.id}-activities`} />
      </div>
      <div className={styles.sideBarContainer}>
        <div className={styles.savedToggle}>
          <Button
            text="Programs"
            type={sideBarTab === 'programs' ? 'primary' : 'ghost'}
            size="large"
            onClick={() => setSideBarTab('programs')}
          />
          <Button
            text="Similar Careers"
            type={sideBarTab === 'careers' ? 'primary' : 'ghost'}
            size="large"
            onClick={() => setSideBarTab('careers')}
          />
        </div>
        {sideBarTab === 'programs' ? relatedProgramsRendered : relatedCareersRendered}
      </div>
    </div>
  );
};

export default CareerPage;
