/** @format */

import React, { useState } from 'react';

import * as styles from './LearnTimeFilter.css';

import { Checkbox } from 'components/ds/Checkbox/Checkbox';
import { CareerSearchResult } from 'actions/careerActions';
import { Input } from 'components/ds/Input/Input';
import { Icon } from 'components/ds/Icon/Icon';
import { orderBy, sortBy } from 'lodash';

type Props = {
  savedCareers: CareerSearchResult[];
  unsavedCareers: CareerSearchResult[];
  selectedOptions?: number[];
  updateSelectedOptions: (option: number, isSelected: boolean) => void;
  numResultsToShow?: number;
  disabled?: boolean;
};

const CareerFilter = ({
  savedCareers,
  unsavedCareers,
  selectedOptions,
  updateSelectedOptions,
  numResultsToShow,
  disabled,
}: Props) => {
  const [searchString, setSearchString] = useState<string>('');
  let careersToRender = savedCareers;
  if (searchString.length > 0) {
    const savedSearch = savedCareers.filter((c) =>
      c.name.toLowerCase().includes(searchString.toLowerCase()),
    );
    const unsavedSearch = unsavedCareers.filter((c) =>
      c.name.toLowerCase().includes(searchString.toLowerCase()),
    );

    careersToRender = [...savedSearch, ...unsavedSearch];
  } else {
    careersToRender = [
      ...savedCareers,
      ...unsavedCareers.filter((c) => selectedOptions?.includes(c.id)),
    ];
    if (numResultsToShow && careersToRender.length < numResultsToShow) {
      careersToRender = [
        ...careersToRender,
        ...unsavedCareers
          .filter((c) => careersToRender.findIndex((career) => career.id === c.id) === -1)
          .slice(0, numResultsToShow - careersToRender.length),
      ];
    }
    careersToRender = orderBy(careersToRender, (c) => selectedOptions?.includes(c.id), 'desc');
  }

  return (
    <div className={styles.root}>
      <Input
        value={searchString}
        onChange={(newValue) => setSearchString(newValue)}
        className={styles.searchInput}
        placeholder="Search Careers"
      />
      <div className={styles.options}>
        {careersToRender.map((career) => {
          const isChecked = (selectedOptions || []).indexOf(career.id) > -1;
          return (
            <div className={styles.inputContainer} key={`career-career-filter-${career.id}`}>
              <div className={styles.option}>
                <Checkbox
                  isChecked={isChecked}
                  onChange={() => updateSelectedOptions(career.id, !isChecked)}
                  disabled={disabled}
                />
                <div className={styles.label}>{career.name}</div>
              </div>
              {career.is_saved && <Icon name="bookmark" filled />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CareerFilter;
