/** @format */

import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { Modal } from 'components/ds/Modal/Modal';
import * as styles from './CreateBoardModal.css';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';
import { createBoard } from 'actions/boardActions';
import { Input } from 'components/ds/Input/Input';
import { ANALYTIC_EVENTS, track } from 'analytics';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  onCreate?: () => void;
  location: string;
};

const CreateBoardModal = ({ isOpen, closeModal, location }: Props) => {
  const [newBoardInfo, setNewBoardInfo] = useState<{ name: string }>({ name: '' });
  const [boardNameError, setBoardNameError] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { createBoardLoading } = useSelector(
    (state: ReduxState) => ({
      createBoardLoading: createLoadingSelector([ACTION.CREATE_BOARD], false)(state),
    }),
    shallowEqual,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        closeModal();
        setNewBoardInfo({ name: '' });
      }}
      size="small"
      title="Create a new Board"
      tertiaryButtonProps={{
        text: 'Cancel',
        onClick: () => {
          setNewBoardInfo({ name: '' });
          closeModal();
        },
      }}
      primaryButtonProps={{
        text: 'Create',
        loading: createBoardLoading,
        onClick: () => {
          if (!newBoardInfo.name) {
            setBoardNameError(true);
            return;
          }

          dispatch(
            createBoard({ postData: { name: newBoardInfo.name } }, () => {
              track(ANALYTIC_EVENTS.CREATE_BOARD_SUBMITTED, { name: newBoardInfo.name, location });

              setNewBoardInfo({ name: '' });
              closeModal();
            }),
          );
        },
      }}>
      <div className={styles.createBoardModalBody}>
        <Input
          error={boardNameError}
          className={styles.createBoardNameInput}
          value={newBoardInfo.name}
          onChange={(newValue) => {
            setNewBoardInfo({ ...newBoardInfo, name: newValue });

            if (newValue) setBoardNameError(false);
          }}
          placeholder="Give it a name"
        />
      </div>
    </Modal>
  );
};

export default CreateBoardModal;
