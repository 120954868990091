/** @format */

import { createTheme } from '@material-ui/core/styles';

interface CustomShadows {
  basic: string;
  sideNav: string;
}

declare module '@material-ui/core/index' {
  interface Color {
    border: string;
    lightBorder: string;
    light: string;
    middle: string;
    medium: string;
    normal: string;
    slight: string;
    lightText: string;
    dark: string;
    darkest: string;
    blueprintMutedGray: string;
    blueprintDarkWhite: string;
    blueprintGrey: string;
    disabledText: string;
    modalBG: string;
    main: string;
    backgroundGrey: string;
    pageBackground: string;
    navBarItemGrey: string;
    bold: string;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    black: string;
    white: string;
    dangerRed: string;
    lightRed: string;
    blue: string;
    exploBlue: string;
    exploBlue100: string;
    black100: string;
    grey100: string;
    grey200: string;
    grey300: string;
    grey400: string;
    grey800: string;
    grey900: string;
    darkBlue: string;
    navSelectBlue: string;
  }

  interface Palette {
    black: string;
    white: string;
    dangerRed: string;
    lightRed: string;
    blue: string;
    exploBlue: string;
    exploBlue100: string;
    black100: string;
    grey100: string;
    grey200: string;
    grey300: string;
    grey400: string;
    grey800: string;
    grey900: string;
    darkBlue: string;
    navSelectBlue: string;
  }

  interface SimplePaletteColorOptions {
    veryLight?: string;
    selected?: string;
    selectedText?: string;
    highlighted?: string;
  }

  interface PaletteColor {
    veryLight?: string;
    selected?: string;
    selectedText?: string;
    highlighted?: string;
  }
}

declare module '@material-ui/core/styles/createTheme' {
  interface ThemeOptions {
    customShadows: CustomShadows;
  }

  interface Theme {
    customShadows: CustomShadows;
  }
}

const theme = createTheme({
  palette: {
    // design system colors
    exploBlue: '#0069ED',
    exploBlue100: '#3890FF',
    black100: '#0A111E',
    grey100: '#AFBDD6',
    grey200: '#3C547C',
    grey300: 'rgba(43, 69, 113, 0.6)',
    grey400: '#1E3150',
    grey800: '#3D4C62',
    grey900: '#253041',

    primary: {
      veryLight: '#F5F8FF',
      main: '#348CF7',
      light: '#CCEDF9',
      selected: '#E7F0F7',
      selectedText: '#106ba3',
      highlighted: '#B2D7FF',
    },
    secondary: {
      main: '#003459',
    },
    error: {
      main: '#f44336',
      light: '#f77b72',
    },
    // 50 shades of grey
    grey: {
      border: '#BDBDBD',
      lightBorder: '#C1CEE0',
      light: '#F2F2F2',
      middle: '#CECECE',
      medium: '#A7A2A9',
      normal: '#E2E2E2',
      slight: '#DDE5F0', // to be removed
      lightText: '#3D4C62',
      dark: '#404E5C',
      darkest: '#333333',
      blueprintMutedGray: '#a7b6c2',
      blueprintDarkWhite: '#f5f8fa',
      blueprintGrey: '#394b59',
      disabledText: '#8A9BA8',
      modalBG: '#ebf1f5',
      backgroundGrey: '#5C7080',
      pageBackground: '#F1F5FB',
      navBarItemGrey: '#869AB7',
      bold: '#697B97',
    },
    black: '#000000',
    white: '#FFFFFF',
    dangerRed: '#db3737',
    lightRed: '#EB5757',
    blue: '#4B8DF0',
    darkBlue: '#0069ED',
    navSelectBlue: '#04469A',
  },
  spacing: 4,
  customShadows: {
    basic: '0px 4px 5px rgba(0, 0, 0, 0.25);',
    sideNav:
      '0px 2px 6px rgba(16, 22, 26, 0.2), 0px 1px 1px rgba(16, 22, 26, 0.2), 0px 0px 0px rgba(16, 22, 26, 0.1);',
  },
});

export default theme;
