/** @format */

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { groupBy } from 'lodash';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';

import * as styles from './HomePage.css';
import { Button } from 'components/ds/Button/Button';
import { Icon } from 'components/ds/Icon/Icon';
import { InterestReactions, fetchInterests } from 'actions/interestActions';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import { REACT_TO_BUTTON_CONFIG } from 'components/ds/InterestButton/InterestButton';
import InterestCard from 'components/HomePage/InterestCard';
import { fetchBoards } from 'actions/boardActions';
import BoardCard from 'components/HomePage/BoardCard';
import CreateBoardModal from 'components/shared/CreateBoardModal';
import { ANALYTIC_EVENTS, ANALYTIC_PAGES, page, track } from 'analytics';
import { ONBOARDING_STEP_DATA, OnboardingStep } from 'onboarding/steps';
import { updateOnboardingStatus } from 'actions/onboardingActions';

type Props = {};

const HomePage = ({}: Props) => {
  const { isTablet } = useWindowDimensions();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(!isTablet);
  const [createBoardModalOpen, setCreateBoardModalOpen] = useState<boolean>(false);
  const [selectedInterestReact, setSelectedInterestReact] = useState<InterestReactions>(
    InterestReactions.LOVE,
  );
  const [tourEnabled, setTourEnabled] = useState(true);
  const dispatch = useDispatch();

  const {
    currentUser,
    interests,
    interestsLoading,
    boardsById,
    careersById,
    programsById,
    boardsLoading,
  } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      interests: state.interestReducer,
      boardsById: state.boards.boards,
      careersById: state.boards.careers,
      programsById: state.boards.programs,
      interestsLoading: createLoadingSelector([ACTION.FETCH_INTERESTS], true)(state),
      boardsLoading: createLoadingSelector([ACTION.FETCH_BOARDS], true)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchInterests());
    dispatch(fetchBoards());

    page(ANALYTIC_PAGES.HOME_PAGE);
  }, []);

  useEffect(() => {
    if (currentUser.profile_data?.is_done_with_homepage_onboarding) setTourEnabled(false);
  }, [currentUser]);

  const interestByReaction = groupBy(Object.values(interests), 'reaction');
  const selectedInterests = interestByReaction[selectedInterestReact];

  const numBoards = Object.keys(boardsById).length;

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setTourEnabled(false);
      dispatch(updateOnboardingStatus({ postData: { is_done_with_homepage_onboarding: true } }));
    }
  };

  let mainBody;
  if (boardsLoading) {
    mainBody = (
      <div className={styles.boardsLoadingView}>
        <LoadingSpinner />
      </div>
    );
  } else if (numBoards === 0) {
    mainBody = (
      <div className={styles.noBoardView}>
        <Button
          size="large"
          type="primary"
          text="Create your first Board"
          icon="add-board"
          onClick={() => setCreateBoardModalOpen(true)}
        />
      </div>
    );
  } else {
    mainBody = (
      <>
        <div className={styles.boardsHeader}>
          <div className={styles.numBoards}>
            {numBoards} board{numBoards > 1 ? 's' : ''}
          </div>
          <Button
            className={OnboardingStep.NEW_BOARDS}
            size="large"
            type="primary"
            text="Create new Board"
            icon="add-board"
            onClick={() => {
              setCreateBoardModalOpen(true);
              track(ANALYTIC_EVENTS.CREATE_BOARD_CLICKED, { location: 'Home Page' });
            }}
          />
        </div>
        <div className={styles.boardBody}>
          {Object.values(boardsById).map((board, i) => (
            <BoardCard
              key={`home-page-board-${board.id}`}
              className={cx(styles.boardCard, { [OnboardingStep.RECOMMENDED_BOARD]: i === 0 })}
              board={board}
              careersById={careersById}
              programsById={programsById}
            />
          ))}
        </div>
      </>
    );
  }

  return (
    <div className={styles.root}>
      <Joyride
        continuous
        hideCloseButton
        scrollToFirstStep
        callback={handleJoyrideCallback}
        run={tourEnabled}
        steps={[
          ONBOARDING_STEP_DATA[OnboardingStep.BOARD_OVERVIEW],
          ONBOARDING_STEP_DATA[OnboardingStep.RECOMMENDED_BOARD],
          ONBOARDING_STEP_DATA[OnboardingStep.NEW_BOARDS],
          ONBOARDING_STEP_DATA[OnboardingStep.INTERESTS_TAB_OVERVIEW],
          ONBOARDING_STEP_DATA[OnboardingStep.CAREERS_AND_PROGRAM_SEARCH],
        ]}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#000',
          },
        }}
      />
      <div className={styles.pageBody}>
        <div className={styles.header}>
          <div className={styles.headerTop}>
            <div className={styles.headerTitle}>
              {currentUser.first_name} {currentUser.last_name}
            </div>

            <Button
              icon="dock-to-left"
              type="ghost"
              size="large"
              onClick={() => {
                setSidebarOpen(!sidebarOpen);
                track(ANALYTIC_EVENTS.HOME_PAGE_SIDEBAR_TOGGLE, { is_open: !sidebarOpen });
              }}
            />
          </div>
          <div className={styles.graduationBanner}>
            <Icon className={styles.graduationIcon} name="celebration" />8 months to graduation
          </div>
        </div>
        <div className={cx(styles.mainBody, OnboardingStep.BOARD_OVERVIEW)}>{mainBody}</div>
      </div>

      {sidebarOpen && <div className={styles.interestBarOverlay} />}
      <div
        className={cx(
          styles.interestBar,
          { open: sidebarOpen },
          OnboardingStep.INTERESTS_TAB_OVERVIEW,
        )}>
        {interestsLoading ? (
          <div className={styles.interestLoading}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className={styles.headerBuffer} />
            <div className={styles.interestsHeader}>
              <div className={styles.interestTitle}>
                My Interests
                <Button
                  className={styles.sidebarCrossButton}
                  icon="cross"
                  type="ghost"
                  onClick={() => setSidebarOpen(false)}
                />
              </div>
              <div className={styles.interestToggle}>
                {Object.values(InterestReactions).map((reaction) => (
                  <Button
                    className={styles.interestButtons}
                    key={`interest-home-react-${reaction}`}
                    color={reaction === selectedInterestReact ? 'neutral' : undefined}
                    icon={REACT_TO_BUTTON_CONFIG[reaction].rightIcon}
                    type={reaction === selectedInterestReact ? 'primary' : 'ghost'}
                    size="large"
                    onClick={() => {
                      setSelectedInterestReact(reaction);
                      track(ANALYTIC_EVENTS.HOME_PAGE_INTEREST_REACTION_SECTION_CLICKED, {
                        reaction: reaction,
                      });
                    }}
                  />
                ))}
              </div>
            </div>
            <div className={styles.selectedInterestName}>{selectedInterestReact}</div>
            <div className={styles.interestsSection}>
              {(selectedInterests || []).map((interest) => (
                <InterestCard
                  key={`interest-card-home-${interest.id}`}
                  className={styles.interestCard}
                  interest={interest}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <CreateBoardModal
        isOpen={createBoardModalOpen}
        closeModal={() => setCreateBoardModalOpen(false)}
        location="Home Page"
      />
    </div>
  );
};

export default HomePage;
