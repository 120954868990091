/** @format */

import { createReducer } from '@reduxjs/toolkit';

import {
  BoardData,
  fetchBoardDataRequest,
  fetchBoardDataSuccess,
  removeCareerFromBoard,
  removeProgramFromBoard,
  updateBoardRequest,
} from 'actions/boardActions';
import { CareerSearchResult } from 'actions/careerActions';
import { ProgramSearchResult, updateSaveProgramRequest } from 'actions/programsActions';

type State = {
  board?: BoardData;
  careers: { [careerId: number]: CareerSearchResult };
  programs: { [programId: number]: ProgramSearchResult };
};

const initialState: State = { careers: {}, programs: {} };

export default createReducer<State>({ ...initialState }, (builder) => {
  builder
    .addCase(fetchBoardDataRequest, (state, { payload }) => {
      return { ...initialState };
    })
    .addCase(fetchBoardDataSuccess, (state, { payload }) => {
      state.board = payload.board;

      payload.programs.forEach((program) => {
        state.programs[program.id] = program;
      });

      payload.careers.forEach((career) => {
        state.careers[career.id] = career;
      });

      return state;
    })
    .addCase(updateBoardRequest, (state, { payload }) => {
      if (!payload.id || !state.board) return state;
      state.board = {
        ...state.board,
        ...payload.postData.board,
      };

      return state;
    })
    .addCase(updateSaveProgramRequest, (state, { payload }) => {
      const program = state.programs[payload.id as number];

      if (!program) return state;

      program.is_saved = payload.postData.isSaved;

      return state;
    })
    .addCase(removeCareerFromBoard, (state, { payload }) => {
      if (!state.board) return state;

      state.board = {
        ...state.board,
        career_ids: state.board.career_ids.filter((id) => id !== payload.careerId),
      };

      return state;
    })
    .addCase(removeProgramFromBoard, (state, { payload }) => {
      if (!state.board) return state;

      state.board = {
        ...state.board,
        program_ids: state.board.program_ids.filter((id) => id !== payload.programId),
      };

      return state;
    })

    .addDefaultCase((state) => state);
});
