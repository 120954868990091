/** @format */

import React from 'react';

import * as styles from './LearningProgramAIDescriptions.css';
import { Link } from 'react-router-dom';

type Props = {};

const LearningProgramAIDescriptions = ({}: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTop}>
          <div className={styles.headerTopContainer}>
            <div className={styles.headerText}>Program AI Descriptions</div>
          </div>
        </div>
      </div>
      <div className={styles.bodyContainer}></div>
    </div>
  );
};

export default LearningProgramAIDescriptions;
