/** @format */

import { createReducer } from '@reduxjs/toolkit';
import { saveCareerBoardsSuccess, saveProgramBoardsSuccess } from 'actions/boardActions';
import { updateSaveCareerRequest } from 'actions/careerActions';

import {
  ProgramInfoResult,
  fetchProgramInfoSuccess,
  updateSaveProgramRequest,
} from 'actions/programsActions';

type State = { program?: ProgramInfoResult };

const initialState: State = {};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(fetchProgramInfoSuccess, (state, { payload }) => {
      state.program = payload.program;

      return state;
    })
    .addCase(updateSaveProgramRequest, (state, { payload }) => {
      if (!state.program) return state;
      if (state.program.id === payload.id) {
        state.program.is_saved = payload.postData.isSaved;
      } else {
        const program = state.program.related_programs.find((program) => program.id == payload.id);
        if (program) {
          program.is_saved = payload.postData.isSaved;
        }
      }

      return state;
    })
    .addCase(updateSaveCareerRequest, (state, { payload }) => {
      if (!state.program) return state;
      const isSaved = payload.postData.isSaved;
      const careerId = payload.id;
      if (!careerId) return state;

      const career = state.program.careers.find((career) => career.id == careerId);
      if (!career) return state;

      career.is_saved = isSaved;

      return state;
    })
    .addCase(saveProgramBoardsSuccess, (state, { payload }) => {
      if (!state.program) return state;
      if (state.program.id === payload.postData.program_id) {
        state.program.board_ids = payload.postData.board_ids;
      } else {
        const program = state.program.related_programs.find(
          (program) => program.id == payload.postData.program_id,
        );
        if (program) {
          program.board_ids = payload.postData.board_ids;
        }
      }

      return state;
    })
    .addCase(saveCareerBoardsSuccess, (state, { payload }) => {
      if (!state.program) return state;
      const career = state.program.careers.find(
        (career) => career.id == payload.postData.career_id,
      );

      if (career) {
        career.board_ids = payload.postData.board_ids;
      }

      return state;
    })

    .addDefaultCase((state) => state);
});
