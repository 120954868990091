/** @format */

import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';

import * as styles from './InterestMatchingPage.css';
import { LOGO } from 'constants/svgs';
import { ReduxState } from 'reducers/rootReducer';
import {
  InterestReactions,
  fetchInterests,
  updateStudentInterestReaction,
} from 'actions/interestActions';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { times } from 'lodash';
import { Icon } from 'components/ds/Icon/Icon';
import { Button } from 'components/ds/Button/Button';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import { completeInterestsStep } from 'actions/onboardingActions';
import { Tooltip } from 'components/ds/Tooltip/Tooltip';
import { ONBOARDING_STEP_DATA, OnboardingStep } from 'onboarding/steps';
import { REACT_TO_BUTTON_CONFIG } from 'components/ds/InterestButton/InterestButton';
import { ANALYTIC_PAGES, page } from 'analytics';

const InterestMatchingPage = () => {
  const cardRef = useRef<any>();
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();
  const [tourEnabled, setTourEnabled] = useState(true);

  const { interests, interestsLoading } = useSelector(
    (state: ReduxState) => ({
      interests: state.interestReducer,
      interestsLoading: createLoadingSelector([ACTION.FETCH_INTERESTS], true)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(fetchInterests());
    page(ANALYTIC_PAGES.INTEREST_MATCHING_PAGE);
  }, []);

  if (interestsLoading)
    return (
      <div className={styles.loadingPage}>
        <LoadingSpinner />
      </div>
    );

  const orderedInterests = Object.values(interests);
  orderedInterests.sort((a, b) => a.name.localeCompare(b.name));
  const interestsToDo = orderedInterests.filter((interest) => interest.reaction === null);
  const interestsDone = orderedInterests.filter((interest) => interest.reaction !== null);
  const lastInterest =
    interestsDone.length > 0 ? interestsDone[interestsDone.length - 1] : undefined;

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setTourEnabled(false);
    }
  };

  if (interestsToDo.length === 0) {
    return (
      <div className={styles.root}>
        <div className={styles.congratsBody}>
          <div className={styles.congratsBodyContainer}>
            <div className={styles.congratsContainer}>
              <div className={styles.header}>Almost Done!</div>
              <div className={styles.subheader}>
                We have a few more questions to personalize your experience
              </div>
              <Button
                type="primary"
                size="large"
                text="Continue"
                rightIcon="right-arrow"
                onClick={() =>
                  dispatch(
                    completeInterestsStep({ postData: {} }, () => {
                      window.location.href = '/career-preferences';
                    }),
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const numDone = Object.values(interests).filter((interest) => interest.reaction !== null).length;
  const numInterests = Object.values(interests).length;
  const currentInterest = interestsToDo[0];

  const processReaction = (interestId: number, react: InterestReactions | null) => {
    dispatch(updateStudentInterestReaction({ id: interestId, postData: { reaction: react } }));
  };

  return (
    <div className={styles.root}>
      <Joyride
        continuous
        hideCloseButton
        scrollToFirstStep
        callback={handleJoyrideCallback}
        run={tourEnabled}
        steps={[
          ONBOARDING_STEP_DATA[OnboardingStep.INTEREST_MATCHING_CARD],
          ONBOARDING_STEP_DATA[OnboardingStep.INTEREST_MATCHING_REACTS],
        ]}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#000',
          },
        }}
      />

      <div className={styles.sidebar}>
        <div className={styles.top}>
          <div className={styles.logo}>{LOGO(40, 40)}</div>
          <div className={styles.title}>Great! Lets get a sense of your interests</div>
        </div>
        <div className={styles.footerText}>
          React to interest cards and we'll personalize career suggestions to match your interests.
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.bodyContainer}>
          <div>
            <div className={styles.counterContainer}>
              {numDone} of {Object.values(interests).length}
            </div>
            <div className={styles.cardsContainer}>
              <div className={cx(styles.flipCardOuter, OnboardingStep.INTEREST_MATCHING_CARD)}>
                <div className={styles.flipCardInner} ref={cardRef}>
                  <div className={cx(styles.cardStyle, styles.currentCard)}>
                    <Button
                      onClick={() => (cardRef.current.style.transform = 'rotateY(180deg)')}
                      className={cx(styles.infoButton, styles.infoButtonWhite)}
                      icon="info"
                      type="ghost"
                    />
                    {currentInterest.name}
                  </div>
                  <div className={cx(styles.cardStyle, styles.cardBack)}>
                    <Button
                      onClick={() => (cardRef.current.style.transform = 'rotateY(0deg)')}
                      className={styles.infoButton}
                      icon="cross"
                      type="ghost"
                    />

                    <div>
                      <div className={styles.backHeader}>{currentInterest.name}</div>
                      <div className={styles.backBody}>{currentInterest.description}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {times(numInterests - numDone).map((i) => (
              <div
                className={styles.fillerCard}
                key={`fillercard-${i}`}
                style={{ zIndex: 100 - i }}></div>
            ))}
          </div>
          <div className={cx(styles.reactionBar, OnboardingStep.INTEREST_MATCHING_REACTS)}>
            <Tooltip text="Love" side="left">
              <div
                className={styles.reactionBtn}
                onClick={() => processReaction(currentInterest.id, InterestReactions.LOVE)}>
                <Icon
                  className={styles.reactionIcon}
                  name={REACT_TO_BUTTON_CONFIG[InterestReactions.LOVE].rightIcon}
                />
              </div>
            </Tooltip>
            <Tooltip text="Like" side="left">
              <div
                className={styles.reactionBtn}
                onClick={() => processReaction(currentInterest.id, InterestReactions.LIKE)}>
                <Icon
                  className={styles.reactionIcon}
                  name={REACT_TO_BUTTON_CONFIG[InterestReactions.LIKE].rightIcon}
                />
              </div>
            </Tooltip>
            <Tooltip text="Neutral" side="left">
              <div
                className={styles.reactionBtn}
                onClick={() => processReaction(currentInterest.id, InterestReactions.NEUTRAL)}>
                <Icon
                  className={styles.reactionIcon}
                  name={REACT_TO_BUTTON_CONFIG[InterestReactions.NEUTRAL].rightIcon}
                />
              </div>
            </Tooltip>
            <Tooltip text="Dislike" side="left">
              <div
                className={styles.reactionBtn}
                onClick={() => processReaction(currentInterest.id, InterestReactions.DISLIKE)}>
                <Icon
                  className={styles.reactionIcon}
                  name={REACT_TO_BUTTON_CONFIG[InterestReactions.DISLIKE].rightIcon}
                />
              </div>
            </Tooltip>
            {lastInterest ? (
              <Tooltip text="Undo" side="left">
                <div
                  className={cx(styles.reactionBtn, styles.undoBtn)}
                  onClick={() => processReaction(lastInterest.id, null)}>
                  <Icon className={styles.undoIcon} name="undo" />
                </div>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestMatchingPage;
