/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';

import * as styles from './index.css';
import { BOARD_PATH_INFO, BoardStartingPath, createBoardPath } from 'actions/createBoardActions';
import { Icon } from 'components/ds/Icon/Icon';

type Props = {};

const PickBoardPath = ({}: Props) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.root}>
      <div className={styles.subHeader}>Create a Board</div>
      <div className={styles.header}>What is this board about?</div>

      <div>
        {Object.values(BoardStartingPath)
          .filter((path) => path !== BoardStartingPath.INSTITUTION)
          .map((pathName) => (
            <div
              key={`create-board-path-start-${pathName}`}
              className={styles.pathCard}
              onClick={() => dispatch(createBoardPath({ path: pathName }))}>
              <div className={styles.cardContent}>
                <div className={styles.cardIcon}>
                  <Icon name={BOARD_PATH_INFO[pathName].icon} />
                </div>
                <div>
                  <div className={styles.cardName}>{pathName}</div>
                  <div className={styles.cardDescription}>
                    {BOARD_PATH_INFO[pathName].description}
                  </div>
                </div>
              </div>
              <div>
                <Icon name="right-arrow" />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PickBoardPath;
