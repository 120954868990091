/** @format */

import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import * as styles from './searchFilterSortBar.css';
import { DropdownButton } from 'components/ds/DropdownButton/DropdownButton';
import { Menu, MenuActionItem } from 'components/ds/Menu/Menu';
import { SearchSortOption } from 'constants/search';
import { FilterConfig, FilterType, LocationConfigOptions } from './SearchPage';
import { Tag } from 'components/ds/Tag/Tag';
import { COMP_STATE_OPTIONS } from 'constants/data';
import LocationFilter from './ProgramFilters/LocationFilter';
import { RegionSelector } from 'components/ds/RegionSelector/RegionSelector';
import { Modal } from 'components/ds/Modal/Modal';
import { Button } from 'components/ds/Button/Button';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { OnboardingStep } from 'onboarding/steps';

const state_codes = Object.keys(COMP_STATE_OPTIONS);
state_codes.sort();

type Props = {
  filtersConfig: FilterConfig[];
};

const SearchFilterSortBar = ({ filtersConfig }: Props) => {
  const filterByType: Record<string, FilterConfig> = {};
  filtersConfig.forEach((filter) => (filterByType[filter.type] = filter));

  return (
    <div className={styles.root}>
      <div className={styles.backBtnContainer}>
        <Button
          className={styles.backBtn}
          text="Back"
          icon="left-arrow"
          type="ghost"
          to="/explore"
        />
      </div>

      {Object.keys(filterByType).map((filterType) => {
        return (
          <div
            className={styles.filterModalSection}
            key={`modal-popover-filter-search-${filterType}`}>
            <div className={styles.filterSectionName}>{filterByType[filterType].type}</div>
            <div className={styles.filterSectionContent}>{filterByType[filterType].component}</div>
          </div>
        );
      })}
    </div>
  );
};

export default SearchFilterSortBar;
