/** @format */

import React, { useEffect } from 'react';

import * as styles from './ProfilePage.css';
import ProfileEditor from 'components/SetupPage/ProfileEditor';
import { ANALYTIC_PAGES, page } from 'analytics';

const ProfilePage = () => {
  useEffect(() => {
    page(ANALYTIC_PAGES.PROFILE_EDIT_PAGE);
  }, []);

  return (
    <div className={styles.root}>
      <ProfileEditor className={styles.editor} onSave={() => {}} />
    </div>
  );
};

export default ProfilePage;
