/** @format */

import { createAction } from '@reduxjs/toolkit';

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';

import { ACTION } from 'actions/types';
import { ProgramSearchResult } from './programsActions';
import { InterestData } from './interestActions';

export type UpdateCareerCacheUrlParamsArgs = {
  urlParams: string;
};

export type CacheCareerSearchScrollTopArgs = {
  scrollTop: number;
};

export const cacheCareerSearch = createAction(ACTION.CAREER_SEARCH_CACHE);
export const updateCareerCacheUrlParams = createAction<UpdateCareerCacheUrlParamsArgs>(
  ACTION.UPDATE_CAREER_CACHE_URL_PARAMS,
);
export const useCareerSearchCache = createAction(ACTION.USE_CAREER_SEARCH_CACHE);
export const cacheCareerSearchScrollTop = createAction<CacheCareerSearchScrollTopArgs>(
  ACTION.CACHE_CAREER_SEARCH_SCROLL_TOP,
);

export type CareerSearchResult = {
  id: number;
  name: string;
  code: string;
  description: string;
  career_pathway: string;
  career_cluster: string;
  job_zone: string | null;
  num_love_interests: number;
  program_length_min: number | null;
  program_length_max: number | null;
  program_length_avg: number | null;
  program_length_expected: number | null;
  avg_starting_salary: number | null;
  avg_ending_salary: number | null;
  is_saved: boolean;
  board_ids: number[];

  search_description_highlighted?: string;
  search_name_highlighted?: string;
};

type CareerSearchResponse = {
  careers: CareerSearchResult[];
  total_results: number;
};

export const {
  actionFn: fetchCareerSearch,
  requestAction: fetchCareerSearchRequest,
  successAction: fetchCareerSearchSuccess,
  errorAction: fetchCareerSearchError,
} = defineAPIAction<CareerSearchResponse>(ACTION.CAREER_SEARCH, 'careers', 'search', 'GET');

export type TaskData = {
  task_id: string;
  task_name: string;
  task_importance: number;
};

export type CompensationInfoData = {
  location: string;
  state: string;
  level: string;
  zipcode?: string;

  num_employed?: number;
  local_quotient?: number;

  annual_avg?: number;
  hourly_avg?: number;

  annual_10?: number;
  annual_25?: number;
  annual_50?: number;
  annual_75?: number;
  annual_90?: number;

  hourly_10?: number;
  hourly_25?: number;
  hourly_50?: number;
  hourly_75?: number;
  hourly_90?: number;

  annual_only?: boolean;
  hourly_only?: boolean;

  living_wage_1_adult_0_kids?: number;
};

export type CareerInfoResult = {
  id: number;
  code: string;
  name: string;
  description: string;
  career_pathway: string;
  career_cluster: string;
  tasks: TaskData[];
  interests: InterestData[];
  job_zone: string;
  comp_data: CompensationInfoData[];
  processed_comp_data?: {
    state: { [location: string]: CompensationInfoData };
    msa: { [location: string]: CompensationInfoData };
    state_to_msa_map: { [state: string]: string[] };
  };
  related_careers: CareerSearchResult[];
  programs: ProgramSearchResult[];
  program_length_min: number;
  program_length_max: number;
  num_programs: number;
  board_ids: number[];
  is_saved: boolean;
};

type CareerInfoResponse = {
  career: CareerInfoResult;
};

export const {
  actionFn: fetchCareerInfo,
  requestAction: fetchCareerInfoRequest,
  successAction: fetchCareerInfoSuccess,
  errorAction: fetchCareerInfoError,
} = defineAPIAction<CareerInfoResponse>(ACTION.CAREER_INFO, 'careers', 'info', 'GET');

type UpdateSaveCareerArgs = {
  isSaved: boolean;
};

export const {
  actionFn: updateSaveCareer,
  requestAction: updateSaveCareerRequest,
  successAction: updateSaveCareerSuccess,
  errorAction: updateSaveCareerError,
} = defineAPIPostAction<UpdateSaveCareerArgs, {}>(
  ACTION.UPDATE_STUDENT_SAVE_CAREER,
  'careers',
  'save_career',
  'POST',
);
