/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import * as styles from './index.css';
import { CareerSearchResult, updateSaveCareer } from 'actions/careerActions';
import { Button } from 'components/ds/Button/Button';
import { Icon } from 'components/ds/Icon/Icon';
import { convertProgramLengthToMixMaxTime } from 'components/SearchPage/CareerSearchResult';
import { Tooltip } from 'components/ds/Tooltip/Tooltip';
import { ANALYTIC_EVENTS, track } from 'analytics';
import { toast } from 'react-toastify';
import AddToBoardPopover from 'components/AddToBoardPopover';
import { saveCareerBoards } from 'actions/boardActions';
import { Link } from 'react-router-dom';
import { getVideoUrl } from './videoUtil';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type Props = {
  career: CareerSearchResult;
  className?: string;
  location: string;
  hideVideo?: boolean;
  hideAddToBoard?: boolean;
  rightButtons?: JSX.Element;
  onClick?: () => void;
};

const CareerCard = ({
  className,
  career,
  location,
  hideVideo,
  hideAddToBoard,
  rightButtons,
  onClick,
}: Props) => {
  const dispatch = useDispatch();
  const [boardIds, setBoardIds] = useState<number[]>(career.board_ids);
  const [videoErrored, setVideoErrored] = useState<boolean>(false);

  const mainBody = (
    <>
      <div className={cx(styles.headerContainer, { [styles.noBottomPadding]: videoErrored })}>
        <div className={styles.headerText}>{career.name}</div>
        <div className={styles.headerButtons}>
          {rightButtons ?? (
            <>
              {/* <Tooltip text="Not for me" side="bottom" align="center">
                <Button
                  className={styles.cancelBtn}
                  icon="cancel"
                  type="ghost"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </Tooltip> */}
              <Tooltip text="Save" side="bottom" align="center">
                <Button
                  icon="bookmark"
                  type="ghost"
                  iconFilled={career.is_saved}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    track(ANALYTIC_EVENTS.CAREER_SAVED, {
                      location,
                      career_id: career.id,
                      career_name: career.name,
                      is_saved: !career.is_saved,
                    });
                    dispatch(
                      updateSaveCareer(
                        { id: career.id, postData: { isSaved: !career.is_saved } },
                        () => {
                          toast(
                            <div>
                              <b>{career.name}</b> {!career.is_saved ? 'saved' : 'unsaved'}!
                            </div>,
                            {
                              position: toast.POSITION.BOTTOM_RIGHT,
                              hideProgressBar: true,
                              autoClose: 2000,
                            },
                          );
                        },
                      ),
                    );
                  }}
                />
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {!hideVideo && !videoErrored ? (
        <div>
          <video
            src={getVideoUrl(career.code)}
            width="100%"
            height="100%"
            controls
            onError={() => setVideoErrored(true)}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        </div>
      ) : null}
      <div className={styles.infoContainer}>
        <div className={styles.infoLeft}>
          <div className={styles.infoDescription}>{career.description}</div>
          {career.num_love_interests ? (
            <div className={styles.infoMatchInfo}>
              <Icon fontSize={14} className={styles.infoMatchIcon} filled name="heart" /> Matches{' '}
              {career.num_love_interests} Interests
            </div>
          ) : null}
        </div>
        <div className={styles.infoRight}>
          <div className={styles.nuggetContainer}>
            <div className={styles.nuggetHeader}>Salary</div>
            <div className={styles.nuggetValue}>
              💰{' '}
              {career.avg_starting_salary
                ? USDollar.format(Math.round(career.avg_starting_salary / 1000) * 1000)
                : '-'}{' '}
              -{' '}
              {career.avg_ending_salary
                ? USDollar.format(Math.round(career.avg_ending_salary / 1000) * 1000)
                : '-'}
            </div>
          </div>
          <div className={styles.nuggetContainer}>
            <div className={styles.nuggetHeader}>Learn Time</div>
            <div className={styles.nuggetValue}>
              📖{' '}
              {convertProgramLengthToMixMaxTime(
                career.program_length_max,
                career.program_length_expected,
              )}
            </div>
          </div>
        </div>
      </div>
      {!hideAddToBoard && (
        <AddToBoardPopover
          title="Add Program to Board"
          selectedBoards={boardIds}
          onSaveClicked={(boardIds) => {
            setBoardIds(boardIds);
            dispatch(saveCareerBoards({ postData: { career_id: career.id, board_ids: boardIds } }));
          }}
          buttonProps={
            boardIds.length > 0
              ? {
                  icon: 'board',
                  iconFilled: true,
                }
              : undefined
          }
          location={location}
          entityType="Career"
          entityId={career.id}
          trigger={
            <div
              className={styles.addToBoardTrigger}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}>
              <div className={styles.addBoardText}>Add to Board</div>
              <Icon
                name={boardIds.length > 0 ? 'board' : 'add-board'}
                filled={boardIds.length > 0}
              />
            </div>
          }
        />
      )}
    </>
  );

  if (onClick) {
    return (
      <div className={cx(styles.root, className)} onClick={onClick}>
        {mainBody}
      </div>
    );
  }

  return (
    <div className={cx(styles.root, className)}>
      <Link to={`/careers/${career.id}`} className={styles.linkContainer}>
        {mainBody}
      </Link>
    </div>
  );
};

export default CareerCard;
