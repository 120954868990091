/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import * as styles from './index.css';
import { Button } from 'components/ds/Button/Button';
import { Icon } from 'components/ds/Icon/Icon';
import { Tooltip } from 'components/ds/Tooltip/Tooltip';
import { ANALYTIC_EVENTS, track } from 'analytics';
import { toast } from 'react-toastify';
import { ProgramSearchResult, updateSaveProgram } from 'actions/programsActions';
import { convertProgramLengthToMixMaxTime } from 'components/SearchPage/ProgramSearchResult';
import AddToBoardPopover from 'components/AddToBoardPopover';
import { saveProgramBoards } from 'actions/boardActions';
import { Link } from 'react-router-dom';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type Props = {
  program: ProgramSearchResult;
  className?: string;
  location: string;
  hideAddToBoard?: boolean;
  rightButtons?: JSX.Element;
  onClick?: () => void;
};

const ProgramCard = ({
  className,
  program,
  location,
  hideAddToBoard,
  rightButtons,
  onClick,
}: Props) => {
  const [boardIds, setBoardIds] = useState<number[]>(program.board_ids);
  const dispatch = useDispatch();

  const costPerYear =
    program.student_cost_per_year !== null
      ? USDollar.format(Math.round(program.student_cost_per_year / 1000) * 1000)
      : '-';

  const costText =
    program.program_length_max && program.program_length_max <= 12 ? 'Cost (Total)' : 'Cost / Yr';

  const body = (
    <>
      <div className={styles.headerContainer}>
        <div>
          <div className={styles.headerDegreeType}>{program.degree_type}</div>
          <div className={styles.headerText}>{program.name}</div>
          <div className={styles.headerProviderName}>{program.provider_name}</div>
        </div>

        <div className={styles.headerButtons}>
          {rightButtons ?? (
            <>
              <Tooltip text="Save" side="bottom" align="center">
                <Button
                  icon="bookmark"
                  type="ghost"
                  iconFilled={program.is_saved}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    track(ANALYTIC_EVENTS.PROGRAM_SAVED, {
                      location,
                      program_id: program.id,
                      program_name: program.name,
                      is_saved: !program.is_saved,
                    });
                    dispatch(
                      updateSaveProgram(
                        { id: program.id, postData: { isSaved: !program.is_saved } },
                        () => {
                          toast(
                            <div>
                              <b>{program.name}</b> {!program.is_saved ? 'saved' : 'unsaved'}!
                            </div>,
                            {
                              position: toast.POSITION.BOTTOM_RIGHT,
                              hideProgressBar: true,
                              autoClose: 2000,
                            },
                          );
                        },
                      ),
                    );
                  }}
                />
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.infoLeft}>
          <div className={styles.nuggetContainer}>
            <div className={styles.nuggetHeader}>{costText}</div>
            <div className={styles.nuggetValue}>💸 {costPerYear}</div>
          </div>
          <div className={styles.nuggetContainer}>
            <div className={styles.nuggetHeader}>Learn Time</div>
            <div className={styles.nuggetValue}>
              📖{' '}
              {convertProgramLengthToMixMaxTime(
                program.program_length_max,
                program.program_length_min,
              )}
            </div>
          </div>
          <div className={styles.nuggetContainer}>
            <div className={styles.nuggetHeader}>Location</div>
            <div className={styles.nuggetValue}>
              📍 {program.provider_city}, {program.provider_state}
            </div>
          </div>
        </div>
      </div>
      {hideAddToBoard ? null : (
        <AddToBoardPopover
          title="Add Program to Board"
          selectedBoards={boardIds}
          onSaveClicked={(boardIds) => {
            setBoardIds(boardIds);
            dispatch(
              saveProgramBoards({ postData: { program_id: program.id, board_ids: boardIds } }),
            );
          }}
          buttonProps={
            boardIds.length > 0
              ? {
                  icon: 'board',
                  iconFilled: true,
                }
              : undefined
          }
          location={location}
          entityType="Program"
          entityId={program.id}
          trigger={
            <div
              className={styles.addToBoardTrigger}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}>
              <div className={styles.addBoardText}>Add to Board</div>
              <Icon
                name={boardIds.length > 0 ? 'board' : 'add-board'}
                filled={boardIds.length > 0}
              />
            </div>
          }
        />
      )}
    </>
  );

  if (onClick) {
    return (
      <div className={cx(styles.root, className)} onClick={onClick}>
        {body}
      </div>
    );
  }

  return (
    <div className={cx(styles.root, className)}>
      <Link to={`/programs/${program.id}`} className={styles.linkContainer}>
        {body}
      </Link>
    </div>
  );
};

export default ProgramCard;
