/** @format */

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as styles from './index.css';
import WizardStep from '../WizardStep/WizardStep';
import SearchPage from 'components/SearchPage/SearchPage';
import { theme } from 'components/ds/theme.css';
import {
  DEFAULT_SEARCH_PARAMS,
  SearchParams,
  createCareerFilterConfigs,
  fetchCareerSearchData,
} from 'pages/CareersOverviewPage';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { fetchCareerSearch } from 'actions/careerActions';
import { CAREER_SEARCH_SORTS } from 'constants/search';
import CareerCard from 'components/HomePage/CareerCard/CareerCard';
import { Checkbox } from 'components/ds/Checkbox/Checkbox';
import { Button } from 'components/ds/Button/Button';
import { createBoardCareers } from 'actions/createBoardActions';

type Props = {};

const BoardCareer = ({}: Props) => {
  const dispatch = useDispatch();
  const [selectedCareerIds, setSelectedCareerIds] = useState<number[]>([]);
  const [selectedTab, setSelectedTab] = useState<'explore' | 'saved'>('explore');

  const [searchParams, setSearchParams] = useState<SearchParams>({
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [tempSearchParams, setTempSearchParams] = useState<SearchParams>(searchParams);

  const {
    interests,
    careers,
    compMax,
    compMin,
    totalResults,
    loadingResults,
    createBoardCareerIds,
  } = useSelector(
    (state: ReduxState) => ({
      interests: state.interestReducer,
      careers: state.careersSearch.careers,
      compMax: 300000,
      compMin: 0,
      totalResults: state.careersSearch.totalResults,
      loadingResults: createLoadingSelector([ACTION.CAREER_SEARCH], true)(state),
      createBoardCareerIds: state.createBoardReducer.boardConfig?.careerIds || [],
    }),
    shallowEqual,
  );

  const fetchData = (params: SearchParams) =>
    fetchCareerSearchData(
      params,
      (urlParams) => {},
      (params) => dispatch(fetchCareerSearch({ getData: params })),
      true,
    );

  useEffect(() => {
    const newParams = { ...DEFAULT_SEARCH_PARAMS, savedOnly: selectedTab === 'saved' };
    fetchData(newParams);
    setSearchParams(newParams);
    setTempSearchParams(newParams);
  }, [selectedTab]);

  useEffect(() => {
    setSelectedCareerIds(createBoardCareerIds);
  }, []);

  const careerSearchConfig = createCareerFilterConfigs(
    searchParams,
    tempSearchParams,
    {
      ...DEFAULT_SEARCH_PARAMS,
      bucketName: searchParams.bucketName,
      savedOnly: selectedTab === 'saved',
    },
    setSearchParams,
    setTempSearchParams,
    fetchData,
    interests,
    compMin,
    compMax,
    loadingResults,
  );

  return (
    <WizardStep
      nextDisabled={selectedCareerIds.length === 0}
      onNextClicked={() => dispatch(createBoardCareers({ careerIds: selectedCareerIds }))}>
      <div className={styles.root}>
        <div className={styles.header}>Select career(s)</div>
        <div className={styles.subHeader}>{selectedCareerIds.length} selected</div>
        <div className={styles.savedToggle}>
          <Button
            text="Explore"
            icon="explore"
            type={selectedTab === 'explore' ? 'primary' : 'ghost'}
            size="large"
            onClick={() => setSelectedTab('explore')}
          />
          <Button
            text="Saved"
            icon="bookmark"
            type={selectedTab === 'saved' ? 'primary' : 'ghost'}
            size="large"
            onClick={() => setSelectedTab('saved')}
          />
        </div>

        <div className={styles.searchContainer}>
          <SearchPage
            mini
            hideControls={selectedTab === 'saved'}
            style="career"
            searchPlaceholder="Search Careers"
            onBodyScroll={(scrollTop) => {}}
            secondaryColor={theme.colors.careersSecondaryColor}
            categoryFilters={[]}
            totalResults={totalResults}
            loadingResults={loadingResults}
            resultObjects={careers}
            resultRenderer={(career) => (
              <CareerCard
                hideVideo
                hideAddToBoard
                className={cx(styles.careerCard, {
                  [styles.selectedCard]: selectedCareerIds.includes(career.id),
                })}
                career={career}
                key={career.id}
                location="Programs Search Page"
                rightButtons={
                  <Checkbox isChecked={selectedCareerIds.includes(career.id)} onChange={() => {}} />
                }
                onClick={() => {
                  if (selectedCareerIds.includes(career.id)) {
                    setSelectedCareerIds(selectedCareerIds.filter((id) => id !== career.id));
                  } else {
                    setSelectedCareerIds([...selectedCareerIds, career.id]);
                  }
                }}
              />
            )}
            onSortChange={careerSearchConfig.onSortChange}
            resetFilters={careerSearchConfig.resetFilters}
            searchString={searchParams.searchString}
            onSearchStringUpdated={careerSearchConfig.onSearchStringUpdated}
            onLoadMoreClicked={careerSearchConfig.onLoadMoreClicked}
            loadMoreBtnText="Load more programs"
            sortOptions={CAREER_SEARCH_SORTS}
            selectedSort={searchParams.sort}
            filtersConfig={careerSearchConfig.filterConfigs}
          />
        </div>
      </div>
    </WizardStep>
  );
};

export default BoardCareer;
