/** @format */

import React, { useState } from 'react';
import cx from 'classnames';

import * as styles from './BoardCard.css';
import { BoardData, CareersCompressedData, ProgramCompressedData } from 'actions/boardActions';
import { Link } from 'react-router-dom';
import { Icon } from 'components/ds/Icon/Icon';
import { Button } from 'components/ds/Button/Button';

type Props = {
  className?: string;
  board: BoardData;
  programsById: Record<number, ProgramCompressedData>;
  careersById: Record<number, CareersCompressedData>;
};

const BoardCard = ({ className, board, careersById, programsById }: Props) => {
  return (
    <Link to={`/board/${board.id}`} className={cx(styles.root, className)}>
      <div
        className={board.cover_photo_url ? styles.boardHeaderImage : styles.boardHeader}
        style={{
          backgroundImage: board.cover_photo_url ? `url("${board.cover_photo_url}")` : undefined,
        }}>
        <div className={styles.boardName}>
          {board.emoji} {board.name}
        </div>
      </div>
      <div className={styles.boardBodyContainer}>
        <div className={styles.careerSection}>
          <Icon className={styles.sectionIcon} name="career" />
          <div className={styles.sectionList}>
            {board.career_ids.slice(0, 2).map((careerId, i) => (
              <div key={`board-career-${board.id}-${careerId}`} className={styles.entityName}>
                {careersById[careerId].name}
              </div>
            ))}
            {board.career_ids.slice(2).length > 0 ? (
              <div className={styles.entityName}>+{board.career_ids.slice(2).length} more</div>
            ) : null}
            {board.career_ids.length === 0 ? (
              <Button type="outline" to="/careers" stopPropogation text="Select a career(s)" />
            ) : null}
          </div>
        </div>
        <div className={styles.programsSection}>
          <Icon className={styles.sectionIcon} name="programs" />
          <div className={styles.sectionList}>
            {Array.from(
              new Set(
                board.program_ids
                  .slice(0, 2)
                  .map((programId, i) => programsById[programId].provider_name),
              ),
            ).map((programName) => (
              <div key={`board-program-${board.id}-${programName}`} className={styles.entityName}>
                {programName}
              </div>
            ))}
            {board.program_ids.slice(2).length > 0 ? (
              <div className={styles.entityName}>+{board.program_ids.slice(2).length} more</div>
            ) : null}
            {board.program_ids.length === 0 ? (
              <Button type="outline" to="/programs" stopPropogation text="Select a program(s)" />
            ) : null}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BoardCard;
