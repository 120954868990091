/** @format */

import React, { FC, forwardRef } from 'react';
import * as RadixSelect from '@radix-ui/react-select';
import cx from 'classnames';

import { Icon } from 'components/ds/Icon/Icon';
import { sprinkles } from 'components/ds';

import * as styles from './index.css';

type SelectValues<T extends string | number> = {
  label?: string;
  value: T;
}[];

export type Props<T extends string | number> = {
  className?: string;
  label?: string;
  infoText?: string;
  placeholder?: string;
  portalContainerId?: string;
  onChange: (value: T) => void;
  selectedValue?: T;
  values: SelectValues<T>;
};

export const Select: FC<Props<string | number>> = ({
  className,
  infoText,
  label,
  onChange,
  placeholder,
  portalContainerId,
  selectedValue,
  values,
}) => {
  const content = (
    <RadixSelect.Content className={styles.content} position="popper" sideOffset={8}>
      <RadixSelect.ScrollUpButton />
      <RadixSelect.Viewport>
        {values.map(({ value, label }) => (
          <SelectItem className={styles.item} key={value} value={value}>
            {label ?? value}
          </SelectItem>
        ))}
      </RadixSelect.Viewport>
      <RadixSelect.ScrollDownButton />
    </RadixSelect.Content>
  );

  return (
    <>
      <RadixSelect.Root value={selectedValue?.toString()} onValueChange={onChange}>
        <RadixSelect.Trigger className={cx(styles.trigger, className)} id={label}>
          <span className={sprinkles({ truncateText: 'ellipsis' })}>
            <RadixSelect.Value placeholder={placeholder ?? 'Select...'} />
          </span>
          <RadixSelect.Icon>
            <Icon
              className={sprinkles({ color: 'black', paddingLeft: 'sp.5' })}
              name="caret-down"
            />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>

        {portalContainerId ? (
          <RadixSelect.Portal container={document.getElementById(portalContainerId)}>
            {content}
          </RadixSelect.Portal>
        ) : (
          content
        )}
      </RadixSelect.Root>
    </>
  );
};

type SelectItemProps = {
  className?: string;
  value: string | number;
};

const SelectItem: FC<SelectItemProps> = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, className, value, ...props }, forwardedRef) => {
    return (
      <RadixSelect.Item className={className} ref={forwardedRef} value={String(value)} {...props}>
        <span className={sprinkles({ truncateText: 'ellipsis' })} title={children?.toString()}>
          <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
        </span>
        <RadixSelect.ItemIndicator className="SelectItemIndicator">
          <Icon className={sprinkles({ paddingLeft: 'sp.5' })} name="check" />
        </RadixSelect.ItemIndicator>
      </RadixSelect.Item>
    );
  },
);

SelectItem.displayName = 'SelectItem';
