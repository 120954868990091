/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import * as styles from 'components/EntityInfoPage/styles.css';
import { CareerInfoResult } from 'actions/careerActions';
import { Select } from 'components/ds/Select/Select';
import SalaryLineChart from './SalaryLineChart';
import { COMP_STATE_OPTIONS, STATE_CODE_TO_NAME } from 'constants/data';
import { ReduxState } from 'reducers/rootReducer';
import { Tooltip } from 'components/ds/Tooltip/Tooltip';
import { ANALYTIC_EVENTS, track } from 'analytics';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

type Props = {
  career: CareerInfoResult;
};

const FULL_STATE_VALUE = 'Full State';

const getInitialSelection = (career: CareerInfoResult) => {
  const comp_data = career.processed_comp_data;
  if (!comp_data) return {};

  const states = getStates(career);

  if (states.length === 0) return {};

  const selectedState = states[0];

  if (!comp_data.state[selectedState] && !comp_data.state_to_msa_map[selectedState]) return {};

  const selectedMSA = comp_data.state[selectedState]
    ? FULL_STATE_VALUE
    : comp_data.state_to_msa_map[selectedState][0];

  return {
    state: selectedState,
    msa: selectedMSA,
  };
};

const getStates = (career: CareerInfoResult) => {
  const comp_data = career.processed_comp_data;

  if (!comp_data) return [];

  const stateCodes = Object.keys(comp_data.state_to_msa_map);
  stateCodes.sort();
  return stateCodes;
};

const SalarySection = ({ career }: Props) => {
  const initialSelection = getInitialSelection(career);
  const [selectedState, setSelectedState] = useState<string | undefined>(initialSelection.state);
  const [selectedMSA, setSelectedMSA] = useState<string | undefined>(initialSelection.msa);

  const { currentUser } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
    }),
    shallowEqual,
  );

  useEffect(() => {
    setSelectedState(currentUser.profile_data?.state_code);
    let location = currentUser.profile_data?.location_name;
    if (COMP_STATE_OPTIONS[currentUser.profile_data?.state_code || '']) {
      const stateLevel = COMP_STATE_OPTIONS[currentUser.profile_data?.state_code || ''][0];
      if (stateLevel.location === currentUser.profile_data?.location_name) {
        location = FULL_STATE_VALUE;
      }
    }

    setSelectedMSA(location);
  }, [currentUser]);

  if (!career.processed_comp_data) return <div>Loading</div>;

  if (!selectedState || !selectedMSA) return <div>No compensation data available</div>;

  const comp_data = career.processed_comp_data;
  const msas = comp_data.state_to_msa_map[selectedState] || [];

  const selectedComp =
    selectedMSA === FULL_STATE_VALUE ? comp_data.state[selectedState] : comp_data.msa[selectedMSA];

  const livingWageText = selectedComp?.living_wage_1_adult_0_kids ? (
    <div>
      The income needed to cover expenses for a single person in this region is{' '}
      <b>{Math.round(selectedComp.living_wage_1_adult_0_kids / 1000)}k</b>
    </div>
  ) : (
    'The income needed to cover expenses for a single person in this region.'
  );

  return (
    <div className={styles.compContainer}>
      <div className={styles.compLeftPanel}>
        <Select
          className={styles.stateSelector}
          onChange={(state) => {
            const stateCode = state as string;
            setSelectedState(stateCode);
            if (!comp_data.state_to_msa_map[stateCode]) {
              setSelectedMSA(FULL_STATE_VALUE);
            } else {
              setSelectedMSA(comp_data.state_to_msa_map[stateCode][0]);
            }
            track(ANALYTIC_EVENTS.CAREER_PAGE_SALARY_STATE_CHANGED, {
              state: stateCode,
              career_id: career.id,
            });
          }}
          selectedValue={selectedState}
          values={Object.keys(STATE_CODE_TO_NAME).map((stateCode) => ({
            value: stateCode,
            label: STATE_CODE_TO_NAME[stateCode],
          }))}
        />

        <Select
          onChange={(msa) => {
            setSelectedMSA(msa as string);
            track(ANALYTIC_EVENTS.CAREER_PAGE_SALARY_MSA_CHANGED, {
              msa: msa,
              state: selectedState,
              career_id: career.id,
            });
          }}
          selectedValue={selectedMSA}
          values={[{ value: FULL_STATE_VALUE, label: 'Statewide' }].concat(
            COMP_STATE_OPTIONS[selectedState]
              .filter((v) => v.level === 'msa')
              .map((compMsa) => ({
                value: compMsa.location,
                label: compMsa.location.split(',')[0],
              })),
          )}
        />
        <div className={styles.startingSalary}>
          <div className={styles.startingSalaryText}>Estimated Starting Salary</div>
          <div className={styles.salary}>
            {selectedComp ? USDollar.format(selectedComp.annual_10 || 0) : '-'}
          </div>
        </div>
      </div>
      <div className={styles.compRightPanel}>
        <div className={styles.compLegend}>
          <Tooltip text={livingWageText}>
            <div className={styles.compLegendContainer}>
              <div className={styles.compLegendSquare}></div>
              <div className={styles.compLegendText}>Living Wage</div>
            </div>
          </Tooltip>
        </div>
        <SalaryLineChart
          salary10={selectedComp?.annual_10 || 0}
          salary90={selectedComp?.annual_90 || 0}
          livingWageSalary={selectedComp?.living_wage_1_adult_0_kids}
          noData={!selectedComp}
        />
      </div>
    </div>
  );
};

export default SalarySection;
