/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { groupBy } from 'lodash';

import * as styles from './index.css';
import { InterestData, InterestReactions, fetchInterests } from 'actions/interestActions';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';
import { ANALYTIC_EVENTS, ANALYTIC_PAGES, page, track } from 'analytics';
import { fetchCareerSearch } from 'actions/careerActions';
import { SearchParams } from 'pages/CareersOverviewPage';
import { User } from 'auth/types';
import { SearchSortOption } from 'constants/search';
import { COMP_STATE_OPTIONS } from 'constants/data';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import CareerCard from 'components/HomePage/CareerCard/CareerCard';
import { Button } from 'components/ds/Button/Button';
import { REACT_TO_BUTTON_CONFIG } from 'components/ds/InterestButton/InterestButton';
import { Icon } from 'components/ds/Icon/Icon';
import { Modal } from 'components/ds/Modal/Modal';
import InterestCard from 'components/HomePage/InterestCard';
import CareerClusterFilter from 'components/SearchPage/CareerFilters/CareerClusterFilter';
import { updateOnboardingStatus } from 'actions/onboardingActions';

const DEFAULT_SEARCH_PARAMS: SearchParams = {
  page: 0,
  sort: SearchSortOption.INTEREST_RELEVANCE,
};

type Props = {
  currentUser: User;
};

const getMaxValueInMap = (map: Record<string, number>) => {
  let maxValue = 0;
  let maxKey: string = '';

  Object.keys(map).forEach((key) => {
    if (map[key] > maxValue) {
      maxKey = key;
      maxValue = map[key];
    }
  });

  return maxKey;
};

const topCategoryByInterestReaction = (interests: Record<number, InterestData>) => {
  const categoryByReaction: Record<InterestReactions, Record<string, number>> = {
    [InterestReactions.LOVE]: {},
    [InterestReactions.LIKE]: {},
    [InterestReactions.DISLIKE]: {},
    [InterestReactions.NEUTRAL]: {},
  };

  Object.values(interests).forEach((interest) => {
    if (!interest.reaction) return;

    const categoryMap = categoryByReaction[interest.reaction];

    if (!categoryMap[interest.category]) {
      categoryMap[interest.category] = 0;
    }
    categoryMap[interest.category] += 1;
  });

  return {
    [InterestReactions.LOVE]: getMaxValueInMap(categoryByReaction[InterestReactions.LOVE]),
    [InterestReactions.LIKE]: getMaxValueInMap(categoryByReaction[InterestReactions.LIKE]),
    [InterestReactions.DISLIKE]: getMaxValueInMap(categoryByReaction[InterestReactions.DISLIKE]),
    [InterestReactions.NEUTRAL]: getMaxValueInMap(categoryByReaction[InterestReactions.DISLIKE]),
  };
};

const HomePageV2 = ({ currentUser }: Props) => {
  const dispatch = useDispatch();

  const [interestEditModalOpen, setInterestEditModalOpen] = useState<boolean>(false);
  const [selectedInterestReact, setSelectedInterestReact] = useState<InterestReactions>(
    InterestReactions.LOVE,
  );

  const [searchParams, setSearchParams] = useState<SearchParams>({
    ...DEFAULT_SEARCH_PARAMS,
    locationName: currentUser.profile_data?.location_name,
    stateCode: currentUser.profile_data?.state_code,
  });

  const { careers, loadingResults, interests, interestsLoading } = useSelector(
    (state: ReduxState) => ({
      interests: state.interestReducer,
      careers: state.careersSearch.careers,
      loadingResults: createLoadingSelector([ACTION.CAREER_SEARCH], true)(state),
      interestsLoading: createLoadingSelector([ACTION.FETCH_INTERESTS], true)(state),
    }),
    shallowEqual,
  );

  const fetchData = (params: SearchParams) => {
    const getData: Record<string, string> = {
      page: params.page.toString(),
      sort: params.sort,
      results_per_page: '10',
    };

    if (params.bucketName !== undefined) getData['category'] = params.bucketName;
    if (params.searchString !== undefined) getData['search_string'] = params.searchString;
    if (params.savedOnly !== undefined) getData['saved'] = params.savedOnly.toString();
    if (params.compMax !== undefined) getData['comp_max'] = params.compMax.toString();
    if (params.compMin !== undefined) getData['comp_min'] = params.compMin.toString();
    if (params.learnTimeOptions !== undefined && params.learnTimeOptions.length > 0)
      getData['learn_time'] = params.learnTimeOptions.toString();
    if (params.careerClusterOptions !== undefined && params.careerClusterOptions.length > 0)
      getData['career_clusters'] = params.careerClusterOptions.join('|');
    if (params.interestsOptions !== undefined && params.interestsOptions.length > 0)
      getData['interests'] = params.interestsOptions.toString();

    if (params.stateCode && params.locationName) {
      const locations = COMP_STATE_OPTIONS[params.stateCode];
      const level = locations[0].location === params.locationName ? 'state' : 'msa';
      getData['location_name'] = params.locationName;
      getData['location_level'] = level;
      getData['state_code'] = params.stateCode;
    }

    dispatch(fetchCareerSearch({ getData }));
  };

  useEffect(() => {
    let params = {
      ...DEFAULT_SEARCH_PARAMS,
      locationName: currentUser.profile_data?.location_name,
      stateCode: currentUser.profile_data?.state_code,
    };

    setSearchParams(params);

    dispatch(fetchInterests());
    fetchData(params);

    page(ANALYTIC_PAGES.HOME_PAGE);
  }, []);

  const topCatReactions = topCategoryByInterestReaction(interests);
  const interestByReaction = groupBy(Object.values(interests), 'reaction');
  const selectedInterests = interestByReaction[selectedInterestReact];

  return (
    <div className={styles.root}>
      <div className={styles.mainContainer}>
        <div className={styles.mainContainerScroller}>
          <div className={styles.headerContainer}>
            <div className={styles.headerText}>For You</div>
            <div className={styles.subHeaderText}>
              Career recommendations based on your interest
            </div>
          </div>
          <div className={styles.resultsContainer}>
            {careers.map((career) => (
              <CareerCard
                key={`career-home-page${career.id}`}
                className={styles.careerCards}
                career={career}
                location="Home Page"
              />
            ))}
          </div>
          {loadingResults ? (
            <div className={styles.loadingContainer}>
              <LoadingSpinner />
            </div>
          ) : null}
          {!loadingResults ? (
            <Button
              className={styles.loadMoreBtn}
              type="primary"
              size="large"
              text="Load More Careers"
              loading={loadingResults}
              onClick={() => {
                const newParams = {
                  ...searchParams,
                  page: searchParams.page + 1,
                };

                setSearchParams(newParams);
                fetchData(newParams);
              }}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.interestContainer}>
        <div className={styles.interestHeader}>Career Preferences</div>
        <div className={styles.clusterDropdownContainer}>
          <CareerClusterFilter
            selectedOptions={
              currentUser.profile_data?.category_preferences !== ''
                ? currentUser.profile_data?.category_preferences
                    ?.split('|')
                    .filter((c) => c !== '') || undefined
                : undefined
            }
            updateSelectedOptions={(option, isSelected) => {
              const onPreferenceChange = () => {
                const newParams = {
                  ...searchParams,
                  page: 0,
                };
                setSearchParams(newParams);
                fetchData(newParams);
              };

              const currentSelections =
                currentUser.profile_data?.category_preferences?.split('|') ?? [];

              track(ANALYTIC_EVENTS.HOME_PAGE_CLUSTER_SELECTED, {
                cluster: option,
                selected: isSelected,
              });
              if (currentSelections.includes(option)) {
                dispatch(
                  updateOnboardingStatus(
                    {
                      postData: {
                        category_preferences: currentSelections
                          .filter((c) => c !== option)
                          .join('|'),
                      },
                    },
                    onPreferenceChange,
                  ),
                );
              } else {
                dispatch(
                  updateOnboardingStatus(
                    {
                      postData: {
                        category_preferences: [...currentSelections, option].join('|'),
                      },
                    },
                    onPreferenceChange,
                  ),
                );
              }
            }}
            disabled={loadingResults}
          />
        </div>
        <div className={styles.interestHeader}>Interests</div>
        <div>
          {Object.values(InterestReactions).map((reaction) => {
            let numInterests = 0;
            Object.values(interests).forEach((interest) => {
              if (interest.reaction === reaction) numInterests += 1;
            });
            return (
              <div className={styles.interestSummary} key={`interest-react-home-page-${reaction}`}>
                <div
                  className={
                    reaction === InterestReactions.LOVE || reaction === InterestReactions.LIKE
                      ? styles.interestIconFilled
                      : styles.interestIconUnfilled
                  }>
                  <Icon fontSize={20} name={REACT_TO_BUTTON_CONFIG[reaction].rightIcon} />
                </div>
                <div>
                  <div className={styles.reactionCategory}>
                    {REACT_TO_BUTTON_CONFIG[reaction].name}
                  </div>
                  <div className={styles.reactionNum}>
                    {numInterests} {reaction} interest{numInterests !== 1 ? 's' : ''}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Button
          type="outline"
          text="Edit interest cards"
          onClick={() => {
            setInterestEditModalOpen(true);
            track(ANALYTIC_EVENTS.HOME_PAGE_INTEREST_MODAL_OPENED);
          }}
        />
      </div>
      <Modal
        size="small"
        isOpen={interestEditModalOpen}
        title="Interest Cards"
        onClose={() => setInterestEditModalOpen(false)}>
        <div className={styles.modalBody}>
          <div className={styles.interestToggle}>
            {Object.values(InterestReactions).map((reaction) => (
              <Button
                className={styles.interestButtons}
                key={`interest-home-react-${reaction}`}
                color={reaction === selectedInterestReact ? 'neutral' : undefined}
                icon={REACT_TO_BUTTON_CONFIG[reaction].rightIcon}
                type={reaction === selectedInterestReact ? 'primary' : 'ghost'}
                size="large"
                onClick={() => {
                  setSelectedInterestReact(reaction);
                  track(ANALYTIC_EVENTS.HOME_PAGE_INTEREST_REACTION_SECTION_CLICKED, {
                    reaction: reaction,
                  });
                }}
              />
            ))}
          </div>
          <div className={styles.interestsSection}>
            {(selectedInterests || []).map((interest) => (
              <InterestCard
                key={`interest-card-home-${interest.id}`}
                className={styles.interestCard}
                interest={interest}
              />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HomePageV2;
