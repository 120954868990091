/** @format */

import React, { useEffect } from 'react';

import * as styles from './SetupPage.css';
import { LOGO } from 'constants/svgs';
import ProfileEditor from 'components/SetupPage/ProfileEditor';
import { ANALYTIC_PAGES, page } from 'analytics';

const SetupPage = () => {
  useEffect(() => {
    page(ANALYTIC_PAGES.PROFILE_SETUP);
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.sidebar}>
        <div className={styles.top}>
          <div className={styles.logo}>{LOGO(40, 40)}</div>
          <div className={styles.title}>Let's start by setting up your profile</div>
        </div>
        <div className={styles.footerText}>
          You can change settings anytime by going to your profile page
        </div>
      </div>
      <ProfileEditor className={styles.profileEditor} />
    </div>
  );
};

export default SetupPage;
