/** @format */

import React from 'react';

import * as styles from './SalaryFilter.css';
import { Slider } from 'components/ds/Slider/Slider';

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type Props = {
  compMax: number;
  compMin: number;
  setMax?: number;
  setMin?: number;
  updateMinMax: (min: number, max: number, applyFilter?: boolean) => void;
  disabled?: boolean;
};

const SalaryFilter = ({ compMax, compMin, setMin, setMax, updateMinMax, disabled }: Props) => {
  return (
    <div className={styles.root}>
      <Slider
        numThumbs={2}
        value={[setMin ?? compMin, setMax ?? compMax]}
        onValueChange={(values) => updateMinMax(values[0], values[1])}
        onValueCommit={(values) => updateMinMax(values[0], values[1], true)}
        max={compMax}
        step={1000}
        min={compMin}
        disabled={disabled}
      />
      <div className={styles.values}>
        <div>{USDollar.format(setMin ?? compMin)}</div>
        <div>{USDollar.format(setMax ?? compMax)}</div>
      </div>
    </div>
  );
};

export default SalaryFilter;
