/** @format */

import { User } from 'auth/types';

export const identify = (user: User) => {
  // @ts-ignore
  analytics.identify(`${user.id}`, {
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
  });
};

export const track = (
  eventName: string,
  properties?: Record<string, null | string | number | boolean | string[] | number[] | undefined>,
) => {
  // @ts-ignore
  analytics.track(eventName, properties);
};

export const page = (
  pageName: string,
  category?: string,
  properties?: Record<string, null | string | number | boolean | string[] | number[] | undefined>,
) => {
  // @ts-ignore
  analytics.page(category, pageName, properties);
};

export enum ANALYTIC_PAGES {
  PROFILE_SETUP = 'Profile Setup Page',
  INTEREST_MATCHING_PAGE = 'Interest Matching Page',
  HOME_PAGE = 'Home Page',
  NEW_BOARD_PAGE = 'New Board Page',
  EXPLORE_PAGE = 'Explore Page',
  BOARD_PAGE = 'Board Page',
  CAREER_SEARCH_PAGE = 'Career Search Page',
  CAREER_PAGE = 'Career Page',
  PROGRAM_SEARCH_PAGE = 'Program Search Page',
  PROGRAM_PAGE = 'Program Page',
  PROFILE_EDIT_PAGE = 'Profile Edit Page',
  SAVED_PAGE = 'Saved Page',
}

export enum ANALYTIC_EVENTS {
  THEME_SELECTED = 'THEME_SELECTED',
  PROFILE_UPDATE_SUBMITTED = 'PROFILE_UPDATE_SUBMITTED',
  LOGOUT_CLICKED = 'LOGOUT_CLICKED',

  HOME_PAGE_SIDEBAR_TOGGLE = 'HOME_PAGE_SIDEBAR_TOGGLE',
  HOME_PAGE_INTEREST_REACTION_SECTION_CLICKED = 'HOME_PAGE_INTEREST_REACTION_SECTION_CLICKED',
  HOME_PAGE_INTEREST_CARD_OPEN_TOGGLE = 'HOME_PAGE_INTEREST_CARD_OPEN_TOGGLE',
  HOME_PAGE_INTEREST_MODAL_OPENED = 'HOME_PAGE_INTEREST_MODAL_OPENED',
  HOME_PAGE_CLUSTER_SELECTED = 'HOME_PAGE_CLUSTER_SELECTED',

  CREATE_BOARD_CLICKED = 'CREATE_BOARD_CLICKED',
  ADD_TO_BOARD_CLICKED = 'ADD_TO_BOARD_CLICKED',
  ADD_TO_BOARD_SAVED = 'ADD_TO_BOARD_SAVED',
  CREATE_BOARD_SUBMITTED = 'CREATE_BOARD_SUBMITTED',
  EMOJI_SELECTED = 'EMOJI_SELECTED',
  BOARD_BY_CAREER_SELECTED = 'BOARD_BY_CAREER_SELECTED',
  BOARD_BY_PROGRAM_SELECTED = 'BOARD_BY_PROGRAM_SELECTED',
  BOARD_PATHWAY_CONTAINER_COLLAPSE_TOGGLED = 'BOARD_PATHWAY_CONTAINER_COLLAPSE_TOGGLED',
  BOARD_COVER_IMAGE_SAVED = 'BOARD_COVER_IMAGE_SAVED',
  BOARD_PAGE_EDIT_COVER_IMAGE_OPENED = 'BOARD_PAGE_EDIT_COVER_IMAGE_OPENED',
  BOARD_ARCHIVE_CLICKED = 'BOARD_ARCHIVE_CLICKED',
  BOARD_ARCHIVED = 'BOARD_ARCHIVED',
  BOARD_PAGE_EDIT_CLICKED = 'BOARD_PAGE_EDIT_CLICKED',

  EXPLORE_ALL_CAREERS_CLICKED = 'EXPLORE_ALL_CAREERS_CLICKED',
  EXPLORE_ALL_PROGRAMS_CLICKED = 'EXPLORE_ALL_PROGRAMS_CLICKED',
  EXPLORE_SEARCH = 'EXPLORE_SEARCH',

  CAREER_SEARCH_SEARCH = 'CAREER_SEARCH_SEARCH',
  CAREER_SEARCH_SAVE_TOGGLE = 'CAREER_SEARCH_SAVE_TOGGLE',
  CAREER_SEARCH_LOCATION_UPDATED = 'CAREER_SEARCH_LOCATION_UPDATED',
  CAREER_SEARCH_BUCKET_UPDATED = 'CAREER_SEARCH_BUCKET_UPDATED',
  CAREER_SEARCH_SORT_UPDATED = 'CAREER_SEARCH_SORT_UPDATED',
  CAREER_SEARCH_LOAD_MORE_CLICKED = 'CAREER_SEARCH_LOAD_MORE_CLICKED',
  CAREER_SEARCH_RESET_FILTERS = 'CAREER_SEARCH_RESET_FILTERS',
  CAREER_SEARCH_FILTERS_APPLIED = 'CAREER_SEARCH_FILTERS_APPLIED',
  CAREER_SEARCH_FILTER_CLEARED = 'CAREER_SEARCH_FILTER_CLEARED',

  CAREER_SAVED = 'CAREER_SAVED',

  CAREER_PAGE_SECTION_SELECTED = 'CAREER_PAGE_SECTION_SELECTED',
  CAREER_PAGE_SALARY_STATE_CHANGED = 'CAREER_PAGE_SALARY_STATE_CHANGED',
  CAREER_PAGE_SALARY_MSA_CHANGED = 'CAREER_PAGE_SALARY_MSA_CHANGED',
  CAREER_PAGE_ACTIVITIES_TOGGLED = 'CAREER_PAGE_ACTIVITIES_TOGGLED',
  CAREER_PAGE_INTEREST_REACTION_OPENED = 'CAREER_PAGE_INTEREST_REACTION_OPENED',
  CAREER_PAGE_INTEREST_REACTION_UPDATED = 'CAREER_PAGE_INTEREST_REACTION_UPDATED',

  PROGRAM_SEARCH_SEARCH = 'PROGRAM_SEARCH_SEARCH',
  PROGRAM_SEARCH_SAVE_TOGGLE = 'PROGRAM_SEARCH_SAVE_TOGGLE',
  PROGRAM_SEARCH_LOCATION_UPDATED = 'PROGRAM_SEARCH_LOCATION_UPDATED',
  PROGRAM_SEARCH_BUCKET_UPDATED = 'PROGRAM_SEARCH_BUCKET_UPDATED',
  PROGRAM_SEARCH_SORT_UPDATED = 'PROGRAM_SEARCH_SORT_UPDATED',
  PROGRAM_SEARCH_LOAD_MORE_CLICKED = 'PROGRAM_SEARCH_LOAD_MORE_CLICKED',
  PROGRAM_SEARCH_RESET_FILTERS = 'PROGRAM_SEARCH_RESET_FILTERS',
  PROGRAM_SEARCH_FILTERS_APPLIED = 'PROGRAM_SEARCH_FILTERS_APPLIED',
  PROGRAM_SEARCH_FILTER_CLEARED = 'PROGRAM_SEARCH_FILTER_CLEARED',

  PROGRAM_SAVED = 'PROGRAM_SAVED',

  PROGRAM_PAGE_SECTION_SELECTED = 'PROGRAM_PAGE_SECTION_SELECTED',
  PROGRAM_PAGE_WEBSITE_CLICKED = 'PROGRAM_PAGE_WEBSITE_CLICKED',
}
