/** @format */

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';

import { ACTION } from 'actions/types';

export enum InterestReactions {
  LOVE = 'love',
  LIKE = 'like',
  NEUTRAL = 'neutral',
  DISLIKE = 'dislike',
}

export type InterestData = {
  id: number;
  name: string;
  category: string;
  description: string;
  importance_score?: number;
  level_score?: number;
  reaction: InterestReactions | null;
};

type FetchInterestsResponse = {
  interests: InterestData[];
};

export const {
  actionFn: fetchInterests,
  requestAction: fetchInterestsRequest,
  successAction: fetchInterestsSuccess,
  errorAction: fetchInterestsError,
} = defineAPIAction<FetchInterestsResponse>(ACTION.FETCH_INTERESTS, 'interests', '', 'GET');

type UpdateStudentInterestReactionArgs = {
  reaction: InterestReactions | null;
  careerId?: number;
};

export const {
  actionFn: updateStudentInterestReaction,
  requestAction: updateStudentInterestReactionRequest,
  successAction: updateStudentInterestReactionSuccess,
  errorAction: updateStudentInterestReactionError,
} = defineAPIPostAction<UpdateStudentInterestReactionArgs, {}>(
  ACTION.UPDATE_STUDENT_INTEREST_REACTION,
  'interests',
  'reaction',
  'POST',
);
