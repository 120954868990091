/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as styles from './index.css';
import WizardStep from '../WizardStep/WizardStep';
import { Input } from 'components/ds/Input/Input';
import { createBoardPurpose } from 'actions/createBoardActions';
import { ReduxState } from 'reducers/rootReducer';

type Props = {};

const BoardPurpose = ({}: Props) => {
  const dispatch = useDispatch();
  const [purpose, setPurpose] = useState<string>('');

  const { boardConfig } = useSelector(
    (state: ReduxState) => ({
      boardConfig: state.createBoardReducer.boardConfig,
      step: state.createBoardReducer.step,
      stepNum: state.createBoardReducer.stepNum,
    }),
    shallowEqual,
  );

  useEffect(() => {
    setPurpose(boardConfig?.purpose || '');
  }, [boardConfig?.purpose]);

  return (
    <WizardStep
      nextDisabled={!purpose}
      onNextClicked={() => {
        dispatch(createBoardPurpose({ purpose }));
      }}>
      <div className={styles.root}>
        <div className={styles.header}>Tell us about your purpose</div>
        <div className={styles.subHeader}>
          Example: Advocating for the rights of people with mental illnesses
        </div>
        <Input
          value={purpose}
          onChange={(value) => setPurpose(value)}
          className={styles.input}
          placeholder="Your purpose"
        />
      </div>
    </WizardStep>
  );
};

export default BoardPurpose;
