/** @format */

import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ReduxState } from 'reducers/rootReducer';
import * as styles from './App.css';

import PrivateRoute from 'auth/PrivateRoute';
import SignInPage from 'pages/SignInPage';
import HomePage from 'pages/HomePage';
import CareersOverviewPage from 'pages/CareersOverviewPage';
import ProgramsOverviewPage from 'pages/ProgramsOverviewPage';
import CareerPage from 'pages/CareerPage/CareerPage';
import ProgramPage from 'pages/ProgamPage/ProgramPage';
import DataCleaningPage from 'pages/DataCleaningPage';
import WelcomePage from 'pages/WelcomePage';
import BoardPage from 'pages/BoardPage';

import { ROUTES } from 'constants/routes';
import { fetchProfile } from 'auth/userAuth';
import { logInUserSuccess, logOutUser } from 'actions/authActions';

import 'index.scss';
import { NAV_TAB_NAMES } from 'components/Navigation/Sidebar';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import LearningProviderPage from 'pages/DataCleaning/LearningProviderPage';
import SetupPage from 'pages/SetupPage';
import { THEME_BY_CLASS_NAME, THEME_PALETTE } from 'constants/setup';
import InterestMatchingPage from 'pages/InterestMatchingPage';
import ProfilePage from 'pages/ProfilePage';
import DataPage from 'pages/DataPage';
import CareerCleaningPage from 'pages/DataCleaning/CareerCleaningPage';
import LearningProgramAIDescriptions from 'pages/DataCleaning/LearningProgramAIDescriptions';
import SuperuserActionsPage from 'pages/SuperuserActionsPage';
import HomePageV2 from 'pages/HomePageV2/HomePageV2';
import ExplorePage from 'pages/ExplorePage/ExplorePage';
import NewBoardWizardPage from 'pages/NewBoardWizardPage/NewBoardWizardPage';
import SavedPage from 'pages/SavedPage/SavedPage';
import CareerPreferencesPage from 'pages/CareerPreferencesPage/CareerPreferencesPage';

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class Routes extends Component<Props> {
  state = {
    loading: true,
  };

  componentDidMount() {
    fetchProfile(
      (user) => {
        this.props.logInUserSuccess(user);

        this.setState({ loading: false });
      },
      () => {
        this.props.logOutUser();
        this.setState({ loading: false });
      },
    );
  }

  render() {
    const { currentUser } = this.props;

    const selectedThemeName =
      currentUser.profile_data?.data.selected_theme || THEME_PALETTE.LUMINOVA;

    return this.state.loading ? (
      <div className={styles.loadingRoot}>
        <div className={styles.loadingPage}>
          <LoadingSpinner />
        </div>
      </div>
    ) : (
      <Router>
        <div
          className={cx(styles.appRoot, THEME_BY_CLASS_NAME[selectedThemeName as THEME_PALETTE])}>
          <Switch>
            <PrivateRoute exact path={ROUTES.LOGIN} pageComponent={SignInPage} />
            <PrivateRoute exact path={ROUTES.WELCOME} pageComponent={WelcomePage} />
            <PrivateRoute exact path={ROUTES.SETUP} pageComponent={SetupPage} />
            <PrivateRoute
              exact
              path={ROUTES.INTEREST_MATCHER}
              pageComponent={InterestMatchingPage}
            />
            <PrivateRoute
              exact
              componentProps={{ currentUser }}
              path={ROUTES.CAREER_PREFERENCES}
              pageComponent={CareerPreferencesPage}
            />
            <PrivateRoute
              exact
              withNavigation
              activeTabId={NAV_TAB_NAMES.PROFILE}
              path={ROUTES.PROFILE}
              pageComponent={ProfilePage}
            />
            {/* <PrivateRoute
              exact
              withNavigation
              activeTabId={NAV_TAB_NAMES.HOME}
              path={ROUTES.HOME}
              pageComponent={HomePage}
            /> */}
            <PrivateRoute
              exact
              withNavigation
              activeTabId={NAV_TAB_NAMES.BOARDS}
              path={ROUTES.BOARD}
              pageComponent={BoardPage}
            />
            <PrivateRoute
              exact
              withNavigation
              activeTabId={NAV_TAB_NAMES.EXPLORE}
              path={ROUTES.CAREERS_OVERVIEW}
              componentProps={{ currentUser }}
              pageComponent={CareersOverviewPage}
            />
            <PrivateRoute
              exact
              withNavigation
              activeTabId={NAV_TAB_NAMES.EXPLORE}
              path={ROUTES.PROGRAMS_OVERVIEW}
              componentProps={{ currentUser }}
              pageComponent={ProgramsOverviewPage}
            />
            <PrivateRoute
              exact
              withNavigation
              componentProps={{ currentUser }}
              activeTabId={NAV_TAB_NAMES.CAREERS}
              path={ROUTES.CAREER_PAGE}
              pageComponent={CareerPage}
            />
            <PrivateRoute
              exact
              withNavigation
              activeTabId={NAV_TAB_NAMES.PROGRAMS}
              path={ROUTES.PROGRAM_PAGE}
              pageComponent={ProgramPage}
            />
            {/* v2 routes */}
            <PrivateRoute
              exact
              componentProps={{ currentUser }}
              withNavigation
              activeTabId={NAV_TAB_NAMES.HOME}
              path={ROUTES.HOME}
              pageComponent={HomePageV2}
            />
            <PrivateRoute
              exact
              withNavigation
              activeTabId={NAV_TAB_NAMES.EXPLORE}
              path={ROUTES.EXPLORE}
              pageComponent={ExplorePage}
            />
            <PrivateRoute
              exact
              componentProps={{ currentUser }}
              withNavigation
              path={ROUTES.NEW_BOARD_WIZARD}
              pageComponent={NewBoardWizardPage}
            />
            <PrivateRoute
              exact
              componentProps={{ currentUser }}
              withNavigation
              path={ROUTES.SAVED}
              activeTabId={NAV_TAB_NAMES.SAVED}
              pageComponent={SavedPage}
            />
            {/* internal routes */}
            <PrivateRoute
              exact
              withNavigation
              superuserOnly
              activeTabId={NAV_TAB_NAMES.DATA}
              path={ROUTES.PROGRAM_CLEANING}
              pageComponent={DataCleaningPage}
            />
            <PrivateRoute
              exact
              withNavigation
              superuserOnly
              activeTabId={NAV_TAB_NAMES.DATA}
              path={ROUTES.PROGRAM_AI_DESCRIPTION}
              pageComponent={LearningProgramAIDescriptions}
            />
            <PrivateRoute
              exact
              withNavigation
              superuserOnly
              activeTabId={NAV_TAB_NAMES.DATA}
              path={ROUTES.DATA}
              pageComponent={DataPage}
            />
            <PrivateRoute
              exact
              withNavigation
              superuserOnly
              activeTabId={NAV_TAB_NAMES.DATA}
              path={ROUTES.CAREER_CLEANING}
              pageComponent={CareerCleaningPage}
            />
            <PrivateRoute
              exact
              withNavigation
              superuserOnly
              activeTabId={NAV_TAB_NAMES.DATA}
              path={ROUTES.LEARNING_PROVIDER_CLEAN}
              pageComponent={LearningProviderPage}
            />{' '}
            <PrivateRoute
              exact
              withNavigation
              superuserOnly
              activeTabId={NAV_TAB_NAMES.DATA}
              path={ROUTES.SUPERUSER_ACTIONS}
              pageComponent={SuperuserActionsPage}
            />
            <PrivateRoute
              withNavigation
              pageComponent={HomePage}
              activeTabId={NAV_TAB_NAMES.HOME}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  logInUserSuccess,
  logOutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
