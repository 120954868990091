/** @format */

import React, { FC } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';
import * as styles from './index.css';
import { Icon } from 'components/ds/Icon/Icon';
import { Button } from 'components/ds/Button/Button';
import { sprinkles } from 'components/ds';
import cx from 'classnames';

type BaseProps = {
  // Style override
  className?: string;
  // Specify a container element id to portal the content into.
  portalContainerId?: string;
  // Width variant for popovers, if undefined hugs content or class override for custom width
  width?: 'small' | 'medium' | 'large';
  // The preferred side of the anchor to render against when open.
  // Will be reversed when collisions occur and avoidCollisions is enabled.
  side?: 'top' | 'right' | 'bottom' | 'left';
  // The preferred alignment against the anchor. May change when collisions occur.
  align?: 'start' | 'center' | 'end';
  // The distance in pixels from the anchor.
  sideOffset?: number;
  // Offset from the start or end alignment options
  alignOffset?: number;
  // Event handler when focus occurs outside of the popover
  onFocusOutside?: (e: Event) => void;
  // Event handler when popover opens and automatically focuses
  onOpenAutoFocus?: (e: Event) => void;
  // Trigger to open and close popover
  trigger: JSX.Element;
  compact?: boolean;
};

type ExternalStateProps = {
  defaultOpen?: never;
  // The controlled open state of the popover. Must be used in conjunction with onOpenChange.
  isOpen: boolean;
  // Event handler called when the open state of the popover changes.
  onOpenChange: (open: boolean) => void;
};

type InternalStateProps = {
  isOpen?: never;
  onOpenChange?: (open: boolean) => void;
  // The open state of the popover when it is initially rendered. Use when you do not need to control its open state.
  defaultOpen?: boolean;
};

type InteractionProps = ExternalStateProps | InternalStateProps;

export type Props = BaseProps & InteractionProps;

export const Popover: FC<Props> = ({
  className,
  isOpen,
  width,
  portalContainerId,
  children,
  defaultOpen = false,
  side = 'bottom',
  sideOffset = 4,
  alignOffset,
  align = 'center',
  onOpenChange,
  onFocusOutside,
  onOpenAutoFocus,
  trigger,
  compact,
}) => {
  const content = (
    <RadixPopover.Content
      align={align}
      alignOffset={alignOffset}
      className={cx(
        styles.popoverContent({ width, compact: compact ? 'compact' : undefined }),
        className,
      )}
      onFocusOutside={onFocusOutside}
      onOpenAutoFocus={onOpenAutoFocus}
      side={side}
      sideOffset={sideOffset}>
      {children}
    </RadixPopover.Content>
  );

  return (
    <RadixPopover.Root defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={isOpen}>
      {trigger ? <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger> : null}
      {portalContainerId ? (
        <RadixPopover.Portal container={document.getElementById(portalContainerId)}>
          {content}
        </RadixPopover.Portal>
      ) : (
        content
      )}
    </RadixPopover.Root>
  );
};
