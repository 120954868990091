/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

import * as styles from './index.css';
import { LOGO } from 'constants/svgs';
import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ANALYTIC_PAGES, page } from 'analytics';
import { Button } from 'components/ds/Button/Button';
import {
  CAREER_CLUSTER_INFO,
  CareerCluster,
} from 'components/SearchPage/CareerFilters/CareerClusterFilter';
import { Icon } from 'components/ds/Icon/Icon';
import { submitPreferredCareers, updateOnboardingStatus } from 'actions/onboardingActions';
import { User } from 'auth/types';
import {
  SearchParams,
  createCareerFilterConfigs,
  fetchCareerSearchData,
} from 'pages/CareersOverviewPage';
import { fetchCareerSearch } from 'actions/careerActions';
import SearchPage from 'components/SearchPage/SearchPage';
import CareerCard from 'components/HomePage/CareerCard/CareerCard';
import { theme } from 'components/ds/theme.css';
import { Checkbox } from 'components/ds/Checkbox/Checkbox';
import { CAREER_SEARCH_SORTS, SearchSortOption } from 'constants/search';

enum CareerPreferenceStage {
  CAREER_THEMES = 'Career Themes',
  CAREER_INTEREST = 'Career Interests',
}

const DEFAULT_SEARCH_PARAMS = {
  page: 0,
  sort: SearchSortOption.RELEVANCE,
};

type Props = {
  currentUser: User;
};

const CareerPreferencesPage = ({ currentUser }: Props) => {
  const dispatch = useDispatch();
  const { width, height } = useWindowSize();
  const [stage, setStage] = useState<CareerPreferenceStage>(CareerPreferenceStage.CAREER_THEMES);
  const [processDone, setProcessDone] = useState<boolean>(false);
  const [selectedClusters, setSelectedClusters] = useState<CareerCluster[]>(
    currentUser.profile_data?.category_preferences
      ? (currentUser.profile_data.category_preferences.split('|') as CareerCluster[])
      : [],
  );
  const [selectedCareerIds, setSelectedCareerIds] = useState<number[]>(
    currentUser.profile_data?.career_preference_ids ?? [],
  );
  const [searchParams, setSearchParams] = useState<SearchParams>({
    ...DEFAULT_SEARCH_PARAMS,
    locationName: currentUser.profile_data?.location_name,
    stateCode: currentUser.profile_data?.state_code,
  });
  const [tempSearchParams, setTempSearchParams] = useState<SearchParams>(searchParams);

  const { careers, totalResults, loadingCareerResults } = useSelector(
    (state: ReduxState) => ({
      careers: state.careersSearch.careers,
      totalResults: state.careersSearch.totalResults,
      loadingCareerResults: createLoadingSelector([ACTION.CAREER_SEARCH], true)(state),
    }),
    shallowEqual,
  );

  useEffect(() => {
    page(ANALYTIC_PAGES.INTEREST_MATCHING_PAGE);
  }, []);

  const fetchData = (params: SearchParams) =>
    fetchCareerSearchData(
      params,
      (urlParams) => {},
      (params) => dispatch(fetchCareerSearch({ getData: params })),
      true,
    );

  const careerSearchConfig = createCareerFilterConfigs(
    searchParams,
    tempSearchParams,
    {
      ...DEFAULT_SEARCH_PARAMS,
      locationName: currentUser.profile_data?.location_name,
      stateCode: currentUser.profile_data?.state_code,
    },
    setSearchParams,
    setTempSearchParams,
    fetchData,
    {},
    0,
    300000,
    loadingCareerResults,
  );

  if (processDone) {
    return (
      <div className={styles.root}>
        <Confetti width={width} height={height} numberOfPieces={100} />
        <div className={styles.congratsBody}>
          <div className={styles.congratsBodyContainer}>
            <div className={styles.congratsContainer}>
              <div className={styles.header}>Woohooo!</div>
              <div className={styles.subheader}>
                We have matched careers to your interest, let's get started!
              </div>
              <Button
                type="primary"
                size="large"
                text="View Careers"
                onClick={() =>
                  dispatch(
                    updateOnboardingStatus(
                      {
                        postData: {
                          is_done_with_career_preferences: true,
                        },
                      },
                      () => {
                        window.location.href = '/';
                      },
                    ),
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  let body = <div></div>;

  if (stage === CareerPreferenceStage.CAREER_THEMES) {
    body = (
      <>
        <div className={styles.bodyHeader}>
          <div className={styles.bodySubheaderText}>Career Themes</div>
          <div className={styles.bodyHeaderText}>Select all the themes that interest you</div>
          <div className={styles.bodyHeaderButtons}>
            <Button
              className={styles.bodyHeaderBtnSkip}
              type="outline"
              size="large"
              text="Skip"
              onClick={() => {
                setStage(CareerPreferenceStage.CAREER_INTEREST);
                fetchData(searchParams);
              }}
            />
            <Button
              type="primary"
              size="large"
              text="Select"
              rightIcon="right-arrow"
              disabled={selectedClusters.length === 0}
              onClick={() =>
                dispatch(
                  updateOnboardingStatus(
                    {
                      postData: { category_preferences: selectedClusters.join('|') },
                    },
                    () => {
                      setStage(CareerPreferenceStage.CAREER_INTEREST);
                      fetchData(searchParams);
                    },
                  ),
                )
              }
            />
          </div>
        </div>
        <div className={styles.clustersContainer}>
          {Object.values(CAREER_CLUSTER_INFO).map((bucket) => (
            <div
              onClick={() => {
                if (selectedClusters.includes(bucket.id))
                  setSelectedClusters(selectedClusters.filter((c) => c !== bucket.id));
                else setSelectedClusters([...selectedClusters, bucket.id]);
              }}
              key={`career-preferences-cluter-${bucket.id}`}
              className={cx(styles.clusterCard, {
                selected: selectedClusters.includes(bucket.id),
              })}>
              <div>
                <Icon name={bucket.icon} />
              </div>
              <div>{bucket.name}</div>
            </div>
          ))}
        </div>
      </>
    );
  } else if (stage === CareerPreferenceStage.CAREER_INTEREST) {
    body = (
      <>
        <div className={styles.bodyHeader}>
          <div className={styles.bodySubheaderText}>Career Interest</div>
          <div className={styles.bodyHeaderText}>
            Do you have a specific career you are interested in?
          </div>
          <div className={styles.bodyHeaderButtons}>
            <Button
              className={styles.bodyHeaderBtnSkip}
              type="outline"
              size="large"
              text="Skip"
              onClick={() => {
                setProcessDone(true);
              }}
            />
            <Button
              type="primary"
              size="large"
              text={
                selectedCareerIds.length > 0 ? `Select (${selectedCareerIds.length})` : 'Select'
              }
              rightIcon="right-arrow"
              disabled={selectedCareerIds.length === 0}
              onClick={() =>
                dispatch(
                  submitPreferredCareers({ postData: { career_ids: selectedCareerIds } }, () => {
                    setProcessDone(true);
                  }),
                )
              }
            />
          </div>
        </div>
        <div className={styles.careerSearchContainer}>
          <SearchPage
            mini
            hideFilterSort
            style="career"
            searchPlaceholder="Search Careers"
            onBodyScroll={(scrollTop) => {}}
            secondaryColor={theme.colors.careersSecondaryColor}
            categoryFilters={[]}
            totalResults={totalResults}
            loadingResults={loadingCareerResults}
            resultObjects={careers}
            resultRenderer={(career) => (
              <CareerCard
                hideVideo
                hideAddToBoard
                className={cx(styles.careerCard, {
                  [styles.selectedCard]: selectedCareerIds.includes(career.id),
                })}
                career={career}
                key={career.id}
                location="Programs Search Page"
                rightButtons={
                  <Checkbox isChecked={selectedCareerIds.includes(career.id)} onChange={() => {}} />
                }
                onClick={() => {
                  if (selectedCareerIds.includes(career.id)) {
                    setSelectedCareerIds(selectedCareerIds.filter((id) => id !== career.id));
                  } else {
                    setSelectedCareerIds([...selectedCareerIds, career.id]);
                  }
                }}
              />
            )}
            onSortChange={careerSearchConfig.onSortChange}
            resetFilters={careerSearchConfig.resetFilters}
            searchString={searchParams.searchString}
            onSearchStringUpdated={careerSearchConfig.onSearchStringUpdated}
            onLoadMoreClicked={careerSearchConfig.onLoadMoreClicked}
            loadMoreBtnText="Load more programs"
            sortOptions={CAREER_SEARCH_SORTS}
            selectedSort={searchParams.sort}
            filtersConfig={careerSearchConfig.filterConfigs}
          />
        </div>
      </>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.sidebar}>
        <div className={styles.top}>
          <div className={styles.logo}>{LOGO(40, 40)}</div>
          <div className={styles.title}>Great! Lets get a sense of your interests</div>
        </div>
        <div className={styles.footerText}>Tell us more about your career preferences!</div>
      </div>
      <div className={styles.body}>
        <div className={styles.bodyContainer}>{body}</div>
      </div>
    </div>
  );
};

export default CareerPreferencesPage;
