/** @format */

import React from 'react';
import cx from 'classnames';

import * as styles from './ProviderResult.css';
import * as sharedStyles from '../SearchPage/SharedResultStyles.css';
import { Link } from 'react-router-dom';
import { LearningProviderData } from 'actions/dataCleaningActions';
import { Menu, MenuActionItem } from 'components/ds/Menu/Menu';
import { User } from 'auth/types';
import { DropdownButton } from 'components/ds/DropdownButton/DropdownButton';
import ProgressBar from 'components/ds/ProgressBar/ProgressBar';

type Props = {
  provider: LearningProviderData;
  assignees: User[];
  onAssign: (assignToId: number) => void;
};

export default ({ provider, assignees, onAssign }: Props) => {
  const assignedTo = assignees.find((user) => user.id === provider.manual_entry_assignee_id);

  const assigneeMenu = (
    <Menu
      align="end"
      side="bottom"
      trigger={
        <DropdownButton
          text={assignedTo ? `${assignedTo.first_name} ${assignedTo.last_name}` : 'Assign'}
          icon={assignedTo ? 'person' : 'person-add'}
        />
      }>
      {assignees.map((user) => (
        <MenuActionItem
          style="data"
          text={`${user.first_name} ${user.last_name}`}
          key={`location-selection-state-program-${user.id}`}
          onSelect={() => user.id !== provider.manual_entry_assignee_id && onAssign(user.id)}
          selected={user.id === provider.manual_entry_assignee_id}
        />
      ))}
    </Menu>
  );

  const pctComplete = Math.round(
    ((provider.num_processed_programs || 0) / (provider.num_programs || 1)) * 100,
  );

  return (
    <div className={styles.root}>
      <Link
        className={cx(sharedStyles.resultContainer, styles.providerResultContainer)}
        to={`/program-cleaning/provider/${provider.id}`}>
        <div className={styles.providerNameContainer}>
          <div className={styles.resultSubHeader}>
            {provider.city}, {provider.state}
          </div>
          <div className={styles.providerNameText}>{provider.name}</div>
        </div>
        <div className={styles.resultMetadataContainer}>
          <div className={sharedStyles.resultMetadataTitle}>{pctComplete}% Programs Processed</div>
          <div className={sharedStyles.resultsMetadataData}>
            <ProgressBar percentFilled={pctComplete} />
            <div className={styles.pctNums}>
              {(provider.num_processed_programs || 0).toLocaleString()} /{' '}
              {(provider.num_programs || 1).toLocaleString()}
            </div>
          </div>
        </div>
        <div className={styles.resultMetadataContainer}>
          <div className={sharedStyles.resultMetadataTitle}>Created Programs</div>
          <div className={sharedStyles.resultsMetadataData}>{provider.num_created_programs}</div>
        </div>

        <div className={styles.resultActionGhost}>{assigneeMenu}</div>
      </Link>
      <div className={styles.resultActionContainer}>{assigneeMenu}</div>
    </div>
  );
};
