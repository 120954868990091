/** @format */

import React from 'react';

import * as styles from './CareerClusterFilter.css';

import { Checkbox } from 'components/ds/Checkbox/Checkbox';
import { Menu } from 'components/ds/Menu/Menu';
import { DropdownButton } from 'components/ds/DropdownButton/DropdownButton';
import { IconName } from 'components/ds/Icon/Icon';

const CLUSTERS = [
  'Agriculture, Food & Natural Resources',
  'Architecture & Construction',
  'Arts, Audio/Video Technology & Communications',
  'Business Management & Administration',
  'Education & Training',
  'Finance',
  'Government & Public Administration',
  'Health Science',
  'Hospitality & Tourism',
  'Human Services',
  'Information Technology',
  'Law, Public Safety, Corrections & Security',
  'Manufacturing',
  'Marketing',
  'Science, Technology, Engineering & Mathematics',
  'Transportation, Distribution & Logistics',
];

export enum CareerCluster {
  AGRICULTURE = 'Agriculture, Food & Natural Resources',
  ARCHITECTURE = 'Architecture & Construction',
  ART_TECH = 'Arts, Audio/Video Technology & Communications',
  BUSINESS = 'Business Management & Administration',
  EDUCATION = 'Education & Training',
  FINANCE = 'Finance',
  GOVERNMENT = 'Government & Public Administration',
  HEALTH = 'Health Science',
  HOSPITALITY = 'Hospitality & Tourism',
  HUMAN_SERVICES = 'Human Services',
  IT = 'Information Technology',
  LAW = 'Law, Public Safety, Corrections & Security',
  MANUFACTURING = 'Manufacturing',
  MARKETING = 'Marketing',
  STEM = 'Science, Technology, Engineering & Mathematics',
  TRANSPORTATION = 'Transportation, Distribution & Logistics',
}

export const CAREER_CLUSTER_INFO: Record<
  CareerCluster,
  { id: CareerCluster; name: string; tooltipText: string; icon: IconName }
> = {
  [CareerCluster.AGRICULTURE]: {
    id: CareerCluster.AGRICULTURE,
    name: 'Natural Resource Management',
    tooltipText: 'Cultivation and stewardship of plant and animal resources',
    icon: 'agriculture',
  },
  [CareerCluster.ARCHITECTURE]: {
    id: CareerCluster.ARCHITECTURE,
    name: 'Architecture & Construction',
    tooltipText: 'Creating the built environment',
    icon: 'architecture',
  },
  [CareerCluster.ART_TECH]: {
    id: CareerCluster.ART_TECH,
    name: 'Art, Music, & Entertainment',
    tooltipText: 'Mass communication',
    icon: 'art',
  },
  [CareerCluster.BUSINESS]: {
    id: CareerCluster.BUSINESS,
    name: 'Business',
    tooltipText: 'Builds and manages businesses',
    icon: 'work',
  },
  [CareerCluster.EDUCATION]: {
    id: CareerCluster.EDUCATION,
    name: 'Education',
    tooltipText: 'Unlocks human potential',
    icon: 'teacher',
  },
  [CareerCluster.FINANCE]: {
    id: CareerCluster.FINANCE,
    name: 'Finance',
    tooltipText: 'Manages financial resources',
    icon: 'finance',
  },
  [CareerCluster.GOVERNMENT]: {
    id: CareerCluster.GOVERNMENT,
    name: 'Government',
    tooltipText: 'Advances government functions at home and abroad',
    icon: 'college',
  },
  [CareerCluster.HEALTH]: {
    id: CareerCluster.HEALTH,
    name: 'Healthcare',
    tooltipText: 'Builds a healthier world',
    icon: 'healthcare',
  },
  [CareerCluster.HOSPITALITY]: {
    id: CareerCluster.HOSPITALITY,
    name: 'Hospitality & Tourism',
    tooltipText: 'Helps people eat, drink, and feel welcome',
    icon: 'hospitality',
  },
  [CareerCluster.HUMAN_SERVICES]: {
    id: CareerCluster.HUMAN_SERVICES,
    name: 'Human Services',
    tooltipText: 'Meets people’s  physical and emotional needs',
    icon: 'hr',
  },
  [CareerCluster.IT]: {
    id: CareerCluster.IT,
    name: 'Technology',
    tooltipText: 'Builds and works with hardware and software',
    icon: 'computer',
  },
  [CareerCluster.LAW]: {
    id: CareerCluster.LAW,
    name: 'Law & Order',
    tooltipText: 'Keeps people safe by upholding the law',
    icon: 'gavel',
  },
  [CareerCluster.MANUFACTURING]: {
    id: CareerCluster.MANUFACTURING,
    name: 'Manufacturing',
    tooltipText: 'Makes useful things',
    icon: 'manufacturing',
  },
  [CareerCluster.MARKETING]: {
    id: CareerCluster.MARKETING,
    name: 'Marketing',
    tooltipText:
      'Helps an organization meet its goals by communicating its value to people who could benefit from its products and services',
    icon: 'marketing',
  },
  [CareerCluster.STEM]: {
    id: CareerCluster.STEM,
    name: 'Engineering, Science & Math',
    tooltipText: 'Develops new products and performs R&D',
    icon: 'stem',
  },
  [CareerCluster.TRANSPORTATION]: {
    id: CareerCluster.TRANSPORTATION,
    name: 'Transportation',
    tooltipText: 'Ensures people and goods move where they need to go',
    icon: 'transportation',
  },
};

type Props = {
  selectedOptions?: string[];
  updateSelectedOptions: (option: string, isSelected: boolean) => void;
  disabled?: boolean;
};

const CareerClusterFilter = ({ selectedOptions, updateSelectedOptions, disabled }: Props) => {
  return (
    <div className={styles.root}>
      <Menu
        noMaxHeight
        trigger={
          <DropdownButton
            text={
              selectedOptions?.length && selectedOptions?.length > 0
                ? `${selectedOptions.length} clusters selected`
                : 'Select a Career Cluster'
            }
            size="large"
            fullWidth
          />
        }
        side="bottom"
        align="start">
        <div className={styles.popoverBody}>
          <div className={styles.options}>
            {Object.values(CAREER_CLUSTER_INFO).map((cluster) => {
              const isChecked = (selectedOptions || []).indexOf(cluster.id) > -1;
              return (
                <div className={styles.inputContainer} key={`career-cluster-filter-${cluster}`}>
                  <Checkbox
                    isChecked={isChecked}
                    onChange={() => updateSelectedOptions(cluster.id, !isChecked)}
                    disabled={disabled}
                  />
                  <div className={styles.label}>{cluster.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default CareerClusterFilter;
