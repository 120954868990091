/** @format */

import React, { FC } from 'react';
import cx from 'classnames';

export type Props = {
  className?: string;
  small?: boolean;
};

export const LoadingSpinner: FC<Props> = ({ className, small }) => {
  return (
    <div className={cx('lds-ring', className, { small: small })}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
