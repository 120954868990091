/** @format */

import React, { useState, useRef, useEffect, KeyboardEvent } from 'react';

import * as styles from './index.css';

type Props = {
  startingText: string;
  onNewTextSaved: (newText: string) => void;
};

const InputInPlace = ({ startingText, onNewTextSaved }: Props) => {
  const inputRef = useRef<any>();
  const hiddenInputRef = useRef<any>();
  const mainTextRef = useRef<any>();
  const [inputOpened, setInputOpened] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(startingText);

  useEffect(() => {
    setInputValue(startingText);
  }, [startingText]);

  return inputOpened ? (
    <>
      <input
        autoFocus
        className={styles.input}
        ref={inputRef}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onBlur={() => {
          onNewTextSaved(inputValue);
          setInputOpened(false);
        }}
        style={{
          width:
            (hiddenInputRef?.current?.offsetWidth || mainTextRef?.current?.offsetWidth || 0) + 50,
        }}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') inputRef?.current.blur();
        }}
      />
      <div ref={hiddenInputRef} className={styles.ghostInput}>
        {inputValue}
      </div>
    </>
  ) : (
    <div className={styles.mainText} ref={mainTextRef} onClick={() => setInputOpened(true)}>
      {startingText}
    </div>
  );
};

export default InputInPlace;
