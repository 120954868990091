/** @format */

import React from 'react';
import EmojiPicker from 'emoji-picker-react';

import { Button, Props as ButtonProps } from '../Button/Button';
import { Menu } from 'components/ds/Menu/Menu';

type Props = {
  onSelectedEmoji: (emoji: string) => void;
  buttonProps: ButtonProps;
};

const EmojiPickerPopover = ({ onSelectedEmoji, buttonProps }: Props) => {
  return (
    <Menu
      noContentPadding
      noMaxHeight
      align="center"
      side="left"
      trigger={<Button {...buttonProps} />}>
      <EmojiPicker
        previewConfig={{ showPreview: false }}
        onEmojiClick={(emoji) => onSelectedEmoji(emoji.emoji)}
      />
    </Menu>
  );
};

export default EmojiPickerPopover;
