/** @format */

import { createReducer } from '@reduxjs/toolkit';
import { saveProgramBoardsSuccess } from 'actions/boardActions';
import { CareerSearchResult } from 'actions/careerActions';

import {
  ProgramSearchResult,
  ProviderBasicInfo,
  cacheProgramSearch,
  cacheProgramSearchScrollTop,
  fetchCareerOptionsSuccess,
  fetchProgramSearchRequest,
  fetchProgramSearchSuccess,
  updateProgramCacheUrlParams,
  updateSaveProgramRequest,
  useProgramSearchCache,
} from 'actions/programsActions';

type State = {
  careerOptions: {
    saved: CareerSearchResult[];
    unsaved: CareerSearchResult[];
  };
  programs: ProgramSearchResult[];
  totalResults: number;
  costMin: number;
  costMax: number;
  providers: ProviderBasicInfo[];

  cachedState?: {
    programs: ProgramSearchResult[];
    totalResults: number;
    costMin: number;
    costMax: number;
    providers: ProviderBasicInfo[];
  };
  cachedUrlSearch?: string;
  cachedScrollTop?: number;
};

const initialState: State = {
  careerOptions: { saved: [], unsaved: [] },
  programs: [],
  totalResults: 0,
  costMax: 0,
  costMin: 0,
  providers: [],
};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(fetchProgramSearchSuccess, (state, { payload }) => {
      if (payload.getData?.page === '0') {
        state.programs = payload.programs;
        state.totalResults = payload.total_results;
        state.costMax = payload.cost_max;
        state.costMin = payload.cost_min;
        state.providers = payload.providers;
      } else {
        state.programs = [...state.programs, ...payload.programs];
        state.totalResults = payload.total_results;
      }
      return state;
    })
    .addCase(fetchProgramSearchRequest, (state, { payload }) => {
      if (payload.getData?.page === '0') {
        return {
          ...initialState,
          careerOptions: state.careerOptions,
          cachedUrlSearch: state.cachedUrlSearch,
          costMax: state.costMax,
          costMin: state.costMin,
          providers: state.providers,
        };
      }
    })
    .addCase(fetchCareerOptionsSuccess, (state, { payload }) => {
      const saved: CareerSearchResult[] = [];
      const unsaved: CareerSearchResult[] = [];

      payload.careers.forEach((career) =>
        career.is_saved ? saved.push(career) : unsaved.push(career),
      );

      state.careerOptions.saved = saved;
      state.careerOptions.unsaved = unsaved;
    })
    .addCase(updateSaveProgramRequest, (state, { payload }) => {
      const isSaved = payload.postData.isSaved;
      const programId = payload.id;
      if (!programId) return state;

      const program = state.programs.find((program) => program.id == programId);
      if (!program) return state;

      program.is_saved = isSaved;

      return state;
    })
    .addCase(saveProgramBoardsSuccess, (state, { payload }) => {
      const programId = payload.postData.program_id;

      const program = state.programs.find((program) => program.id == programId);
      if (!program) return state;

      program.board_ids = payload.postData.board_ids;

      const cachedProgram = state.cachedState?.programs.find((program) => program.id === programId);
      if (cachedProgram) cachedProgram.board_ids = payload.postData.board_ids;

      return state;
    })
    .addCase(cacheProgramSearch, (state, { payload }) => {
      state.cachedState = {
        programs: state.programs,
        costMax: state.costMax,
        costMin: state.costMin,
        totalResults: state.totalResults,
        providers: state.providers,
      };
      return state;
    })
    .addCase(updateProgramCacheUrlParams, (state, { payload }) => {
      state.cachedUrlSearch = payload.urlParams;
      return state;
    })
    .addCase(cacheProgramSearchScrollTop, (state, { payload }) => {
      state.cachedScrollTop = payload.scrollTop;
      return state;
    })
    .addCase(useProgramSearchCache, (state, { payload }) => {
      if (!state.cachedState) return;
      return {
        careerOptions: state.careerOptions,
        cachedState: state.cachedState,
        programs: state.cachedState.programs,
        costMax: state.cachedState.costMax,
        costMin: state.cachedState.costMin,
        totalResults: state.cachedState.totalResults,
        cachedUrlSearch: state.cachedUrlSearch,
        providers: state.cachedState.providers,
      };
    })

    .addDefaultCase((state) => state);
});
