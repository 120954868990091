/** @format */

import React, { ReactNode } from 'react';
import Sidebar, { NAV_TAB_NAMES } from './Sidebar';

import * as styles from './styles.css';
import { sprinkles } from 'components/ds';

type PassedProps = {
  activeTabId?: NAV_TAB_NAMES;
  children?: ReactNode;
};

type Props = PassedProps;

const SiteSidebarWrapper = (props: Props) => {
  const { activeTabId, children } = props;
  return (
    <div className={styles.sidebarWrapperRoot}>
      <Sidebar activeTabId={activeTabId} className={styles.sideBar} />
      <div className={styles.sideBarContent}>{children}</div>
    </div>
  );
};

export default SiteSidebarWrapper;
