/** @format */

import React, { FC, MouseEvent as ReactMouseEvent, forwardRef, Ref } from 'react';
import cx from 'classnames';
import * as styles from './index.css';
import { Icon, IconName } from '../Icon/Icon';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

type ClassNameType = {
  /**
   * Style override
   */
  className?: string;
};

type LinkProps = {
  onClick?: never;
  to: string;
};

type ButtonProps = {
  href?: never;
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  to?: never;
};

export type ButtonSize = 'large';
export type ButtonType = 'primary' | 'ghost' | 'outline' | 'outline-dashed';
export type ButtonColor =
  | 'transparent'
  | 'interest-like'
  | 'interest-love'
  | 'neutral'
  | 'negative'
  | 'danger'
  | undefined;

type BaseProps = ClassNameType & {
  text?: string;
  type: ButtonType;
  color?: ButtonColor;
  icon?: IconName;
  iconFilled?: boolean;
  customIcon?: JSX.Element;
  rightIcon?: IconName;
  spacing?: 'compact';
  disabled?: boolean;
  loading?: boolean;
  stopPropogation?: boolean;
  size?: ButtonSize;
  isIconOnly?: boolean;
  whiteMode?: boolean;
};

type InteractionProps = LinkProps | ButtonProps;

export type Props = BaseProps & InteractionProps;

export const Button: FC<Props> = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    {
      text,
      type,
      icon,
      color,
      spacing,
      rightIcon,
      disabled,
      iconFilled,
      loading,
      stopPropogation,
      onClick,
      to,
      size,
      className,
      customIcon,
      isIconOnly,
      whiteMode,
      ...props
    },
    ref,
  ) => {
    // const elementRef = parentRef ?? ref;
    const elementRef = ref;
    const sharedProps = {
      ...props,
      className: cx(
        styles.root({
          type,
          color,
          spacing,
          whiteMode: whiteMode ? 'whiteMode' : undefined,
          size,
          disabled: disabled ? 'disabled' : undefined,
          iconOnly:
            (text === undefined && rightIcon === undefined) || isIconOnly ? 'iconOnly' : undefined,
        }),
        className,
      ),
    };

    if (to) {
      return (
        <Link {...sharedProps} ref={elementRef as Ref<HTMLAnchorElement>} to={to}>
          {icon && !loading && (
            <Icon
              filled={iconFilled}
              name={icon}
              className={
                text === undefined && rightIcon === undefined ? styles.iconOnly : styles.icon
              }
            />
          )}
          {text}
          {rightIcon && <Icon name={rightIcon} className={styles.rightIcon} />}
        </Link>
      );
    } else {
      return (
        <button
          {...sharedProps}
          onClick={(e) => {
            !disabled && onClick && onClick(e);
            stopPropogation && e.stopPropagation();
          }}
          ref={elementRef as Ref<HTMLButtonElement>}>
          {icon && !loading && (
            <Icon
              filled={iconFilled}
              name={icon}
              className={
                text === undefined && rightIcon === undefined ? styles.iconOnly : styles.icon
              }
            />
          )}
          {customIcon && !loading ? customIcon : null}
          {loading ? <LoadingSpinner small className={styles.loadingIcon} /> : null}
          {text}
          {rightIcon && <Icon name={rightIcon} className={styles.rightIcon} />}
        </button>
      );
    }
  },
);
