/** @format */

import React, { FC } from 'react';

import * as styles from './index.css';
import { Popover } from 'components/ds/Popover/Popover';
import { Button, ButtonColor, ButtonType } from 'components/ds/Button/Button';
import { IconName } from '../Icon/Icon';
import { InterestReactions } from 'actions/interestActions';
import { ANALYTIC_EVENTS, track } from 'analytics';

type ButtonConfig = {
  type: ButtonType;
  color: ButtonColor;
  rightIcon: IconName;
  name: string;
};

export const REACT_TO_BUTTON_CONFIG: Record<InterestReactions, ButtonConfig> = {
  [InterestReactions.LOVE]: {
    type: 'outline',
    color: 'interest-love',
    rightIcon: 'heart',
    name: 'Love',
  },
  [InterestReactions.DISLIKE]: {
    type: 'outline',
    color: 'negative',
    rightIcon: 'thumbs-down',
    name: 'Dislike',
  },
  [InterestReactions.NEUTRAL]: {
    type: 'outline',
    color: 'neutral',
    rightIcon: 'neutral-face',
    name: 'Neutral',
  },
  [InterestReactions.LIKE]: {
    type: 'outline',
    color: 'interest-like',
    rightIcon: 'thumbs-up',
    name: 'Like',
  },
};

const NO_REACTION_CONFIG: ButtonConfig = {
  type: 'outline-dashed',
  color: 'transparent',
  rightIcon: 'add-reaction',
  name: 'No Reaction',
};

type ClassNameType = {
  /**
   * Style override
   */
  className?: string;
};

type BaseProps = ClassNameType & {
  name: string;
  fontSize?: number;
  selectedReaction: InterestReactions | null;
  onInterestSelected: (reaction: InterestReactions) => void;
  careerId: number;
};

export type Props = BaseProps;

export const InterestButton: FC<Props> = ({
  className,
  name,
  selectedReaction,
  onInterestSelected,
  careerId,
}) => {
  const buttonConfig =
    selectedReaction === null ? NO_REACTION_CONFIG : REACT_TO_BUTTON_CONFIG[selectedReaction];
  return (
    <Popover
      compact
      align="end"
      side="top"
      trigger={
        <Button
          text={name}
          type={buttonConfig.type}
          color={buttonConfig.color}
          spacing="compact"
          rightIcon={buttonConfig.rightIcon}
        />
      }
      onOpenChange={(open) => {
        if (open) {
          track(ANALYTIC_EVENTS.CAREER_PAGE_INTEREST_REACTION_OPENED, {
            career_id: careerId,
            interest: name,
            location: 'Career Page',
          });
        }
      }}>
      <InterestPopover
        selectedReaction={selectedReaction}
        onInterestSelected={onInterestSelected}
      />
    </Popover>
  );
};

export type InterestPopoverProps = {
  selectedReaction: InterestReactions | null;
  onInterestSelected: (reaction: InterestReactions) => void;
};

export const InterestPopover: FC<InterestPopoverProps> = ({
  selectedReaction,
  onInterestSelected,
}) => {
  return (
    <div className={styles.popoverRoot}>
      <Button
        className={styles.reactButton}
        icon={REACT_TO_BUTTON_CONFIG[InterestReactions.LOVE].rightIcon}
        type="ghost"
        color={
          selectedReaction === InterestReactions.LOVE
            ? REACT_TO_BUTTON_CONFIG.love.color
            : undefined
        }
        onClick={() => onInterestSelected(InterestReactions.LOVE)}
      />
      <Button
        className={styles.reactButton}
        icon={REACT_TO_BUTTON_CONFIG[InterestReactions.LIKE].rightIcon}
        type="ghost"
        color={
          selectedReaction === InterestReactions.LIKE
            ? REACT_TO_BUTTON_CONFIG.like.color
            : undefined
        }
        onClick={() => onInterestSelected(InterestReactions.LIKE)}
      />
      <Button
        className={styles.reactButton}
        icon={REACT_TO_BUTTON_CONFIG[InterestReactions.NEUTRAL].rightIcon}
        type={selectedReaction === InterestReactions.NEUTRAL ? 'outline' : 'ghost'}
        color={
          selectedReaction === InterestReactions.NEUTRAL
            ? REACT_TO_BUTTON_CONFIG.neutral.color
            : undefined
        }
        onClick={() => onInterestSelected(InterestReactions.NEUTRAL)}
      />
      <Button
        icon={REACT_TO_BUTTON_CONFIG[InterestReactions.DISLIKE].rightIcon}
        type="ghost"
        color={
          selectedReaction === InterestReactions.DISLIKE
            ? REACT_TO_BUTTON_CONFIG.dislike.color
            : undefined
        }
        onClick={() => onInterestSelected(InterestReactions.DISLIKE)}
      />
    </div>
  );
};
