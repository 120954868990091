/** @format */

import { createReducer } from '@reduxjs/toolkit';
import { CareersCompressedData } from 'actions/boardActions';
import { CareerSearchResult } from 'actions/careerActions';

import {
  LearningProgramFullData,
  LearningProviderData,
  LearningProviderFullData,
  assignProviderToRequest,
  createNewLearningProgramSuccess,
  fetchAssignedProvidersSuccess,
  fetchProviderDataForCleaningRequest,
  fetchProviderDataForCleaningSuccess,
  listCareersSuccess,
  saveCareerCategoriesSuccess,
  saveManualProgramUpdatesRequest,
  saveManualProgramUpdatesSuccess,
  saveManualProviderUpdatesSuccess,
  updateCareerCategories,
} from 'actions/dataCleaningActions';
import { fetchCareerOptionsSuccess } from 'actions/programsActions';
import { User } from 'auth/types';

type State = {
  providers: LearningProviderData[];
  assignees: User[];
  editingProvider?: LearningProviderFullData;
  orderedPrograms?: LearningProgramFullData[];
  careers: CareerSearchResult[];
  careerCleaning: {
    careerList: CareersCompressedData[];
  };
};

const initialState: State = {
  providers: [],
  assignees: [],
  careers: [],
  careerCleaning: { careerList: [] },
};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(fetchAssignedProvidersSuccess, (state, { payload }) => {
      state.providers = payload.providers;
      state.assignees = payload.assignees;
      return state;
    })
    .addCase(fetchProviderDataForCleaningRequest, (state, { payload }) => {
      state.editingProvider = undefined;
      state.orderedPrograms = undefined;
      return state;
    })
    .addCase(fetchCareerOptionsSuccess, (state, { payload }) => {
      state.careers = payload.careers;
      return state;
    })

    .addCase(fetchProviderDataForCleaningSuccess, (state, { payload }) => {
      state.editingProvider = payload.provider;
      const orderedPrograms = [...payload.programs];
      orderedPrograms.sort((a, b) => a.name.localeCompare(b.name));
      state.orderedPrograms = orderedPrograms;
      return state;
    })
    .addCase(saveManualProgramUpdatesSuccess, (state, { payload }) => {
      if (!state.orderedPrograms || !payload.id) return state;

      const programIndex = state.orderedPrograms.findIndex((program) => program.id === payload.id);

      state.orderedPrograms[programIndex] = {
        ...state.orderedPrograms[programIndex],
        ...payload.postData.updates,
      };
      return state;
    })
    .addCase(saveManualProgramUpdatesRequest, (state, { payload }) => {
      if (!state.orderedPrograms || !payload.id) return state;
      if (payload.postData.updates.manual_entry_completed === undefined) return;

      const programIndex = state.orderedPrograms.findIndex((program) => program.id === payload.id);

      state.orderedPrograms[programIndex] = {
        ...state.orderedPrograms[programIndex],
        manual_entry_completed: payload.postData.updates.manual_entry_completed,
      };
      return state;
    })
    .addCase(saveManualProviderUpdatesSuccess, (state, { payload }) => {
      if (!state.editingProvider) return state;
      state.editingProvider = {
        ...state.editingProvider,
        ...payload.postData.updates,
      };
      return state;
    })

    .addCase(assignProviderToRequest, (state, { payload }) => {
      if (!state.providers) return state;

      const provider = state.providers.find((provider) => provider.id === payload.id);
      if (!provider) return state;

      provider.manual_entry_assignee_id = payload.postData.assign_to_id;

      return state;
    })
    .addCase(createNewLearningProgramSuccess, (state, { payload }) => {
      state.orderedPrograms = [...(state.orderedPrograms || []), payload.new_program];
      state.orderedPrograms.sort((a, b) => a.name.localeCompare(b.name));

      return state;
    })
    .addCase(listCareersSuccess, (state, { payload }) => {
      state.careerCleaning.careerList = payload.careers;

      return state;
    })
    .addCase(updateCareerCategories, (state, { payload }) => {
      const career = state.careerCleaning.careerList.find((c) => c.id == payload.careerId);

      if (!career) return state;

      career.manual_categories = payload.categories;

      return state;
    })
    .addCase(saveCareerCategoriesSuccess, (state, { payload }) => {
      const career = state.careerCleaning.careerList.find(
        (c) => c.id == payload.postData.career_id,
      );

      if (!career) return state;

      career.manual_category_processed = true;

      return state;
    })

    .addDefaultCase((state) => state);
});
