/** @format */

import React from 'react';
import { Step } from 'react-joyride';
import { OnboardingTooltip } from 'onboarding/OnboardingTooltip';

export enum OnboardingStep {
  INTEREST_MATCHING_CARD = 'INTEREST_MATCHING_CARD',
  INTEREST_MATCHING_REACTS = 'INTEREST_MATCHING_REACTS',
  BOARD_OVERVIEW = 'BOARD_OVERVIEW',
  RECOMMENDED_BOARD = 'RECOMMENDED_BOARD',
  NEW_BOARDS = 'NEW_BOARDS',
  INTERESTS_TAB_OVERVIEW = 'INTERESTS_TAB_OVERVIEW',
  CAREERS_AND_PROGRAM_SEARCH = 'CAREERS_AND_PROGRAM_SEARCH',
  CAREER_SEARCH_PAGE_SAVE_TOGGLE = 'CAREER_SEARCH_PAGE_SAVE_TOGGLE',
  CAREER_SEARCH_PAGE_BUCKETS = 'CAREER_SEARCH_PAGE_BUCKETS',
  CAREER_SEARCH_PAGE_FILTERS = 'CAREER_SEARCH_PAGE_FILTERS',
  CAREER_SEARCH_PAGE_SORTS = 'CAREER_SEARCH_PAGE_SORTS',
}

export const ONBOARDING_STEP_DATA: Record<OnboardingStep, Step> = {
  [OnboardingStep.CAREER_SEARCH_PAGE_SAVE_TOGGLE]: {
    target: `.${OnboardingStep.CAREER_SEARCH_PAGE_SAVE_TOGGLE}`,
    content: (
      <OnboardingTooltip
        title="Explore, Save, and Search"
        text="View careers by exploring or searching, if you like a career save it to your Saved careers!"
      />
    ),
    disableBeacon: true,
    placement: 'bottom',
  },
  [OnboardingStep.CAREER_SEARCH_PAGE_BUCKETS]: {
    target: `.${OnboardingStep.CAREER_SEARCH_PAGE_BUCKETS}`,
    content: (
      <OnboardingTooltip
        title="Categories"
        text="Explore your career matches by browsing different categories."
      />
    ),
    disableBeacon: true,
    placement: 'bottom',
  },
  [OnboardingStep.CAREER_SEARCH_PAGE_FILTERS]: {
    target: `.${OnboardingStep.CAREER_SEARCH_PAGE_FILTERS}`,
    content: (
      <OnboardingTooltip
        title="Get specific"
        text="Narrow down your career interests by filtering your career matches."
      />
    ),
    disableBeacon: true,
    placement: 'right',
  },
  [OnboardingStep.CAREER_SEARCH_PAGE_SORTS]: {
    target: `.${OnboardingStep.CAREER_SEARCH_PAGE_SORTS}`,
    content: (
      <OnboardingTooltip
        title="Sorting by"
        text="Sort the order of career matches depending on what matters to you."
      />
    ),
    disableBeacon: true,
    placement: 'left',
  },

  [OnboardingStep.INTEREST_MATCHING_CARD]: {
    target: `.${OnboardingStep.INTEREST_MATCHING_CARD}`,
    content: (
      <OnboardingTooltip
        title="Check out Interests!"
        text="Before we get started, we are going to reflect on your interests."
      />
    ),
    disableBeacon: true,
    placement: 'bottom',
  },
  [OnboardingStep.INTEREST_MATCHING_REACTS]: {
    target: `.${OnboardingStep.INTEREST_MATCHING_REACTS}`,
    content: (
      <OnboardingTooltip
        title="How do you feel about them?"
        text="React to each interest by selecting a reaction button."
      />
    ),
    disableBeacon: true,
    placement: 'left',
  },
  [OnboardingStep.BOARD_OVERVIEW]: {
    target: `.${OnboardingStep.BOARD_OVERVIEW}`,
    content: (
      <OnboardingTooltip
        title="Boards"
        text="Envision your next steps after high school by creating your very own future boards."
      />
    ),
    disableBeacon: true,
    placement: 'top',
  },
  [OnboardingStep.RECOMMENDED_BOARD]: {
    target: `.${OnboardingStep.RECOMMENDED_BOARD}`,
    content: (
      <OnboardingTooltip
        title="Recommended board"
        text="We got you started with this board containing 10 careers that we think you may like!"
      />
    ),
    disableBeacon: true,
    placement: 'top',
  },
  [OnboardingStep.NEW_BOARDS]: {
    target: `.${OnboardingStep.NEW_BOARDS}`,
    content: (
      <OnboardingTooltip
        title="New boards"
        text="Envision life after high school by creating your own boards with careers and programs."
      />
    ),
    disableBeacon: true,
    placement: 'top',
  },
  [OnboardingStep.INTERESTS_TAB_OVERVIEW]: {
    target: `.${OnboardingStep.INTERESTS_TAB_OVERVIEW}`,
    content: (
      <OnboardingTooltip
        title="Interests"
        text="View all of your interest and change your reactions to update careers suggestions."
      />
    ),
    disableBeacon: true,
    placement: 'left',
  },
  [OnboardingStep.CAREERS_AND_PROGRAM_SEARCH]: {
    target: `.${OnboardingStep.CAREERS_AND_PROGRAM_SEARCH}`,
    content: (
      <OnboardingTooltip
        title="Careers & Programs"
        text="Explore personalized careers and programs based on your interests!"
      />
    ),
    disableBeacon: true,
    placement: 'right',
  },
};
