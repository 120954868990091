/** @format */

import React, { FC, useEffect, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as styles from './index.css';
import cx from 'classnames';
import { sprinkles } from 'components/ds';
import { Button } from '../Button/Button';

type ClassNameType = {
  /**
   * Style override
   */
  className?: string;
};

type BaseProps = ClassNameType & {
  /**
   * width of menu content
   */
  width?: 'small' | 'large';
  /**
   * Specify a container element id to portal the content into.
   */
  portalContainerId?: string;
  /**
   * The preferred side of the anchor to render against when open. Will be reversed when collisions occur and avoidCollisions is enabled.
   */
  side?: 'top' | 'right' | 'bottom' | 'left';
  /**
   * The preferred alignment against the anchor. May change when collisions occur.
   */
  align?: 'start' | 'center' | 'end';
  /**
   * The distance in pixels from the trigger.
   */
  sideOffset?: number;
  /**
   * The offset from the start or end align options
   */
  alignOffset?: number;
  style?: 'career' | 'program' | 'data';
  headerTitle?: string;
  footerConfig?: {
    primaryActionBtnText: string;
    onPrimaryBtnClicked: () => void;
    primaryBtnDisabled?: boolean;
    secondaryActionBtnText?: string;
    onSecondaryBtnClicked?: () => void;
  };
  disabled?: boolean;
  noMaxHeight?: boolean;
  noContentPadding?: boolean;
};

type ExternalStateProps = {
  defaultOpen?: never;
  trigger?: never;
  /**
   * The controlled open state of the menu. Must be used in conjunction with onOpenChange.
   */
  isOpen: boolean;
  /**
   * Event handler called when the open state of the menu changes.
   */
  onOpenChange: (open: boolean) => void;
};

type InternalStateProps = {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  /**
   * The open state of the menu when it is initially rendered. Use when you do not need to control its open state.
   */
  defaultOpen?: boolean;
  /**
   * Trigger to open and close menu
   */
  trigger: JSX.Element;
};

type InteractionProps = ExternalStateProps | InternalStateProps;

export type Props = BaseProps & InteractionProps;

export const Menu: FC<Props> = ({
  className,
  width,
  isOpen,
  align,
  side,
  sideOffset = 4,
  alignOffset,
  portalContainerId,
  children,
  defaultOpen = false,
  trigger,
  onOpenChange,
  headerTitle,
  footerConfig,
  disabled,
  noMaxHeight,
  noContentPadding,
}) => {
  const [menuOpen, setMenuOpen] = useState(isOpen);

  useEffect(() => {
    setMenuOpen(isOpen);
  }, [isOpen]);

  const handleOpenChange = (open: boolean) => {
    setMenuOpen(open);
    onOpenChange?.(open);
  };

  const content = (
    <DropdownMenu.Content
      align={align}
      alignOffset={alignOffset}
      className={cx(styles.menuContent({ width }), className)}
      onInteractOutside={() => setMenuOpen(false)}
      side={side}
      sideOffset={sideOffset}
      style={{ zIndex: 999999999999 }}>
      {headerTitle && (
        <div className={styles.header}>
          <div>{headerTitle}</div>
          <Button icon="cross" type="ghost" spacing="compact" onClick={() => setMenuOpen(false)} />
        </div>
      )}
      <div
        className={cx(headerTitle ? styles.contentWithHeaderContainer : styles.contentContainer, {
          [styles.contentContainerNoMaxHeight]: noMaxHeight,
          [styles.contentContainerNoPadding]: noContentPadding,
        })}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        {children}
      </div>
      {footerConfig && (
        <div
          className={styles.footer}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}>
          {footerConfig.secondaryActionBtnText ? (
            <Button
              type="ghost"
              spacing="compact"
              text={footerConfig.secondaryActionBtnText}
              onClick={footerConfig.onSecondaryBtnClicked}
            />
          ) : (
            <div></div>
          )}
          <Button
            type="primary"
            spacing="compact"
            disabled={footerConfig.primaryBtnDisabled}
            text={footerConfig.primaryActionBtnText}
            onClick={() => {
              footerConfig.onPrimaryBtnClicked();
              setMenuOpen(false);
            }}
          />
        </div>
      )}
    </DropdownMenu.Content>
  );

  return (
    <DropdownMenu.Root defaultOpen={defaultOpen} onOpenChange={handleOpenChange} open={menuOpen}>
      {trigger ? (
        <DropdownMenu.Trigger disabled={disabled} asChild>
          {trigger}
        </DropdownMenu.Trigger>
      ) : null}
      <DropdownMenu.Portal container={document.getElementById('app-root')}>
        {content}
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

type MenuSubProps = Omit<Props, 'align' | 'side'>;

export const MenuSub: FC<MenuSubProps> = ({
  className,
  width,
  isOpen,
  sideOffset = 4,
  alignOffset,
  portalContainerId,
  children,
  defaultOpen = false,
  trigger,
  onOpenChange,
  style,
}) => {
  const content = (
    <DropdownMenu.SubContent
      alignOffset={alignOffset}
      className={cx(styles.menuContent({ width }), className)}
      sideOffset={sideOffset}>
      {children}
    </DropdownMenu.SubContent>
  );

  return (
    <DropdownMenu.Sub defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={isOpen}>
      {trigger ? (
        <DropdownMenu.SubTrigger asChild className={styles.menuItem({ style })}>
          {trigger}
        </DropdownMenu.SubTrigger>
      ) : null}
      <DropdownMenu.Portal container={document.getElementById('app-root')}>
        {content}
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
};

type MenuItemProps = {
  /**
   * When true, prevents the user from interacting with the item.
   */
  disabled?: boolean;
  /**
   * action text
   */
  text: string;
  /**
   * Event handler called when the user selects an item (via mouse or keyboard).
   * Calling event.preventDefault in this handler will prevent the dropdown menu
   * from closing when selecting that item.
   */
  onSelect?: (e: Event) => void;
  style?: 'career' | 'program' | 'data' | 'home';
  selected?: boolean;
};

type MenuActionItemProps = MenuItemProps & {
  /**
   * Optional test ID for selecting component in tests
   */
  'data-testid'?: string;
};

export const MenuActionItem: FC<MenuActionItemProps> = ({
  disabled,
  'data-testid': testId,
  onSelect,
  text,
  selected,
  style,
}) => (
  <DropdownMenu.Item
    className={styles.menuItem({ style, selected: selected ? 'selected' : undefined })}
    data-testid={testId}
    disabled={disabled}
    onSelect={onSelect}>
    <div className={styles.menuItemLabelIconStyles}>
      <div className={sprinkles({ color: 'contentPrimary' })}>{text}</div>
    </div>
  </DropdownMenu.Item>
);

type LabelProps = ClassNameType & {
  /**
   * Label Text
   */
  text: string;
};

export const MenuLabel: FC<LabelProps> = ({ className, text }) => (
  <DropdownMenu.Label className={cx(styles.menuLabel, className)}>{text}</DropdownMenu.Label>
);

export const MenuSeparator: FC<ClassNameType> = ({ className }) => (
  <DropdownMenu.Separator className={cx(styles.menuSeparator, className)} />
);
