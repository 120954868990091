/** @format */

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as styles from './index.css';
import WizardStep from '../WizardStep/WizardStep';
import SearchPage from 'components/SearchPage/SearchPage';
import { theme } from 'components/ds/theme.css';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ReduxState } from 'reducers/rootReducer';
import { ACTION } from 'actions/types';
import { fetchCareerSearch } from 'actions/careerActions';
import { Checkbox } from 'components/ds/Checkbox/Checkbox';
import { Button } from 'components/ds/Button/Button';
import {
  DEFAULT_SEARCH_PARAMS,
  LocationSearchOptions,
  SearchParams,
  createProgramFilterConfigs,
  fetchProgramSearchData,
} from 'pages/ProgramsOverviewPage';
import { fetchCareerOptions, fetchProgramSearch } from 'actions/programsActions';
import ProgramCard from 'components/HomePage/ProgramCard/ProgramCard';
import { PROGRAM_SEARCH_SORTS } from 'constants/search';
import { createBoardPrograms } from 'actions/createBoardActions';
import { DistanceRangeMiles } from 'constants/data';

type Props = {};

const BoardPrograms = ({}: Props) => {
  const dispatch = useDispatch();
  const [selectedProgramIds, setSelectedProgramIds] = useState<number[]>([]);
  const [selectedTab, setSelectedTab] = useState<'explore' | 'saved'>('explore');

  const [searchParams, setSearchParams] = useState<SearchParams>({
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [tempSearchParams, setTempSearchParams] = useState<SearchParams>(searchParams);

  const {
    currentUser,
    createBoardCareerIds,
    programs,
    totalResults,
    loadingResults,
    costMin,
    careerOptions,
    costMax,
  } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      programs: state.programsSearch.programs,
      totalResults: state.programsSearch.totalResults,
      careerOptions: state.programsSearch.careerOptions,
      costMin: state.programsSearch.costMin,
      costMax: state.programsSearch.costMax,
      loadingResults: createLoadingSelector([ACTION.PROGRAM_SEARCH], true)(state),
      createBoardCareerIds: state.createBoardReducer.boardConfig?.careerIds || [],
    }),
    shallowEqual,
  );

  const fetchProgramDataWrapper = (params: SearchParams) =>
    fetchProgramSearchData(
      params,
      () => {},
      (params) => dispatch(fetchProgramSearch({ getData: params })),
      true,
    );

  const programSearchConfig = createProgramFilterConfigs(
    searchParams,
    tempSearchParams,
    {
      ...DEFAULT_SEARCH_PARAMS,
      savedOnly: selectedTab === 'saved',
      careerOptions: createBoardCareerIds,
      selectedLocationName: currentUser.profile_data?.location_name,
      selectedStateCode: currentUser.profile_data?.state_code,
      selectedZipcode: currentUser.profile_data?.zipcode,
      locationSearchBy: 'zipcode' as LocationSearchOptions,
      locationRange: DistanceRangeMiles.WITHIN_100_MILES,
    },
    setSearchParams,
    setTempSearchParams,
    fetchProgramDataWrapper,
    careerOptions,
    costMin,
    costMax,
    loadingResults,
  );

  useEffect(() => {
    const newParams = {
      ...DEFAULT_SEARCH_PARAMS,
      savedOnly: selectedTab === 'saved',
      careerOptions: selectedTab === 'saved' ? [] : createBoardCareerIds,
      selectedLocationName: currentUser.profile_data?.location_name,
      selectedStateCode: currentUser.profile_data?.state_code,
      selectedZipcode: currentUser.profile_data?.zipcode,
      locationSearchBy: 'zipcode' as LocationSearchOptions,
      locationRange: DistanceRangeMiles.WITHIN_100_MILES,
    };
    fetchProgramDataWrapper(newParams);
    setSearchParams(newParams);
    setTempSearchParams(newParams);
  }, [selectedTab]);

  useEffect(() => {
    dispatch(fetchCareerOptions());
  }, []);

  return (
    <WizardStep
      nextDisabled={selectedProgramIds.length === 0}
      onNextClicked={() => dispatch(createBoardPrograms({ programIds: selectedProgramIds }))}>
      <div className={styles.root}>
        <div className={styles.header}>Select program(s)</div>
        <div className={styles.subHeader}>{selectedProgramIds.length} selected</div>
        <div className={styles.savedToggle}>
          <Button
            text="Explore"
            icon="explore"
            type={selectedTab === 'explore' ? 'primary' : 'ghost'}
            size="large"
            onClick={() => setSelectedTab('explore')}
          />
          <Button
            text="Saved"
            icon="bookmark"
            type={selectedTab === 'saved' ? 'primary' : 'ghost'}
            size="large"
            onClick={() => setSelectedTab('saved')}
          />
        </div>

        <div className={styles.searchContainer}>
          <SearchPage
            mini
            hideControls={selectedTab === 'saved'}
            style="program"
            searchPlaceholder="Search Programs"
            onBodyScroll={(scrollTop) => {}}
            secondaryColor={theme.colors.careersSecondaryColor}
            categoryFilters={[]}
            totalResults={totalResults}
            loadingResults={loadingResults}
            resultObjects={programs}
            resultRenderer={(program) => (
              <ProgramCard
                hideAddToBoard
                className={cx(styles.programCard, {
                  [styles.selectedCard]: selectedProgramIds.includes(program.id),
                })}
                program={program}
                key={program.id}
                location="Programs Search Page"
                rightButtons={
                  <Checkbox
                    isChecked={selectedProgramIds.includes(program.id)}
                    onChange={() => {}}
                  />
                }
                onClick={() => {
                  if (selectedProgramIds.includes(program.id)) {
                    setSelectedProgramIds(selectedProgramIds.filter((id) => id !== program.id));
                  } else {
                    setSelectedProgramIds([...selectedProgramIds, program.id]);
                  }
                }}
              />
            )}
            onSortChange={programSearchConfig.onSortChange}
            resetFilters={programSearchConfig.resetFilters}
            searchString={searchParams.searchString}
            onSearchStringUpdated={programSearchConfig.onSearchStringUpdated}
            onLoadMoreClicked={programSearchConfig.onLoadMoreClicked}
            loadMoreBtnText="Load more programs"
            sortOptions={PROGRAM_SEARCH_SORTS}
            selectedSort={searchParams.sort}
            filtersConfig={programSearchConfig.filterConfigs}
          />
        </div>
      </div>
    </WizardStep>
  );
};

export default BoardPrograms;
