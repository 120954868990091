/** @format */

import React, { FC } from 'react';

import * as RadixCheckbox from '@radix-ui/react-checkbox';
import * as styles from './index.css';
import { Icon } from 'components/ds/Icon/Icon';

export type Props = { isChecked: boolean; onChange: () => void; disabled?: boolean };

export const Checkbox: FC<Props> = ({ isChecked, onChange, disabled }) => {
  return (
    <RadixCheckbox.Root
      checked={isChecked}
      className={styles.checkbox}
      onCheckedChange={onChange}
      disabled={disabled}>
      <RadixCheckbox.Indicator>
        <Icon name="check" fontSize={18} />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
};
