/** @format */

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';

import { ACTION } from 'actions/types';
import { ProgramSearchResult } from './programsActions';
import { CareerSearchResult } from './careerActions';
import { createAction } from '@reduxjs/toolkit';

export type BoardData = {
  id: number;
  student_id: number;
  name: string;
  explanation: string;
  emoji: string;
  cover_photo_url: string;
  career_ids: number[];
  program_ids: number[];
  archived: boolean;
};

export type ProgramCompressedData = {
  id: number;
  name: string;
  provider_name: string;
};

export type CareersCompressedData = {
  id: number;
  name: string;
  description: string;
  manual_categories?: string;
  manual_category_processed: boolean;
};

type FetchBoardsResponse = {
  boards: BoardData[];
  programs: ProgramCompressedData[];
  careers: CareersCompressedData[];
};

export const {
  actionFn: fetchBoards,
  requestAction: fetchBoardsRequest,
  successAction: fetchBoardsSuccess,
  errorAction: fetchBoardsError,
} = defineAPIAction<FetchBoardsResponse>(ACTION.FETCH_BOARDS, 'boards', '', 'GET');

type FetchBoardDataResponse = {
  board: BoardData;
  programs: ProgramSearchResult[];
  careers: CareerSearchResult[];
};

export const {
  actionFn: fetchBoardData,
  requestAction: fetchBoardDataRequest,
  successAction: fetchBoardDataSuccess,
  errorAction: fetchBoardDataError,
} = defineAPIAction<FetchBoardDataResponse>(ACTION.FETCH_BOARD_DATA, 'boards', '', 'GET');

type CreateBoardArgs = {
  name?: string;
};

type CreateBoardResponse = {
  board: BoardData;
};

export const {
  actionFn: createBoard,
  requestAction: createBoardRequest,
  successAction: createBoardSuccess,
  errorAction: createBoardError,
} = defineAPIPostAction<CreateBoardArgs, CreateBoardResponse>(
  ACTION.CREATE_BOARD,
  'boards',
  '',
  'POST',
);

type SaveCareerBoardsArgs = {
  career_id: number;
  board_ids: number[];
};

type SaveCareerBoardsResponse = {
  board: BoardData;
};

export const {
  actionFn: saveCareerBoards,
  requestAction: saveCareerBoardsRequest,
  successAction: saveCareerBoardsSuccess,
  errorAction: saveCareerBoardsError,
} = defineAPIPostAction<SaveCareerBoardsArgs, SaveCareerBoardsResponse>(
  ACTION.SAVE_CAREER_BOARDS,
  'boards',
  'save_career_boards',
  'POST',
);

type SaveProgramBoardsArgs = {
  program_id: number;
  board_ids: number[];
};

type SaveProgramBoardsResponse = {
  board: BoardData;
};

export const {
  actionFn: saveProgramBoards,
  requestAction: saveProgramBoardsRequest,
  successAction: saveProgramBoardsSuccess,
  errorAction: saveProgramBoardsError,
} = defineAPIPostAction<SaveProgramBoardsArgs, SaveProgramBoardsResponse>(
  ACTION.SAVE_PROGRAM_BOARDS,
  'boards',
  'save_program_boards',
  'POST',
);

type UpdateBoardArgs = {
  board: Partial<BoardData>;
};

type UpdateBoardRepsonse = {
  board: BoardData;
};

export const {
  actionFn: updateBoard,
  requestAction: updateBoardRequest,
  successAction: updateBoardSuccess,
  errorAction: updateBoardError,
} = defineAPIPostAction<UpdateBoardArgs, UpdateBoardRepsonse>(
  ACTION.SAVE_PROGRAM_BOARDS,
  'boards',
  '',
  'PUT',
);

export const {
  actionFn: createRecommendedOnboardingBoard,
  requestAction: createRecommendedOnboardingBoardRequest,
  successAction: createRecommendedOnboardingBoardSuccess,
  errorAction: createRecommendedOnboardingBoardError,
} = defineAPIPostAction<{}, {}>(
  ACTION.CREATE_RECOMMENDED_ONBOARDING_BOARD,
  'boards',
  'create_recommended_board',
  'POST',
);

export const removeCareerFromBoard = createAction<{ boardId: number; careerId: number }>(
  ACTION.REMOVE_CAREER_FROM_BOARD,
);
export const removeProgramFromBoard = createAction<{ boardId: number; programId: number }>(
  ACTION.REMOVE_PROGRAM_FROM_BOARD,
);
