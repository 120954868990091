/** @format */
import React from 'react';

export const LOGO = (height?: number, width?: number) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${width || 100}`}
    height={`${height || 80}`}
    viewBox="0 0 100 80"
    fill="none">
    <g clipPath="url(#clip0_1157_12734)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80 17.5H30V60C30 71.0457 38.9542 80 50 80H80C91.0457 80 100 71.0457 100 60V37.5C100 26.4542 91.0457 17.5 80 17.5ZM65 57.5005C70.5228 57.5005 75 53.0232 75 47.5002C75 41.9772 70.5228 37.5 65 37.5C59.4772 37.5 55 41.9772 55 47.5002C55 53.0232 59.4772 57.5005 65 57.5005Z"
        fill="#211E1E"
      />
      <path
        d="M10 80.0004C15.5228 80.0004 20 75.5232 20 70.0002C20 64.4773 15.5228 60 10 60C4.47715 60 0 64.4773 0 70.0002C0 75.5232 4.47715 80.0004 10 80.0004Z"
        fill="#211E1E"
      />
      <path
        d="M30 17.5C30 7.83503 37.835 0 47.5 0C57.165 0 65 7.83503 65 17.5V35H30V17.5Z"
        fill="#211E1E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1157_12734">
        <rect width="100" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export enum AVATARS {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
  SIX = 'SIX',
}

export const AVATAR_1 = (height?: number, width?: number) => (
  <svg
    width={`${width || 32}`}
    height={`${height || 32}`}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2135)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1429 0H14.8571V10.1205L11.0659 0.736931L8.94666 1.59318L12.8361 11.2198L5.49442 3.87816L3.87818 5.49442L10.9148 12.5311L1.79586 8.5469L0.880734 10.6414L10.5297 14.8571H0V17.1429H10.5297L0.880738 21.3586L1.79586 23.4531L10.9148 19.469L3.87818 26.5056L5.49442 28.1218L12.8361 20.7802L8.94666 30.4069L11.0659 31.263L14.8571 21.8795V32H17.1429V21.8795L20.9341 31.263L23.0533 30.4069L19.164 20.7802L26.5056 28.1218L28.1218 26.5056L21.0851 19.469L30.2042 23.4531L31.1192 21.3586L21.4704 17.1429H32V14.8571H21.4702L31.1192 10.6414L30.2042 8.5469L21.0851 12.5311L28.1218 5.4944L26.5056 3.87816L19.164 11.2198L23.0533 1.59318L20.9341 0.736931L17.1429 10.1205V0Z"
        fill="#060A23"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2135">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AVATAR_2 = (height?: number, width?: number) => (
  <svg
    width={`${width || 32}`}
    height={`${height || 32}`}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2143)">
      <path
        d="M16 32C14.9444 32 13.9722 31.7362 13.0833 31.2083C12.2222 30.7083 11.5278 30.0278 11 29.1667C10.5 28.2778 10.25 27.3056 10.25 26.2499C10.25 24.9166 10.5556 23.8056 11.1667 22.9166C11.7778 22.0278 12.7222 20.9861 14 19.7917C14.9444 18.9306 15.4167 18.111 15.4167 17.3333V16.5834H14.6667C13.8056 16.5834 12.625 17.4445 11.125 19.1667C9.65278 20.889 7.8611 21.7499 5.75 21.7499C4.69445 21.7499 3.72222 21.5 2.83333 21C1.97222 20.4722 1.27778 19.7778 0.75 18.9166C0.25 18.0278 0 17.0555 0 16C0 14.9444 0.25 13.9861 0.75 13.125C1.27778 12.2361 1.97222 11.5417 2.83333 11.0417C3.72222 10.5139 4.69445 10.25 5.75 10.25C7.83333 10.25 9.6111 11.0972 11.0833 12.7917C12.5556 14.4861 13.75 15.3333 14.6667 15.3333H15.4167V14.6667C15.4167 13.8889 14.9444 13.0694 14 12.2083L13.0417 11.3333C12.3472 10.6944 11.7083 9.93056 11.125 9.04166C10.5417 8.12499 10.25 7.02778 10.25 5.75C10.25 4.69445 10.5 3.73611 11 2.87501C11.5278 1.98611 12.2222 1.29167 13.0833 0.791666C13.9722 0.263888 14.9444 0 16 0C17.0555 0 18.0139 0.263888 18.875 0.791666C19.7638 1.31944 20.4584 2.01389 20.9584 2.87501C21.4861 3.73611 21.7499 4.69445 21.7499 5.75C21.7499 7.83333 20.9027 9.6111 19.2083 11.0833C17.5139 12.5556 16.6667 13.75 16.6667 14.6667V15.3333H17.3333C18.2778 15.3333 19.4722 14.4861 20.9166 12.7917C22.3333 11.0972 24.111 10.25 26.2501 10.25C27.3056 10.25 28.2638 10.5139 29.125 11.0417C30.0139 11.5417 30.7083 12.2222 31.2083 13.0833C31.7362 13.9444 32 14.9167 32 16C32 17.0555 31.7362 18.0278 31.2083 18.9166C30.7083 19.7778 30.0139 20.4722 29.125 21C28.2638 21.5 27.3056 21.7499 26.2501 21.7499C24.9445 21.7499 23.8195 21.4306 22.875 20.7917C21.9584 20.1528 20.9306 19.2222 19.7917 18C18.9306 17.0555 18.111 16.5834 17.3333 16.5834H16.6667V17.3333C16.6667 18.3611 17.5139 19.5555 19.2083 20.9166C20.9027 22.2778 21.7499 24.0555 21.7499 26.2499C21.7499 27.3056 21.4861 28.2778 20.9584 29.1667C20.4584 30.0278 19.7778 30.7083 18.9166 31.2083C18.0555 31.7362 17.0834 32 16 32Z"
        fill="#060A23"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2143">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AVATAR_3 = (height?: number, width?: number) => (
  <svg
    width={`${width || 32}`}
    height={`${height || 32}`}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2155)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8365 32 32 24.8365 32 16C32 7.16344 24.8365 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8365 7.16344 32 16 32ZM16 23C19.8659 23 23 19.8659 23 16C23 12.134 19.8659 9 16 9C12.134 9 9 12.134 9 16C9 19.8659 12.134 23 16 23Z"
        fill="#060A23"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2155">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AVATAR_4 = (height?: number, width?: number) => (
  <svg
    width={`${width || 32}`}
    height={`${height || 32}`}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2239)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9961 16C7.16136 15.9979 7.72403e-07 8.83526 0 2.79754e-06L32 0C32 8.83526 24.8387 15.9979 16.0038 16C24.8387 16.0021 32 23.1648 32 32H1.39876e-06C1.39876e-06 23.1648 7.16136 16.0021 15.9961 16Z"
        fill="#060A23"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2239">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AVATAR_5 = (height?: number, width?: number) => (
  <svg
    width={`${width || 32}`}
    height={`${height || 32}`}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2157)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 12.4183 19.5818 16 24 16C19.5818 16 16 19.5818 16 24C16 19.5818 12.4183 16 8 16ZM8 16C3.58173 16 0 19.5818 0 24C0 28.4182 3.58173 32 8 32C12.4183 32 16 28.4182 16 24C16 28.4182 19.5818 32 24 32C28.4182 32 32 28.4182 32 24C32 19.5818 28.4182 16 24 16C28.4182 16 32 12.4183 32 8C32 3.58173 28.4182 0 24 0C19.5818 0 16 3.58173 16 8C16 3.58173 12.4183 0 8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16Z"
        fill="#060A23"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2157">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AVATAR_6 = (height?: number, width?: number) => (
  <svg
    width={`${width || 32}`}
    height={`${height || 32}`}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_2181)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16.1074L16 0L0 16.1074H15.7867L0 32H32L16.2133 16.1074H32Z"
        fill="#060A23"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2181">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AVATAR_BY_ID: Record<AVATARS, (height?: number, width?: number) => React.JSX.Element> =
  {
    [AVATARS.ONE]: AVATAR_1,
    [AVATARS.TWO]: AVATAR_2,
    [AVATARS.THREE]: AVATAR_3,
    [AVATARS.FOUR]: AVATAR_4,
    [AVATARS.FIVE]: AVATAR_5,
    [AVATARS.SIX]: AVATAR_6,
  };
