/** @format */

import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';

import * as styles from './style.css';
import { Button } from 'components/ds/Button/Button';
import SearchFilterSortBar from './SearchFilterSortBar';
import { SearchSortOption } from 'constants/search';
import { LoadingSpinner } from 'components/ds/LoadingSpinner/LoadingSpinner';
import { LocationSearchOptions } from 'pages/ProgramsOverviewPage';
import { DistanceRangeMiles } from 'constants/data';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Menu, MenuActionItem } from 'components/ds/Menu/Menu';
import { DropdownButton } from 'components/ds/DropdownButton/DropdownButton';
import { Icon, IconName } from 'components/ds/Icon/Icon';

type CategoryFilterConfig = {
  id: string;
  name: string;
  icon: string;
  tooltipText?: string;
};

export enum FilterType {
  INTERESTS = 'Interests',
  SALARY = 'Starting Salary',
  LEARN_TIME = 'Learn Time',
  CAREER_CLUSTER = 'Career Cluster',
  PROGRAM_COST = 'Cost',
  CREDENTIAL = 'Credential',
  ASSIGNEE = 'Assignee',
  CAREER = 'Career',
  LOCATION = 'Location',
  SCHOOLS = 'Schools',
}

export type FilterConfig = {
  type: FilterType;
  component: JSX.Element;
};

export type LocationConfigOptions = {
  selectedStateCode?: string;
  selectedLocationName?: string;
  selectedZipcode?: string;
  locationSearchBy?: LocationSearchOptions;
  locationRange?: DistanceRangeMiles;
};

type Props<T> = {
  style: 'career' | 'program';
  resultObjects: T[];
  resultRenderer: (result: T) => JSX.Element;
  secondaryColor: string;
  categoryFilters: CategoryFilterConfig[];
  onSortChange: (sort: SearchSortOption) => void;
  onLoadMoreClicked: () => void;
  loadMoreBtnText: string;
  sortOptions: SearchSortOption[];
  selectedSort: SearchSortOption;
  filtersConfig: FilterConfig[];
  totalResults: number;
  loadingResults?: boolean;
  selectedBucket?: string;
  resetFilters: () => void;
  searchString?: string;
  onSearchStringUpdated: (s?: string) => void;
  searchPlaceholder: string;
  onBodyScroll: (scrollTop: number) => void;
  cachedScrollTop?: number;
  mini?: boolean;
  hideControls?: boolean;
  hideFilterSort?: boolean;
  resultsClassName?: string;
};

const SearchPage = <T,>({
  style,
  resultObjects,
  resultRenderer,
  secondaryColor,
  categoryFilters,
  onSortChange,
  onLoadMoreClicked,
  loadMoreBtnText,
  sortOptions,
  selectedSort,
  filtersConfig,
  totalResults,
  loadingResults,
  selectedBucket,
  resetFilters,
  searchString,
  onSearchStringUpdated,
  searchPlaceholder,
  onBodyScroll,
  cachedScrollTop,
  mini,
  hideControls,
  hideFilterSort,
  resultsClassName,
}: Props<T>) => {
  const bodyRef = useRef<any>();
  const { isMobile } = useWindowDimensions();

  const [searchOpen, setSearchOpen] = useState<boolean>(!!searchString);

  const noMorePages = resultObjects.length >= totalResults;

  useEffect(() => {
    if (searchString === undefined) setSearchOpen(false);
    else setSearchOpen(true);
  }, [searchString]);

  const bucketObj = categoryFilters.find((category) => category.id === selectedBucket);

  const filterByType: Record<string, FilterConfig> = {};
  filtersConfig.forEach((filter) => (filterByType[filter.type] = filter));

  const resultsContainer = (
    <div
      className={cx(
        mini ? styles.resultsContainerMini : styles.resultsContainer,
        resultsClassName,
      )}>
      {resultObjects.length === 0 && loadingResults ? (
        <div className={styles.loadingBody}>
          <LoadingSpinner />
        </div>
      ) : null}
      {resultObjects.map(resultRenderer)}
      {!noMorePages && !loadingResults ? (
        <div className={styles.loadMoreContainer}>
          <Button type="primary" text={loadMoreBtnText} onClick={onLoadMoreClicked} />
        </div>
      ) : null}
      {resultObjects.length > 0 && loadingResults ? (
        <div className={styles.loadMoreContainer}>
          <LoadingSpinner />
        </div>
      ) : null}
      {!loadingResults && resultObjects.length === 0 ? (
        <div className={styles.calloutContainer}>
          <div className={styles.calloutHeader}>No results found</div>
          <div className={styles.calloutDescription}>Adjust your search to find careers</div>
          <Button type="primary" text="Reset Filters" onClick={resetFilters} />
        </div>
      ) : null}
    </div>
  );

  if (mini) {
    return (
      <div className={styles.miniSearchPage}>
        {hideControls ? null : (
          <div className={styles.filterMiniBar}>
            {hideFilterSort ? null : (
              <Menu
                noMaxHeight
                trigger={<DropdownButton icon="filters" hideDownCaret />}
                side="left"
                align="start">
                <div className={styles.filterMiniBarPopoverBody}>
                  {Object.keys(filterByType).map((filterType) => {
                    return (
                      <div
                        className={styles.filterModalSection}
                        key={`modal-popover-filter-search-${filterType}`}>
                        <div className={styles.filterSectionName}>
                          {filterByType[filterType].type}
                        </div>
                        <div className={styles.filterSectionContent}>
                          {filterByType[filterType].component}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Menu>
            )}
            <input
              className={styles.searchInputMini}
              placeholder={searchPlaceholder}
              onChange={(e) => onSearchStringUpdated(e.target.value)}
            />
            {hideFilterSort ? null : (
              <Menu
                align="end"
                side="bottom"
                trigger={
                  <DropdownButton hideDownCaret className={styles.sortButtonMini} text="Sort By" />
                }>
                {sortOptions.map((option) => (
                  <MenuActionItem
                    style={style}
                    selected={selectedSort === option}
                    text={option}
                    key={option}
                    onSelect={() => onSortChange(option)}
                  />
                ))}
              </Menu>
            )}
          </div>
        )}
        <div className={styles.numResultsTextMini}>
          {loadingResults ? '-' : totalResults} {style}
          {totalResults !== 1 ? 's' : ''}
        </div>
        {resultsContainer}
      </div>
    );
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.filtersContainer} style={{ backgroundColor: secondaryColor }}>
        <SearchFilterSortBar filtersConfig={filtersConfig} />
      </div>
      <div
        ref={bodyRef}
        onScroll={() => {
          onBodyScroll(bodyRef.current.scrollTop);
        }}
        className={styles.bodyContainer}
        style={{ backgroundColor: secondaryColor }}>
        <div className={styles.headerContainer}>
          <div>
            <div className={styles.headerText}>
              {bucketObj ? (
                <>
                  <Icon name={bucketObj.icon as IconName} /> {bucketObj.name}
                </>
              ) : (
                searchPlaceholder
              )}
            </div>
            {bucketObj ? <div className={styles.subHeaderText}>{bucketObj.tooltipText}</div> : null}
          </div>
          <div className={styles.headerRightSide}>
            <div className={styles.numResultsText}>
              {loadingResults ? '-' : totalResults} {style}
              {totalResults !== 1 ? 's' : ''}
            </div>
            <Menu
              align="end"
              side="bottom"
              trigger={
                <DropdownButton text={isMobile ? `${selectedSort}` : `Sort By: ${selectedSort}`} />
              }>
              {sortOptions.map((option) => (
                <MenuActionItem
                  style={style}
                  selected={selectedSort === option}
                  text={option}
                  key={option}
                  onSelect={() => onSortChange(option)}
                />
              ))}
            </Menu>
          </div>
        </div>
        {resultsContainer}
      </div>
    </div>
  );
};

export default SearchPage;
