/** @format */

import React, { useState } from 'react';
import cx from 'classnames';

import * as styles from './InterestCard.css';
import {
  InterestData,
  InterestReactions,
  updateStudentInterestReaction,
} from 'actions/interestActions';
import { Popover } from 'components/ds/Popover/Popover';
import {
  InterestPopover,
  REACT_TO_BUTTON_CONFIG,
} from 'components/ds/InterestButton/InterestButton';
import { Button } from 'components/ds/Button/Button';
import { useDispatch } from 'react-redux';
import { ANALYTIC_EVENTS, track } from 'analytics';

type Props = {
  className?: string;
  interest: InterestData;
};

const InterestCard = ({ className, interest }: Props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (isOpen) {
    return (
      <div className={cx(styles.rootOpen, className)}>
        <div
          className={styles.openBody}
          onClick={() => {
            setIsOpen(false);
            track(ANALYTIC_EVENTS.HOME_PAGE_INTEREST_CARD_OPEN_TOGGLE, {
              is_oppen: false,
            });
          }}>
          <div className={styles.openTitle}>{interest.name}</div>
          <div className={styles.openDescription}>{interest.description}</div>
        </div>
        <div className={styles.openFooter}>
          <Popover
            compact
            align="start"
            side="top"
            trigger={
              <Button
                icon={REACT_TO_BUTTON_CONFIG[interest.reaction as InterestReactions].rightIcon}
                type="ghost"
              />
            }
            onOpenChange={(open) => {
              if (open) {
                track(ANALYTIC_EVENTS.CAREER_PAGE_INTEREST_REACTION_OPENED, {
                  interest: interest.name,
                  location: 'Home Page',
                });
              }
            }}>
            <InterestPopover
              selectedReaction={interest.reaction}
              onInterestSelected={(reaction) => {
                dispatch(
                  updateStudentInterestReaction({
                    id: interest.id,
                    postData: { reaction },
                  }),
                );
                track(ANALYTIC_EVENTS.CAREER_PAGE_INTEREST_REACTION_UPDATED, {
                  interest: interest.name,
                  reaction: reaction,
                  location: 'Home Page',
                });
              }}
            />
          </Popover>
          <Button type="ghost" to={`/careers?interests=${interest.id}`} text="View careers" />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={cx(styles.rootClosed, className)}
        onClick={() => {
          setIsOpen(true);
          track(ANALYTIC_EVENTS.HOME_PAGE_INTEREST_CARD_OPEN_TOGGLE, {
            is_oppen: true,
          });
        }}>
        {interest.name}
      </div>
    );
  }
};

export default InterestCard;
