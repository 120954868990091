/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as styles from './index.css';
import WizardStep from '../WizardStep/WizardStep';
import { createBoardEmoji } from 'actions/createBoardActions';
import EmojiPicker from 'components/ds/EmojiPicker/EmojiPicker';
import { ReduxState } from 'reducers/rootReducer';

type Props = {};

const BoardEmoji = ({}: Props) => {
  const dispatch = useDispatch();
  const [emoji, setEmoji] = useState<string>('');

  const { createBoardEmojiSet } = useSelector(
    (state: ReduxState) => ({
      createBoardEmojiSet: state.createBoardReducer.boardConfig?.emoji || '',
    }),
    shallowEqual,
  );

  useEffect(() => {
    setEmoji(createBoardEmojiSet);
  }, []);

  return (
    <WizardStep
      nextDisabled={!emoji}
      onNextClicked={() => {
        dispatch(createBoardEmoji({ emoji }));
      }}>
      <div className={styles.root}>
        <div className={styles.header}>Select an emoji</div>
        <div className={styles.subHeader}>:)</div>

        <EmojiPicker
          onSelectedEmoji={setEmoji}
          buttonProps={{
            className: styles.emojiSelector,
            icon: emoji ? undefined : 'add-reaction',
            text: emoji ? emoji : undefined,
            isIconOnly: true,
            size: 'large',
            type: 'outline',
          }}
        />
      </div>
    </WizardStep>
  );
};

export default BoardEmoji;
