/** @format */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as styles from 'components/SearchPage/style.css';
import SearchPage, { FilterType, LocationConfigOptions } from 'components/SearchPage/SearchPage';
import { ReduxState } from 'reducers/rootReducer';
import {
  cacheProgramSearch,
  cacheProgramSearchScrollTop,
  fetchCareerOptions,
  fetchProgramSearch,
  updateProgramCacheUrlParams,
} from 'actions/programsActions';
import { PROGRAM_SEARCH_SORTS, SearchSortOption } from 'constants/search';
import { createLoadingSelector } from 'reducers/loadingReducer';
import { ACTION } from 'actions/types';
import SalaryFilter from 'components/SearchPage/CareerFilters/SalaryFilter';
import LearnTimeFilter from 'components/SearchPage/CareerFilters/LearnTimeFilter';
import DegreeTypeFilter from 'components/SearchPage/CareerFilters/DegreeTypeFilter';
import CareerFilter from 'components/SearchPage/CareerFilters/CareerFilter';
import { theme } from 'components/ds/theme.css';
import { debounce } from 'lodash';
import { DistanceRangeMiles, LAT_LONG_BY_REGION } from 'constants/data';
import { User } from 'auth/types';
import { ANALYTIC_EVENTS, ANALYTIC_PAGES, page, track } from 'analytics';
import LocationFilter from 'components/SearchPage/ProgramFilters/LocationFilter';
import ProgramCard from 'components/HomePage/ProgramCard/ProgramCard';
import { CareerSearchResult } from 'actions/careerActions';

export const CATEGORY_FILTERS = [
  // { name: 'Nearby', icon: 'location-pin', tooltipText: 'Programs within 50 miles of you.' },
  {
    id: 'In-state Tuition',
    name: 'In-state Tuition',
    icon: 'thumbs-up',
    tooltipText: 'Programs in your state that you pay less for because they offer in-state tuition',
  },
  {
    id: 'Earn While You Learn',
    name: 'Earn While You Learn',
    icon: 'money',
    tooltipText:
      'Programs that pay you during training. Often Apprenticeships, Part-time, and Military.',
  },
  {
    id: 'No Admission Test',
    name: 'No Admission Test',
    icon: 'no-test',
    tooltipText: 'Programs that admit 100% of students.',
  },
  {
    id: 'Selective',
    name: 'Selective',
    icon: 'selective',
    tooltipText: 'Programs with a less than 30% acceptance rate',
  },
  {
    id: 'Big Student Body',
    name: 'Big Student Body',
    icon: 'students',
    tooltipText: 'Programs with more than 20,000 students.',
  },
  {
    id: 'Small Student Body',
    name: 'Small Student Body',
    icon: 'person',
    tooltipText: 'Programs with less than 5,000 students',
  },
  // {
  //   name: 'Military',
  //   icon: 'military',
  //   tooltipText: 'Programs offered by the United States Military.',
  // },
  { id: 'Online', name: 'Online', icon: 'computer', tooltipText: 'Programs that are full online.' },
  {
    id: 'Community College',
    name: 'Community College',
    icon: 'community-college',
    tooltipText: 'Programs offered by Community Colleges.',
  },
  {
    id: 'Private University',
    name: 'Private University',
    icon: 'private-university',
    tooltipText: 'Programs offered by 4-year Private Universities.',
  },
  {
    id: 'Public University',
    name: 'Public University',
    icon: 'college',
    tooltipText: 'Programs offered by 4-year Public Universities.',
  },
  {
    id: 'City Life',
    name: 'City Life',
    icon: 'locale',
    tooltipText: 'Programs in cities with a population of more than 100,000',
  },
  { id: 'Rural', name: 'Rural', icon: 'rural', tooltipText: 'Programs in rural areas.' },
];

export type LocationSearchOptions = 'region' | 'state' | 'zipcode';

export type SearchParams = {
  page: number;
  sort: SearchSortOption;
  costMax?: number;
  costMin?: number;
  learnTimeOptions?: number[];
  degreeTypeOptions?: number[];
  careerClusterOptions?: string[];
  careerOptions?: number[];
  savedOnly?: boolean;
  searchString?: string;
  selectedCategory?: string;
} & LocationConfigOptions;

export const DEFAULT_SEARCH_PARAMS: SearchParams = {
  page: 0,
  sort: SearchSortOption.DISTANCE_FROM_YOU_LH,
  locationSearchBy: 'state',
};

type Props = {
  currentUser: User;
};

export const fetchProgramSearchData = (
  params: SearchParams,
  onUpdateCache: (urlParams: string) => void,
  onFetchResults: (params: Record<string, string>) => void,
  dontUpdateUrl?: boolean,
) => {
  const getData: Record<string, string> = {
    page: params.page.toString(),
    sort: params.sort,
    results_per_page: '10',
  };

  if (params.searchString !== undefined) getData['search_string'] = params.searchString;
  if (params.selectedCategory !== undefined) getData['category'] = params.selectedCategory;
  if (params.savedOnly !== undefined) getData['saved'] = params.savedOnly.toString();
  if (params.costMax !== undefined) getData['cost_max'] = params.costMax.toString();
  if (params.costMin !== undefined) getData['cost_min'] = params.costMin.toString();
  if (params.learnTimeOptions !== undefined && params.learnTimeOptions.length > 0)
    getData['learn_time'] = params.learnTimeOptions.toString();
  if (params.degreeTypeOptions !== undefined && params.degreeTypeOptions.length > 0)
    getData['degree_types'] = params.degreeTypeOptions.toString();
  if (params.careerClusterOptions !== undefined && params.careerClusterOptions.length > 0)
    getData['career_clusters'] = params.careerClusterOptions.join('|');
  if (params.careerOptions !== undefined && params.careerOptions.length > 0)
    getData['career_options'] = params.careerOptions.toString();

  // location options
  if (params.locationSearchBy !== undefined) getData['location_type'] = params.locationSearchBy;
  if (params.locationRange !== undefined) getData['location_range'] = params.locationRange;
  if (params.selectedZipcode !== undefined) getData['zipcode'] = params.selectedZipcode;
  if (params.selectedStateCode !== undefined) getData['state_code'] = params.selectedStateCode;
  if (params.selectedLocationName !== undefined) {
    getData['location_name'] = params.selectedLocationName;
    if (LAT_LONG_BY_REGION[params.selectedLocationName]) {
      getData['lat'] = LAT_LONG_BY_REGION[params.selectedLocationName].lat.toString();
      getData['long'] = LAT_LONG_BY_REGION[params.selectedLocationName].long.toString();
    }
  }

  if (!dontUpdateUrl) {
    const urlParams = new URLSearchParams(getData).toString();
    const newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      urlParams;
    window.history.pushState({ path: newUrl }, '', newUrl);
    onUpdateCache(urlParams);
  }

  onFetchResults(getData);
};

export const createProgramFilterConfigs = (
  searchParams: SearchParams,
  tempSearchParams: SearchParams,
  defaultSearchParams: SearchParams,
  setSearchParams: (params: SearchParams) => void,
  setTempSearchParams: (params: SearchParams) => void,
  fetchData: (params: SearchParams) => void,
  careerOptions: {
    saved: CareerSearchResult[];
    unsaved: CareerSearchResult[];
  },
  costMin: number,
  costMax: number,
  loadingResults?: boolean,
  excludeCareerFilter?: boolean,
) => {
  const applyFilters = (searchParams: SearchParams) => {
    const newSearchParam = {
      ...searchParams,
      page: 0,
    };
    setSearchParams(newSearchParam);
    fetchData(newSearchParam);
    setTempSearchParams(newSearchParam);

    track(ANALYTIC_EVENTS.PROGRAM_SEARCH_FILTERS_APPLIED, newSearchParam);
  };

  const allConfigs = [
    {
      type: FilterType.SCHOOLS,
      component: (
        <LocationFilter
          onApplyFilters={() => {
            const newSearchParam = {
              ...tempSearchParams,
              page: 0,
            };
            setSearchParams(newSearchParam);
            fetchData(newSearchParam);
            setTempSearchParams(newSearchParam);

            track(ANALYTIC_EVENTS.PROGRAM_SEARCH_FILTERS_APPLIED, newSearchParam);
          }}
          locationConfig={{
            selectedStateCode: tempSearchParams.selectedStateCode,
            selectedLocationName: tempSearchParams.selectedLocationName,
            locationSearchBy: tempSearchParams.locationSearchBy,
            locationRange: tempSearchParams.locationRange,
            selectedZipcode: tempSearchParams.selectedZipcode,
          }}
          applyRequired={
            tempSearchParams.selectedStateCode !== searchParams.selectedStateCode ||
            tempSearchParams.selectedLocationName !== searchParams.selectedLocationName ||
            tempSearchParams.locationSearchBy !== searchParams.locationSearchBy ||
            tempSearchParams.locationRange !== searchParams.locationRange ||
            tempSearchParams.selectedZipcode !== searchParams.selectedZipcode
          }
          onLocationConfigUpdated={(newLocation) => {
            const newSearchParam = {
              ...tempSearchParams,
              ...newLocation,
            };
            setTempSearchParams(newSearchParam);

            track(ANALYTIC_EVENTS.PROGRAM_SEARCH_LOCATION_UPDATED, newSearchParam);
          }}
        />
      ),
    },

    {
      type: FilterType.CAREER,
      component: (
        <CareerFilter
          savedCareers={careerOptions.saved}
          unsavedCareers={careerOptions.unsaved}
          selectedOptions={tempSearchParams.careerOptions}
          updateSelectedOptions={(option, isSelected) => {
            let newOptions;
            if (isSelected) {
              newOptions = [...(tempSearchParams.careerOptions || []), option];
            } else {
              newOptions = (tempSearchParams.careerOptions || []).filter(
                (selectedOption) => selectedOption !== option,
              );
            }
            const newSearchParam = {
              ...tempSearchParams,
              careerOptions: [...newOptions],
            };
            setTempSearchParams(newSearchParam);
            applyFilters(newSearchParam as SearchParams);
          }}
          disabled={loadingResults}
        />
      ),
    },
    {
      type: FilterType.PROGRAM_COST,
      component: (
        <SalaryFilter
          compMax={costMax}
          compMin={costMin}
          setMax={tempSearchParams.costMax}
          setMin={tempSearchParams.costMin}
          updateMinMax={(min, max, applyFilter) => {
            const newSearchParam = {
              ...tempSearchParams,
              costMax: max,
              costMin: min,
            };
            setTempSearchParams(newSearchParam);
            applyFilter && applyFilters(newSearchParam as SearchParams);
          }}
          disabled={loadingResults}
        />
      ),
    },
    {
      type: FilterType.LEARN_TIME,
      component: (
        <LearnTimeFilter
          selectedOptions={tempSearchParams.learnTimeOptions}
          updateSelectedOptions={(option, isSelected) => {
            let newOptions;
            if (isSelected) {
              newOptions = [...(tempSearchParams.learnTimeOptions || []), option];
            } else {
              newOptions = (tempSearchParams.learnTimeOptions || []).filter(
                (selectedOption) => selectedOption !== option,
              );
            }
            const newSearchParam = {
              ...tempSearchParams,
              learnTimeOptions: [...newOptions],
            };
            setTempSearchParams(newSearchParam);
            applyFilters(newSearchParam as SearchParams);
          }}
          disabled={loadingResults}
        />
      ),
    },
    {
      type: FilterType.CREDENTIAL,
      component: (
        <DegreeTypeFilter
          selectedOptions={tempSearchParams.degreeTypeOptions}
          updateSelectedOptions={(option, isSelected) => {
            let newOptions;
            if (isSelected) {
              newOptions = [...(tempSearchParams.degreeTypeOptions || []), option];
            } else {
              newOptions = (tempSearchParams.degreeTypeOptions || []).filter(
                (selectedOption) => selectedOption !== option,
              );
            }
            const newSearchParam = {
              ...tempSearchParams,
              degreeTypeOptions: [...newOptions],
            };
            setTempSearchParams(newSearchParam);
            applyFilters(newSearchParam as SearchParams);
          }}
          disabled={loadingResults}
        />
      ),
    },
  ];

  const usedConfigs = allConfigs.filter((config) =>
    excludeCareerFilter ? config.type !== FilterType.CAREER : true,
  );

  return {
    onSearchStringUpdated: debounce((s?: string) => {
      if (!searchParams.searchString && !s) return;

      const newSearchParam = {
        ...searchParams,
        page: 0,
        searchString: s,
      };
      fetchData(newSearchParam);
      setSearchParams(newSearchParam);
      setTempSearchParams(newSearchParam);

      track(ANALYTIC_EVENTS.PROGRAM_SEARCH_SEARCH, newSearchParam);
    }, 500),
    resetFilters: () => {
      fetchData(defaultSearchParams);
      setSearchParams(defaultSearchParams);
      setTempSearchParams(defaultSearchParams);

      track(ANALYTIC_EVENTS.PROGRAM_SEARCH_RESET_FILTERS);
    },
    onLoadMoreClicked: () => {
      const newSearchParam = {
        ...searchParams,
        page: searchParams.page + 1,
      };
      fetchData(newSearchParam);
      setTempSearchParams(newSearchParam);
      setSearchParams(newSearchParam);

      track(ANALYTIC_EVENTS.PROGRAM_SEARCH_LOAD_MORE_CLICKED, newSearchParam);
    },
    onSortChange: (sort: SearchSortOption) => {
      const newSearchParam = {
        ...searchParams,
        page: 0,
        sort,
      };
      fetchData(newSearchParam);
      setTempSearchParams(newSearchParam);
      setSearchParams(newSearchParam);

      track(ANALYTIC_EVENTS.PROGRAM_SEARCH_SORT_UPDATED, newSearchParam);
    },
    filterConfigs: usedConfigs,
  };
};

const ProgramsOverviewPage = ({ currentUser }: Props) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [tempSearchParams, setTempSearchParams] = useState<SearchParams>(searchParams);

  const {
    cachedState,
    cachedScrollTop,
    programs,
    totalResults,
    loadingResults,
    costMin,
    careerOptions,
    costMax,
    providers,
  } = useSelector(
    (state: ReduxState) => ({
      cachedState: state.programsSearch.cachedState,
      cachedScrollTop: state.programsSearch.cachedScrollTop,
      programs: state.programsSearch.programs,
      totalResults: state.programsSearch.totalResults,
      careerOptions: state.programsSearch.careerOptions,
      costMin: state.programsSearch.costMin,
      costMax: state.programsSearch.costMax,
      providers: state.programsSearch.providers,
      loadingResults: createLoadingSelector([ACTION.PROGRAM_SEARCH], true)(state),
    }),
    shallowEqual,
  );

  const fetchData = (params: SearchParams, dontUpdateUrl?: boolean) => {
    fetchProgramSearchData(
      params,
      (urlParams) => dispatch(updateProgramCacheUrlParams({ urlParams })),
      (params) => dispatch(fetchProgramSearch({ getData: params })),
      dontUpdateUrl,
    );
  };
  useEffect(() => {
    dispatch(fetchCareerOptions());

    let params: SearchParams = {
      ...DEFAULT_SEARCH_PARAMS,
      selectedLocationName: currentUser.profile_data?.location_name,
      selectedStateCode: currentUser.profile_data?.state_code,
      selectedZipcode: currentUser.profile_data?.zipcode,
      locationSearchBy: 'zipcode' as LocationSearchOptions,
      locationRange: DistanceRangeMiles.WITHIN_100_MILES,
    };

    const defaultFilters: FilterType[] = [];

    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const queryParams = Object.fromEntries(urlParams.entries());

      params.savedOnly = queryParams.saved === 'true';
      params.selectedCategory = queryParams.category;
      params.searchString = queryParams.search_string;
      params.costMax = queryParams.cost_max ? parseInt(queryParams.cost_max) : undefined;
      params.costMin = queryParams.cost_min ? parseInt(queryParams.cost_min) : undefined;
      if (queryParams.sort) params.sort = queryParams.sort as SearchSortOption;
      if (queryParams.location_type)
        params.locationSearchBy = queryParams.location_type as LocationSearchOptions;
      if (queryParams.location_range)
        params.locationRange = queryParams.location_range as DistanceRangeMiles;
      if (queryParams.state_code) params.selectedStateCode = queryParams.state_code;
      if (queryParams.location_name) params.selectedLocationName = queryParams.location_name;
      if (queryParams.zipcode) params.selectedZipcode = queryParams.zipcode;

      if (queryParams.learn_time) {
        defaultFilters.push(FilterType.LEARN_TIME);
        params.learnTimeOptions = queryParams.learn_time.split(',').map((s) => parseInt(s));
      }
      if (queryParams.degree_types) {
        defaultFilters.push(FilterType.CREDENTIAL);
        params.degreeTypeOptions = queryParams.degree_types.split(',').map((s) => parseInt(s));
      }
      if (queryParams.career_clusters) {
        defaultFilters.push(FilterType.CAREER_CLUSTER);
        params.careerClusterOptions = queryParams.career_clusters.split('|');
      }
      if (queryParams.career_options) {
        defaultFilters.push(FilterType.CAREER);
        params.careerOptions = queryParams.career_options.split(',').map((s) => parseInt(s));
        params.locationSearchBy = undefined;
      }

      if (queryParams.cost_max || queryParams.cost_min) {
        defaultFilters.push(FilterType.PROGRAM_COST);
      }
    }

    setSearchParams(params);
    setTempSearchParams(params);

    // if (cachedState) {
    //   dispatch(useProgramSearchCache());
    // } else {
    fetchData(params, true);
    // }

    page(ANALYTIC_PAGES.PROGRAM_SEARCH_PAGE);

    return () => {
      dispatch(cacheProgramSearch());
    };
  }, [dispatch]);

  const programSearchConfig = createProgramFilterConfigs(
    searchParams,
    tempSearchParams,
    { ...DEFAULT_SEARCH_PARAMS, selectedCategory: searchParams.selectedCategory },
    setSearchParams,
    setTempSearchParams,
    fetchData,
    careerOptions,
    costMin,
    costMax,
    loadingResults,
  );

  return (
    <SearchPage
      style="program"
      searchPlaceholder="Search Programs"
      cachedScrollTop={cachedScrollTop}
      onBodyScroll={(scrollTop) => {
        dispatch(cacheProgramSearchScrollTop({ scrollTop }));
      }}
      secondaryColor={theme.colors.programsSecondaryColor}
      categoryFilters={CATEGORY_FILTERS}
      totalResults={totalResults}
      loadingResults={loadingResults}
      resultObjects={programs}
      resultRenderer={(program) => (
        <ProgramCard
          className={styles.programCard}
          program={program}
          key={program.id}
          location="Programs Search Page"
        />
      )}
      onSortChange={programSearchConfig.onSortChange}
      resetFilters={programSearchConfig.resetFilters}
      searchString={searchParams.searchString}
      onSearchStringUpdated={programSearchConfig.onSearchStringUpdated}
      selectedBucket={searchParams.selectedCategory}
      onLoadMoreClicked={programSearchConfig.onLoadMoreClicked}
      loadMoreBtnText="Load more programs"
      sortOptions={PROGRAM_SEARCH_SORTS}
      selectedSort={searchParams.sort}
      filtersConfig={programSearchConfig.filterConfigs}
    />
  );
};

export default ProgramsOverviewPage;
