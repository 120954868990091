/** @format */

import { createAction } from '@reduxjs/toolkit';

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';

import { ACTION } from 'actions/types';
import { CareerSearchResult } from './careerActions';

export type UpdateProgramCacheUrlParamsArgs = {
  urlParams: string;
};

export type CacheProgramSearchScrollTopArgs = {
  scrollTop: number;
};

export const cacheProgramSearch = createAction(ACTION.PROGRAM_SEARCH_CACHE);
export const updateProgramCacheUrlParams = createAction<UpdateProgramCacheUrlParamsArgs>(
  ACTION.UPDATE_PROGRAM_CACHE_URL_PARAMS,
);
export const useProgramSearchCache = createAction(ACTION.USE_PROGRAM_SEARCH_CACHE);
export const cacheProgramSearchScrollTop = createAction<CacheProgramSearchScrollTopArgs>(
  ACTION.CACHE_PROGRAM_SEARCH_SCROLL_TOP,
);

export type ProviderBasicInfo = {
  id: number;
  name: string;
};

export type ProgramSearchResult = {
  id: number;
  name: string;

  provider_name: string | null;
  provider_city: string | null;
  provider_state: string | null;
  provider_website_url: string | null;

  degree_type: string | null;

  cost_score: number | null;

  program_length_min: number | null;
  program_length_max: number | null;
  cost_per_year_instate: number | null;
  cost_per_year_outstate: number | null;
  student_cost_per_year: number | null;
  is_saved: boolean;

  yearly_earning?: number;

  board_ids: number[];
  career_ids: number[];

  search_name_highlighted?: string;
  search_provider_name_highlighted?: string;
};

type ProgramSearchResponse = {
  programs: ProgramSearchResult[];
  total_results: number;
  cost_max: number;
  cost_min: number;
  providers: ProviderBasicInfo[];
};

export const {
  actionFn: fetchProgramSearch,
  requestAction: fetchProgramSearchRequest,
  successAction: fetchProgramSearchSuccess,
  errorAction: fetchProgramSearchError,
} = defineAPIAction<ProgramSearchResponse>(
  ACTION.PROGRAM_SEARCH,
  'learning_program',
  'search',
  'GET',
);

type CareerOptionsResponse = {
  careers: CareerSearchResult[];
};

export const {
  actionFn: fetchCareerOptions,
  requestAction: fetchCareerOptionsRequest,
  successAction: fetchCareerOptionsSuccess,
  errorAction: fetchCareerOptionsError,
} = defineAPIAction<CareerOptionsResponse>(
  ACTION.FETCH_CAREER_OPTIONS,
  'learning_program',
  'career_options',
  'GET',
);

export type ProviderInfo = {
  num_students: number | null;
  website_url: string | null;
  local: string | null;
  admit_rate: number | null;
  latitude: number | null;
  longitude: number | null;
};

export type ProgramInfoResult = {
  id: number;
  name: string;
  description?: string;
  website_url?: string;

  provider_name: string | null;
  provider_city: string | null;
  provider_state: string | null;

  provider_info: ProviderInfo;

  degree_type: string | null;

  program_length_min: number | null;
  program_length_max: number | null;
  cost_per_year_instate: number | null;
  cost_per_year_outstate: number | null;
  student_cost_per_year: number | null;
  total_cost_instate: number | null;
  total_cost_outstate: number | null;

  careers: CareerSearchResult[];
  related_programs: ProgramSearchResult[];
  num_related_programs: number;

  is_saved?: boolean;
  board_ids: number[];
};

type ProgramInfoResponse = {
  program: ProgramInfoResult;
};

export const {
  actionFn: fetchProgramInfo,
  requestAction: fetchProgramInfoRequest,
  successAction: fetchProgramInfoSuccess,
  errorAction: fetchProgramInfoError,
} = defineAPIAction<ProgramInfoResponse>(ACTION.PROGRAM_INFO, 'learning_program', 'info', 'GET');

type UpdateSaveProgramArgs = {
  isSaved: boolean;
};

export const {
  actionFn: updateSaveProgram,
  requestAction: updateSaveProgramRequest,
  successAction: updateSaveProgramSuccess,
  errorAction: updateSaveProgramError,
} = defineAPIPostAction<UpdateSaveProgramArgs, {}>(
  ACTION.UPDATE_STUDENT_SAVE_PROGRAM,
  'learning_program',
  'save_program',
  'POST',
);
